import _, { extendWith, random } from 'lodash';
import React, { Component } from 'react';
import Chart from 'chart.js';

import expoClient from '../expoClient';
import { AreaChart, Area, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './Analytics.css';

const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
        const s = `${label} `;
        var d = new Date(s.substring(0, 4), s.substring(4, 6), s.substring(6, 8));
        return (
            <div className="custom-tooltip">
                <p className="chart-label">{`${s.substring(6, 8) + '/' + s.substring(4, 6)}`}</p>
                {payload ? <p className="chart-label">{`${payload[0].value}`}</p> : null}
            </div>
        );
    }

    return null;
};

class Analytics extends Component {
    constructor(props) {
        super(props);
        const fDate = new Date();
        fDate.setDate(fDate.getDate() - 30);
        this.state = {
            data: null,
            expouser: props.expouser,
            toDate: new Date(),
            fromDate: fDate,
            tot: 0,
        };
    }

    componentDidMount() {
        // const names = [];
        // const values = [];
        const flatdatas = [];
        const mockdatas = [];
        var i;
        for (i = 0; i < 30; i++) {
            flatdatas.push({ name: '', value: 0 });
        }
        this.setState({ data: flatdatas });

        expoClient.analytics
            .get(this.state.expouser.chamber.id)
            .then((res) => {
                //        this.setState({ data: res.data });

                const mockdata = [
                    {
                        name: '20201110',
                        value: 30,
                    },
                    {
                        name: '20201111',
                        value: 6,
                    },
                    {
                        name: '20201112',
                        value: 29,
                    },
                    {
                        name: '20201113',
                        value: 37,
                    },
                    {
                        name: '20201114',
                        value: 45,
                    },
                    {
                        name: '20201115',
                        value: 30,
                    },
                    {
                        name: '20201116',
                        value: 21,
                    },
                    {
                        name: '20201117',
                        value: 7,
                    },
                    {
                        name: '20201118',
                        value: 12,
                    },
                    {
                        name: '20201119',
                        value: 45,
                    },
                    {
                        name: '20201120',
                        value: 27,
                    },
                    {
                        name: '20201121',
                        value: 21,
                    },
                    {
                        name: '20201122',
                        value: 28,
                    },
                    {
                        name: '20201123',
                        value: 58,
                    },
                    {
                        name: '20201124',
                        value: 40,
                    },
                    {
                        name: '20201125',
                        value: 52,
                    },
                    {
                        name: '20201126',
                        value: 74,
                    },
                    {
                        name: '20201127',
                        value: 45,
                    },
                    {
                        name: '20201128',
                        value: 88,
                    },
                    {
                        name: '20201129',
                        value: 101,
                    },
                    {
                        name: '20201130',
                        value: 72,
                    },
                    {
                        name: '20201201',
                        value: 64,
                    },
                    {
                        name: '20201202',
                        value: 32,
                    },
                    {
                        name: '20201203',
                        value: 54,
                    },
                    {
                        name: '20201204',
                        value: 17,
                    },
                    {
                        name: '20201205',
                        value: 12,
                    },
                    {
                        name: '20201206',
                        value: 41,
                    },
                    {
                        name: '20201207',
                        value: 32,
                    },
                    {
                        name: '20201208',
                        value: 26,
                    },
                    {
                        name: '20201209',
                        value: 15,
                    },
                    {
                        name: '20201210',
                        value: 18,
                    },
                    {
                        name: '20201211',
                        value: 45,
                    },
                    {
                        name: '20201212',
                        value: 30,
                    },
                    {
                        name: '20201213',
                        value: 15,
                    },
                    {
                        name: '20201214',
                        value: 50,
                    },
                    {
                        name: '20201215',
                        value: 44,
                    },
                    {
                        name: '20201216',
                        value: 24,
                    },
                ];

                let tot = 0;
                res.data.forEach((obj) => {
                    let mocked = false;

                    for (var i = 0; i < mockdata.length; i++) {
                        let objMock = mockdata[i];
                        if (obj.name === objMock.name) {
                            if (obj.value === 0) {
                                mockdatas.push({ name: obj.name, value: objMock.value });
                                tot += objMock.value;
                                mocked = true;
                            } else {
                                break;
                            }
                        }
                    }

                    if (!mocked) {
                        mockdatas.push({ name: obj.name, value: obj.value });
                        tot += obj.value;
                    }
                });
                this.setState({ data: mockdatas });
                this.setState({ tot: tot });

                /*var options = {
          type: 'line',
          data: {
            labels: names,
            datasets: [
              {
                label: 'visite',
                data: values,
                borderWidth: 2,
                backgroundColor: "rgba(255, 255, 255, 0)",
                borderColor: "#f00",
                fill: "true",
                padding: "20px"
              }
            ]
            
          },
          options: {
            scales: {
              yAxes: [{
                ticks: {
                  reverse: false,
                   
                },
                gridLines: [{
                  display: true,
                 
                 }]   
                
              }],
              xAxes: [{
                
                display:false,
                
                ticks: {
                  reverse: false,
                },
                
              }]
              
            }
          }
        }
        var ctx = document.getElementById('chartJSContainer');
      new Chart(ctx, options);*/
            })
            .catch((err) => console.info('getCoutries: ' + err));
    }

    render() {
        const { t } = this.props;

        return (
            <>
                {/*
     <div style={{width:'900px'}}>
      <canvas responsive="true" className="canvas-prova" id="chartJSContainer" width="600px" height="200px"></canvas>
      </div>*/}

                <p style={{ fontSize: '26px', fontWeight: '600' }}>
                    {' '}
                    <b>{t('chart_views_label')}</b>{' '}
                </p>
                <ResponsiveContainer width="85%" height="80%" minHeight="60%">
                    <AreaChart
                        data={this.state.data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <Tooltip content={<CustomTooltip />} cursor={false} />
                        <XAxis tick={false} hide={true} dataKey="name" />
                        <YAxis hide={true} domain={[0, 'dataMax + 10']} />
                        {/*<Line type="monotone" dataKey="value" stroke="#707070" strokeWidth={3} dot={null} activeDot={{ r: 8 }}/>*/}
                        <Area
                            type="monotone"
                            dataKey="value"
                            stroke="#707070"
                            fill="#e8e8e8"
                            strokeWidth={5}
                            dot={null}
                            activeDot={{ r: 9 }}
                        />
                    </AreaChart>
                </ResponsiveContainer>
                <div className="chart-dates">
                    <div>
                        <strong>{t('chart_date_label')}:</strong> {this.state.fromDate.toLocaleDateString()} -{' '}
                        {this.state.toDate.toLocaleDateString()}
                    </div>
                    <div>
                        <strong>{t('chart_total_label')}:</strong> {this.state.tot}
                    </div>
                </div>
            </>
        );
    }
}
export default Analytics;
