import React, { Component } from 'react';
import { Route, Router, Switch } from 'react-router-dom';

import NavBarUser from './components/NavBarUser';
import Signup from './components/Signup';
import history from './utils/history';
import Homepage from './components/Homepage';
import EventInsert from './components/EventInsert';
import EventPage from './components/EventPage';
import Event from './components/Event/Event';
import CompanyShowCase from './components/CompanyShowCase';
import ChamberShowCase from './components/ChamberShowCase';

import JoinEvent from './components/JoinEvent';
import ItemShowCase from './components/ItemShowCase';
import PageNotFound from './components/PageNotFound';
import SearchCompanies from './components/SearchCompanies';
import CompaniesAcceptance from './components/CompaniesAcceptance';
import Footer from './components/Footer';
import CookiePolicy from './components/CookiePolicy';
import CompaniesJoined from './components/CompaniesJoined';
import RequestB2B from './components/RequestB2B';
import CompanyRequestB2B from './components/CompanyRequestB2B';
import CompaniesSuspended from './components/CompaniesSuspended';
import CompaniesDrafted from './components/CompaniesDrafted';
import Subscription from './components/Subscription';

import B2BRouter from './components/B2B/B2BRouter';
import B2BLeave from './components/B2B/B2BLeave';
import Pricing from './components/Pricing';

class App extends Component {
    render() {
        //const { Content } = Layout;
        return (
            <div
                id="main-layout"
                style={{
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Router history={history}>
                    <NavBarUser />
                    <Switch>
                        <Route path="/" exact component={Homepage} />
                        <Route path="/signup" component={Signup} />
                        <Route path="/chamber" component={ChamberShowCase} />
                        <Route path="/eventpage" component={EventPage} />
                        <Route path="/item" component={ItemShowCase} />
                        <Route path="/company" component={CompanyShowCase} />
                        <Route path="/eventinsert" component={EventInsert} />
                        <Route path="/eventedit" component={EventInsert} />
                        <Route path="/search" component={SearchCompanies} />
                        <Route path="/companiesacceptance" component={CompaniesAcceptance} />
                        <Route path="/cookiepolicy" component={CookiePolicy} />
                        <Route path="/companiesjoined" component={CompaniesJoined} />
                        <Route path="/requestb2b" component={RequestB2B} />
                        <Route path="/companyrequestb2b" component={CompanyRequestB2B} />
                        <Route path="/companiesuspended" component={CompaniesSuspended} />
                        <Route path="/companiesdrafted" component={CompaniesDrafted} />
                        <Route path="/subscription" component={Subscription} />
                        {/*
                        // 18/02/2022 SG: nascondiamo la voce menù e la rotta pricing
                        <Route path="/pricing" component={Pricing} />
                        */}
                        {/*
                        <Route exact path="/event/:joinToken" component={JoinEvent} />
                        <Route exact path="/event" component={Event} />
                        */}
                        <Route path="/event/leave/:joinToken" component={B2BLeave} />
                        <Route path="/event/:joinToken" component={B2BRouter} />

                        {/* route temporanea per test b2b meeting */}
                        {/*
                        <Route path="/b2b/leave/:joinToken" component={B2BLeave} />
                        <Route path="/b2b/:joinToken" component={B2BRouter} />
                        */}
                        {/* Qualsiasi altra rotta è un 404 */}
                        <Route path="*" component={PageNotFound} />
                    </Switch>
                    <Footer />
                </Router>
            </div>
        );
    }
}

export default App;
