import classNames from 'classnames/bind';
import React, { useContext, useEffect, useState } from 'react';
import { DefaultModality } from 'amazon-chime-sdk-js';

import getChimeContext from '../../context/getChimeContext';
import getMeetingStatusContext from '../../context/getMeetingStatusContext';

import ViewMode from '../../enums/ViewMode';
import LocalVideo from './LocalVideo';
import RemoteVideo from './RemoteVideo';

import styles from './RemoteVideoGroup.css';

const cx = classNames.bind(styles);
const MAX_REMOTE_VIDEOS = 16;
//const MAX_LOW_CONNECTION_WARNING = 3;

export default function RemoteVideoGroup(props) {
    const { viewMode, isContentShareEnabled } = props;
    const chime = useContext(getChimeContext());

    let { b2bInfo, updateB2B } = useContext(getMeetingStatusContext());

    const [attendeeInCall, setAttendeeInCall] = useState({}); //index => {boundAttendeeId: attendeeId}
    const [visibleIndices, setVisibleIndices] = useState({}); //index => {boundAttendeeId: attendeeId}
    //const [lowConnectionWarning, setLowConnectionWarning] = useState(0);
    const [guestAttendeeId, setGuestAttendeeId] = useState(null);
    const [guestExtAttendeeId, setGuestExtAttendeeId] = useState(null);
    const [tilesAttendeeId, setTilesAttendeeId] = useState({});

    const videoElements = [];
    const tiles = {}; //index (video) => tileId
    //const tilesAttendeeId = {}; //index => attendeeId

    const acquireVideoIndex = (tileId, attendeeId) => {
        console.log('RemoteVideoGroup.acquireVideoIndex', tileId, attendeeId, tiles, tilesAttendeeId);
        if (tileId) {
            for (let index = 0; index < MAX_REMOTE_VIDEOS; index += 1) {
                if (tiles[index] === tileId) {
                    return index;
                }
            }
        }
        for (let index = 0; index < MAX_REMOTE_VIDEOS; index += 1) {
            if (tilesAttendeeId[index] === attendeeId) {
                tiles[index] = tileId;
                return index;
            }
        }

        for (let index = 0; index < MAX_REMOTE_VIDEOS; index += 1) {
            if (!(index in tiles) && !tilesAttendeeId[index]) {
                tiles[index] = tileId;
                let tA = tilesAttendeeId;
                tA[index] = attendeeId;
                setTilesAttendeeId(tA);
                return index;
            }
        }
        throw new Error('no tiles are available');
    };

    const releaseVideoIndex = (tileId, attendeeId) => {
        if (tileId) {
            for (let index = 0; index < MAX_REMOTE_VIDEOS; index += 1) {
                if (tiles[index] === tileId) {
                    delete tiles[index];
                    return index;
                }
            }
        }
        if (attendeeId) {
            for (let index = 0; index < MAX_REMOTE_VIDEOS; index += 1) {
                if (tilesAttendeeId[index] === attendeeId) {
                    delete tiles[index];
                    delete tilesAttendeeId[index];
                    return index;
                }
            }
        }
        return -1;
    };

    // Posso avere un associazione key <--> null quindi uso il metodo reduce
    const numberOfAttendees = Object.keys(attendeeInCall).reduce(
        (result, key) => result + (attendeeInCall[key] ? 1 : 0),
        0,
    );

    useEffect(() => {
        if (chime && chime.audioVideo) {
            // chime.audioVideo.addObserver({
            //     audioVideoDidStop: (sessionStatus) => {
            //         // See the "Stopping a session" section for details.
            //         console.log('Stopped with a session status code: ', sessionStatus.statusCode());
            //         onClickLeaveButton();
            //     }
            // });

            //reloadB2b('stega', 'stega');
            chime.audioVideo.realtimeSubscribeToAttendeeIdPresence((presentAttendeeId, present, externalAttendeeId) => {
                const baseAttendeeId = new DefaultModality(presentAttendeeId).base();
                if (baseAttendeeId !== presentAttendeeId) {
                    // Don't include the content attendee in the roster.
                    return;
                }

                // Se sono me stesso non devo fare nulla
                if (b2bInfo.attendee.AttendeeId === presentAttendeeId) {
                    return;
                }
                // associo al primo video disponibile l'attendeeId del nuovo arrivato in call
                if (present) {
                    console.log('RemoteVideoGroup.useEffect.attendeePresence b2bInfo', b2bInfo);
                    const userData = b2bInfo.attendees.find((attendee) => attendee.token === externalAttendeeId);
                    if (userData) {
                        const index = acquireVideoIndex(null, presentAttendeeId);
                        setAttendeeInCall((previousAttendeeInCall) => ({
                            ...previousAttendeeInCall,
                            [index]: {
                                boundAttendeeId: presentAttendeeId,
                                userData: userData,
                            },
                        }));
                    } else {
                        setGuestAttendeeId(presentAttendeeId);
                        setGuestExtAttendeeId(externalAttendeeId);
                    }
                    console.log('RemoteVideoGroup.useEffect.attendeePresence b2bInfo updated', b2bInfo);
                } else {
                    console.log(
                        'RemoteVideoGroup.realtimeSubscribeToAttendeeIdPresence is leaving',
                        externalAttendeeId,
                        presentAttendeeId,
                    );
                    //rilascio il video allocato per l'attendee
                    const index = releaseVideoIndex(null, presentAttendeeId);
                    setAttendeeInCall((previousAttendeeInCall) => ({
                        ...previousAttendeeInCall,
                        [index]: null,
                    }));
                }
            });

            chime.audioVideo.addObserver({
                videoTileDidUpdate: async (tileState) => {
                    // Se è uno share oppure se non ho tutte le info che servono non faccio nulla
                    if (tileState.isContent || !tileState.boundAttendeeId || tileState.localTile || !tileState.tileId) {
                        return;
                    }

                    // Recupero l'indice di un video disponibile e ne faccio il bind
                    await sleep(1000);
                    const index = acquireVideoIndex(tileState.tileId, tileState.boundAttendeeId);
                    if (chime.audioVideo) chime.audioVideo.bindVideoElement(tileState.tileId, videoElements[index]);
                    // Salvo l'associazione indice <--> attendeeId
                    setVisibleIndices((previousVisibleIndices) => ({
                        ...previousVisibleIndices,
                        [index]: {
                            boundAttendeeId: tileState.boundAttendeeId,
                        },
                    }));
                },
                videoTileWasRemoved: (tileId) => {
                    //Rilascio l'indice del video
                    const index = releaseVideoIndex(tileId);
                    //Rimuovo l'associazione indice <--> attendeeId
                    setVisibleIndices((previousVisibleIndices) => ({
                        ...previousVisibleIndices,
                        [index]: null,
                    }));

                    console.log('RemoteVideoGroup.reloadB2B visibleIndices', visibleIndices);
                },
                /*
                connectionDidSuggestStopVideo: () => {
                    console.warn('Connessione lenta è consigliabile spegnere la propria webcam.', lowConnectionWarning);
                    setLowConnectionWarning((prev) => prev + 1);
                },
                */
            });
        }
    }, []);

    function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    useEffect(() => {
        const reloadB2b = async (attendeeId, extAttendeeId) => {
            b2bInfo = await updateB2B();
            console.log('RemoteVideoGroup.reloadB2B info', b2bInfo);
            const index = acquireVideoIndex(null, attendeeId);
            setAttendeeInCall((previousAttendeeInCall) => ({
                ...previousAttendeeInCall,
                [index]: {
                    boundAttendeeId: attendeeId,
                    userData: b2bInfo.attendees.find((attendee) => attendee.token === extAttendeeId),
                },
            }));
            setGuestAttendeeId(null);
            setGuestExtAttendeeId(null);
        };

        if (guestAttendeeId !== null && guestExtAttendeeId !== null) reloadB2b(guestAttendeeId, guestExtAttendeeId);
    }, [guestAttendeeId, guestExtAttendeeId]);

    const buildRemoteVideoGroup = () => {
        let counter = 0;
        const rvArray = [];
        Array.from(Array(MAX_REMOTE_VIDEOS).keys()).map((key, index) => {
            //Valorizzato se camera accesa
            const visibleIndex = visibleIndices[index];
            const attendeeIndex = attendeeInCall[index];
            //Valorizzato se camera accesa almeno una volta
            const attendeeId = attendeeIndex ? attendeeIndex.boundAttendeeId : null;
            //Valorizzato se attendee entrato nella call
            const viewAvatar = (attendeeId || attendeeIndex) && !visibleIndex;
            const tileInGrid = !!visibleIndex || !!viewAvatar;
            // console.log('RemoteVideoGroup.render', visibleIndex, attendeeId, attendeeIndex, viewAvatar);
            counter = tileInGrid ? counter + 1 : counter;
            rvArray.push(
                <RemoteVideo
                    counter={tileInGrid ? counter : 'hidden'}
                    key={key}
                    viewMode={viewMode}
                    enabledAvatar={!!viewAvatar}
                    enabledVideo={!!visibleIndex}
                    videoElementRef={(element) => {
                        //console.log('RemoteVideoGroup.assignVideoElement index, element', index, element);
                        if (element) videoElements[index] = element;
                    }}
                    attendeeData={attendeeIndex ? attendeeIndex.userData : null}
                    attendeeId={attendeeId ? attendeeId : ''}
                    isContentShareEnabled={isContentShareEnabled}
                />,
            );
        });
        return rvArray;
    };

    return (
        <div
            className={cx('remoteVideoGroup', `remoteVideoGroup-${numberOfAttendees}`, {
                roomMode: viewMode === ViewMode.Room,
                screenShareMode: viewMode === ViewMode.ScreenShare,
                isContentShareEnabled,
            })}
        >
            {console.log('RemoteVideoGroup.render tiles, tilesAttendeeId', visibleIndices, attendeeInCall)}
            {
                //numberOfAttendees === 0 && (
                /*<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Row className="callLeft-panel box-center-cont">
                        <Col className="callLeft-panel-image box-center-cont" span={24}>
                            <img style={{ width: '640px' }} src={b2bInfo.image} alt="event logo" />
                        </Col>
                        <Col className="callLeft-panel-text box-center-cont" span={24}>
                            <p>{t('call empty')}</p>
                        </Col>
                    </Row>
                </div>*/
                <LocalVideo
                    classList={cx('remoteVideo', `remoteVideoGroup-0`, {
                        roomMode: viewMode === ViewMode.Room,
                        enabled: numberOfAttendees === 0,
                    })}
                    main={true}
                />
                //)
            }
            {buildRemoteVideoGroup()}
        </div>
    );
}
