import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Collapse, Divider, Row, Spin, Badge, notification, Space } from 'antd';
import { Loading3QuartersOutlined, CaretRightOutlined } from '@ant-design/icons';
import expoClient from '../expoClient';
import './RequestB2B.css';
import { ReactComponent as ArrowRequest } from '../icons/arrow1.svg';
import { ReactComponent as Pending } from '../icons/pending2.svg';
import { ReactComponent as Save } from '../icons/salva.svg';
import { ReactComponent as Url } from '../icons/link.svg';
import { ReactComponent as SuspendCompany } from '../icons/sospese.svg';
import { ReactComponent as Draft } from '../icons/draft.svg';
import { ReactComponent as NewEvent } from '../icons/new-event.svg';
import { ReactComponent as IconRequestB2B } from '../icons/requestb2b.svg';
import { ReactComponent as ApproveButton } from '../icons/check.svg';
import ConfirmModal from './ConfirmModal';
import { useAuth0 } from '../react-auth0-spa';
import expoConfig from '../config/expo_config.json';
import history from '../utils/history';
import moment from 'moment';
import IconAndLabel from './IconAndLabel';
import TopBar from './TopBar';
import { Link } from 'react-router-dom';

const RequestB2B = ({ location }) => {
    const { t } = useTranslation();
    const [requests, setRequests] = useState(null);
    const [submittingData, setSubmittingData] = useState(false);
    const { isAuthenticated, expoUser, loading } = useAuth0();
    const [chamber, setChamber] = useState();
    const { Panel } = Collapse;
    const [currentDataPage, setCurrentDataPage] = useState(-1);
    const [displayDataPage, setDisplayDataPage] = useState('none');
    const [contactRequestsNumber, setContactRequestsNumber] = useState(1);
    const [unviewedContactRequests, setUnviewedContactRequests] = useState(false);
    const [companies, setCompanies] = useState(null);
    const [pageLoading, setPageLoading] = useState(true);

    useEffect(() => {
        const init = async () => {
            const isChamber = expoUser.entityTypeId === expoConfig.domain.entity_type.chamber;
            //Per qualche motivo non ho camber in expoUser la rileggo dal DB
            if (isChamber) {
                //sono utente camera recupero l'info dall'entityid dello user
                const chamberRes = await expoClient.chambers.get(expoUser.entityId, expoUser.token);
                expoUser.chamber = chamberRes.data;
            }

            if (expoUser.chamber) {
                //expoUser ha tutte le info non serve ricavarsele
                //Dato che gli eventi sono editabili li rileggo ogni volta dal server
                setChamber(expoUser.chamber);

                const cListRes = await expoClient.chambers.getContactRequests(expoUser.chamber.id, expoUser.token);
                const requests = cListRes.data;
                console.log('RequestB2B.useEffect ', requests);

                //invio le richieste da impostare come visualizzate al server
                expoClient.chambers.requestsViewed(expoUser.chamber.id, expoUser.token);

                setRequests(requests);
                loadCompanies();
                if (!expoUser.chamber.items) {
                    const counters = await expoClient.chambers.countCompanies(expoUser.chamber.id, expoUser.token);
                    expoUser.chamber.pendingCounter = counters.data.pending;
                    expoUser.chamber.suspendedCounter = counters.data.suspended;
                    expoUser.chamber.draftedCounter = counters.data.active;
                }
                const requestCount = await expoClient.chambers.getContactRequestsCount(
                    expoUser.chamber.id,
                    expoUser.token,
                );
                setUnviewedContactRequests(requestCount.data.someRequested);
                setContactRequestsNumber(requestCount.data.num);
            }
            setPageLoading(false);
        };
        if (!loading && isAuthenticated && expoUser && requests === null) {
            init();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submittingData, isAuthenticated, expoUser, loading]);

    const loadCompanies = async () => {
        const cListRes = await expoClient.chambers.getPendingCompanies(expoUser.chamber.id, expoUser.token);
        const companies = cListRes.data;
        console.log('CompaniesAcceptance.useEffect ', companies);
        //Per via delle operazioni asyncrone sono costretto ad eseguire due volte il ciclo for
        for (const c of companies) {
            const { data } = await expoClient.chambers.getTags(expoUser.chamber.id, c.id, expoUser.token);
            c.tags = data;
        }
        for (const c of companies) {
            const { data } = await expoClient.user.getByEntityId(c.entityId, expoUser.token);
            c.user = data[0];
        }

        setCompanies(companies);
    };

    const postData = async (request) => {
        setSubmittingData(true);
        //chiamare Expoclient accept
        //se tutto ok
        const c = request;
        // const acceptRes = await expoClient.chambers.acceptCompany(chamber.id, company.id, expoUser.token);
        // if (typeof acceptRes !== 'undefined') {
        //     //console.log("Risposta rest acceptCompany", acceptRes);
        //     c.approved = acceptRes.status === 200;
        //     setSubmittingData(false);
        // }
    };
    const hide = async (request) => {
        //chiamare Expoclient accept
        //se tutto ok
        try {
            const c = request;
            const hideRes = await expoClient.chambers.requestDone(chamber.id, request.id, expoUser.token);
            if (typeof hideRes !== 'undefined') {
                //console.log("Risposta rest acceptCompany", acceptRes);
                if (hideRes.status === 200) {
                    const desc = t('request_archived_ok');
                    notification.success({
                        key: 'archived',
                        message: desc,
                        placement: 'topRight',
                        duration: 7,
                    });
                    const cListRes = await expoClient.chambers.getContactRequests(expoUser.chamber.id, expoUser.token);
                    const requests = cListRes.data;
                    console.log('RequestB2B.hide ', requests);
                    setRequests(requests);
                }
            }
        } catch (error) {
            console.error(error);
            const msg = t('error');
            let desc = t('request_archived_nok');
            notification.error({
                key: 'archivedError',
                message: msg,
                description: desc,
                placement: 'topRight',
                duration: 0,
            });
        }
    };

    const creaEvento = async (request) => {
        //chiamare Expoclient accept
        //se tutto ok
        const hideRes = await expoClient.chambers.requestProcessing(chamber.id, request.id, expoUser.token);

        let k = [request.requestingCompanyDto.entityId, request.requestedCompanyDto.entityId];
        if (typeof hideRes !== 'undefined') {
            if (hideRes.status === 200) {
                history.push('/eventinsert', {
                    companies: k,
                });
            }
        }
    };

    const normalizeHour = (dateStr) => {
        if (dateStr === null) return '';
        if (dateStr.endsWith('[UTC]')) dateStr = dateStr.substring(0, dateStr.length - 5);
        var date = new Date(dateStr);
        let normalizedDate = '';
        if (date) normalizedDate = moment(date).format('DD.MM.YY') + ' ' + moment(date).format('hh.mm a');
        return normalizedDate;
    };

    const normalizeData = (dateStr) => {
        if (dateStr === null) return '';
        if (dateStr.endsWith('[UTC]')) dateStr = dateStr.substring(0, dateStr.length - 5);
        var date = new Date(dateStr);
        let normalizedDate = '';
        if (date) normalizedDate = moment(date).format('DD.MM.YY');
        return normalizedDate;
    };

    const handleOk = (e) => {
        //console.log("handleOK", e);
        postData(e);
    };

    const transitToDataPageFromToolBar = (e) => {
        transitToDataPage(e, -1);
    };

    const transitToDataPage = (e, pageToGo) => {
        if (e) e.stopPropagation();
        if (pageToGo === currentDataPage || pageToGo < 0) {
            setDisplayDataPage('none');
            setCurrentDataPage(-1);
        } else {
            setDisplayDataPage('block');
            setCurrentDataPage(pageToGo);
        }
    };

    /*  const printAddress = e => {
        let address = e.addressVenue + ', ' + e.addressCity + ', ';
        address += e.zipCode ? e.zipCode + ', ' : '';
        address += e.province ? e.province + ', ' : '';
        address += e.country.name;
        return address;
    }*/

    const onVieItemCallB2b = (eventb2b, eventB2bList) => {
        history.push('/eventpage', {
            event: eventb2b,
            eventList: eventB2bList,
        });
    };

    const creatEventList = (e) => {
        const ret = [];
        if (e && e.eventDtoList) {
            for (const event of e.eventDtoList) {
                ret.push(
                    <div
                        style={{
                            padding: '10px 0px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            fontSize: '18px',
                        }}
                    >
                        <div>
                            <div>
                                {' '}
                                <b>{event.name.toUpperCase()}</b>
                            </div>
                            <div>
                                {' '}
                                <i>
                                    {' '}
                                    {t('event_from_label').toUpperCase()} {normalizeHour(event.startTime)}{' '}
                                    {t('event_to_label').toUpperCase()} {normalizeHour(event.endTime)}
                                </i>
                            </div>
                        </div>
                        <div>
                            <Url
                                style={{ width: '30px', cursor: 'pointer' }}
                                onClick={() => onVieItemCallB2b(event, e.eventDtoList)}
                            />
                        </div>
                    </div>,
                );
            }
        }
        return ret;
    };

    const cards = [];

    if (requests) {
        for (const e of requests) {
            console.log('Requests ', e);
            if (!(e.statusId === expoConfig.domain.contact_request_status.DONE)) {
                cards.push(
                    <div key={e.id} className="contain-company-request">
                        <div className="header-request">
                            <div style={{ display: 'flex' }}>
                                {e.statusId == expoConfig.domain.contact_request_status.VIEWED ||
                                e.statusId == expoConfig.domain.contact_request_status.REQUESTED ? (
                                    <p style={{ marginBottom: '0px' }}> {t('request_pending').toUpperCase()} </p>
                                ) : (
                                    <p style={{ marginBottom: '0px' }}> {t('request_taking_charge').toUpperCase()} </p>
                                )}
                                {e.statusId == expoConfig.domain.contact_request_status.REQUESTED ? (
                                    <Badge
                                        className="badge_request_b2b"
                                        status="processing"
                                        style={{ marginLeft: '5px' }}
                                    />
                                ) : null}
                                <div className="contain-icon-pause-save">
                                    {e.statusId == expoConfig.domain.contact_request_status.VIEWED ||
                                    e.statusId == expoConfig.domain.contact_request_status.REQUESTED ? (
                                        <Pending className="icon-pause-save" />
                                    ) : (
                                        <Save className="icon-pause-save" />
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', fontSize: '14px' }}>
                                {' '}
                                <span>
                                    <b>{t('request_date_b2b').toUpperCase()}</b>
                                </span>
                                {':'} {normalizeData(e.createdAt)}{' '}
                            </div>
                            <Divider className="divider-company" style={{ margin: '10px 0px 10px' }} />
                        </div>
                        <div className="request_card_header">
                            <Row align="middle" justify="center">
                                <Col
                                    xxl={{ span: 6 }}
                                    xl={{ span: 10 }}
                                    xs={{ span: 10 }}
                                    lg={{ span: 10 }}
                                    sm={{ span: 10 }}
                                    style={{
                                        color: '#000000',
                                        fontSize: '16px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <div className="profile2">
                                        <img width="100%" src={e.requestingCompanyDto.entityDto.logoPath}></img>
                                    </div>
                                </Col>
                                <Col
                                    xl={{ span: 2 }}
                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                >
                                    <ArrowRequest className="arrow-request" />
                                </Col>
                                <Col
                                    xxl={{ span: 6 }}
                                    xl={{ span: 10 }}
                                    xs={{ span: 10 }}
                                    lg={{ span: 10 }}
                                    sm={{ span: 10 }}
                                    style={{
                                        color: '#000000',
                                        fontSize: '16px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <div className="profile2">
                                        <img width="100%" src={e.requestedCompanyDto.entityDto.logoPath}></img>
                                    </div>
                                </Col>
                            </Row>

                            <Row justify="center">
                                <Col
                                    xxl={{ span: 11 }}
                                    xl={{ span: 10 }}
                                    xs={{ span: 11 }}
                                    lg={{ span: 10 }}
                                    sm={{ span: 9 }}
                                >
                                    <div className="container-info-company" style={{ textAlign: 'right' }}>
                                        <div className="name-company-request">
                                            <b>{e.requestingCompanyDto.entityDto.name}</b>
                                        </div>
                                        <div>{e.requestingCompanyDto.entityDto.addressVenue}</div>
                                        <span>
                                            {e.requestingCompanyDto.entityDto.addressCity} -{' '}
                                            {e.requestingCompanyDto.entityDto.country.name}
                                        </span>
                                        {e.requestingUserDto ? (
                                            <div>
                                                {e.requestingUserDto.name} {e.requestingUserDto.surname}
                                            </div>
                                        ) : null}
                                        {e.requestingUserDto ? <div>{e.requestingUserDto.email}</div> : null}
                                    </div>
                                </Col>
                                <Col
                                    xxl={{ span: 11, offset: 2 }}
                                    xl={{ span: 10, offset: 4 }}
                                    xs={{ span: 11, offset: 2 }}
                                    lg={{ span: 10, offset: 4 }}
                                    sm={{ span: 9, offset: 4 }}
                                >
                                    <div className="container-info-company" style={{ textAlign: 'left' }}>
                                        <div className="name-company-request">
                                            <b> {e.requestedCompanyDto.entityDto.name}</b>
                                        </div>
                                        <div>{e.requestedCompanyDto.entityDto.addressVenue}</div>
                                        <span>
                                            {e.requestedCompanyDto.entityDto.addressCity} -{' '}
                                            {e.requestedCompanyDto.entityDto.country.name}
                                        </span>
                                        {e.requestedCompanyDto.entityDto.email ? (
                                            <div>{e.requestedCompanyDto.entityDto.email}</div>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        {e.eventDtoList.length > 0 ? (
                            <Collapse
                                bordered={false}
                                defaultActiveKey={['1']}
                                expandIcon={({ isActive }) => (
                                    <CaretRightOutlined className="icon-arrow-collapse" rotate={isActive ? 90 : 0} />
                                )}
                                className="collapse-card-approvazione"
                            >
                                <Panel header={t('scheduled_events').toUpperCase()} id={e.id + '_panel'}>
                                    <div className="container_event_collapse scrollbar-request">
                                        {creatEventList(e)}
                                    </div>
                                </Panel>
                            </Collapse>
                        ) : null}

                        <div
                            className="button_operation_request"
                            style={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
                        >
                            <Row justify="center">
                                <Col className="two-button-distance">
                                    <ConfirmModal
                                        message={
                                            <div>
                                                <p>{t('request_archive_confirm_text')}</p>
                                            </div>
                                        }
                                        onOk={() => hide(e)}
                                    >
                                        <Button shape="round" className="blackMode button-request" htmlType="button">
                                            {t('button_archives_request_b2b').toUpperCase()}
                                        </Button>
                                    </ConfirmModal>
                                </Col>
                                <Col className="two-button-distance">
                                    <Button
                                        shape="round"
                                        className="blackMode button-request"
                                        htmlType="button"
                                        onClick={() => creaEvento(e)}
                                    >
                                        {' '}
                                        {t('chamber_create_event').toUpperCase()}{' '}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </div>,
                );
            }
        }
    }

    if (pageLoading) {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Space direction="horizontal">
                    <Spin size="large" />
                    <h1>{t('label_loading')}</h1>
                </Space>
            </div>
        );
    }

    return requests ? (
        <>
            <TopBar arrowsVisible={false} onClickCallBack={transitToDataPageFromToolBar}>
                <div className="blackLink topBarIconCol">
                    <Link to={{ pathname: '/eventinsert' }} style={{ display: 'flex' }}>
                        <IconAndLabel label={t('chamber_create_event').toUpperCase()}>
                            <NewEvent className="dataIcons" style={{ width: '30px' }} />
                        </IconAndLabel>
                    </Link>
                </div>

                <div className="blackLink topBarIconCol">
                    <Link to={{ pathname: '/companiesacceptance' }} style={{ display: 'flex' }}>
                        <IconAndLabel label={t('button_approve').toUpperCase()}>
                            {expoUser.chamber.pendingCounter > 0 ? (
                                <Badge className="badge_request_b2b button_request_badge" status="Success">
                                    <ApproveButton className="dataIcons" style={{ width: '30px' }} />
                                </Badge>
                            ) : (
                                <ApproveButton className="dataIcons" style={{ width: '30px' }} />
                            )}
                        </IconAndLabel>
                    </Link>
                </div>
                <div className="blackLink topBarIconCol">
                    <Link to={{ pathname: '/requestb2b' }} style={{ display: 'flex' }} className="icon-selected">
                        <IconAndLabel label={t('request_b2b').toUpperCase()}>
                            {unviewedContactRequests ? (
                                <Badge className="badge_request_b2b button_request_badge" status="processing">
                                    <IconRequestB2B style={{ width: '30px' }} />
                                </Badge>
                            ) : (
                                <IconRequestB2B style={{ width: '30px' }} />
                            )}
                        </IconAndLabel>
                    </Link>
                </div>
                <div className="blackLink topBarIconCol">
                    <Link to={{ pathname: '/companiesdrafted' }} style={{ display: 'flex' }}>
                        <IconAndLabel label={t('drafted_company').toUpperCase()}>
                            <Draft className="dataIcons" style={{ width: '30px' }} />
                        </IconAndLabel>
                    </Link>
                </div>
                <div className="blackLink topBarIconCol">
                    <Link to={{ pathname: '/companiesuspended' }} style={{ display: 'flex' }}>
                        <IconAndLabel label={t('suspended').toUpperCase()}>
                            <SuspendCompany className="dataIcons" style={{ width: '30px' }} />
                        </IconAndLabel>
                    </Link>
                </div>
            </TopBar>
            <Spin
                spinning={submittingData}
                indicator={<Loading3QuartersOutlined spin style={{ fontSize: '100px', color: '#000' }} />}
            >
                <div className="section-shadow">
                    <Row className="company_acceptance card_container">
                        <Col span="24">
                            <Row key="1" gutter={[8, 24]} justify="center">
                                <Col xl={{ span: 12 }} xs={{ span: 24 }} lg={{ span: 16 }} sm={{ span: 20 }}>
                                    <Row gutter={[8, 24]}>
                                        {requests.length > 0 ? (
                                            <Col span="24">{cards}</Col>
                                        ) : (
                                            <Col span="24">
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <div className="b2bLeave-panel">
                                                        <div className="b2bLeave-image">
                                                            {expoUser && expoUser.chamber ? (
                                                                <img
                                                                    style={{ width: '100%' }}
                                                                    alt="camera cover"
                                                                    src={expoUser.chamber.bannerPath}
                                                                />
                                                            ) : null}
                                                        </div>
                                                        <div
                                                            className="b2bLeave-text"
                                                            style={{ flexDirection: 'column' }}
                                                        >
                                                            <p
                                                                style={{
                                                                    fontSize: '24px',
                                                                    color: '#262626',
                                                                    padding: '0px 40px',
                                                                }}
                                                            >
                                                                <b>{t('label_empty_list_companies')}</b>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Spin>
        </>
    ) : null;
};
export default RequestB2B;
