import is from 'is_js';
import React, { useEffect, useState } from 'react';
import { Row, Col, Skeleton } from 'antd';
import { useAuth0 } from '../react-auth0-spa';

import NoAuthPage from './NoAuthPage';
import CompanyShowCase from './CompanyShowCase';
import ChamberShowCase from './ChamberShowCase';
import Subscription from './Subscription';
import expoConfig from '../config/expo_config.json';
import history from '../utils/history';

const Homepage = () => {
    const { isAuthenticated, expoUser, loading } = useAuth0();

    const [setupComplete, setSetupComplete] = useState(false);
    const [setupTimeout, setSetupTimeout] = useState(false);
    const [waitCounter, setWaitCounter] = useState(50);

    useEffect(() => {
        const setup = async () => {
            //Promise per aspettare che lo user sia completo
            const waitForUser = new Promise((resolve, reject) => {
                setTimeout(function () {
                    if (expoUser && expoUser.complete) {
                        resolve();
                    } else {
                        console.log(
                            'Homepage.useEffect.waitForUser, expoUser ancora incompleto',
                            waitCounter,
                            expoUser,
                        );
                        reject();
                    }
                }, 200);
            });

            console.log('Homepage.useEffect: waiting to know if someone has logged in', isAuthenticated, expoUser);
            if (isAuthenticated && expoUser) {
                if (expoUser.complete) {
                    setSetupComplete(true);
                    console.log('Homepage.useEffect expoUser complete!');
                } else {
                    console.log('Homepage.useEffect expoUser not complete, waiting...');
                    waitForUser
                        //expoUser completo imposto il flag nello stato così rieseguo la init
                        .then(() => setSetupComplete(true))
                        //expoUser ancora incompleto se ho ancora tentativi da fare
                        //decremento il counter e aggiorno lo stato così rifaccio un giro di attesa
                        .catch(() => {
                            if (waitCounter > 0) {
                                setWaitCounter(waitCounter - 1);
                            } else {
                                setSetupComplete(true);
                                setSetupTimeout(true);
                            }
                        });
                }
            } else {
                console.log('Homepage.useEffect nobody logged in');
                setSetupComplete(true);
            }
        };
        if (!loading) {
            setup();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, expoUser, loading, waitCounter, setupComplete]);

    const buildComponent = () => {
        if (setupTimeout) {
            console.log('Homepage.render: setupTimeout, gestire pagina di errore/reload');
            return <div>setupuser timeout, provare a ricaricare la pagina.</div>;
        }
        //console.log("Homepage buildComponent", expoUser);
        if (
            expoUser.entityTypeId === expoConfig.domain.entity_type.c_ita ||
            expoUser.entityTypeId === expoConfig.domain.entity_type.c_other
        ) {
            if (is.not.existy(expoUser.chamber)) {
                console.log(
                    'Homepage.render: la camera non è definita, gestire messaggio per farla scegliere dal burger menu',
                    expoUser,
                );
                return <div>Selezionare una camera dal burger menu</div>;
            } else if (is.existy(expoUser.registration)) {
                if (expoUser.registration.subscriptionId && !expoUser.registration.expired) {
                    console.log('Homepage.render: company ha sottoscrizione (verificare la sua validità)', expoUser);
                    //company homepage
                    history.push('/company');
                    //return <CompanyShowCase key="CompanyShowCase" />;
                } else {
                    console.log('Homepage.render: company non ha sottoscrizione, gestirne la scelta', expoUser);
                    return <Subscription />;
                }
            }
        }

        if (expoUser.entityTypeId === expoConfig.domain.entity_type.chamber) {
            console.log('Homepage.render: utente camera', expoUser);
            //chamber homepage
            return <ChamberShowCase key="ChamberShowCase" />;
        }
        return <div>OPS, questo non dovrebbe succedere!</div>;
    };

    return (
        <Row className="whiteMode">
            <Col span={24} justify="space-around" align="middle" className="whiteMode">
                <Skeleton active loading={loading}>
                    {isAuthenticated && expoUser && !loading && setupComplete ? (
                        buildComponent()
                    ) : (
                        <NoAuthPage key="NoAuthPage" />
                    )}
                </Skeleton>
            </Col>
        </Row>
    );
};
export default Homepage;
