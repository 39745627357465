import './CompanyShowCase.css';
import '../css/flag-css.min.css';
import './EntityItemSlider.css';
import './Signup.css';

import { Label, Modal } from 'semantic-ui-react';
import expoClient from '../expoClient';
import expoConfig from '../config/expo_config.json';
import authorization from '../utils/authorization';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Carousel, Col, Form, notification, Row, Badge } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseButton } from '../icons/close.svg';
import { useAuth0 } from '../react-auth0-spa';
import history from '../utils/history';
import ConfirmModal from './ConfirmModal';
import TextArea from 'antd/lib/input/TextArea';

const ModalSuspendedCompanies = (props) => {
    const { isAuthenticated, expoUser, loading } = useAuth0();
    const [company, setCompany] = useState(null);
    const [registrationStatus, setRegistrationStatus] = useState(0);
    const { t } = useTranslation();
    const [suspensionMessageColor, setSuspensionMessageColor] = useState('red');
    const [tempSuspensionMessage, setTempSuspensionMessage] = useState('');
    const [disableSuspensionButton, setDisableSuspensionButton] = useState(true);

    let placeHolderSuspendCompanyDescription = t('motivation_suspend_company') + '*';

    const changedSuspensionMessage = (mess) => {
        setTempSuspensionMessage(mess);
        if (mess.length < 40) {
            if (suspensionMessageColor != 'red') {
                setSuspensionMessageColor('red');
            }
            setDisableSuspensionButton(true);
        } else {
            if (suspensionMessageColor != 'black') {
                setSuspensionMessageColor('black');
            }
            setDisableSuspensionButton(false);
        }
    };

    const postSuspendRegistration = async () => {
        if (props.company != null) {
            try {
                let res = await expoClient.chambers.suspendCompany(
                    expoUser.chamber.id,
                    props.company.id,
                    tempSuspensionMessage,
                    expoUser.token,
                );
                if (res.status === 200) {
                    setRegistrationStatus(expoConfig.domain.registration.suspended);
                    const desc = t('request_suspended_ok');
                    notification.success({
                        key: 'suspended',
                        message: desc,
                        placement: 'topRight',
                        duration: 7,
                    });
                    history.push('/companiesuspended');
                }
            } catch (error) {
                console.error('postChangeRegistration:', error);
            } finally {
            }
            props.closeModalSuspendCompany();
        }
        // setShowModalSuspendCompany(false);
    };

    return (
        <>
            {props.company ? (
                <Modal
                    className="modal-suspend-company"
                    key="modalSuspendCompany"
                    open={props.showModalSuspendCompany}
                    trigger={null}
                    header={null}
                    actions={null}
                    style={{ border: '4px solid #707070', borderRadius: '16px' }}
                >
                    <Modal.Header className="modal-media">
                        {' '}
                        {t('title_suspension_procedure').toUpperCase()}{' '}
                    </Modal.Header>
                    <ConfirmModal onOk={props.closeModalSuspendCompany()} message={t('ask_for_close_live')}>
                        <CloseButton className="exitButtonOnLoading" />
                    </ConfirmModal>
                    <Row align="middle" style={{ width: '100%' }}>
                        <div
                            className="company_description_header"
                            style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                padding: '40px 50px 30px',
                            }}
                        >
                            <div style={{ fontSize: '16px' }}>
                                <h2 className="panding_company_name">
                                    <strong>{props.company.name}</strong>
                                </h2>
                                <div>
                                    <p>
                                        {props.company.addressVenue} {'-'}{' '}
                                        {props.company.zipCode ? props.company.zipCode : null}
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        {props.company.addressCity}{' '}
                                        {props.company.province ? props.company.province : null} {' - '}{' '}
                                        {props.company.country.name}
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <strong>
                                            {t('P.iva')}
                                            {':'}
                                        </strong>{' '}
                                        {props.company.vatNumber}
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <strong>
                                            {t('phone')}
                                            {':'}
                                        </strong>{' '}
                                        {props.company.phone}
                                    </p>
                                </div>
                                <div>
                                    {props.company.website ? (
                                        <p>
                                            <strong>
                                                {t('website')}
                                                {':'}
                                            </strong>{' '}
                                            <a className="link_website" href={props.company.website} target="_blank">
                                                {' '}
                                                <u>{props.company.website}</u>
                                            </a>
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                            <div>
                                {props.company.logoPath ? (
                                    <div className="profile2" style={{ margin: '0px' }}>
                                        <img width="100%" src={props.company.logoPath}></img>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div style={{ padding: '0px 50px 40px', width: '100%' }}>
                            <span style={{ fontSize: '20px' }}>
                                <b>{t('suspension_reason').toUpperCase()}</b>
                            </span>
                            <TextArea
                                maxLength={250}
                                rows={3}
                                id="suspendMessage"
                                style={{ margin: '20px 0px 0px', fontSize: '18px', color: suspensionMessageColor }}
                                placeholder={placeHolderSuspendCompanyDescription}
                                onChange={(e) => changedSuspensionMessage(e.target.value)}
                            />
                        </div>
                        <div className="button_operation_request_media">
                            <Row justify="center" align="middle">
                                <Col className="two-button-distance">
                                    <ConfirmModal
                                        onOk={props.closeModalSuspendCompany()}
                                        message={t('ask_for_close_live')}
                                    >
                                        <Button
                                            block
                                            shape="round"
                                            className="blackMode button-request"
                                            htmlType="button"
                                        >
                                            {t('button_cancel').toUpperCase()}
                                        </Button>
                                    </ConfirmModal>
                                </Col>
                                <Col className="two-button-distance">
                                    {!disableSuspensionButton ? (
                                        <ConfirmModal
                                            onOk={() => postSuspendRegistration()}
                                            message={t('warning_suspend_company', { 0: props.company.name })}
                                        >
                                            <Button
                                                block
                                                shape="round"
                                                className="blackMode button-request"
                                                htmlType="button"
                                            >
                                                {t('company_suspen_button_label').toUpperCase()}
                                            </Button>
                                        </ConfirmModal>
                                    ) : (
                                        <Button
                                            block
                                            shape="round"
                                            disabled={true}
                                            className="blackMode button-request"
                                            htmlType="button"
                                        >
                                            {t('company_suspen_button_label').toUpperCase()}
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </Modal>
            ) : null}
        </>
    );
};
export default ModalSuspendedCompanies;
