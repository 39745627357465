import React, { useEffect, useState } from 'react';
import { Col, Input, Row, Typography, notification } from 'antd';
import './EntityInfoPanel.css';
import EditSaveDiscardIcons from './EditSaveDiscardIcons';
import { useTranslation } from 'react-i18next';
//testgit
const EntityInfoPanel = ({ company, chamber, setEditModeCallBackParent, globalEditState, isEditable }) => {
    const entity = company ? company : chamber ? chamber : null;
    const { t } = useTranslation();

    const [editMode, setEditMode] = useState(false);
    const [tempDescription, setTempDescription] = useState(null);
    const [descriptionColor, setDescriptionColor] = useState('black');
    let localEntityTmp = entity;
    useEffect(() => {
        console.log('EntityInfoPanel.useEffect');
    }, []);

    //Funzioni CallBack per EditSaveDiscard Component
    const setSaveCallBack = () => {
        if (tempDescription && tempDescription.length > 150) {
            setEditMode(false);
            //Notifico al Parent che ho salvato e non sono più in editing cosi lo notifica agli altri componenti
            if (tempDescription != null) localEntityTmp.description = tempDescription;
            setEditModeCallBackParent(false, true, false);
        } else {
            const n = 150 - tempDescription.length;
            const desc = t('min_desc_150') + n;
            notification.error({
                key: 'postputUser',
                message: desc,
                placement: 'topRight',
                duration: 7,
            });
        }
    };

    const setDiscardCallBack = () => {
        setEditMode(false);
        //Discardo le modifiche prendendo la entity iniziale
        //Notifico al Parent che ho salvato e non sono più in editing cosi lo notifica agli altri componenti
        setEditModeCallBackParent(false, false, true);
    };

    const setEditModeCallBack = () => {
        setEditMode(true);
        //Notifico al Parent che ho salvato e non sono più in editing cosi lo notifica agli altri componenti
        setEditModeCallBackParent(true, false, true);
    };

    //Funzioni CallBack per la modifica dei vari componenti e mi tengo in pancia i nuovi valori
    //finche non si fa salva e allora mando al server la nuova entity

    const changedDescription = (newDescription) => {
        setTempDescription(newDescription);
        if (newDescription.length < 150) {
            if (descriptionColor != 'red') {
                setDescriptionColor('red');
            }
        } else {
            if (descriptionColor != 'black') {
                setDescriptionColor('black');
            }
        }
    };

    const enabled = globalEditState ? editMode : true;
    const editButton = isEditable ? (
        <EditSaveDiscardIcons
            showButtons={true}
            enabled={enabled}
            editMode={editMode}
            callBackOnEdit={setEditModeCallBack}
            callBackOnSave={setSaveCallBack}
            callBackOnDiscard={setDiscardCallBack}
            cssPositionClass="editingButtonStandardPositionBlock"
        />
    ) : null;

    const renderEntity = (entity, textAreaOrParag, inputOrSpanName, inputOrSpanAddressCityAndCode) => (
        <Row className="description-info-panel">
            <Col span="24">
                <Row>
                    <Col span="24" align="start">
                        {inputOrSpanName}
                    </Col>
                </Row>
                <Row>
                    <Col span="24" align="start">
                        {inputOrSpanAddressCityAndCode}
                    </Col>
                </Row>
                {/* <Row style={{ paddingTop: "15px" }}>
                    <Col span="24" align="start">
                        <Space>
                            <Linkedin style={{ width: "30px" }} />
                            <Facebook style={{ width: "30px" }} />
                        </Space>
                    </Col>
                </Row>
 */}{' '}
                <Row style={{ padding: '35px 0 0 0' }}>
                    <Col span="24" align="start">
                        {textAreaOrParag}
                    </Col>
                </Row>
            </Col>
            {editButton}
        </Row>
    );
    const { Text } = Typography;
    const textAreaOrParag = editMode ? (
        <Row>
            <Col span="24">
                <Input.TextArea
                    rows={13}
                    maxLength={750}
                    minLength={150}
                    style={{ Height: '245px', fontSize: '20px', color: descriptionColor }}
                    onChange={(e) => changedDescription(e.target.value)}
                    defaultValue={entity.description}
                />
            </Col>
        </Row>
    ) : (
        <pre className="wrap" style={{ Height: '245px', fontSize: '20px' }}>
            {entity.description}
        </pre>
    );
    const inputOrSpanName = (
        <span style={{ fontWeight: '600', fontSize: '26px', lineHeight: '1.35' }}>{entity.name}</span>
    );
    const inputOrSpanAddressCityAndCode = (
        <Row>
            <Text>{entity.addressCity}</Text>
            <Text>, {entity.country.code}</Text>
        </Row>
    );
    return entity ? renderEntity(entity, textAreaOrParag, inputOrSpanName, inputOrSpanAddressCityAndCode) : null;
};
export default EntityInfoPanel;
