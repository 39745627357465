import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const Button = (props) => {
    const { onClick, className, children } = props;

    useEffect(() => {
        var buttonColor = props.color;
        Array.from(document.getElementsByClassName('button-submit')).forEach(function (element) {
            element.style.backgroundColor = buttonColor;
        });
    }, []);

    return (
        <button type="button" onClick={onClick} className={className}>
            {children}
        </button>
    );
};
export default Button;

Button.propTypes = {
    color: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
};
