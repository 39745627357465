import is from 'is_js';

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { Drawer } from 'antd';
import classNames from 'classnames/bind';

import ContentVideo from './ContentVideo';
import LocalVideo from './LocalVideo';
import Controls from './Controls';
import Roster from './Roster';
import RemoteVideoGroup from './RemoteVideoGroup';

import history from '../../utils/history';
import getChimeContext from '../../context/getChimeContext';
import ViewMode from '../../enums/ViewMode';
import MeetingStatus from '../../enums/MeetingStatus';

import getMeetingStatusContext from '../../context/getMeetingStatusContext';
import styles from './B2BCall.css';

import LanguageDropdown from '../LanguageDropdown';
import { ReactComponent as People } from '../../icons/people.svg';
import expoClient from '../../expoClient';

const cx = classNames.bind(styles);

const B2BCall = () => {
    const chime = useContext(getChimeContext());
    const { meetingStatus, errorMessage, b2bInfo, callUuid } = useContext(getMeetingStatusContext());

    const [viewMode, setViewMode] = useState(ViewMode.Room);
    const [isModeTransitioning, setIsModeTransitioning] = useState(false);
    const [isContentShareEnabled, setIsContentShareEnabled] = useState(false);
    const [shareUser, setShareUser] = useState(null);
    const [leavePath, setLeavePath] = useState(null);
    const [drawerVisible, setDrawerVisible] = useState(false);

    const myShareVideo = useRef(null); //reference a html video per la preview del proprio share
    let b2bFooter = null;

    const stopContentShare = async () => {
        setIsModeTransitioning(true);
        await new Promise((resolve) => setTimeout(resolve, 200));
        try {
            chime.audioVideo.stopContentShare();
        } catch (error) {
            // eslint-disable-next-line
            console.error(error);
        } finally {
            setViewMode(ViewMode.Room);
            setIsModeTransitioning(false);
        }
    };

    function leaveB2B(passedLeavePath) {
        // Rendo visibili i componenti nascosti in avvio
        const headerBar = document.getElementById('headerBar');
        if (is.existy(headerBar)) {
            headerBar.style.display = 'flex';
        } else {
            console.log('B2B.leaveB2B div #headerBar not found, cannot do the operation');
        }
        const footerBar = document.getElementById('expoFooter');
        if (is.existy(footerBar)) {
            footerBar.style.display = 'block';
        } else {
            console.log('B2BCall.modifyHeaderBar footer NOT FOUND???');
        }
        if (passedLeavePath) {
            history.push(passedLeavePath);
        } else {
            history.push(leavePath);
        }
        const tokens = b2bInfo.user.token.split('-');
        const leaveCallResponse = expoClient.meeting.leaveCall(tokens[0], tokens[1], callUuid);
        history.go();
    }

    function setupPage(b2bInfo) {
        // Nascondo header e footer
        const headerBar = document.getElementById('headerBar');
        if (is.not.existy(headerBar)) {
            console.log('B2B.modifyHeaderBar div #headerBar not found, cannot do the operation');
            return;
        }
        if (is.not.existy(b2bInfo)) {
            console.log('B2B.modifyHeaderBar b2bInfo is null, cannot do the operation');
            return;
        }
        // console.log('B2BCall.modifyHeaderBar replacing headerbar with ', b2bInfo.cameraLogo, b2bInfo.event);
        headerBar.style.display = 'none';
        const footerBar = document.getElementById('expoFooter');
        if (is.existy(footerBar)) {
            // console.log('B2BCall.modifyHeaderBar hiding footer');
            footerBar.style.display = 'none';
        } else {
            console.log('B2BCall.modifyHeaderBar footer NOT FOUND???');
        }
    }

    /*const hideFooter = () => {
		//console.log('B2BCall.hideFooter footer', b2bFooter);
		if (b2bFooter) {
			b2bFooter.classList.add('hidden');
		}
	};

	const showFooter = () => {
		//console.log('B2BCall.showFooter footer', b2bFooter);
		if (b2bFooter) {
			b2bFooter.classList.remove('hidden');
		}
	};

	let hideFooterTimer = setTimeout(hideFooter, 3000);

	function mouseMove(event) {
		if (event.clientY > window.innerHeight - 60) {
			clearTimeout(hideFooterTimer);
			showFooter();
		} else if (event.clientY > window.innerHeight * 0.65) {
			//console.log('B2BCall.mouseMove Y 0.65', event.clientY);
			clearTimeout(hideFooterTimer);
			showFooter();
			hideFooterTimer = setTimeout(hideFooter, 3000);
		}
	}

	function mouseClick(event) {
		if (!b2bFooter) return;
		//console.log('B2BCall.mouseClick event', b2bFooter.classList);
		if (b2bFooter.classList.value.indexOf('hidden') !== -1) {
			//console.log('B2BCall.mouseClick mostro footer');
			b2bFooter.classList.remove('hidden');
		} else {
			//console.log('B2BCall.mouseClick nascondo footer');
			b2bFooter.classList.add('hidden');
		}
	}*/

    const mainLayout = document.getElementById('main-layout');
    if (mainLayout) {
        mainLayout.style.height = '100%';
        /*mainLayout.addEventListener('mousemove', mouseMove);
		mainLayout.addEventListener('click', mouseClick);*/
    }

    const deviceCallbackUpdate = ({ currentAudioInputDevice, currentAudioOutputDevice, currentVideoInputDevice }) => {
        console.log('CurrentVideoInputDevice: ', currentVideoInputDevice);
    };

    useEffect(() => {
        window.FreshworksWidget('hide');
    }, []);

    useEffect(() => {
        if (chime && chime.audioVideo) {
            chime.audioVideo.addObserver({
                audioVideoDidStop: (sessionStatus) => {
                    // See the "Stopping a session" section for details.
                    console.log('Stopped with a session status code: ', sessionStatus.statusCode());
                    let passedLeavePath = `/event/leave/${b2bInfo.user.token}`;
                    leaveB2B(passedLeavePath);
                },
            });

            chime.subscribeToDevicesUpdated(deviceCallbackUpdate);
        }

        if (meetingStatus === MeetingStatus.Succeeded) {
            //Se setup CHIME e Meeting sono stati completati
            console.log('B2BCall.useEffect all seems to be ready to start: MeetingStatus', meetingStatus);
            setupPage(b2bInfo);
            setLeavePath(`/event/leave/${b2bInfo.user.token}`);
            b2bFooter = document.getElementById('b2bFooter');
        }
        window.onbeforeunload = async (event) => {
            // Prevent the window from closing immediately
            // eslint-disable-next-line
            event.returnValue = true;
            const tokens = b2bInfo.user.token.split('-');
            const leaveCallResponse = expoClient.meeting.leaveCall(tokens[0], tokens[1], callUuid);
            try {
                chime.unsubscribeFromDevicesUpdated(deviceCallbackUpdate);
                await chime.leaveRoom();
            } catch (error) {
                // eslint-disable-next-line
                console.error(error);
            } finally {
                window.onbeforeunload = null;
            }
        };
        return () => {
            window.onbeforeunload = null;
        };
    }, [meetingStatus]);

    // Must pass a memoized callback to the ContentVideo component using useCallback().
    // ContentVideo will re-render only when one dependency "viewMode" changes.
    // See more comments in ContentVideo.
    const onContentShareEnabled = useCallback(
        async (enabled, shareUser) => {
            //console.log('B2BCall.onContentShareEnabled enabled, viewMode', enabled, viewMode);
            //if (meetingStatus && meetingStatus !== MeetingStatus.Succeeded) return;

            if (viewMode === ViewMode.ScreenShare) {
                await stopContentShare();
            }
            setShareUser(shareUser);
            setIsContentShareEnabled(enabled);
        },
        [viewMode],
    );

    //Funzioni di comparsa/scomparsa drawer
    const onClose = () => {
        setDrawerVisible(false);
    };

    const toggleDrawer = () => {
        setDrawerVisible(!drawerVisible);
    };

    const onStopSelfContentShare = useCallback(() => {
        setViewMode(ViewMode.Room);
    }, [viewMode]);

    const handleShareContentButton = async () => {
        if (viewMode === ViewMode.ScreenShare) {
            await stopContentShare();
            //console.log('B2BCall.onClickShareButton share stopped');
            return;
        }
        try {
            //console.log('B2BCall.onClickShareButton try to start share');
            await chime.audioVideo.startContentShareFromScreenCapture();
            setViewMode(ViewMode.ScreenShare);
            setIsModeTransitioning(false);
            //console.log('B2BCall.onClickShareButton share started');
        } catch (error) {
            // eslint-disable-next-line
            console.error(error);
            await stopContentShare();
        }
    };

    function renderUI() {
        return (
            <>
                <div className="b2b_header noSelect blackLink">
                    <a target="_blank" href="/">
                        <div className="pointer">
                            <img
                                src={b2bInfo.cameraLogo}
                                alt={`logo ${b2bInfo.camera}`}
                                style={{ padding: '0 40px 0 10px' }}
                                height={50}
                                title={b2bInfo.event}
                            />
                            {window.innerWidth > 900 ? b2bInfo.event : null}
                        </div>
                    </a>
                    {/* <div className="b2b_language">
					<LanguageDropdown className="headerButton_p" languageList={b2bInfo.languageDtoList} />
				</div> */}
                    <div className="drawer-video-col" id="openList" onClick={toggleDrawer}>
                        <People
                            style={{
                                width: '30px',
                                position: 'absolute',
                            }}
                        />
                    </div>
                    <div className="chat-video-col">
                        <video ref={myShareVideo} style={{ height: '100%' }} />
                    </div>
                    <div className="localVideoWrapper">
                        <LocalVideo />
                    </div>
                </div>
                <Drawer
                    className="drawer-video"
                    placement="right"
                    closable={false}
                    mask={true}
                    maskStyle={{ background: 'transparent' }}
                    onClose={onClose}
                    visible={drawerVisible}
                    getContainer={false}
                    width={'321'}
                    style={{ height: 'calc(100% - 120px)', top: '60px' }}
                >
                    <Roster />
                </Drawer>
                <div id="b2bBody" className="b2b_body">
                    <div className="contentVideoWrapper">
                        <ContentVideo
                            myShareVideo={myShareVideo}
                            onContentShareEnabled={onContentShareEnabled}
                            onStopSelfContentShare={onStopSelfContentShare}
                        />
                    </div>
                    <div className={cx('remoteVideoGroupWrapper', { scroll: isContentShareEnabled })}>
                        <RemoteVideoGroup viewMode={viewMode} isContentShareEnabled={isContentShareEnabled} />
                    </div>
                </div>
                <div id="b2bFooter" className="b2b_footer">
                    <Controls
                        viewMode={viewMode}
                        isContentShareEnabled={isContentShareEnabled}
                        shareUser={shareUser}
                        onClickShareButton={handleShareContentButton}
                        onClickLeaveButton={() => leaveB2B()}
                    />
                </div>
            </>
        );
    }

    return (
        <div
            className={cx('b2bcall', {
                roomMode: viewMode === ViewMode.Room,
                screenShareMode: viewMode === ViewMode.ScreenShare,
                isModeTransitioning,
                isContentShareEnabled,
            })}
        >
            {meetingStatus === MeetingStatus.Loading && <h1>Loading...</h1>}
            {meetingStatus === MeetingStatus.Failed && <h1>{errorMessage}</h1>}
            {meetingStatus === MeetingStatus.Succeeded && renderUI()}
        </div>
    );
};
export default B2BCall;
