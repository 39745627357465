import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import auth0Config from './config/auth_config.json';
import expoClient from './expoClient';
import history from './utils/history';
import expoConfig from './config/expo_config.json';
const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({ children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions }) => {
    const [isAuthenticated, setIsAuthenticated] = useState();
    const [user, setUser] = useState();
    const [auth0Client, setAuth0] = useState();
    const [loading, setLoading] = useState(true);
    const [popupOpen, setPopupOpen] = useState(false);
    //EXPO CUSTOMIZATION
    const [expoUser, setExpoUser] = useState();

    useEffect(() => {
        const initAuth0 = async () => {
            const auth0FromHook = await createAuth0Client(initOptions);
            setAuth0(auth0FromHook);
            const onRedirectCallback = (appState) => {
                history.push(localStorage.getItem(expoConfig.pageToRedirect) || '/');
            };
            if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
                const { appState } = await auth0FromHook.handleRedirectCallback();
                onRedirectCallback(appState);
            }
            const isAuthenticated = await auth0FromHook.isAuthenticated();

            setIsAuthenticated(isAuthenticated);

            if (isAuthenticated) {
                const user = await auth0FromHook.getUser();
                setUser(user);
                //Recupero expo user
                setExpoUser(await getExpoUser(auth0FromHook));
            }
            setLoading(false);
        };
        initAuth0();
        // eslint-disable-next-line
    }, []);

    const getExpoUser = async (client) => {
        const token = await client.getTokenSilently(auth0Config.audience);
        console.log(`Auth0Provider Token:----<${token}]>----`);
        if (token) {
            try {
                const result = await expoClient.user.info(token);
                console.log('getExpoUser', result);
                if (result && result.status === 200) {
                    const data = result.data;
                    data.token = token;
                    return data;
                }
            } catch (error) {
                console.log('Errore nel chiamare userInfo', error);
                return null;
            }
        }
        console.log('Error retrieving an Auth0 token');
        return null;
    };

    const loginWithPopup = async (params = {}) => {
        setPopupOpen(true);
        try {
            await auth0Client.loginWithPopup(params);
        } catch (error) {
            console.error(error);
        } finally {
            setPopupOpen(false);
        }
        const user = await auth0Client.getUser();
        setUser(user);
        setExpoUser(await getExpoUser(auth0Client));
        setIsAuthenticated(true);
    };

    const handleRedirectCallback = async () => {
        setLoading(true);
        await auth0Client.handleRedirectCallback();
        const user = await auth0Client.getUser();
        setIsAuthenticated(true);
        setUser(user);
        setExpoUser(await getExpoUser(auth0Client));
        setLoading(false);
    };

    return (
        <Auth0Context.Provider
            value={{
                isAuthenticated,
                user,
                expoUser,
                loading,
                popupOpen,
                loginWithPopup,
                handleRedirectCallback,
                getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
                loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
                getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
                getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
                logout: (...p) => auth0Client.logout(...p),
            }}
        >
            {children}
        </Auth0Context.Provider>
    );
};
