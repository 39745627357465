import _ from 'lodash';
import is from 'is_js';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '../react-auth0-spa';
import {
    Button,
    Carousel,
    Col,
    DatePicker,
    Form,
    Input,
    Modal,
    notification,
    Row,
    Select,
    Skeleton,
    Spin,
    Space,
    Typography,
} from 'antd';

import { Loading3QuartersOutlined } from '@ant-design/icons';
import { QuestionCircleOutlined } from '@ant-design/icons';

//Componenti
import EventEntityAttendeesCard from './EventEntityAttendeesCard';
import ImageEditorModal from './ImageEditor';
import TopBar from './TopBar';
import EventInfoPanel from './EventInfoPanel';
import XtensibleGallery from './XtensibleGallery';
//utils e resource
import expoClient from '../expoClient';
import expoConfig from '../config/expo_config.json';
import timezones from '../config/timezone.json';
import defaultEventImage from '../images/default-event-image.jpg';
import history from '../utils/history';
//CSS
import './EventInsert.css';

const EventInsert = ({ location }) => {
    const event = location.state ? location.state.event : null;
    const editMode = is.existy(event);

    console.log('EventInsert.constructor', event, editMode);
    const { t } = useTranslation();
    const { Option } = Select;
    const { RangePicker } = DatePicker;
    const { isAuthenticated, expoUser, loading } = useAuth0();
    const { Title } = Typography;

    //Se ho completato il setup dell'evento
    const [eventReady, setEventReady] = useState(false);
    //La pagina visualizzata dal carousel (step di inserimento/edit)
    const [carouselIndex, setCarouselIndex] = useState(0);
    //La camera che sta organizzando l'evento
    const [chamber, setChamber] = useState(null);
    //Counter per i tentativi di attesa inizializzazione camera (nello stato per fare i render ad ogni tentativo)
    const [waitCounter, setWaitCounter] = useState(5);
    //elenco aziende, con tutti i loro utenti, legate alla camera
    const [entities, setEntities] = useState(null);
    //true durante il post dei dat di creazione evento (pilota l'animazione di attesa)
    const [submittingData, setSubmittingData] = useState(false);
    //flag che indica se i dati inseriti nel form sono corretti
    const [formDataValid, setFormDataValid] = useState(false);

    //i valori inseriti nel form (serve per il refresh della pagina?? forse si può togliere)
    const [formValues, setFormValues] = useState(null);
    //Flag che indica se ho selezionato almeno una compagnia (per abilitare bottone prosegui)
    const [noCardSelected, setNoCardSelected] = useState(true);

    //Entity italiane selezionate per partecipare all'evento
    const [selectedEntitiesIta, setSelectedEntitiesIta] = useState([]);
    //Entity estere selezionate per partecipare all'evento
    const [selectedEntitiesOther, setSelectedEntitiesOther] = useState([]);
    //Tutte le entity selezionate da visualizzare nel secondo step della creazione evento
    const [selectedEntities, setSelectedEntities] = useState([]);
    //Flag visibilità modale di conferma uscita dalle operazioni di edit
    const [showExitEditModal, setShowExitEditModal] = useState(false);

    //Istanza del form per manipolarlo da codice
    //const [form] = Form.useForm();
    const formRef = useRef();
    //Reference del carousel per gestire i cambi di pagina da codice
    const carouselRef = useRef();
    //Gestisce l'editing della parte descrittiva quando si sta scegliendo l'immagine
    const [isEditingImage, setIsEditItingImage] = useState(false);

    const [itaCompanies, setItaCompanies] = useState(null);
    const [otherCompanies, setOtherCompanies] = useState(null);

    useEffect(() => {
        //normalizza i dati dello user per essere conformi al formato del post
        const mapUser = (userEntity) =>
            userEntity.map((ue) => ({
                name: ue.user.name,
                surname: ue.user.surname,
                email: ue.user.email,
                id: ue.user.id,
                businessRole: ue.businessRole,
            }));

        const handleResize = () => {
            var img = document.getElementById('bannerImage');
            var w = window.innerWidth;
            if (document.getElementById('descriptionText') != null) {
                if (w < 1200) {
                    document.getElementById('descriptionText').style.height = 'auto';
                } else if (img) {
                    var height = img.clientHeight;
                    document.getElementById('descriptionText').style.height = height + 'px';
                }
            }
            if (document.getElementById('descriptionFormText') != null) {
                if (w < 1200) {
                    document.getElementById('descriptionFormText').style.height = 'auto';
                } else if (img) {
                    var height = img.clientHeight;
                    document.getElementById('descriptionFormText').style.height = height + 'px';
                }
            }
        };

        const init = async () => {
            window.addEventListener('resize', handleResize);
            //Promise per attendere che la NavBarUser completi il setup dell'expoUser
            const waitForChamber = new Promise((resolve, reject) => {
                setTimeout(function () {
                    if (expoUser.chamber) {
                        resolve(expoUser.chamber);
                    } else {
                        console.log(
                            'EventInsert.userEffect.waitForChamber, chamber in expoUser ancora NULL',
                            waitCounter,
                        );
                        reject();
                    }
                }, 100);
            });

            //Se ho la camera posso completare le operazioni di init
            if (is.existy(chamber)) {
                console.log('EventInsert.useEffect got chamber try to setup', chamber);
                //Aziende legate alla camera
                const cListRes = await expoClient.chambers.getAllCompanies(chamber.id, expoUser.token);
                const entities = cListRes.data;
                //Per ogni azienda recupero i suoi utenti e li rimappo
                for (const c of entities) {
                    const { data } = await expoClient.user.getByEntityId(c.entityId, expoUser.token);
                    c.users = mapUser(data);
                    c.attendees = [...c.users];
                }
                //Se la camera non ha ancora i suoi utenti, li leggo
                if (is.not.existy(chamber.users)) {
                    const { data } = await expoClient.user.getByEntityId(chamber.entityId, expoUser.token);
                    chamber.users = mapUser(data);
                }
                //Se non ho definito gli attendees della camera aggiungo lo user che sta creando l'evento
                if (is.not.existy(chamber.attendees)) {
                    chamber.attendees = chamber.users.filter((u) => u.id === expoUser.user.id);
                }
                entities.unshift(chamber);
                setEntities(entities);

                //Da entities prendo le compagnie ITA
                const iCompanies = entities
                    ? entities.filter((e) => e.entityTypeId === expoConfig.domain.entity_type.c_ita)
                    : [];

                //Da entities prendo le compagnie NON ITA
                const oCompanies = entities
                    ? entities.filter((e) => e.entityTypeId === expoConfig.domain.entity_type.c_other)
                    : [];

                //Se arrivo nella pagina da requestB2B preseleziono le due aziende in questione

                if (location && location.state && location.state.companies) {
                    const companies = location.state.companies;
                    const id1 = companies[0]; //c.entityId
                    const id2 = companies[1];
                    setSelectedEntitiesIta(iCompanies.filter((c) => companies.includes(c.entityId)));
                    setSelectedEntitiesOther(oCompanies.filter((c) => companies.includes(c.entityId)));
                    setNoCardSelected(false);
                    putSelectedOnTop(iCompanies, id1, id2, oCompanies);
                }
                setItaCompanies(iCompanies);
                setOtherCompanies(oCompanies);

                if (is.truthy(editMode)) {
                    //sono in edit
                    console.log('EventInsert.useEffect edit', event);
                    //Se nell'evento ho degli attendees li devo distribuire sulle loro liste
                    if (is.existy(event.attendees)) {
                        //console.log("EventInsert.useEffect edit attendees", event.attendees);
                        const selIta = [];
                        const selOther = [];
                        const selChamber = [];
                        event.attendees.forEach((a) => {
                            if (a.entityTypeId === expoConfig.domain.entity_type.chamber) {
                                selChamber.push(a);
                            } else if (a.entityTypeId === expoConfig.domain.entity_type.c_ita) {
                                selIta.push(a);
                            } else if (a.entityTypeId === expoConfig.domain.entity_type.c_other) {
                                selOther.push(a);
                            }
                        });
                        setSelectedEntitiesIta(selIta);
                        setSelectedEntitiesOther(selOther);
                        setSelectedEntities([...selChamber, ...selIta, ...selOther]);
                    }
                    //Check guardie per bottone avanti
                    setNoCardSelected(is.empty(event.attendees));
                    setFormDataValid(!is.any.empty(event.name, event.startTime, event.endTime));
                }
                console.log('EventInsert.useEffect init completata', selectedEntitiesIta, selectedEntitiesOther);
                setEventReady(true);
            } //chamber ancora non trovata, verifico la promise e casomai aspetto altro tempo decrementando il counter
            else {
                console.log('EventInsert.useEffect no chamber ... Try to wait');
                waitForChamber
                    //expoUser completo imposto la chamber nello stato così rieseguo la init
                    .then((chamber) => setChamber(chamber))
                    //expoUser ancora incompleto se ho ancora tentativi da fare
                    //decremento il counter e aggiorno lo stato così rifaccio un giro di attesa
                    .catch(() => {
                        if (waitCounter > 0) {
                            setWaitCounter(waitCounter - 1);
                        }
                    });
            }
            var img = document.getElementById('bannerImage');
            if (img) {
                handleResize();
            }
        };
        //Se ho completato il processo di login
        if (!loading && isAuthenticated && is.existy(expoUser)) {
            init();
        }
    }, [isAuthenticated, expoUser, loading, waitCounter, chamber, eventReady, isEditingImage]);

    const getDateWithTimeZone = (date, tz) => {
        //offset del client
        const utcOffset = date.utcOffset();
        //data client in utc
        const utcDate = moment.utc(date);
        // aggiungo offset alla data utc (annullo offset del client)
        const utcDatePlusOffset = moment(utcDate).add(utcOffset, 'm');
        // imposto timezone selezionato
        const tzDate = moment.tz(utcDatePlusOffset, tz);
        // tolgo offset alla data
        return tzDate.subtract(tzDate.utcOffset(), 'm');
    };

    const postData = async (values) => {
        console.log('Post values', values);
        setSubmittingData(true);
        try {
            const mapUsers = (entityId) => {
                const result = {
                    entityId: entityId,
                    userIdList: Object.keys(values)
                        //undefined == true (il componente non trasmette il suo valore di default al form)
                        .filter(
                            (v) =>
                                v.startsWith(`entity_${entityId}_user_`) &&
                                (typeof values[v] === 'undefined' || values[v] === true),
                        )
                        .map((v) => parseInt(_.split(v, '_')[3], 10)),
                };

                return result.userIdList && result.userIdList.length > 0 ? result : null;
            };
            const formTimezone = values.eventtimezone ? values.eventtimezone : timezones.default;
            const data = {
                name: values.eventname,
                addressVenue: values.eventvenue,
                description: values.eventdescription,
                startTime: getDateWithTimeZone(values.eventrangedate[0], formTimezone).format(),
                endTime: getDateWithTimeZone(values.eventrangedate[1], formTimezone).format(),
                timeZone: formTimezone,
                eventImageBase64: values.file,
                attendees: Object.keys(values)
                    .filter((v) => v.startsWith('card_') && values[v] && mapUsers(values[v]))
                    .map((v) => mapUsers(values[v])),
            };
            console.log('Sending post data', data);
            const createRes = await expoClient.event.create(data, expoUser.token);
            if (typeof createRes !== 'undefined') {
                //rileggo elenco eventi
                const eRes = await expoClient.chambers.getEvents(expoUser.chamber.id, null, expoUser.token);
                //Visualizzo l'evento creato
                history.push('/eventpage', {
                    event: eRes.data.filter((e) => e.id === createRes.data)[0],
                    eventList: eRes.data,
                });
            }
        } catch (error) {
            console.error(error);
            const msg = _.capitalize(t('error'));
            const desc = t('event_create_error_post');
            notification.error({
                key: 'postCreateEventError',
                message: msg,
                description: desc,
                placement: 'topRight',
                duration: 5,
            });
        } finally {
            setSubmittingData(false);
        }
    };

    const putData = async (values) => {
        setSubmittingData(true);
        try {
            const mapUsers = (entityId) => {
                const result = {
                    entityId: entityId,
                    userIdList: Object.keys(values)
                        .filter((v) => v.startsWith(`entity_${entityId}_user_`) && values[v] === true)
                        .map((v) => parseInt(_.split(v, '_')[3], 10)),
                };

                return result.userIdList && result.userIdList.length > 0 ? result : null;
            };
            const formTimezone = values.eventtimezone ? values.eventtimezone : timezones.default;
            const data = {
                id: event.id,
                roomId: event.roomId,
                path: event.path,
                name: values.eventname,
                addressVenue: values.eventvenue,
                description: values.eventdescription,
                startTime: getDateWithTimeZone(values.eventrangedate[0], formTimezone).format(),
                endTime: getDateWithTimeZone(values.eventrangedate[1], formTimezone).format(),
                timeZone: formTimezone,
                eventImageBase64: values.file && !values.file.startsWith('http') ? values.file : null,
                attendees: Object.keys(values)
                    .filter((v) => v.startsWith('card_') && values[v] && mapUsers(values[v]))
                    .map((v) => mapUsers(values[v])),
            };
            console.log('Sending put data', data);
            let res = await expoClient.event.update(data, expoUser.token);
            if (typeof res !== 'undefined') {
                //rileggo elenco eventi
                const eRes = await expoClient.chambers.getEvents(expoUser.chamber.id, null, expoUser.token);
                //Visualizzo l'evento  creato
                history.push('/eventpage', {
                    event: eRes.data.filter((e) => e.id === event.id)[0],
                    eventList: eRes.data,
                });
            }
        } catch (error) {
            console.error(error);
            const msg = _.capitalize(t('error'));
            const desc = t('event_create_error_post');
            notification.error({
                key: 'putEventError',
                message: msg,
                description: desc,
                placement: 'topRight',
                duration: 5,
            });
        } finally {
            setSubmittingData(false);
        }
    };

    const changeData = (changedValues, allValues) => {
        const fields = ['eventname'];
        let valid = true;
        fields.forEach((f) => {
            if (f in allValues && allValues[f]) {
                valid = valid && allValues[f].trim().length > 0;
            } else {
                valid = false;
            }
        });
        const rangeDate = 'eventrangedate';
        if (rangeDate in allValues && allValues[rangeDate]) {
            valid = valid && allValues[rangeDate][0] !== null && allValues[rangeDate][1] !== null;
        } else {
            valid = false;
        }
        setFormDataValid(valid);
        setFormValues(allValues);
    };

    //Tono in homepage
    const cancelInsert = () => history.push('/chamber');

    //Oggetto da passare al componente di editImage per recuperare l'immagine inserita
    const imageWrapper = {};

    //Da entities prendo le compagnie ITA
    // const itaCompanies = entities ? entities.filter((e) => e.entityTypeId === expoConfig.domain.entity_type.c_ita) : [];

    // //Da entities prendo le compagnie NON ITA
    // const otherCompanies = entities
    //     ? entities.filter((e) => e.entityTypeId === expoConfig.domain.entity_type.c_other)
    //     : [];
    //La camera con i suoi invitati
    const chamberAttendees = entities
        ? entities.filter((e) => e.entityTypeId === expoConfig.domain.entity_type.chamber)
        : [];

    const handleEntitySelectionIta = (selections) => {
        console.log('EventPage.selection callback ita', selections, itaCompanies, entities);
        setNoCardSelected(selections.length === 0 && selectedEntitiesOther.length === 0);
        const selectedEntityId = selections.map((s) => s.entityId);
        setSelectedEntitiesIta(itaCompanies.filter((c) => selectedEntityId.includes(c.entityId)));
        itaCompanies.forEach((c) => {
            formRef.current.setFieldsValue({
                [`card_${c.country.code}_${c.entityId}`]: selectedEntityId.includes(c.entityId) ? c.entityId : null,
            });
        });
    };

    const handleEntitySelectionOther = (selections) => {
        console.log('EventPage.selection callback other', selections, otherCompanies, entities);
        setNoCardSelected(selections.length === 0 && selectedEntitiesIta.length === 0);
        const selectedEntityId = selections.map((s) => s.entityId);
        setSelectedEntitiesOther(otherCompanies.filter((c) => selectedEntityId.includes(c.entityId)));
        otherCompanies.forEach((c) => {
            formRef.current.setFieldsValue({
                [`card_${c.country.code}_${c.entityId}`]: selectedEntityId.includes(c.entityId) ? c.entityId : null,
            });
        });
    };

    //Al cambio slide imposto le entities selezionate
    const getSelectedEntities = (carouselIndex) => {
        setCarouselIndex(carouselIndex);
        if (carouselIndex === 2) {
            //Tutte le entities selezionate
            setSelectedEntities([...chamberAttendees, ...selectedEntitiesIta, ...selectedEntitiesOther]);
        }
        window.scrollTo(0, 0); //Porto la view sempre in cima
    };

    // Can not select days before today
    const disabledDate = (current) => current && current < moment().startOf('day');

    const mapEventToForm = () => {
        const formInitialValue = editMode
            ? {
                  eventname: event.name,
                  eventrangedate: [moment(event.startTime), moment(event.endTime)],
                  eventtimezone: event.timeZone,
                  eventvenue: event.addressVenue,
                  eventdescription: event.description,
                  file: event.path,
              }
            : {
                  eventtimezone: timezones.default,
                  file: defaultEventImage,
              };
        //Le aziende selezionate
        [chamber, ...selectedEntitiesIta, ...selectedEntitiesOther].forEach(
            (c) => (formInitialValue[`card_${c.country.code}_${c.entityId}`] = c.entityId),
        );

        console.log('EventInsert mapEventToForm', formInitialValue);
        return formInitialValue;
    };

    //Gestione new portfolio start
    const changeFileDataEvent = (isEdit, changed, fromDiscard) => {
        setIsEditItingImage(isEdit);
    };

    return !eventReady ? (
        //Animazione di loading in attesa che si valorizzi la chamber
        <Skeleton active paragraph={{ rows: 3 }} />
    ) : (
        <div>
            <Modal
                visible={showExitEditModal}
                title={
                    <Space>
                        <QuestionCircleOutlined style={{ fontSize: '24px' }} /> {t('warning').toUpperCase()}
                    </Space>
                }
                footer={[
                    <Button
                        key="1"
                        onClick={() => {
                            cancelInsert();
                            setShowExitEditModal(false);
                        }}
                    >
                        {t('yes').toUpperCase()}
                    </Button>,
                    <Button
                        key="2"
                        onClick={() => {
                            setShowExitEditModal(false);
                        }}
                    >
                        {t('no').toUpperCase()}
                    </Button>,
                ]}
            >
                <p style={{ fontSize: '16px' }}>{t('event_exit_edit_confirm')}</p>
            </Modal>

            <TopBar>
                <div style={{ width: '100%', fontSize: '34px', textAlign: 'center' }}>
                    <strong>{t(editMode ? 'event_edit_title' : 'event_create_title').toUpperCase()}</strong>
                </div>
            </TopBar>
            <Spin
                spinning={submittingData}
                indicator={<Loading3QuartersOutlined spin style={{ fontSize: '100px', color: '#000' }} />}
            >
                <Form
                    ref={formRef}
                    name="eventForm"
                    onFinish={editMode ? putData : postData}
                    onValuesChange={changeData}
                    initialValues={mapEventToForm()}
                >
                    <Carousel
                        ref={carouselRef}
                        key="eventCarousel"
                        dots={false}
                        effect="fade"
                        beforeChange={(from, to) => getSelectedEntities(to)}
                    >
                        <div className="expo-event">
                            <Row
                                className="panel bottom-shadow"
                                style={{ display: carouselIndex === 1 ? 'none' : 'flex' }}
                            >
                                <Col xl={12} xs={24}>
                                    <Form.Item name="file" noStyle>
                                        <Input type="hidden" />
                                    </Form.Item>
                                    <ImageEditorModal
                                        entity={imageWrapper}
                                        className="logoMode"
                                        form={formRef}
                                        imageUrl={editMode ? event.path : defaultEventImage}
                                        globalEditState={false}
                                        isEditable={true}
                                        idInputFile={'dummyEventFileInput'}
                                        isMedia={false}
                                        enableSave={false}
                                        imageEditor={false}
                                        setEditModeCallBackParent={changeFileDataEvent}
                                    />
                                </Col>
                                <Col
                                    xl={12}
                                    xs={24}
                                    id="descriptionFormText"
                                    className="panelForm"
                                    style={{ overflow: 'auto' }}
                                >
                                    <Form.Item
                                        name="eventname"
                                        maxLength="255"
                                        rules={[{ required: true, message: t('event_create_required_event_name') }]}
                                    >
                                        <Input
                                            disabled={isEditingImage}
                                            size="large"
                                            className="input-lg"
                                            placeholder={t('event_create_placeholder_event_name')}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Input.Group compact>
                                            <Form.Item
                                                name="eventrangedate"
                                                rules={[
                                                    { required: true, message: t('event_create_required_event_date') },
                                                ]}
                                            >
                                                <RangePicker
                                                    size="large"
                                                    className="range_picker"
                                                    disabled={isEditingImage}
                                                    format={'DD-MM-YYYY HH:mm'}
                                                    disabledDate={disabledDate}
                                                    showTime
                                                    minuteStep={15}
                                                    placeholder={[
                                                        t('rangepicker_start_placeholder'),
                                                        t('rangepicker_end_placeholder'),
                                                    ]}
                                                />
                                            </Form.Item>
                                            <Form.Item name="eventtimezone">
                                                <Select
                                                    disabled={isEditingImage}
                                                    className="timezone"
                                                    showSearch
                                                    placeholder={t('timezone_placeholder')}
                                                    optionFilterProp="children"
                                                    size="large"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {Object.keys(timezones.values).map((k, index) => (
                                                        <Option key={index} value={k}>
                                                            {timezones.values[k]}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Input.Group>
                                    </Form.Item>
                                    <Form.Item name="eventvenue">
                                        <Input
                                            disabled={isEditingImage}
                                            size="large"
                                            maxLength="255"
                                            placeholder={t('event_create_placeholder_event_venue')}
                                        />
                                    </Form.Item>
                                    <Form.Item name="eventdescription" noStyle>
                                        <Input.TextArea
                                            disabled={isEditingImage}
                                            style={{ fontSize: '16px' }}
                                            size="large"
                                            rows={11}
                                            maxLength="750"
                                            placeholder={t('event_create_placeholder_event_description')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row style={{ padding: '25px 0' }} justify="center" align="middle">
                                <Col span={24} style={{ textAlign: 'center' }}>
                                    <Space size={30}>
                                        <Button
                                            block
                                            shape="round"
                                            className="blackMode"
                                            htmlType="button"
                                            onClick={() => setShowExitEditModal(true)}
                                            style={{ width: '150px' }}
                                        >
                                            {t('button_cancel').toUpperCase()}
                                        </Button>
                                        <Button
                                            block
                                            shape="round"
                                            className="blackMode"
                                            htmlType="button"
                                            onClick={() => carouselRef.current.next()}
                                            style={{ width: '150px' }}
                                            disabled={!formDataValid}
                                        >
                                            {t('button_forward').toUpperCase()}
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </div>
                        <div className="expo-event">
                            <Row justify="center" style={{ margin: '30px 10px', textAlign: 'center' }}>
                                <Col>
                                    <Title level={2}>{t('label_company_select').toUpperCase()}</Title>
                                </Col>
                            </Row>

                            <Row justify="center">
                                <Col span={24} className="company">
                                    {entities.map((
                                        c, //Per ogni entity creo il suo hidden input
                                    ) => (
                                        <Form.Item
                                            name={`card_${c.country.code}_${c.entityId}`}
                                            key={c.entityId}
                                            noStyle
                                        >
                                            <Input type="hidden" />
                                        </Form.Item>
                                    ))}
                                    <XtensibleGallery
                                        title={t('italian_companies')}
                                        titleVisible={true}
                                        isEditable={false}
                                        isSelectable={true}
                                        selection={selectedEntitiesIta}
                                        selectionCallback={handleEntitySelectionIta}
                                        imagepath="logoPath"
                                        contents={itaCompanies}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className="company">
                                    <XtensibleGallery
                                        title={
                                            t(expoUser.chamber.subdomain + '_companies') !=
                                            expoUser.chamber.subdomain + '_companies'
                                                ? t(expoUser.chamber.subdomain + '_companies')
                                                : t('foreign_companies')
                                        }
                                        titleVisible={true}
                                        isEditable={false}
                                        isSelectable={true}
                                        selection={selectedEntitiesOther}
                                        selectionCallback={handleEntitySelectionOther}
                                        imagepath="logoPath"
                                        contents={otherCompanies}
                                    />
                                </Col>
                            </Row>
                            <Row style={{ padding: '25px 0' }} justify="center" align="middle">
                                <Col span={24} style={{ textAlign: 'center' }}>
                                    <Space size={30}>
                                        <Button
                                            block
                                            shape="round"
                                            className="blackMode"
                                            htmlType="button"
                                            onClick={() => carouselRef.current.prev()}
                                            style={{ width: '150px' }}
                                        >
                                            {t('button_back').toUpperCase()}
                                        </Button>
                                        <Button
                                            block
                                            shape="round"
                                            className="blackMode"
                                            htmlType="button"
                                            onClick={() => setShowExitEditModal(true)}
                                            style={{ width: '150px' }}
                                        >
                                            {t('button_cancel').toUpperCase()}
                                        </Button>
                                        <Button
                                            block
                                            shape="round"
                                            className="blackMode"
                                            htmlType="button"
                                            onClick={() => carouselRef.current.next()}
                                            style={{ width: '150px' }}
                                            disabled={noCardSelected}
                                        >
                                            {t('button_forward').toUpperCase()}
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </div>
                        <div className="expo-event">
                            <Row className="panel bottom-shadow">
                                <Col xl={12} xs={24}>
                                    <ImageEditorModal
                                        entity={imageWrapper}
                                        className="logoMode"
                                        imageUrl={formRef.current && formRef.current.getFieldValue('file')}
                                        globalEditState={false}
                                        isEditable={false}
                                        isMedia={false}
                                        enableSave={false}
                                        imageEditor={false}
                                        setEditModeCallBackParent={changeFileDataEvent}
                                    />
                                </Col>
                                <Col
                                    xl={12}
                                    xs={24}
                                    id="descriptionText"
                                    className="panelForm"
                                    style={{ overflow: 'auto' }}
                                >
                                    <EventInfoPanel form={formRef.current} formValues={formValues} />
                                </Col>
                            </Row>
                            <Row className="event-insert" style={{ padding: '25px 0' }}>
                                <Col offset={2} span="20">
                                    <EventEntityAttendeesCard
                                        attendees={selectedEntities}
                                        editMode={true}
                                        chamberUser={true}
                                        creatorUserId={editMode ? event.creatorUserId : null}
                                    />
                                </Col>
                            </Row>
                            <Row style={{ padding: '25px 0' }}>
                                <Col span="24" style={{ textAlign: 'center' }}>
                                    <Space size={30}>
                                        <Button
                                            block
                                            shape="round"
                                            className="blackMode"
                                            htmlType="button"
                                            onClick={() => carouselRef.current.prev()}
                                            style={{ width: '150px' }}
                                        >
                                            {t('button_back').toUpperCase()}
                                        </Button>
                                        <Button
                                            block
                                            shape="round"
                                            className="blackMode"
                                            htmlType="button"
                                            onClick={() => setShowExitEditModal(true)}
                                            style={{ width: '150px' }}
                                        >
                                            {t('button_cancel').toUpperCase()}
                                        </Button>
                                        <Button
                                            name="confirm"
                                            block
                                            shape="round"
                                            className="blackMode"
                                            htmlType="submit"
                                            style={{ width: '150px' }}
                                        >
                                            {t('button_confirm').toUpperCase()}
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </div>
                    </Carousel>
                </Form>
            </Spin>
        </div>
    );
};
export default EventInsert;
function putSelectedOnTop(itaCompanies, id1, id2, otherCompanies) {
    let ita1 = itaCompanies.findIndex((com) => com.entityId === id1);
    let ita2 = itaCompanies.findIndex((com) => com.entityId === id2);

    if (ita1 >= 0 && ita2 >= 0) {
        let temp = itaCompanies[0];
        itaCompanies[0] = itaCompanies[ita1];
        itaCompanies[ita1] = temp;

        if (ita2 == 0) {
            ita2 = ita1;
        }

        let temp2 = itaCompanies[1];
        itaCompanies[1] = itaCompanies[ita2];
        itaCompanies[ita2] = temp2;
    } else if (ita1 > 0) {
        let temp = itaCompanies[0];
        itaCompanies[0] = itaCompanies[ita1];
        itaCompanies[ita1] = temp;
    } else if (ita2 > 0) {
        let temp = itaCompanies[0];
        itaCompanies[0] = itaCompanies[ita2];
        itaCompanies[ita2] = temp;
    }

    const for1 = otherCompanies.findIndex((com) => com.entityId === id1);
    const for2 = otherCompanies.findIndex((com) => com.entityId === id2);
    if (for1 >= 0 && for2 >= 0) {
        let temp = otherCompanies[0];
        otherCompanies[0] = otherCompanies[for1];
        otherCompanies[for1] = temp;
        if (for2 == 0) {
            for2 = for1;
        }
        let temp2 = otherCompanies[1];
        otherCompanies[1] = otherCompanies[for2];
        otherCompanies[for2] = temp2;
    } else if (for1 > 0) {
        let temp = otherCompanies[0];
        otherCompanies[0] = otherCompanies[for1];
        otherCompanies[for1] = temp;
    } else if (for2 > 0) {
        let temp = otherCompanies[0];
        otherCompanies[0] = otherCompanies[for2];
        otherCompanies[for2] = temp;
    }
}
