import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EditIcon } from '../icons/edit.svg';
import { ReactComponent as SaveIcon } from '../icons/save.svg';
import { ReactComponent as CancelIcon } from '../icons/cancel.svg';
import ConfirmModal from './ConfirmModal';

import './EditSaveDiscardIcons.css';

const EditSaveDiscardIcons = (props) => {
    const { t } = useTranslation();
    const {
        enabled,
        editMode,
        showButtons,
        callBackOnEdit,
        callBackOnSave,
        callBackOnDiscard,
        cssPositionClass,
    } = props;

    const clickOnEdit = () => {
        callBackOnEdit();
    };
    const clickOnSave = () => {
        callBackOnSave();
    };
    const clickOnDiscard = () => {
        callBackOnDiscard();
    };
    //Se sono ad esempio nell'imageEditor non devo far vedere i bottoni di save e discard perchè uso i suoi a meno che
    //non sia in insert media dove ho bisogno dei bottoni di save.
    const doubleButton = showButtons ? (
        <>
            <div style={{ padding: '0' }}>
                <SaveIcon className="editIcon" onClick={() => clickOnSave()} />
            </div>
            <div style={{ padding: '0' }}>
                <ConfirmModal onOk={clickOnDiscard} message={t('ask_for_confirm_discard')} scrollToRopAfterOk={false}>
                    <CancelIcon className="editIcon" />
                </ConfirmModal>
            </div>
        </>
    ) : null;

    console.log('EditSaveDiscardIcons: enabled, editMode', enabled, editMode);
    const editModeLogoButton = enabled ? (
        editMode ? (
            doubleButton
        ) : (
            <EditIcon className="editIcon" onClick={() => clickOnEdit()} />
        )
    ) : null;

    return (
        <div id="container" className={cssPositionClass}>
            {editModeLogoButton}
        </div>
    );
};
export default EditSaveDiscardIcons;
