import React from 'react';
import classNames from 'classnames/bind';

import styles from './Avatar.css';

const cx = classNames.bind(styles);

export default function Avatar({ content, active, scale, color }) {
    return (
        <div className={cx('avatar_wrapper', { enabled: active })}>
            <div className={'avatar ' + scale} style={{ borderColor: color, color: color }}>
                {content}
            </div>
        </div>
    );
}
