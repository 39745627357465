import _ from 'lodash';
import is from 'is_js';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { Button, Col, Modal, Row, Space, Skeleton } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '../react-auth0-spa';

import EventEntityAttendeesCard from './EventEntityAttendeesCard';
import EventInfoPanel from './EventInfoPanel';
import IconAndLabel from './IconAndLabel';
import ImageEditorModal from './ImageEditor';
import TopBar from './TopBar';
import XtensibleGallery from './XtensibleGallery';

import expoClient from '../expoClient';
import expoConfig from '../config/expo_config';
import history from '../utils/history';
import { ReactComponent as MyShowCase } from '../icons/myshowcase.svg';
import { ReactComponent as Edit } from '../icons/edit-circular.svg';
import { ReactComponent as Delete } from '../icons/erase-circular.svg';

import './EventPage.css';

const EventPage = ({ location }) => {
    const { event, eventList } = location.state;
    const { t } = useTranslation();
    const { isAuthenticated, expoUser, loading } = useAuth0();
    //Variabili di stato
    const [eventReady, setEventReady] = useState(false);
    const [chamber, setChamber] = useState(null);
    const [isChamberUser, setIsChamberUser] = useState(false);
    const [isEditorUser, setIsEditorUser] = useState(false);

    const [eventIndex, setEventIndex] = useState(_.findIndex(eventList, (e) => e.id === event.id));
    const [currentEvent, setCurrentEvent] = useState(event);
    const [waitCounter, setWaitCounter] = useState(5);
    const [showEventDeleteModal, setShowEventDeleteModal] = useState(false);

    const formRef = useRef();

    useEffect(() => {
        const mapUser = async (userEntity, isChamberUser) => {
            const users = [];
            for (const i in userEntity) {
                const user = userEntity[i].user;
                user['businessRole'] = userEntity[i].businessRole;
                //utente camera ha visibilità degli url di invito al meeting
                if (isChamberUser) {
                    try {
                        console.log('EventPage.mapUser build meeting url', currentEvent, user);
                        const urlRes = await expoClient.meeting.getUrlWithUserId(
                            currentEvent.roomId,
                            user.id,
                            expoUser.token,
                        );
                        user['link'] = urlRes.data;
                    } catch {
                        user['link'] = null;
                    }
                }
                users.push(user);
            }
            return users;
        };

        const mapGuest = async (guests, isChamberUser) => {
            const users = [];
            for (const i in guests) {
                const guest = guests[i];
                if (guest.businessRole === null) {
                    guest['businessRole'] = '';
                }
                //utente camera ha visibilità degli url di invito al meeting
                if (isChamberUser) {
                    try {
                        console.log('EventPage.mapUser build meeting url', currentEvent, guest);
                        const urlRes = await expoClient.meeting.getUrlWithGuestId(
                            currentEvent.roomId,
                            guest.id,
                            expoUser.token,
                        );
                        guest['link'] = urlRes.data;
                    } catch {
                        guest['link'] = null;
                    }
                }
                users.push(guest);
            }
            return users;
        };

        const handleResize = () => {
            var img = document.getElementById('bannerImage');
            var w = window.innerWidth;
            if (document.getElementById('descriptionText') != null) {
                if (w < 1200) {
                    document.getElementById('descriptionText').style.height = 'auto';
                } else if (img) {
                    var height = img.clientHeight;
                    document.getElementById('descriptionText').style.height = height + 'px';
                }
            }
        };

        const init = async () => {
            //Promise per attendere che la NavBarUser completi il setup dell'expoUser
            window.addEventListener('resize', handleResize);
            const waitForChamber = new Promise((resolve, reject) => {
                setTimeout(function () {
                    if (expoUser.chamber) {
                        resolve(expoUser.chamber);
                    } else {
                        console.log('EventPage.useEffect.waitForChamber, chamber in expoUser ancora NULL', waitCounter);
                        reject();
                    }
                }, 100);
            });

            if (is.existy(chamber)) {
                //Chamber valorizzata inizio il setup della pagina
                //user checks
                console.log('EventPage.useEffect', expoUser);
                const isChamberUser = expoUser.entityTypeId === expoConfig.domain.entity_type.chamber;
                setIsChamberUser(isChamberUser);
                const isAdmin = expoUser.roleId === expoConfig.domain.role.admin;
                setIsEditorUser(is.all.truthy(isChamberUser, isAdmin));
                console.log('EventPage.useEffect got chamber', currentEvent, currentEvent.attendees);
                if (is.not.existy(currentEvent.attendees)) {
                    //leggo i partecipanti all'evento
                    const attendeesRes = await expoClient.event.getAttendees(currentEvent.id, expoUser.token);
                    //Raggruppo gli attendees per entityId
                    const p = _.groupBy(attendeesRes.data, (a) => a.entityId);
                    const eventAttendees = [];
                    for (const key of Object.keys(p)) {
                        if (key !== '-1') {
                            const entity = p[key][0].entity;
                            //La funzione ReST include al partecipante il suo entity (non la camera o la compagnia)
                            //Non ho quindi il campo entityId, ma ID.
                            //Per far funzionare l'interfaccia FE devo ricreare la situazione che si aspetta:
                            //In entityId copio id
                            //In id metto l'id della camera o della compagnia a cui appartiene l'invitato
                            if (is.not.existy(entity.entityId)) {
                                entity.entityId = entity.id;
                                entity.id = is.existy(p[key][0].chamberId.value)
                                    ? p[key][0].chamberId.value
                                    : p[key][0].companyId.value;
                            }
                            //Tutti gli user legati all'entità (compagnia o camera)
                            const usersRes = await expoClient.user.getByEntityId(key, expoUser.token);
                            entity.users = await mapUser(usersRes.data, isChamberUser);
                            entity.attendees = [];
                            p[key].forEach((ea) => entity.attendees.push(ea.user));
                            entity.users = _.sortBy(entity.users, ['surname', 'name'], ['asc', 'asc']);
                            eventAttendees.push(entity);
                            //console.log("readAttendees entity", eventAttendees);
                        }
                    }
                    for (const key of Object.keys(p)) {
                        if (key == '-1') {
                            const entity = {
                                addressCity: '',
                                addressVenue: 'N/A',
                                bannerImageBase64: null,
                                bannerPath: 'https://dev-expo.spxlab.com/files/entities/1/banner.png',
                                country: null,
                                countryId: 5,
                                createdAt: '2020-06-25T18:03:05Z',
                                description: 'guest',
                                email: 'N/A',
                                entityId: null,
                                entityTypeId: 1,
                                facebook: null,
                                id: -1,
                                linkedin: null,
                                logoImageBase64: null,
                                logoPath: '',
                                name: 'event_external_guest_list',
                                phone: 'N/A',
                                province: null,
                                website: null,
                                zipCode: null,
                                __proto__: Object,
                            };
                            //La funzione ReST include al partecipante il suo entity (non la camera o la compagnia)
                            //Non ho quindi il campo entityId, ma ID.
                            //Per far funzionare l'interfaccia FE devo ricreare la situazione che si aspetta:
                            //In entityId copio id
                            //In id metto l'id della camera o della compagnia a cui appartiene l'invitato

                            //Tutti gli user legati all'entità (compagnia o camera)
                            const guestRes = [];
                            p[key].forEach((ea) => guestRes.push(ea.guest));

                            entity.users = await mapGuest(guestRes, isChamberUser);
                            entity.attendees = [];
                            p[key].forEach((ea) => entity.attendees.push(ea.guest));
                            entity.users = _.sortBy(entity.users, ['surname', 'name'], ['asc', 'asc']);
                            eventAttendees.push(entity);
                            //console.log("readAttendees entity", eventAttendees);
                        }
                    }
                    currentEvent.attendees = [...eventAttendees];
                    //console.log("readAttendees completed", currentEvent.attendees);
                    setCurrentEvent(currentEvent);
                    setEventReady(true);
                }
            } else {
                console.log('EventPage.useEffect no chamber ... Try to wait');
                waitForChamber
                    //expoUser completo imposto la chamber nello stato così rieseguo la init
                    .then((chamber) => setChamber(chamber))
                    //expoUser ancora incompleto se ho ancora tentativi da fare
                    //decremento il counter e aggiorno lo stato così rifaccio un giro di attesa
                    .catch(() => {
                        if (waitCounter > 0) {
                            setWaitCounter(waitCounter - 1);
                        }
                    });
            }
            var img = document.getElementById('bannerImage');
            if (img) {
                handleResize();
            }
        };
        if (!loading && isAuthenticated && expoUser) {
            init();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, expoUser, loading, waitCounter, chamber, eventReady]);

    const showPrevEvent = () => {
        const newIndex = eventIndex === 0 ? eventList.length - 1 : eventIndex - 1;

        setCurrentEvent(eventList[newIndex]);
        setEventIndex(newIndex);
        //Forzo il render del componente
        setEventReady(false);
        //console.log("EventPage.prevEvent ", eventIndex, eventList.length, newIndex)
    };

    const showNextEvent = () => {
        const newIndex = eventIndex === eventList.length - 1 ? 0 : eventIndex + 1;

        setCurrentEvent(eventList[newIndex]);
        setEventIndex(newIndex);
        //Forzo il render del componente
        setEventReady(false);
        //console.log("EventPage.nextEvent ", eventIndex, eventList.length, newIndex)
    };

    const isEditable = (e) => moment().isBefore(moment(e.startTime));

    const deleteEvent = async () => {
        try {
            //Cancello l'evento
            let res = await expoClient.event.delete(currentEvent.id, expoUser.token);
            if (typeof res !== 'undefined') {
                history.push('/chamber');
            }
        } catch (error) {
            console.error("errore imprevisto nella cancellazione dell'evento", error);
        } finally {
            setShowEventDeleteModal(false);
        }
    };

    const renderTopBarContent = () =>
        isEditorUser ? (
            <div className="blackLink" style={{ textAlign: 'center' }}>
                <Space size={50}>
                    {isEditable(currentEvent) ? (
                        <Link to={{ pathname: '/eventedit', state: { event: currentEvent } }}>
                            <IconAndLabel label={t('button_edit').toUpperCase()}>
                                <Edit style={{ width: '30px' }} />
                            </IconAndLabel>
                        </Link>
                    ) : null}
                    <Link to={{ pathname: isChamberUser ? '/chamber' : '/company' }}>
                        <IconAndLabel label={t('homepage').toUpperCase()}>
                            <MyShowCase style={{ width: '30px' }} />
                        </IconAndLabel>
                    </Link>
                    <div onClick={() => setShowEventDeleteModal(true)} style={{ cursor: 'pointer' }}>
                        <IconAndLabel label={t('button_delete').toUpperCase()}>
                            <Delete style={{ width: '30px' }} />
                        </IconAndLabel>
                    </div>
                </Space>
            </div>
        ) : (
            <div className="blackLink" style={{ textAlign: 'center' }}>
                <Link to={{ pathname: isChamberUser ? '/chamber' : '/company' }}>
                    <IconAndLabel label={t('homepage').toUpperCase()}>
                        <MyShowCase style={{ width: '30px' }} />
                    </IconAndLabel>
                </Link>
            </div>
        );

    return is.not.existy(chamber) && is.not.truthy(eventReady) ? (
        <Skeleton active paragraph={{ rows: 2 }} />
    ) : (
        <>
            <Modal
                visible={showEventDeleteModal}
                title={
                    <Space>
                        <QuestionCircleOutlined style={{ fontSize: '24px' }} /> {t('warning').toUpperCase()}
                    </Space>
                }
                footer={[
                    <Button
                        key="1"
                        onClick={() => {
                            deleteEvent();
                            setShowEventDeleteModal(false);
                        }}
                    >
                        {t('yes').toUpperCase()}
                    </Button>,
                    <Button
                        key="2"
                        onClick={() => {
                            setShowEventDeleteModal(false);
                        }}
                    >
                        {t('no').toUpperCase()}
                    </Button>,
                ]}
            >
                <p style={{ fontSize: '16px' }}>{t('event_delete_confirm')}</p>
            </Modal>

            <TopBar arrowsVisible={true} leftClick={showPrevEvent} rightClick={showNextEvent}>
                {renderTopBarContent()}
            </TopBar>
            <div className="expo-event">
                <Row className="panel bottom-shadow">
                    <Col xl={12} xs={24}>
                        <ImageEditorModal
                            className="logoMode"
                            form={formRef}
                            imageUrl={currentEvent.path}
                            globalEditState={false}
                            isEditable={false}
                            idInputFile={'dummyEventFileInput'}
                            isMedia={false}
                            enableSave={false}
                            imageEditor={false}
                        />
                    </Col>
                    <Col xl={12} xs={24} id="descriptionText" className="panelForm" style={{ overflow: 'auto' }}>
                        <EventInfoPanel eventEntity={currentEvent} />
                    </Col>
                </Row>
                <div className="panel bottom-shadow">
                    <Row className="panel-event-bottom-shadow" style={{ backgroundColor: '#FFF' }}>
                        <Col span="24">
                            {currentEvent.attendees ? (
                                <EventEntityAttendeesCard
                                    attendees={currentEvent.attendees}
                                    editMode={false}
                                    chamberUser={isChamberUser}
                                />
                            ) : null}
                        </Col>
                    </Row>
                    <Row style={{ padding: '25px 0' }}>
                        <Col span={24}>
                            <XtensibleGallery
                                contents={eventList}
                                title={t('events')}
                                isEditable={false}
                                onVieItemCallBack={(eventId) => {
                                    setCurrentEvent(eventList.filter((e) => e.id === eventId)[0]);
                                    setEventReady(false);
                                    window.scrollTo(0, 0);
                                }}
                                titleVisible={true}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};
export default EventPage;
