import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmModal from './ConfirmModal';
import { Button, Row, Col } from 'antd';

import './EditSaveDiscardIconsImage.css';

const EditSaveDiscardIconsImage = (props) => {
    const { t } = useTranslation();
    const { enabled, editMode, showButtons, callBackOnSave, callBackOnDiscard, cssPositionClass } = props;

    const clickOnSave = () => {
        callBackOnSave();
    };
    const clickOnDiscard = () => {
        callBackOnDiscard();
    };
    //Se sono ad esempio nell'imageEditor non devo far vedere i bottoni di save e discard perchè uso i suoi a meno che
    //non sia in insert media dove ho bisogno dei bottoni di save.
    const doubleButton = showButtons ? (
        <>
            <div className="button_operation_request_media">
                <Row justify="center" align="middle">
                    <Col className="two-button-distance">
                        <div style={{ padding: '0' }}>
                            <ConfirmModal
                                onOk={clickOnDiscard}
                                message={t('ask_for_confirm_discard')}
                                scrollToRopAfterOk={false}
                            >
                                {/*  <CancelIcon className="editIcon" /> */}
                                <Button className="blackMode button-request" shape="round">
                                    {' '}
                                    {t('button_cancel').toUpperCase()}{' '}
                                </Button>
                            </ConfirmModal>
                        </div>
                    </Col>
                    <Col className="two-button-distance">
                        <div style={{ padding: '0' }}>
                            {/* <SaveIcon className="editIcon" onClick={() => clickOnSave()} /> */}
                            <Button
                                className="button-hover-green button-request"
                                shape="round"
                                onClick={() => clickOnSave()}
                            >
                                {' '}
                                {t('publish_company').toUpperCase()}{' '}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    ) : null;

    console.log('EditSaveDiscardIcons: enabled, editMode', enabled, editMode);
    const editModeLogoButton = enabled ? (
        editMode ? (
            doubleButton
        ) : (
            <div className="button_operation_request_media">
                <Row justify="center" align="middle">
                    <Col className="two-button-distance">
                        <ConfirmModal
                            onOk={clickOnDiscard}
                            message={t('ask_for_confirm_discard')}
                            scrollToRopAfterOk={false}
                        >
                            {/*  <CancelIcon className="editIcon" /> */}

                            <Button className="blackMode button-request" shape="round">
                                {' '}
                                {t('button_cancel').toUpperCase()}{' '}
                            </Button>
                        </ConfirmModal>
                    </Col>
                    <Col className="two-button-distance">
                        <div style={{ padding: '0' }}>
                            {/* <SaveIcon className="editIcon" onClick={() => clickOnSave()} /> */}
                            <Button className="button-hover-green button-request" shape="round" disabled={true}>
                                {' '}
                                {t('publish_company').toUpperCase()}{' '}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    ) : null;

    return (
        <div id="container" className={cssPositionClass}>
            {editModeLogoButton}
        </div>
    );
};
export default EditSaveDiscardIconsImage;
