import React from 'react';
import classNames from 'classnames/bind';

import useAttendee from '../../hooks/useAttendee';

import { ReactComponent as AudioEnabled } from '../../icons/audio-enabled.svg';
import { ReactComponent as AudioDisabled } from '../../icons/audio-disabled.svg';

import styles from './VideoNameplate.css';

const cx = classNames.bind(styles);

export default function VideoNameplate(props) {
    const { viewMode, size, attendeeId, attendeeData, isContentShareEnabled } = props;
    const attendee = useAttendee(attendeeId);
    if (!attendeeId) {
        return <></>;
    }

    if (attendee && typeof !attendee.muted !== 'boolean') {
        return <></>;
    }

    const fullname = `${attendeeData.name} ${attendeeData.surname}`;
    const sub_name = attendeeData.businessRole
        ? `${attendeeData.businessRole}, ${attendeeData.entityName}`
        : `${attendeeData.entityName}`;
    const muted = attendee ? attendee.muted : null;

    return attendee ? (
        <div className="videoNamePlateWrapper">
            {console.log('VideoNameplate.render muted', muted)}
            <div className="videoNameplateVolume">
                {muted ? (
                    <AudioDisabled className="videoNamePlateVolumeIcon" />
                ) : (
                    <AudioEnabled className="videoNamePlateVolumeIcon" />
                )}
            </div>
            <div className="videoNamePlateText">
                <div title={fullname ? fullname : ''}>{fullname ? fullname : ''}</div>
                <div className="videoAgency">
                    <p style={{ float: 'left', fontSize: '10px' }} title={sub_name}>
                        {attendeeData.entityCountry && attendeeData.entityCountry.toLowerCase() != 'gue' ? (
                            <span
                                className={`flag flag-${attendeeData.entityCountry.toLowerCase()}`}
                                style={{ marginRight: '5px' }}
                            />
                        ) : null}
                        {sub_name}
                    </p>
                </div>
            </div>
        </div>
    ) : null;
}
