import React, { useContext, useEffect } from 'react';

import { Col, Row } from 'antd';

import getMeetingStatusContext from '../../context/getMeetingStatusContext';
import useRoster from '../../hooks/useRoster';
import RosterLine from '../B2B/RosterLine';

export default function Roster() {
    let { b2bInfo } = useContext(getMeetingStatusContext());
    const roster = useRoster();

    /*
    useEffect(() => {
        console.log('Roster.useEffect b2bInfo, roster', b2bInfo, roster);
    }, [roster]);

    useEffect(() => {
        const tileIds = {};
        // <tileId, attendeeId>
        const realTimeVideoAttendees = new Set();

        const removeTileId = (tileId) => {
            const removedAttendeeId = tileIds[tileId];
            delete tileIds[tileId];
            realTimeVideoAttendees.delete(removedAttendeeId);
            setVideoAttendees(new Set(realTimeVideoAttendees));
        };

        if (chime && chime.audioVideo) {
            chime.audioVideo.addObserver({
                videoTileDidUpdate: (tileState) => {
                    if (!tileState.boundAttendeeId || tileState.isContent || !tileState.tileId) {
                        return;
                    }

                    if (tileState.active) {
                        tileIds[tileState.tileId] = tileState.boundAttendeeId;
                        realTimeVideoAttendees.add(tileState.boundAttendeeId);
                        setVideoAttendees(new Set(realTimeVideoAttendees));
                    } else {
                        removeTileId(tileState.tileId);
                    }
                },
                videoTileWasRemoved: (tileId) => {
                    removeTileId(tileId);
                },
            });
        }
    }, []);
    */

    return (
        <Row>
            <Col span={24}>
                <RosterLine data={b2bInfo.user} you={true} />
                {Object.keys(roster).map((id) => {
                    if (b2bInfo.user.token === roster[id].externalAttendeeId) return;
                    const attendee = b2bInfo.attendees.filter((a) => a.token === roster[id].externalAttendeeId);
                    if (attendee && attendee[0]) {
                        console.log('Roster.render attendee', attendee[0], roster[id].externalAttendeeId);
                        return <RosterLine data={attendee[0]} you={false} />;
                    }
                })}
            </Col>
        </Row>
    );
}
