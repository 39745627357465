import axios from 'axios';
import qs from 'qs';
import React from 'react';
import { notification } from 'antd';
import { Trans } from 'react-i18next';

import cfg from './config/expo_config.json';
const { REACT_APP_PAYPAL_API_URL } = process.env;

const paypalApi = axios.create({
    baseURL: REACT_APP_PAYPAL_API_URL,
});

const api = axios.create({
    baseURL: cfg.api.url,
});

api.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        //const originalRequest = error.config;
        if (error.response) {
            const response = error.response;
            console.error('Intercettato errore', error.response.data);
            if (!parseError(response)) return Promise.reject(error);
        } else if (error.request) {
            console.error('error.request', error.request);
            return Promise.reject(error);
        } else {
            console.error('Error', error.message);
            return Promise.reject(error);
        }
        console.log('error.config', error.config);
    },
);

const parseError = (response) => {
    if (response.status === 400) {
        //Unhautorized
        return manageBadRequesr(response.data);
    } else if (response.status === 401) {
        //Unhautorized
        return manageUnhautorized(response.data);
    } else {
        //manageError("Errore intercettato!");
        console.log('Error ', response.data);
        return false;
    }
};

const manageBadRequesr = (errorBody) => {
    if (errorBody && errorBody.eCode) {
        notification.error({
            key: 'axiosNotifyError',
            message: 'Error',
            description: <Trans>{`error_${errorBody.eCode}`}</Trans>,
            placement: 'topRight',
            duration: 0,
        });
        return true;
    }
    return false;
};

const manageUnhautorized = (errorBody) => {
    if (errorBody && errorBody.eCode) {
        notification.error({
            key: 'axiosNotifyError',
            message: 'Error',
            description: <Trans>{`error_${errorBody.eCode}`}</Trans>,
            placement: 'topRight',
            duration: 0,
        });
        return true;
    }
    return false;
};

const createFile = (idCompany, itemData, token, callBackForUploadPercentage, fileType) =>
    api.post(`/companies/${idCompany}/showcase-items/${fileType}`, itemData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'multipart/form-data',
        },
        onUploadProgress: function (progressEvent) {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            if (callBackForUploadPercentage) callBackForUploadPercentage(percentCompleted);
        },
    });

const authHeader = (token) => {
    return token ? { Authorization: `Bearer ${token}` } : null;
};

const countries = {
    get: (filteredCountryId = 0) => api.get(`/countries/${filteredCountryId}`),
};

const topics = {
    get: () => api.get('/topics'),
};

const chambers = {
    getByCountry: (countryid) => {
        const url =
            countryid === cfg.domain.nation.ita.toString(10) ? '/chambers' : `/chambers?country-id=${countryid}`;
        return api.get(url);
    },
    getBySubdomain: (subdomain) => {
        const url = subdomain ? `/chambers?subdomain=${subdomain}` : '/chambers';
        return api.get(url);
    },
    get: (chamberId, token) => api.get(`/chambers/${chamberId}`, { headers: authHeader(token) }),
    getUsers: (chamberId, token) => api.get(`/chambers/${chamberId}/users`, { headers: authHeader(token) }),
    getAllCompanies: (chamberId, token) =>
        api.get(`/chambers/${chamberId}/companies/${cfg.domain.registration.published}`, {
            headers: authHeader(token),
        }),
    getPendingCompanies: (chamberId, token) =>
        api.get(`/chambers/${chamberId}/companies/${cfg.domain.registration.pending}`, { headers: authHeader(token) }),
    getUnpublishedCompanies: (chamberId, token) =>
        api.get(`/chambers/${chamberId}/companies/${cfg.domain.registration.active}`, { headers: authHeader(token) }),
    getSuspendedCompanies: (chamberId, token) =>
        api.get(`/chambers/${chamberId}/companies/${cfg.domain.registration.suspended}`, {
            headers: authHeader(token),
        }),
    getCountries: (chamberId) => api.get(`/chambers/${chamberId}/countries`),
    getCompanies: (chamberId, countryId, token) => {
        const url = `/chambers/${chamberId}/companies`;
        const options = {
            params: {
                country: countryId,
            },
            headers: authHeader(token),
        };
        return api.get(url, options);
    },
    getEvents: (chamberId, companyId, token) => {
        let url = `/chambers/${chamberId}/events`;
        if (companyId) url = url.concat(`?company-id=${companyId}`);
        return api.get(url, { headers: authHeader(token) });
    },
    getRegistration: (chamberId, companyId, token) =>
        api.get(`/chambers/${chamberId}/companies/${companyId}/registration`, { headers: authHeader(token) }),
    getTags: (chamberId, companyId, token) =>
        api.get(`/chambers/${chamberId}/companies/${companyId}/tags`, { headers: authHeader(token) }),
    modifyTags: (chamberId, companyId, data, token) =>
        api.put(`/chambers/${chamberId}/companies/${companyId}/tags`, data, { headers: authHeader(token) }),
    createUser: (chamberId, data, token) =>
        api.post(`/chambers/${chamberId}/users/`, data, { headers: authHeader(token) }),
    createCompanyUser: (chamberId, companyId, data, token) =>
        api.post(`/chambers/${chamberId}/companies/${companyId}/users/`, data, { headers: authHeader(token) }),
    searchCompanies: (chamberId, countryId, search, token) => {
        const url = `chambers/${chamberId}/companies/search`;
        const options = {
            params: {
                country: countryId,
                text: search,
            },
            headers: authHeader(token),
        };
        return api.get(url, options);
    },
    countCompanies: (chamberId, token) => {
        const url = `chambers/${chamberId}/companies/count`;
        return api.get(url, { headers: authHeader(token) });
    },
    acceptCompany: (chamberId, companyId, token) => {
        const url = `chambers/${chamberId}/companies/${companyId}/accept`;
        return api.put(url, null, { headers: authHeader(token) });
    },
    publishCompany: (chamberId, companyId, token) => {
        const url = `chambers/${chamberId}/companies/${companyId}/publish`;
        return api.put(url, null, { headers: authHeader(token) });
    },
    unPublishCompany: (chamberId, companyId, token) => {
        const url = `chambers/${chamberId}/companies/${companyId}/unpublish`;
        return api.put(url, null, { headers: authHeader(token) });
    },
    suspendCompany: (chamberId, companyId, message, token) => {
        const url = `chambers/${chamberId}/companies/${companyId}/suspend/${message}`;
        return api.put(url, null, { headers: authHeader(token) });
    },
    archiveCompany: (chamberId, companyId, token) => {
        const url = `chambers/${chamberId}/companies/${companyId}/archive`;
        return api.put(url, null, { headers: authHeader(token) });
    },
    requestContact: (chamberId, companyId, token) => {
        const url = `chambers/${chamberId}/companies/${companyId}/request-contact`;
        return api.put(url, null, { headers: authHeader(token) });
    },
    requestAdvice: (chamberId, messageArea, token) => {
        const url = `chambers/${chamberId}/request-advice`;
        return api.post(url, messageArea, { headers: authHeader(token) });
    },
    checkRequestContact: (chamberId, companyId, token) => {
        const url = `chambers/${chamberId}/companies/${companyId}/request-contact-exist`;
        return api.get(url, { headers: authHeader(token) });
    },
    checkRequestAdvice: (chamberId, token) => {
        const url = `chambers/${chamberId}/request-advice-exist`;
        return api.get(url, { headers: authHeader(token) });
    },
    getCompanyContactRequests: (chamberId, companyId, token) =>
        api.get(`/chambers/${chamberId}/companies/${companyId}/contact-requests`, { headers: authHeader(token) }),
    getContactRequests: (chamberId, token) =>
        api.get(`/chambers/${chamberId}/contact-requests`, { headers: authHeader(token) }),
    getContactRequestsCount: (chamberId, token) =>
        api.get(`/chambers/${chamberId}/contact-requests/count`, { headers: authHeader(token) }),
    getCompanyContactRequestsCount: (chamberId, companyId, token) =>
        api.get(`/chambers/${chamberId}/companies/${companyId}/contact-requests/count`, { headers: authHeader(token) }),
    requestViewed: (chamberId, contactRequestId, token) => {
        const url = `chambers/${chamberId}/contact-requests/${contactRequestId}/viewed`;
        return api.put(url, null, { headers: authHeader(token) });
    },
    requestsViewed: (chamberId, token) => {
        const url = `chambers/${chamberId}/contact-requests/all-viewed`;
        return api.put(url, null, { headers: authHeader(token) });
    },
    requestProcessing: (chamberId, contactRequestId, token) => {
        const url = `chambers/${chamberId}/contact-requests/${contactRequestId}/processing`;
        return api.put(url, null, { headers: authHeader(token) });
    },
    requestDone: (chamberId, contactRequestId, token) => {
        const url = `chambers/${chamberId}/contact-requests/${contactRequestId}/done`;
        return api.put(url, null, { headers: authHeader(token) });
    },

    lastJoinedCompanies: (chamberId) => api.get(`chambers/${chamberId}/companies/last-joined`),
    getLastItems: (chamberId, itemId) => api.get(`chambers/${chamberId}/showcase-items/last/${itemId}`),
    modify: (chamberId, data, token) => {
        return api.put(`/chambers/${chamberId}`, data, { headers: authHeader(token) });
    },
};

const signup = {
    post: (signupData) => api.post('/registrations', signupData),
};

const analytics = {
    get: (chamberId) => {
        return api.get(`/analytics/${chamberId}`);
    },
};

const user = {
    info: (token) => {
        return api.get('/users/info', { headers: authHeader(token) });
    },
    getByEntityId: (entityId, token) => api.get(`/users/entities/${entityId}`, { headers: authHeader(token) }),
    modify: (userId, data, token) => api.put(`/users/${userId}`, data, { headers: authHeader(token) }),
    delete: (userId, token) => api.delete(`/users/${userId}`, { headers: authHeader(token) }),
    free: (email, token) => api.get(`/users/${email}/free`, { headers: authHeader(token) }),
};

const auth = {
    get: (plan_id, token) => {
        return api.get(`/auth/${plan_id}`, { headers: authHeader(token) });
    },
};

const company = {
    get: (id, token) => api.get(`/companies/${id}`, { headers: authHeader(token) }),
    getAll: (token) => api.get('/companies', { headers: authHeader(token) }),
    getAllShowcaseItems: (id, token) => api.get(`/companies/${id}/showcase-items`, { headers: authHeader(token) }),
    getShowcaseItems: (id, itemType, token) =>
        api.get(`/companies/${id}/showcase-items/${itemType}`, { headers: authHeader(token) }),
    getChambers: (id, token) => api.get(`/companies/${id}/chambers/active-published`, { headers: authHeader(token) }),
    modify: (id, data, token) => {
        return api.put(`/companies/${id}`, data, { headers: authHeader(token) });
    },
    createItem: (idCompany, itemData, token) =>
        api.post(`/companies/${idCompany}/showcase-items`, itemData, { headers: authHeader(token) }),
    createLive: (idCompany, itemData, token, callBackForUploadPercentage) => {
        return createFile(idCompany, itemData, token, callBackForUploadPercentage, 'video');
    },
    createDoc: (idCompany, itemData, token, callBackForUploadPercentage) => {
        return createFile(idCompany, itemData, token, callBackForUploadPercentage, 'docs');
    },
};

const showcase = {
    get: (id, token) => api.get(`/showcase-items/${id}`, { headers: authHeader(token) }),
    getMediaByType: (idItem, mediaType, token) =>
        api.get(`showcase-items/${idItem}/showcase-item-media/${mediaType}`, { headers: authHeader(token) }),
    modifyItem: (id, itemData, token) => {
        return api.put(`/showcase-items/${id}`, itemData, { headers: authHeader(token) });
    },
    createMedia: (id, mediaData, token) => {
        return api.post(`/showcase-items/${id}/showcase-item-media`, mediaData, { headers: authHeader(token) });
    },
    delete: (id, token) => api.delete(`/showcase-items/${id}`, { headers: authHeader(token) }),
    createVideo: (id, itemData, mediaType, token, callBackForUploadPercentage) => {
        if (mediaType) {
            return api.post(`/showcase-items/${id}/showcase-item-media/video/?type-id=${mediaType}`, itemData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-type': 'multipart/form-data',
                },
                onUploadProgress: function (progressEvent) {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    if (callBackForUploadPercentage) callBackForUploadPercentage(percentCompleted);
                },
            });
        } else {
            return api.post(`/showcase-items/${id}/showcase-item-media/video`, itemData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-type': 'multipart/form-data',
                },
                onUploadProgress: function (progressEvent) {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    if (callBackForUploadPercentage) callBackForUploadPercentage(percentCompleted);
                },
            });
        }
    },
};

const media = {
    get: (id, token) => api.get(`/showcase-item-media/${id}`, { headers: authHeader(token) }),
    delete: (id, token) => api.delete(`/showcase-item-media/${id}`, { headers: authHeader(token) }),
};

const meeting = {
    getInfo: (tokenRoom, tokenAttendee) => api.get(`/meetings/${tokenRoom}/${tokenAttendee}`),
    isAttendeeOnline: (tokenRoom, tokenAttendee) => api.get(`/meetings/is-online/${tokenRoom}/${tokenAttendee}`),
    joinCall: (tokenRoom, tokenAttendee, callUuid) => api.get(`/meetings/${tokenRoom}/${tokenAttendee}/${callUuid}`),
    leaveCall: (tokenRoom, tokenAttendee, callUuid) => api.put(`/meetings/${tokenRoom}/${tokenAttendee}/${callUuid}`),
    getCallInfo: (tokenRoom) => api.get(`/meetings/${tokenRoom}`),
    getUrl: (roomId, token) => api.get(`/meetings/url/${roomId}`, { headers: authHeader(token) }),
    getUrlWithUserId: (roomId, userId, token) =>
        api.get(`/meetings/url/${roomId}/${userId}`, { headers: authHeader(token) }),
    getUrlWithUserIdAndRoomToken: (roomToken, userId, token) =>
        api.get(`/meetings/url-token/${roomToken}/${userId}`, { headers: authHeader(token) }),
    getUrlWithGuestId: (roomId, guestId, token) =>
        api.get(`/meetings/guest-url/${roomId}/${guestId}`, { headers: authHeader(token) }),
    createGuest: (tokenRoom, data) => api.post(`meetings/${tokenRoom}/guest/`, data),
};

const event = {
    get: (eventId, token) => api.get(`/events/${eventId}`, { headers: authHeader(token) }),
    //getByEntity: (entityId, token) => api.get(`/events/entities/${entityId}`, { headers: authHeader(token) }),
    getAttendees: (eventId, token) => api.get(`/events/${eventId}/event-attendees`, { headers: authHeader(token) }),
    create: (eventData, token) => api.post('/events', eventData, { headers: authHeader(token) }),
    update: (eventData, token) => api.put(`/events/${eventData.id}`, eventData, { headers: authHeader(token) }),
    delete: (eventId, token) => api.delete(`/events/${eventId}`, { headers: authHeader(token) }),
};

const subscription = {
    post: (data, token) => api.post(`/subscriptions`, data, { headers: authHeader(token) }),
    get: (id, token) => api.get(`/subscriptions/${id}`, { headers: authHeader(token) }),
    cancel: (id, token) => api.put(`/subscriptions/${id}/cancel`, null, { headers: authHeader(token) }),
};

const paypal = {
    getToken: () => {
        return axios({
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            method: 'post',
            url: REACT_APP_PAYPAL_API_URL + '/v1/oauth2/token',
            auth: {
                username: 'AZCcboVMNw265AuV-gknCCV0sCMgeAb2q8Telh_YXTTVgdj3U3-3Mntjh7-zg2M-PTKIwro04fg0YEiu',
                password: 'EDkvxVHiFQqP7980iaPzzkQGpaVgAtftrR5JaaG7ovykQ0-TgGKP4vRl2WoWPpL1UXji_qjPxxV4xkcy',
            },
            data: qs.stringify({
                grant_type: 'client_credentials',
            }),
        });
    },
    cancelSubscription: (subscriptionId, token) =>
        paypalApi.post(`/v1/billing/subscriptions/${subscriptionId}/cancel`, null, {
            headers: authHeader(token),
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }),
};
export default {
    auth,
    chambers,
    company,
    countries,
    event,
    media,
    meeting,
    showcase,
    signup,
    subscription,
    topics,
    user,
    analytics,
    paypal,
};
