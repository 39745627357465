import React from 'react';
import { Space } from 'antd';
import is from 'is_js';

const displayLabel = is.mobile() ? 'none' : 'block';
const IconAndLabel = (props) => {
    return (
        <Space direction="vertical" size={4}>
            <div>{props.children}</div>
            <div className="dispLabelOnToolbar" style={{ whiteSpace: 'nowrap', display: displayLabel }}>
                {props.label}
            </div>
        </Space>
    );
};
export default IconAndLabel;
