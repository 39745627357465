import expoConfig from '../config/expo_config.json';

const detectSubdomain = () => {
    const subdomain = window.location.hostname.split('.')[0];
    return expoConfig.subdomain.whitelist.includes(subdomain.toLowerCase()) ? null : subdomain;
};

export default {
    detectSubdomain,
};
