import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Collapse, Row, Spin, Divider, Badge, notification, Typography } from 'antd';
import { Loading3QuartersOutlined, CaretRightOutlined } from '@ant-design/icons';
import expoClient from '../expoClient';
import './CompanyRequestB2B.css';
import { ReactComponent as ArrowRequest } from '../icons/arrow1.svg';
import { ReactComponent as Pending } from '../icons/pending2.svg';
import { ReactComponent as Save } from '../icons/salva.svg';
import { ReactComponent as Url } from '../icons/link.svg';
import { ReactComponent as MyShowCaseButton } from '../icons/myshowcase.svg';
import ConfirmModal from './ConfirmModal';
import { useAuth0 } from '../react-auth0-spa';
import expoConfig from '../config/expo_config.json';
import history from '../utils/history';
import moment from 'moment';
import locationUtil from '../utils/location';
import { divide } from 'lodash';
import TopBar from './TopBar';
import { Link } from 'react-router-dom';
import IconAndLabel from './IconAndLabel';

const CompanyRequestB2B = ({ location }) => {
    const { t } = useTranslation();
    const [requests, setRequests] = useState(null);
    const [submittingData, setSubmittingData] = useState(false);
    const { isAuthenticated, expoUser, loading } = useAuth0();
    const [chamber, setChamber] = useState();
    const { Panel } = Collapse;
    const [registrationStatus, setRegistrationStatus] = useState(0);
    const { Title } = Typography;
    const [index, setIndex] = useState(null);
    const [companies, setCompanies] = useState(null);
    const [displayDataPageChamber, setDisplayDataPageChamber] = useState('none');
    const [currentDataPage, setCurrentDataPage] = useState(-1);
    const [displayDataPage, setDisplayDataPage] = useState('none');

    useEffect(() => {
        const init = async () => {
            const isChamber = expoUser.entityTypeId === expoConfig.domain.entity_type.chamber;
            if (expoUser && !isChamber) {
                const cRes = await expoClient.company.get(expoUser.entityId, expoUser.token);
                const c = cRes.data;

                const chambersRes = await expoClient.company.getChambers(c.id, expoUser.token);
                const subdomain = locationUtil.detectSubdomain();
                if (subdomain) {
                    //Verifico se la camera definita nel subdomain è fra quelle di competenza
                    const selChambers = chambersRes.data.filter((c) => c.subdomain === subdomain);
                    if (selChambers) {
                        expoUser.chamber = selChambers[0];
                    }
                }

                setChamber(expoUser.chamber);

                const cListRes = await expoClient.chambers.getCompanyContactRequests(
                    expoUser.chamber.id,
                    c.id,
                    expoUser.token,
                );
                const requests = cListRes.data;
                console.log('CompanyRequestB2B.useEffect ', requests);
                const regRes = await expoClient.chambers.getRegistration(expoUser.chamber.id, c.id, expoUser.token);
                setRegistrationStatus(regRes.data.statusId);
                setRequests(requests);
            }
        };
        if (!loading && isAuthenticated && expoUser && requests === null) {
            init();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submittingData, isAuthenticated, expoUser, loading]);

    const postData = async (request) => {
        setSubmittingData(true);
        //chiamare Expoclient accept
        //se tutto ok
        const c = request;
        // const acceptRes = await expoClient.chambers.acceptCompany(chamber.id, company.id, expoUser.token);
        // if (typeof acceptRes !== 'undefined') {
        //     //console.log("Risposta rest acceptCompany", acceptRes);
        //     c.approved = acceptRes.status === 200;
        //     setSubmittingData(false);
        // }
    };
    const transitToDataPage = (e, pageToGo) => {
        if (e) e.stopPropagation();
        if (pageToGo === currentDataPage || pageToGo < 0) {
            setDisplayDataPage('none');
            setCurrentDataPage(-1);
        } else {
            setDisplayDataPage('block');
            setCurrentDataPage(pageToGo);
        }
        if (displayDataPageChamber === 'block') setDisplayDataPageChamber('none');
    };
    const normalizeHour = (dateStr) => {
        if (dateStr === null) return '';
        if (dateStr.endsWith('[UTC]')) dateStr = dateStr.substring(0, dateStr.length - 5);
        var date = new Date(dateStr);
        let normalizedDate = '';
        if (date) normalizedDate = moment(date).format('DD.MM.YY') + ' ' + moment(date).format('hh.mm a');
        return normalizedDate;
    };

    const normalizeData = (dateStr) => {
        if (dateStr === null) return '';
        if (dateStr.endsWith('[UTC]')) dateStr = dateStr.substring(0, dateStr.length - 5);
        var date = new Date(dateStr);
        let normalizedDate = '';
        if (date) normalizedDate = moment(date).format('DD.MM.YY');
        return normalizedDate;
    };

    const handleOk = (e) => {
        //console.log("handleOK", e);
        postData(e);
    };

    const goToMyShowCase = () => {
        setCompanies(null);
        setIndex(null);
        history.push('/company');
    };

    /*  const printAddress = e => {
        let address = e.addressVenue + ', ' + e.addressCity + ', ';
        address += e.zipCode ? e.zipCode + ', ' : '';
        address += e.province ? e.province + ', ' : '';
        address += e.country.name;
        return address;
    }*/

    const onVieItemCallB2b = (eventb2b, eventB2bList) => {
        history.push('/eventpage', {
            event: eventb2b,
            eventList: eventB2bList,
        });
    };

    const transitToDataPageFromToolBar = (e) => {
        transitToDataPage(e, -1);
        if (displayDataPageChamber === 'block') setDisplayDataPageChamber('none');
    };

    const creatEventList = (e) => {
        const ret = [];
        if (e && e.eventDtoList) {
            for (const event of e.eventDtoList) {
                ret.push(
                    <div
                        style={{
                            padding: '10px 0px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            fontSize: '18px',
                        }}
                    >
                        <div>
                            <div>
                                {' '}
                                <b>{event.name.toUpperCase()}</b>
                            </div>
                            <div>
                                {' '}
                                <i>
                                    {' '}
                                    {t('event_from_label').toUpperCase()} {normalizeHour(event.startTime)}{' '}
                                    {t('event_to_label').toUpperCase()} {normalizeHour(event.endTime)}
                                </i>
                            </div>
                        </div>
                        <div>
                            <Url
                                style={{ width: '30px', cursor: 'pointer' }}
                                onClick={() => onVieItemCallB2b(event, e.eventDtoList)}
                            />
                        </div>
                    </div>,
                );
            }
        }
        return ret;
    };
    const myShowCaseButtonLabel = t('my_show_case');

    const personalShowcaseButton = (
        <Link onClick={() => goToMyShowCase()}>
            <IconAndLabel label={myShowCaseButtonLabel}>
                <MyShowCaseButton className="dataIcons" style={{ width: '30px' }} />
            </IconAndLabel>
        </Link>
    );

    const cards = [];

    if (requests) {
        for (const e of requests) {
            console.log('Requests ', e);
            if (!(e.statusId === expoConfig.domain.contact_request_status.DONE)) {
                cards.push(
                    <div key={e.id} className="contain-company-request">
                        <div className="header-request">
                            <div style={{ display: 'flex' }}>
                                {e.statusId == expoConfig.domain.contact_request_status.VIEWED ||
                                e.statusId == expoConfig.domain.contact_request_status.REQUESTED ? (
                                    <p style={{ marginBottom: '0px' }}> {t('request_pending').toUpperCase()} </p>
                                ) : (
                                    <p style={{ marginBottom: '0px' }}> {t('request_taking_charge').toUpperCase()} </p>
                                )}
                                {/* {e.statusId == expoConfig.domain.contact_request_status.REQUESTED ? <Badge className="badge_request_b2b" status="processing" style={{ marginLeft: '5px' }} /> : null} */}
                                <div className="contain-icon-pause-save">
                                    {e.statusId == expoConfig.domain.contact_request_status.VIEWED ||
                                    e.statusId == expoConfig.domain.contact_request_status.REQUESTED ? (
                                        <Pending className="icon-pause-save" />
                                    ) : (
                                        <Save className="icon-pause-save" />
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', fontSize: '14px' }}>
                                {' '}
                                <b>{t('request_date_b2b').toUpperCase()}</b>
                                {':'} {normalizeData(e.createdAt)}{' '}
                            </div>
                            <Divider className="divider-company" style={{ margin: '10px 0px 10px' }} />
                        </div>

                        <div className="request_card_header">
                            <Row align="middle" justify="center">
                                <Col
                                    xxl={{ span: 6 }}
                                    xl={{ span: 10 }}
                                    xs={{ span: 10 }}
                                    lg={{ span: 10 }}
                                    sm={{ span: 10 }}
                                    style={{
                                        color: '#000000',
                                        fontSize: '16px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <div className="profile2">
                                        <img width="100%" src={e.requestingCompanyDto.entityDto.logoPath}></img>
                                    </div>
                                </Col>
                                <Col
                                    xl={{ span: 2 }}
                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                >
                                    <ArrowRequest className="arrow-request" />
                                </Col>
                                <Col
                                    xxl={{ span: 6 }}
                                    xl={{ span: 10 }}
                                    xs={{ span: 10 }}
                                    lg={{ span: 10 }}
                                    sm={{ span: 10 }}
                                    style={{
                                        color: '#000000',
                                        fontSize: '16px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <div className="profile2">
                                        <img width="100%" src={e.requestedCompanyDto.entityDto.logoPath}></img>
                                    </div>
                                </Col>
                            </Row>

                            <Row justify="center">
                                <Col
                                    xxl={{ span: 11 }}
                                    xl={{ span: 10 }}
                                    xs={{ span: 11 }}
                                    lg={{ span: 10 }}
                                    sm={{ span: 9 }}
                                >
                                    <div className="container-info-company" style={{ textAlign: 'right' }}>
                                        <div className="name-company-request">
                                            <b>{e.requestingCompanyDto.entityDto.name}</b>
                                        </div>
                                        <div>{e.requestingCompanyDto.entityDto.addressVenue}</div>
                                        <span>
                                            {e.requestingCompanyDto.entityDto.addressCity} -{' '}
                                            {e.requestingCompanyDto.entityDto.country.name}
                                        </span>
                                        {e.requestingUserDto ? (
                                            <div>
                                                {e.requestingUserDto.name} {e.requestingUserDto.surname}
                                            </div>
                                        ) : null}
                                        {e.requestingUserDto ? <div>{e.requestingUserDto.email}</div> : null}
                                    </div>
                                </Col>
                                <Col
                                    xxl={{ span: 11, offset: 2 }}
                                    xl={{ span: 10, offset: 4 }}
                                    xs={{ span: 11, offset: 2 }}
                                    lg={{ span: 10, offset: 4 }}
                                    sm={{ span: 9, offset: 4 }}
                                >
                                    <div className="container-info-company" style={{ textAlign: 'left' }}>
                                        <div className="name-company-request">
                                            <b> {e.requestedCompanyDto.entityDto.name}</b>
                                        </div>
                                        <div>{e.requestedCompanyDto.entityDto.addressVenue}</div>
                                        <span>
                                            {e.requestedCompanyDto.entityDto.addressCity} -{' '}
                                            {e.requestedCompanyDto.entityDto.country.name}
                                        </span>
                                        {e.requestedCompanyDto.entityDto.email ? (
                                            <div>{e.requestedCompanyDto.entityDto.email}</div>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        {e.eventDtoList.length > 0 ? (
                            <Collapse
                                bordered={false}
                                defaultActiveKey={['1']}
                                expandIcon={({ isActive }) => (
                                    <CaretRightOutlined className="icon-arrow-collapse" rotate={isActive ? 90 : 0} />
                                )}
                                className="collapse-card-approvazione collapse-border-radius"
                            >
                                <Panel header={t('scheduled_events').toUpperCase()} id={e.id + '_panel'}>
                                    <div className="container_event_collapse scrollbar-request">
                                        {creatEventList(e)}
                                    </div>
                                </Panel>
                            </Collapse>
                        ) : null}
                    </div>,
                );
            }
        }
    }

    return requests ? (
        <>
            <TopBar onClickCallBack={transitToDataPageFromToolBar}>
                <div className="blackLink topBarIconCol">{personalShowcaseButton}</div>
            </TopBar>
            <Spin
                spinning={submittingData}
                indicator={<Loading3QuartersOutlined spin style={{ fontSize: '100px', color: '#000' }} />}
            >
                {registrationStatus === expoConfig.domain.registration.active ? (
                    <div style={{ textAlign: 'center' }}>
                        <div className="panelHeaderContainer panelTitle">{t('request_b2b').toUpperCase()}</div>
                        <Title level={4}> {t('request_b2b_company_unpublish')}</Title>
                    </div>
                ) : null}

                <div className="section-shadow">
                    <Row className="company_acceptance card_container">
                        <Col span="24">
                            <Row key="1" gutter={[8, 24]} justify="center">
                                <Col xl={{ span: 12 }} xs={{ span: 24 }} lg={{ span: 16 }} sm={{ span: 20 }}>
                                    <Row gutter={[8, 24]}>
                                        <Col span="24">{cards}</Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Spin>
        </>
    ) : null;
};
export default CompanyRequestB2B;
