import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Space, Row, Col } from 'antd';
import Avatar from '../B2B/Avatar';
import getMeetingStatusContext from '../../context/getMeetingStatusContext';
import RemoteVideo from './RemoteVideo';

const RosterLine = ({ data, you }) => {
    const { t } = useTranslation();

    const fullname = data.name + ' ' + data.surname + (you ? t('self-roster') : '');
    const avatarText = (data.name[0] + data.surname[0]).toUpperCase();

    const { b2bInfo, colorMap } = useContext(getMeetingStatusContext());

    const tokenColor = you
        ? colorMap.find((c) => c.token === b2bInfo.user.token)
        : colorMap.find((c) => c.token === data.token);

    return (
        <Row>
            <Col>
                <Space style={{ marginTop: '15px', marginLeft: '5px' }}>
                    <Avatar
                        content={avatarText}
                        active={true}
                        scale="medium"
                        color={tokenColor ? tokenColor.color : '#FFF'}
                    />
                    <div>
                        <p className="attendeeNameRoster">{fullname}</p>
                        <p className="attendeeCompanyRoster">
                            {data.businessRole ? data.businessRole + ', ' + data.entityName : data.entityName}
                        </p>
                    </div>
                </Space>
            </Col>
        </Row>
    );
};

export default RosterLine;
