import _ from 'lodash';
import React from 'react';

import { ReactComponent as LeftArrow } from '../../icons/sx3.svg';
import { ReactComponent as RightArrow } from '../../icons/dx3.svg';

import './TopBar.css';

const TopBarArrow = ({ direction, onClick = _.identity }) => {
    const arrow =
        direction === 'right' ? (
            <RightArrow className="topBar-arrow right" onClick={onClick} />
        ) : (
            <LeftArrow className="topBar-arrow" onClick={onClick} />
        );

    return <div className="topBar-arrow-container">{arrow}</div>;
};
export default TopBarArrow;
