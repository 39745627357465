import _ from 'lodash';
import React, { Component } from 'react';
import { Form, Input, Row, Col, Typography, Select, notification, Button, Divider } from 'antd';
import { Label } from 'semantic-ui-react';

import { withTranslation } from 'react-i18next';
import { ReactComponent as AddMemberIcon } from '../icons/addmember.svg';
import { Modal } from 'semantic-ui-react';
import ConfirmModal from './ConfirmModal';
import { ReactComponent as ModifyMemberIcon } from '../icons/modifymember.svg';
import { ReactComponent as DeleteMember } from '../icons/elimina.svg';
import { ReactComponent as CloseButton } from '../icons/close.svg';
import PricingTable from './PricingTable';
import PricingSlot from './PricingTable/PricingSlot';
import PricingDetail from './PricingTable/PricingDetail';
import history from '../utils/history';

import location from '../utils/location';
import expoConfig from '../config/expo_config.json';
import expoClient from '../expoClient';
import './CompanyUsers.css';
import UserEditor from './UserEditor.js';
import TextArea from 'antd/lib/input/TextArea';

class CompanyUsers extends Component {
    constructor(props) {
        super(props);
        const { t } = this.props;
        this.state = {
            company: props.company,
            expouser: props.expouser,
            logout: props.logout,
            isEditable: props.isEditable,
            companyUsers: props.companyUsers,
            isChamberUser: props.isChamberUser,
            userData: {
                // used to populate "account" reducer when "Load" is clicked
                contactname: props.expouser.user.name,
                contactsurname: props.expouser.user.surname,
                contactbusinessrole: props.expouser.businessRole,
                contactprofile: this.fromNumberToStringProfile(props.expouser.roleId),
                contactemail: props.expouser.user.email,
            },
            showUserEditor: null,
            userToEdit: false,
            userEditorTitle: t('user_data_insert_label'),
            profiles: [
                { id: expoConfig.domain.role.admin, name: 'admin' },
                { id: expoConfig.domain.role.standard, name: 'standard' },
            ],
            toShow: props.toShow,
            // entity lette dalle API
            countries: [],
            companySubscription: null,
        };

        //Serve per aggiornare questo flag dai componenti figli
        this.handleDisableSubmit = this.handleDisableSubmit.bind(this);
        this.mySelfformRef = React.createRef();
        this.dataFormRef = React.createRef();
        this.postMySelfData = this.postMySelfData.bind(this);
        this.postData = this.postData.bind(this);
        //Detect sottodominio
    }

    getCompanySubscription = async function () {
        if ((this.props.expouser.chamber && !this.state.companySubscription) || this.state.isChamberUser) {
            //conosciamo la camera leggo il piano sottoscritto, autorizzazioni e dettagli sottoscrizione
            const regResponse = await expoClient.chambers.getRegistration(
                this.props.expouser.chamber.id,
                this.props.company.id,
                this.props.expouser.token,
            );
            console.log('NavBarUser.useEffect got registration data', regResponse.data);
            this.props.company.registration = regResponse.data;
            if (this.props.company.registration.subscriptionId) {
                //Info della sottoscrizione fatta
                const subsResponse = await expoClient.subscription.get(
                    this.props.company.registration.subscriptionId,
                    this.props.expouser.token,
                );
                console.log('NavBarUser.useEffect got subscription data', subsResponse.data);
                this.props.company.subscription = subsResponse.data;
                this.setState({ companySubscription: subsResponse.data });
            }
        }
    };
    handleDelete = async function (chosenUser, me) {
        const { t } = this.props;

        try {
            await expoClient.user.delete(chosenUser.id, this.state.expouser.token);
            const desc = t('delete_user_ok');
            notification.success({
                key: 'postputUser',
                message: desc,
                placement: 'topRight',
                duration: 7,
            });
            if (me) {
                await this.props.logout({
                    returnTo: window.location.origin,
                });
                history.push('/');
            }
        } catch (error) {
            console.error(error);
            const msg = _.capitalize(t('error'));
            let desc = t('delete_user_nok');
            notification.error({
                key: 'postRegistrationError',
                message: msg,
                description: desc,
                placement: 'topRight',
                duration: 0,
            });
        } finally {
            this.setState({ submittingData: false });
            this.props.callBackForModifyUserData();
        }
    };
    cancelCompanySubscription = async function () {
        const { t } = this.props;

        try {
            const paypalToken = await expoClient.paypal.getToken();
            console.log('Paypal token', paypalToken.data.access_token);
            await expoClient.paypal.cancelSubscription(
                this.state.companySubscription.subscriptionId,
                paypalToken.data.access_token,
            );
            await expoClient.subscription.cancel(this.state.companySubscription.id, this.props.expouser.token);
            const desc = 'cancellazione effettuata con successo';
            notification.success({
                key: 'postputUser',
                message: desc,
                placement: 'topRight',
                duration: 7,
            });
        } catch (error) {
            console.error('postContactRequest:', error);
            const msg = 'errore nella cancellazione, contattare amministratore';
            let desc = t('request_contact_nok');
            notification.error({
                key: 'postSubriptionCancellationError',
                message: msg,
                description: desc,
                placement: 'topRight',
                duration: 0,
            });
        } finally {
        }
    };
    makeCompanySubscription = function () {
        history.push('/subscription');
    };
    handleDisableSubmit(disableSubmit) {
        this.setState({
            disableSubmit: disableSubmit,
        });
    }

    componentDidMount() {
        expoClient.countries
            .get()
            .then((res) => {
                this.setState({ countries: res.data });
            })
            .catch((err) => console.info('getCoutries: ' + err));
        this.getCompanySubscription();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.toShow !== this.props.toShow) {
            this.setState({ toShow: this.props.toShow });
        }
        if (prevProps.company !== this.props.company) {
            let companyNew = Object.assign({}, this.props.company);
            this.setState({ company: companyNew });
        }
        if (prevProps.companyUsers !== this.props.companyUsers) {
            this.setState({ companyUsers: this.props.companyUsers });
        }
        if (prevProps.expouser !== this.props.expouser) {
            this.setState({ expouser: this.props.expouser });
        }
        this.getCompanySubscription();
    }
    postMySelfData = async function (values) {
        const { t } = this.props;
        this.setState({ submittingData: true });
        const userData = {
            businessRole: values.contactbusinessrole,
            roleId: parseInt(this.fromStringToNumberProfile(values.contactprofile)),
            user: {
                name: values.contactname,
                surname: values.contactsurname,
                email: this.state.expouser.user.email,
            },
        };
        try {
            await expoClient.user.modify(this.state.expouser.user.id, userData, this.state.expouser.token);
            const desc = t('modify_user_ok');
            notification.success({
                key: 'postputUser',
                message: desc,
                placement: 'topRight',
                duration: 7,
            });
            this.refreshMyUser(values);
        } catch (error) {
            console.error(error);
            const msg = _.capitalize(t('error'));
            let desc = t('modify_user_nok');
            notification.error({
                key: 'postRegistrationError',
                message: msg,
                description: desc,
                placement: 'topRight',
                duration: 0,
            });
        } finally {
            this.setState({ submittingData: false });
            this.props.callBackForModifyUserData();
        }
    };

    postData = async function (values) {
        const { t } = this.props;
        this.setState({ submittingData: true });
        const c = this.state.company;
        c.vatNumber = values.companyvatnumber;
        c.name = values.companyname;
        c.addressVenue = values.companyvenue;
        c.zipCode = values.companyzipcode;
        c.province = values.companyprovince;
        c.addressCity = values.companycity;
        c.website = values.companywebsite;
        c.phone = values.companyphone;
        c.countryId = parseInt(this.state.company.countryId);
        c.description = this.state.company.description;
        c.email = this.state.company.email;
        this.setState({ company: c });

        try {
            await expoClient.company.modify(this.state.company.id, this.state.company, this.state.expouser.token);
            let desc = t('modify_data_ok');
            notification.success({
                key: 'postputUser',
                message: desc,
                placement: 'topRight',
                duration: 7,
            });
        } catch (error) {
            console.error(error);
            const msg = _.capitalize(t('error'));
            let desc = t('modify_data_nok');
            notification.error({
                key: 'postRegistrationError',
                message: msg,
                description: desc,
                placement: 'topRight',
                duration: 0,
            });
        } finally {
            this.setState({ submittingData: false });
        }
    };

    refreshMyUser(values) {
        this.setState({
            userData: {
                // used to populate "account" reducer when "Load" is clicked
                contactname: values.contactname,
                contactsurname: values.contactsurname,
                contactbusinessrole: values.contactbusinessrole,
                contactprofile: values.contactprofile,
                contactemail: this.state.expouser.user.email,
            },
        });
    }

    fromStringToNumberProfile(roleName) {
        const { t } = this.props;

        switch (roleName) {
            case t('user_data_role_admin'):
                return expoConfig.domain.role.admin;
            case t('user_data_role_standard'):
                return expoConfig.domain.role.standard;
            default:
                return expoConfig.domain.role.standard;
        }
    }

    fromNumberToStringProfile(roleId) {
        const { t } = this.props;

        switch (roleId) {
            case expoConfig.domain.role.admin:
                return t('user_data_role_admin');
            case expoConfig.domain.role.standard:
                return t('user_data_role_standard');
            default:
                return t('user_data_role_unknown');
        }
    }

    renderRole(roleId) {
        const { t } = this.props;

        switch (roleId) {
            case expoConfig.domain.role.admin:
                return t('user_data_role_admin');
            case expoConfig.domain.role.standard:
                return t('user_data_role_standard');
            default:
                return t('user_data_role_unknown');
        }
    }

    fromNumberToStringPlan(planId) {
        const { t } = this.props;
        switch (planId) {
            case expoConfig.domain.subscription_plan.PREMIUM:
                return t('user_data_plan_premium');
            case expoConfig.domain.subscription_plan.BUSINESS:
                return t('user_data_plan_business');
            case expoConfig.domain.subscription_plan.EXECUTIVE:
                return t('user_data_plan_executive');
            default:
                return t('user_data_plan_unknown');
        }
    }

    renderUser(data) {
        const { t } = this.props;
        const businessRole = data.businessRole ? data.businessRole + ' - ' : null;
        const role =
            data.roleId === expoConfig.domain.role.admin
                ? '(' + this.fromNumberToStringProfile(data.roleId).toLowerCase() + ')'
                : null;
        const buttonModify =
            this.state.expouser.user.id !== data.user.id &&
            this.state.expouser.roleId == expoConfig.domain.role.admin &&
            this.state.isEditable ? (
                <>
                    <Col span={1} offset={2}>
                        <ModifyMemberIcon
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '10px',
                                width: '25px',
                                cursor: 'pointer',
                            }}
                            onClick={() => this.clickedOnModify(data)}
                        ></ModifyMemberIcon>
                    </Col>
                    <Col span={1} offset={2}>
                        <ConfirmModal
                            message={
                                <div>
                                    <p>
                                        {t('request_delete_profile_member', {
                                            member: data.user.name + ' ' + data.user.surname,
                                        })}
                                    </p>
                                </div>
                            }
                            onOk={() => this.handleDelete(data.user, false)}
                        >
                            <DeleteMember
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: '10px',
                                    width: '25px',
                                    cursor: 'pointer',
                                }}
                            ></DeleteMember>
                        </ConfirmModal>
                    </Col>
                </>
            ) : null;
        return (
            <Row style={{ width: '100%', marginTop: '10px' }}>
                <Col span={18}>
                    <Row style={{ textAlign: 'initial' }}>
                        <Col span={24}>
                            <span className="charFontFamily nameandsurname">
                                {data.user.name} {data.user.surname} {role}
                            </span>
                        </Col>
                    </Row>
                    <Row justify="initial" style={{ textAlign: 'initial' }}>
                        <Col span={24}>
                            <span className="charFontFamily mailandrole">
                                {businessRole}
                                {data.user.email}
                            </span>
                        </Col>
                    </Row>
                </Col>
                {buttonModify}
            </Row>
        );
    }

    clickedOnAdd() {
        const { t } = this.props;
        this.setState({ userToEdit: null });
        this.setState({ showUserEditor: true });
        this.setState({ userEditorTitle: t('user_data_insert_label') });
    }

    clickedOnModify(chosenUser) {
        const { t } = this.props;
        this.setState({ userToEdit: chosenUser });
        this.setState({ showUserEditor: true });
        this.setState({ userEditorTitle: t('user_data_modify_label') });
    }

    setShowModalUserEditor(valore) {
        this.setState({ showUserEditor: valore });
    }

    closeShowUserModal() {
        this.setState({ showUserEditor: false });
    }

    normalizeData(dateStr) {
        if (dateStr === null) return '';
        if (dateStr.endsWith('[UTC]')) dateStr = dateStr.substring(0, dateStr.length - 5);
        var date = new Date(dateStr);
        let normalizedDate = '';
        if (date) normalizedDate = date.toLocaleDateString();
        return normalizedDate;
    }

    normalizeAmount(amount, currency) {
        const { t } = this.props;
        let toReturn = amount;
        let feeNotIn = t('fee_not_included');
        if (currency !== null) {
            toReturn += currency == 'EUR' ? '€' : '$';
        }
        toReturn += '(' + feeNotIn + ')';
        return toReturn;
    }

    render() {
        const { t } = this.props;
        const { Option } = Select;
        const { countries } = this.state;

        const companyData = {
            companyvatnumber: this.state.company.vatNumber,
            companyname: this.state.company.name,
            companyvenue: this.state.company.addressVenue,
            companyzipcode: this.state.company.zipCode,
            companyprovince: this.state.company.province,
            companycity: this.state.company.addressCity,
            companywebsite: this.state.company.website,
            companyphone: this.state.company.phone,
            companycountry: this.state.company.countryId.toString(),
            companycountrycode:
                t(`country_${this.state.company.country.code}`) + ' ' + '(' + this.state.company.country.code + ')',
            companydescription: this.state.company.description,
            companycontactemail: this.state.company.email,
        };

        const closeUserModal = (
            <ConfirmModal onOk={() => this.closeShowUserModal()} message={t('user_data_ask_for_close')}>
                <CloseButton className="exitButtonOnLoading" />
            </ConfirmModal>
        );

        const RenderCountry = ({ data }) => {
            return (
                <Row>
                    <Col className="charFontFamily" span="16" style={{ textAlign: 'left' }}>
                        {t(`country_${data.code}`)} ({data.code})
                    </Col>
                    {/* <Col className="charFontFamily" span="8" style={{ textAlign: 'right' }}>{data.code}</Col> */}
                </Row>
            );
        };

        const buildPlanDescription = (planSelected) => {
            return (
                <PricingTable>
                    {/*
                <Row style={{ marginTop: "10px" }}>
                    <Col span={24} className="planDescriptionLabel">
                        {_.capitalize(t('subscription_view_company'))}
                    </Col>
                    <Col span={24} className="planDescriptionLabel">
                        {_.capitalize(t('subscription_b2b'))}
                    </Col>
                    <Col span={24} className="planDescriptionLabel">
                        {_.capitalize(t('subscription_basic_showcase_edit'))}
                    </Col>
                    <Col span={24} className="planDescriptionLabel">
                        {_.capitalize(t('subscription_business_matching_score'))}
                    </Col>
                    <Col span={24} className="planDescriptionLabel">
                        {_.capitalize(t('subscription_view_products_showcase'))}
                    </Col>
                    <Col span={24} className="planDescriptionLabel">
                        {_.capitalize(t('subscription_full_showcase_edit'))}
                    </Col>
                </Row> */}
                    <PricingSlot
                        title={planSelected}
                        shouldDisplayButton={false}
                        backgroundColor="#bf3f48"
                        border="2px solid #bf3f48"
                    >
                        <PricingDetail>{_.capitalize(t('subscription_view_company'))}</PricingDetail>
                        <PricingDetail>{_.capitalize(t('subscription_b2b'))}</PricingDetail>
                        <PricingDetail>{_.capitalize(t('subscription_basic_showcase_edit'))}</PricingDetail>
                        <PricingDetail
                            active={
                                planSelected === t('user_data_plan_business') ||
                                planSelected === t('user_data_plan_executive')
                            }
                        >
                            {_.capitalize(t('subscription_full_showcase_edit'))}
                        </PricingDetail>
                        <PricingDetail
                            active={
                                planSelected === t('user_data_plan_business') ||
                                planSelected === t('user_data_plan_executive')
                            }
                        >
                            {_.capitalize(t('subscription_business_matching_score'))}
                        </PricingDetail>
                        <PricingDetail
                            active={
                                planSelected === t('user_data_plan_business') ||
                                planSelected === t('user_data_plan_executive')
                            }
                        >
                            {_.capitalize(t('subscription_view_products_showcase'))}
                        </PricingDetail>
                        <PricingDetail active={planSelected === t('user_data_plan_executive')}>
                            {_.capitalize(t('subscription_direct_b2b'))}
                        </PricingDetail>
                    </PricingSlot>
                </PricingTable>
            );
        };

        const planDescription = this.props.company.registration
            ? buildPlanDescription(this.fromNumberToStringPlan(this.props.company.registration.companyPlanId))
            : null;
        console.log(this.props.expouser);

        const currentCountry = this.state.company.country ? (
            <Row>
                <Col span="16" style={{ textAlign: 'left' }}>
                    {t(`country_${this.props.company.country.code}`)}
                </Col>
                <Col span="8" style={{ textAlign: 'right' }}>
                    {this.props.company.country.code}
                </Col>
            </Row>
        ) : (
            ''
        );

        const currentCountryCode = 'country_' + this.props.company.country.code;
        const p = t(currentCountryCode);
        /* const countryObject = this.state.isEditable ?
            <Col xl={{span:8, offset:3}} md={{ span: 8, offset: 3 }} xs={{ span: 20, offset: 2 }}><Label style={{ marginBottom: "20px" }} className="userCountryLabel charFontFamily">{t('signup_placeholder_company_country')}</Label>
                <Form.Item name="companycountry" rules={[{ required: true, message: t('signup_required_company_country') }]}>
                    <Select allowClear bordered={false} style={{ width: '100%' }}
                        size="large"
                    >
                        {countries.map(c => <Option key={c.id} value={c.id.toString()}><RenderCountry data={c} /></Option>)}
                    </Select
                </Form.Item>
            </Col> : */

        const visibleFirst = this.props.toShow === 0; //Dati Aziendali
        const visibleSecond = this.props.toShow === 1; //Utenti
        const visibleThird = this.props.toShow === 2; // Piano Sottoscritto
        let creationDate = this.normalizeData(this.state.company.createdAt);
        return (
            <Row className="userSliderContainer">
                <Modal
                    key="modalUserEditor"
                    open={this.state.showUserEditor}
                    onClose={() => this.closeShowUserModal()}
                    trigger={null}
                    header={null}
                    actions={null}
                    closeOnDimmerClick={false}
                    closeOnEscape={false}
                    closeOnDocumentClick={false}
                    closeOnTriggerMouseLeave={false}
                    closeOnTriggerClick={false}
                    className="modalUserEditor"
                >
                    <Row>
                        <Row
                            style={{
                                width: '100%',
                                height: '80px',
                                backgroundColor: '#111111',
                                color: 'white',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <div className="companyDataTitle" style={{ color: 'white' }}>
                                {this.state.userEditorTitle}
                            </div>
                        </Row>

                        <Col xl={{ span: 12, offset: 6 }} xs={{ span: 20, offset: 2 }}>
                            <UserEditor
                                parentInstance={this}
                                profiles={this.state.profiles}
                                currentUser={this.state.userToEdit}
                                expouser={this.state.expouser}
                                company={this.state.company}
                                isCompany={true}
                                callBackForModifyUserData={this.props.callBackForModifyUserData}
                            />
                        </Col>
                        {closeUserModal}
                    </Row>
                </Modal>
                <Row style={{ width: '100%', marginTop: '50px', marginBottom: '50px' }}>
                    <Col span={24}>
                        {visibleFirst ? (
                            <div>
                                <Row justify="center">
                                    <Col xl={{ span: 14 }} xs={{ span: 20 }}>
                                        <Form
                                            style={{
                                                border: '4px solid #707070',
                                                borderRadius: '16px',
                                                backgroundColor: '#fff',
                                                boxShadow: '0px -4px 8px #00000029',
                                            }}
                                            ref={this.dataFormRef}
                                            name="dataForm"
                                            onFinish={this.postData}
                                            initialValues={companyData}
                                        >
                                            <Row className="rowCompanyDataTitle" style={{ width: '100%' }}>
                                                <Col span={24} className="companyDataTitleMainData charFontFamily">
                                                    {t('company_data_main_label').toUpperCase()}
                                                </Col>
                                                <Col span={24} className="companyDataCreationDate charFontFamily">
                                                    <span>
                                                        {t('company_data_signup_time')} {creationDate}
                                                    </span>
                                                </Col>
                                                <Divider className="divider-company" />
                                            </Row>
                                            <Row style={{ marginTop: '30px' }}>
                                                <Col span={24}>
                                                    <Row align="middle">
                                                        <Col
                                                            xl={{ span: 10, offset: 1 }}
                                                            md={{ span: 8, offset: 3 }}
                                                            xs={{ span: 20, offset: 2 }}
                                                        >
                                                            <Label
                                                                style={{ marginBottom: '45px' }}
                                                                className="userSliderLabel charFontFamily"
                                                            >
                                                                {t('signup_placeholder_company_name') + '*'}
                                                            </Label>
                                                            <Form.Item
                                                                name="companyname"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: t('signup_required_company_name'),
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    disabled={!this.state.isEditable}
                                                                    style={{
                                                                        background: '#fff 0% 0% no-repeat padding-box',
                                                                        color: 'black',
                                                                    }}
                                                                    className="companyDataInput"
                                                                    size="large"
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col
                                                            xl={{ span: 10, offset: 2 }}
                                                            md={{ span: 8, offset: 3 }}
                                                            xs={{ span: 20, offset: 2 }}
                                                        >
                                                            <Label
                                                                style={{ marginBottom: '45px' }}
                                                                className="userSliderLabel charFontFamily"
                                                            >
                                                                {t('signup_placeholder_company_country')}
                                                            </Label>
                                                            <Form.Item
                                                                name="companycountrycode"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: t('signup_required_company_country'),
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    disabled={true}
                                                                    style={{
                                                                        background: '#fff 0% 0% no-repeat padding-box',
                                                                        color: 'black',
                                                                    }}
                                                                    size="large"
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row align="bottom">
                                                        <Col
                                                            xl={{ span: 10, offset: 1 }}
                                                            md={{ span: 8, offset: 3 }}
                                                            xs={{ span: 20, offset: 2 }}
                                                        >
                                                            <Label
                                                                style={{ marginBottom: '45px' }}
                                                                className="userSliderLabel charFontFamily"
                                                            >
                                                                {t('signup_placeholder_company_venue')}
                                                            </Label>
                                                            <Form.Item
                                                                name="companyvenue"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: t('signup_required_company_venue'),
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    maxLength="100"
                                                                    disabled={!this.state.isEditable}
                                                                    style={{
                                                                        background: '#fff 0% 0% no-repeat padding-box',
                                                                        color: 'black',
                                                                        fontSize: '16px',
                                                                    }}
                                                                    size="large"
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col
                                                            xl={{ span: 10, offset: 2 }}
                                                            md={{ span: 8, offset: 3 }}
                                                            xs={{ span: 20, offset: 2 }}
                                                        >
                                                            <Label
                                                                style={{ marginBottom: '45px' }}
                                                                className="userSliderLabel charFontFamily"
                                                            >
                                                                {t('signup_placeholder_company_phone')}
                                                            </Label>
                                                            <Form.Item
                                                                name="companyphone"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: t('signup_required_company_phone'),
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    disabled={!this.state.isEditable}
                                                                    style={{
                                                                        background: '#fff 0% 0% no-repeat padding-box',
                                                                        color: 'black',
                                                                    }}
                                                                    size="large"
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col
                                                            xl={{ span: 10, offset: 1 }}
                                                            md={{ span: 8, offset: 3 }}
                                                            xs={{ span: 20, offset: 2 }}
                                                        >
                                                            <Label
                                                                style={{ marginBottom: '45px' }}
                                                                className="userSliderLabel charFontFamily"
                                                            >
                                                                {t('signup_placeholder_company_zip_code')}
                                                            </Label>
                                                            <Form.Item
                                                                name="companyzipcode"
                                                                rules={[
                                                                    { message: t('signup_required_company_zip_code') },
                                                                ]}
                                                            >
                                                                <Input
                                                                    disabled={!this.state.isEditable}
                                                                    style={{
                                                                        background: '#fff 0% 0% no-repeat padding-box',
                                                                        color: 'black',
                                                                    }}
                                                                    size="large"
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col
                                                            xl={{ span: 10, offset: 2 }}
                                                            md={{ span: 8, offset: 3 }}
                                                            xs={{ span: 20, offset: 2 }}
                                                        >
                                                            <Label
                                                                style={{ marginBottom: '45px' }}
                                                                className="userSliderLabel charFontFamily"
                                                            >
                                                                {t('signup_placeholder_company_vatnumber')}
                                                            </Label>
                                                            <Form.Item
                                                                name="companyvatnumber"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: t('signup_required_company_vatnumber'),
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    disabled={!this.state.isEditable}
                                                                    style={{
                                                                        background: '#fff 0% 0% no-repeat padding-box',
                                                                        color: 'black',
                                                                    }}
                                                                    size="large"
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col
                                                            xl={{ span: 10, offset: 1 }}
                                                            md={{ span: 8, offset: 3 }}
                                                            xs={{ span: 20, offset: 2 }}
                                                        >
                                                            <Label
                                                                style={{ marginBottom: '45px' }}
                                                                className="userSliderLabel charFontFamily"
                                                            >
                                                                {t('signup_placeholder_company_city')}
                                                            </Label>
                                                            <Form.Item
                                                                name="companycity"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: t('signup_required_company_city'),
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    disabled={!this.state.isEditable}
                                                                    style={{
                                                                        background: '#fff 0% 0% no-repeat padding-box',
                                                                        color: 'black',
                                                                    }}
                                                                    size="large"
                                                                    placeholder={t('signup_placeholder_company_city')}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col
                                                            xl={{ span: 10, offset: 2 }}
                                                            md={{ span: 8, offset: 3 }}
                                                            xs={{ span: 20, offset: 2 }}
                                                        >
                                                            <Label
                                                                style={{ marginBottom: '45px' }}
                                                                className="userSliderLabel charFontFamily"
                                                            >
                                                                {t('signup_placeholder_company_website')}
                                                            </Label>
                                                            <Form.Item
                                                                name="companywebsite"
                                                                rules={[
                                                                    {
                                                                        type: 'url',
                                                                        message: t('signup_required_company_website'),
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    disabled={!this.state.isEditable}
                                                                    style={{
                                                                        background: '#fff 0% 0% no-repeat padding-box',
                                                                        color: 'black',
                                                                    }}
                                                                    size="large"
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            {this.state.isEditable ? (
                                                <Row style={{ marginTop: '40px' }} justify="center">
                                                    <Col>
                                                        <Form.Item>
                                                            <a href="#" className="scrollToTop">
                                                                <Button
                                                                    htmlType="submit"
                                                                    block
                                                                    shape="round"
                                                                    className="blackMode"
                                                                >
                                                                    {t('company_data_button_save')}
                                                                </Button>
                                                            </a>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            ) : null}
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                        ) : null}
                        {visibleSecond ? (
                            <div>
                                <Row>
                                    <Col span="24">
                                        <Row>
                                            <Col span="24">
                                                <Row style={{ marginTop: '10px' }} justify="center">
                                                    <Col
                                                        xl={{ span: 7, offset: 0 }}
                                                        lg={{ span: 20, offset: 0 }}
                                                        xs={{ span: 20, offset: 0 }}
                                                        className="col-companyData"
                                                    >
                                                        <Row
                                                            className="rowCompanyDataTitle"
                                                            style={{ width: '100%', backgroundColor: '#be1522' }}
                                                        >
                                                            <Col span={23} className="companyDataTitle charFontFamily">
                                                                {t('user_data_users').toUpperCase()}
                                                            </Col>
                                                            <Divider className="divider-company" />
                                                        </Row>
                                                        <Row className="scrollbar-colored">
                                                            <Col span={24}>
                                                                {this.state.companyUsers
                                                                    ? this.state.companyUsers.map((m, index) =>
                                                                          this.renderUser(m),
                                                                      )
                                                                    : null}
                                                            </Col>
                                                        </Row>
                                                        {this.state.isEditable ? (
                                                            <Row justify="center" style={{ margin: '40px 0px 25px' }}>
                                                                {this.state.expouser.roleId ==
                                                                expoConfig.domain.role.admin ? (
                                                                    <Button
                                                                        className="blackMode"
                                                                        shape="round"
                                                                        onClick={() => this.clickedOnAdd()}
                                                                    >
                                                                        {t('user_data_insert')}
                                                                    </Button>
                                                                ) : null}
                                                            </Row>
                                                        ) : null}
                                                    </Col>

                                                    <Col
                                                        xl={{ span: 7, offset: 1 }}
                                                        lg={{ span: 20, offset: 0 }}
                                                        xs={{ span: 20, offset: 0 }}
                                                    >
                                                        <Form
                                                            className="form-company"
                                                            ref={this.mySelfformRef}
                                                            name="mySelfForm"
                                                            onFinish={this.postMySelfData}
                                                            initialValues={this.state.userData}
                                                        >
                                                            <Row
                                                                className="rowCompanyDataTitle"
                                                                style={{ width: '100%' }}
                                                            >
                                                                <Col
                                                                    span={24}
                                                                    className="companyDataTitle charFontFamily"
                                                                >
                                                                    {t('user_data_main_label').toUpperCase()}
                                                                </Col>
                                                                <Divider className="divider-company" />
                                                            </Row>
                                                            <Row style={{ marginTop: '30px' }} justify="center">
                                                                <Col span={20}>
                                                                    <Label
                                                                        style={{ marginBottom: '45px' }}
                                                                        className="userSliderLabel charFontFamily"
                                                                    >
                                                                        {t('company_contact_name')}
                                                                    </Label>
                                                                    <Form.Item
                                                                        name="contactname"
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: t('user_data_name_required'),
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            style={{
                                                                                background:
                                                                                    '#fff 0% 0% no-repeat padding-box',
                                                                                color: 'black',
                                                                            }}
                                                                            size="large"
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row justify="center">
                                                                <Col span={20}>
                                                                    <Label
                                                                        style={{ marginBottom: '45px' }}
                                                                        className="userSliderLabel charFontFamily"
                                                                    >
                                                                        {t('company_contact_surname')}
                                                                    </Label>
                                                                    <Form.Item
                                                                        name="contactsurname"
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: t(
                                                                                    'user_data_surname_required',
                                                                                ),
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            style={{
                                                                                background:
                                                                                    '#fff 0% 0% no-repeat padding-box',
                                                                                color: 'black',
                                                                            }}
                                                                            size="large"
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row justify="center">
                                                                <Col span={20}>
                                                                    <Label
                                                                        style={{ marginBottom: '45px' }}
                                                                        className="userSliderLabel charFontFamily"
                                                                    >
                                                                        {t('company_contact_role')}
                                                                    </Label>
                                                                    <Form.Item name="contactbusinessrole">
                                                                        <Input
                                                                            style={{
                                                                                background:
                                                                                    '#fff 0% 0% no-repeat padding-box',
                                                                                color: 'black',
                                                                            }}
                                                                            size="large"
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row justify="center">
                                                                <Col span={20}>
                                                                    <Label
                                                                        style={{ marginBottom: '45px' }}
                                                                        className="userSliderLabel charFontFamily"
                                                                    >
                                                                        {t('company_contact_email')}
                                                                    </Label>
                                                                    <Form.Item
                                                                        name="contactemail"
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: t('user_data_email_required'),
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            disabled={true}
                                                                            style={{
                                                                                background:
                                                                                    '#fff 0% 0% no-repeat padding-box',
                                                                                color: 'black',
                                                                            }}
                                                                            size="large"
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row justify="center">
                                                                <Col span={20}>
                                                                    <Label
                                                                        style={{ marginBottom: '45px' }}
                                                                        className="userSliderLabel charFontFamily"
                                                                    >
                                                                        {t('company_contact_profile')}
                                                                    </Label>
                                                                    <Form.Item name="contactprofile">
                                                                        <Input
                                                                            disabled={true}
                                                                            style={{
                                                                                background:
                                                                                    '#fff 0% 0% no-repeat padding-box',
                                                                                color: 'black',
                                                                            }}
                                                                            size="large"
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row style={{ marginTop: '40px' }} justify="center">
                                                                <Col className="two-button-distance">
                                                                    <Form.Item>
                                                                        <ConfirmModal
                                                                            message={
                                                                                <div>
                                                                                    <p>{t('request_delete_profile')}</p>
                                                                                </div>
                                                                            }
                                                                            onOk={() =>
                                                                                this.handleDelete(
                                                                                    this.state.expouser.user,
                                                                                    true,
                                                                                )
                                                                            }
                                                                        >
                                                                            <Button
                                                                                disabled={false}
                                                                                block
                                                                                shape="round"
                                                                                className="blackMode"
                                                                            >
                                                                                {t(
                                                                                    'label_delete_profile',
                                                                                ).toUpperCase()}
                                                                            </Button>
                                                                        </ConfirmModal>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col className="two-button-distance">
                                                                    <Form.Item>
                                                                        <Button
                                                                            htmlType="submit"
                                                                            disabled={false}
                                                                            block
                                                                            shape="round"
                                                                            className="blackMode"
                                                                        >
                                                                            {t('company_data_button_save')}
                                                                        </Button>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        ) : null}
                        {visibleThird ? (
                            <div>
                                <Row>
                                    <Col span="24">
                                        <Row>
                                            <Col span="24">
                                                <Row style={{ marginTop: '-20px' }} justify="center" align="middle">
                                                    <Col
                                                        xl={{ span: 7, offset: 0 }}
                                                        lg={{ span: 8, offset: 0 }}
                                                        md={{ span: 9, offset: 0 }}
                                                        xs={{ span: 20, offset: 0 }}
                                                        align="center"
                                                    >
                                                        {planDescription}
                                                    </Col>
                                                    <Col
                                                        xl={{ span: 6, offset: 0 }}
                                                        lg={{ span: 8, offset: 4 }}
                                                        md={{ span: 9, offset: 2 }}
                                                        xs={{ span: 20, offset: 2 }}
                                                        style={{
                                                            border: '4px solid #707070',
                                                            borderRadius: '16px',
                                                            backgroundColor: '#fff',
                                                            boxShadow: '0px -4px 8px #00000029',
                                                        }}
                                                    >
                                                        <Row
                                                            justify="center"
                                                            style={{
                                                                width: '100%',
                                                                background:
                                                                    'transparent linear-gradient(180deg, #707070 0%, #212121 100%) 0% 0% no-repeat padding-box',
                                                                borderTopLeftRadius: '10px',
                                                                borderTopRightRadius: '10px',
                                                            }}
                                                        >
                                                            <Col className="companyDataTitle charFontFamily">
                                                                {t('company_data_profile_validity_label')}
                                                            </Col>
                                                        </Row>
                                                        <Row justify="center">
                                                            <Col style={{ padding: '10px 40px 20px' }}>
                                                                {this.state.isChamberUser ? (
                                                                    <>
                                                                        <Label className="subscriptionLabel">
                                                                            {t(
                                                                                'company_data_profile_access_subscription_date_label',
                                                                            ).toUpperCase()}
                                                                        </Label>
                                                                        <Label className="subscriptionLabelValue">
                                                                            {this.state.companySubscription
                                                                                ? this.normalizeData(
                                                                                      this.state.companySubscription
                                                                                          .start,
                                                                                  )
                                                                                : null}
                                                                        </Label>
                                                                        <Label className="subscriptionLabel ">
                                                                            {t(
                                                                                'company_data_profile_access_subscription_expiration_label',
                                                                            ).toUpperCase()}
                                                                        </Label>
                                                                        <Label className="subscriptionLabelValue">
                                                                            {this.state.companySubscription
                                                                                ? this.normalizeData(
                                                                                      this.state.companySubscription
                                                                                          .expiration,
                                                                                  )
                                                                                : null}
                                                                        </Label>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Label className="subscriptionLabel">
                                                                            {t(
                                                                                'company_data_profile_access_subscription_date_label',
                                                                            ).toUpperCase()}
                                                                        </Label>
                                                                        <Label className="subscriptionLabelValue">
                                                                            {this.state.companySubscription
                                                                                ? this.normalizeData(
                                                                                      this.state.companySubscription
                                                                                          .start,
                                                                                  )
                                                                                : null}
                                                                        </Label>
                                                                        {this.state.companySubscription &&
                                                                        this.state.companySubscription
                                                                            .automaticPaymentActive ? (
                                                                            <Label className="subscriptionLabel ">
                                                                                {t(
                                                                                    'company_data_profile_access_subscription_renew_label',
                                                                                ).toUpperCase()}
                                                                            </Label>
                                                                        ) : null}
                                                                        {this.state.companySubscription &&
                                                                        this.state.companySubscription
                                                                            .automaticPaymentActive ? (
                                                                            <Label className="subscriptionLabelValue">
                                                                                {this.state.companySubscription
                                                                                    ? this.normalizeData(
                                                                                          this.state.companySubscription
                                                                                              .expiration,
                                                                                      )
                                                                                    : null}
                                                                            </Label>
                                                                        ) : null}

                                                                        {this.state.companySubscription &&
                                                                        this.state.companySubscription
                                                                            .automaticPaymentActive ? (
                                                                            <ConfirmModal
                                                                                onOk={() =>
                                                                                    this.cancelCompanySubscription()
                                                                                }
                                                                                message="vuoi cancellare ?"
                                                                            >
                                                                                <div style={{ textAlign: 'center' }}>
                                                                                    {' '}
                                                                                    <Button
                                                                                        disabled={false}
                                                                                        block
                                                                                        shape="round"
                                                                                        className="blackMode cancelButton"
                                                                                    >
                                                                                        {t(
                                                                                            'company_data_profile_cancel_sub',
                                                                                        ).toUpperCase()}
                                                                                    </Button>
                                                                                </div>
                                                                            </ConfirmModal>
                                                                        ) : this.state.companySubscription &&
                                                                          this.state.companySubscription.amount == 0 ? (
                                                                            <Label className="subscriptionLabel">
                                                                                {t(
                                                                                    'company_data_profile_access_subscription_free_label',
                                                                                ).toUpperCase()}
                                                                                <Label className="subscriptionLabelValue">
                                                                                    {this.state.companySubscription
                                                                                        ? this.normalizeData(
                                                                                              this.state
                                                                                                  .companySubscription
                                                                                                  .expiration,
                                                                                          )
                                                                                        : null}
                                                                                </Label>
                                                                                <div style={{ textAlign: 'center' }}>
                                                                                    {' '}
                                                                                    <Button
                                                                                        disabled={false}
                                                                                        onClick={() =>
                                                                                            this.makeCompanySubscription()
                                                                                        }
                                                                                        block
                                                                                        shape="round"
                                                                                        className="blackMode cancelButton"
                                                                                    >
                                                                                        {t(
                                                                                            'company_data_profile_make_sub',
                                                                                        ).toUpperCase()}
                                                                                    </Button>
                                                                                </div>
                                                                            </Label>
                                                                        ) : (
                                                                            <Label className="subscriptionLabel">
                                                                                {t(
                                                                                    'company_data_profile_access_subscription_disabled_label',
                                                                                ).toUpperCase()}
                                                                                <Label className="subscriptionLabel">
                                                                                    {t(
                                                                                        'company_data_profile_access_subscription_disabled_label_2',
                                                                                    ).toUpperCase()}
                                                                                </Label>
                                                                                <Label className="subscriptionLabelValue">
                                                                                    {this.state.companySubscription
                                                                                        ? this.normalizeData(
                                                                                              this.state
                                                                                                  .companySubscription
                                                                                                  .expiration,
                                                                                          )
                                                                                        : null}
                                                                                </Label>
                                                                            </Label>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                        {/*                                                         <Row style={{ marginTop: "50px", height: "50px" }}>
                                                            <Col span={7} offset={8}>
                                                                <Form.Item>
                                                                    <Button htmlType="submit" disabled={false} block shape="round" className="blackMode">{t('company_data_button_save')}</Button>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row> */}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        ) : null}
                    </Col>
                </Row>
            </Row>
        );
    }
}
export default withTranslation()(CompanyUsers);
