import React, { useContext, useEffect, useState } from 'react';
import { Col, Modal, Popover, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import getChimeContext from '../../context/getChimeContext';

import { ReactComponent as VideoDisabled } from '../../icons/video-disabled.svg';
import { ReactComponent as VideoEnabled } from '../../icons/video-enabled.svg';
import { ReactComponent as AudioEnabled } from '../../icons/audio-enabled.svg';
import { ReactComponent as AudioDisabled } from '../../icons/audio-disabled.svg';
import { ReactComponent as CallClose } from '../../icons/call-close.svg';
import { ReactComponent as ShareDesktop } from '../../icons/share-desktop.svg';
import { ReactComponent as Settings } from '../../icons/cog.svg';
import { ReactComponent as Microphone } from '../../icons/microphone.svg';
import { ReactComponent as Audio } from '../../icons/audio.svg';
import { ReactComponent as Camera } from '../../icons/camera.svg';

import styles from './Controls.css';
import ViewMode from '../../enums/ViewMode';

const cx = classNames.bind(styles);
const VideoStatus = {
    Disabled: 'DISABLED',
    Loading: 'LOADING',
    Enabled: 'ENABLED',
};

export default function Controls(props) {
    const { t } = useTranslation();
    const { viewMode, onClickShareButton, onClickLeaveButton, isContentShareEnabled, shareUser } = props;
    const chime = useContext(getChimeContext());
    //const { b2bInfo } = useContext(getMeetingStatusContext());
    const [muted, setMuted] = useState(false);
    const [videoStatus, setVideoStatus] = useState(VideoStatus.Disabled);
    const [showSharePopup, setShowSharePopup] = useState(false);
    const [showDeviceChooser, setShowDeviceChooser] = useState(false);
    const isShareActive = viewMode === ViewMode.ScreenShare;

    useEffect(() => {
        const callback = (localMuted) => {
            setMuted(localMuted);
        };
        if (chime && chime.audioVideo) {
            //console.log('Controls.useEffect registering localAudioObserver');
            if (muted) chime.audioVideo.realtimeMuteLocalAudio();
            chime.audioVideo.realtimeSubscribeToMuteAndUnmuteLocalAudio(callback);
        }
        return () => {
            if (chime && chime.audioVideo) {
                //console.log('Controls.useEffect unregistering localAudioObserver');
                chime.audioVideo.realtimeUnsubscribeToMuteAndUnmuteLocalAudio(callback);
            }
        };
    }, []);

    const toggleMyVideo = async () => {
        if (videoStatus === VideoStatus.Disabled) {
            setVideoStatus(VideoStatus.Loading);
            if (chime) {
                try {
                    if (!chime.currentVideoInputDevice) {
                        throw new Error('currentVideoInputDevice does not exist');
                    }
                    await chime.chooseVideoInputDevice(chime.currentVideoInputDevice);
                    if (chime.audioVideo) {
                        chime.audioVideo.startLocalVideoTile();
                        setVideoStatus(VideoStatus.Enabled);
                    }
                } catch (error) {
                    // eslint-disable-next-line
                    console.error(error);
                    setVideoStatus(VideoStatus.Disabled);
                }
            }
        } else if (videoStatus === VideoStatus.Enabled) {
            setVideoStatus(VideoStatus.Loading);
            if (chime && chime.audioVideo) {
                chime.audioVideo.stopLocalVideoTile();
                setVideoStatus(VideoStatus.Disabled);
            }
        }
    };

    const leaveCall = async () => {
        console.log('Controls.onClickLeaveButton leaving the room');
        // setVideoStatus(VideoStatus.Loading);
        // if (chime && chime.audioVideo) {
        // 	await chime.audioVideo.stopLocalVideoTile();
        // 	setVideoStatus(VideoStatus.Disabled);
        // }
        // await sleep(2000);
        await chime.leaveRoom();
        onClickLeaveButton();
    };

    function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    const toggleMyAudio = async () => {
        if (chime && chime.audioVideo) {
            muted ? chime.audioVideo.realtimeUnmuteLocalAudio() : chime.audioVideo.realtimeMuteLocalAudio();
        }
    };

    const toggleMySharing = () => {
        //TODO: se isContentShareEnabled === true aprire popup che mostra avviso
        //TODO: altrimenti eseguire onClickShareButton
        if (isContentShareEnabled) {
            setShowSharePopup(true);
            setTimeout(() => setShowSharePopup(false), 3000);
        } else onClickShareButton();
    };

    const openModalDevice = () => {
        setShowDeviceChooser(true);
    };

    const closeModalDevice = () => {
        setShowDeviceChooser(false);
    };

    const handleChangeAudioInput = async (device) => {
        if (chime && chime.audioVideo) {
            await chime.chooseAudioInputDevice(device);
        }
    };

    const handleChangeAudioOutput = async (device) => {
        if (chime && chime.audioVideo) {
            await chime.chooseAudioOutputDevice(device);
        }
    };

    const handleChangeVideoInput = async (device) => {
        const wasEnabled = videoStatus === VideoStatus.Enabled;
        if (wasEnabled) {
            setVideoStatus(VideoStatus.Loading);
            if (chime && chime.audioVideo) {
                await chime.audioVideo.stopLocalVideoTile();
                setVideoStatus(VideoStatus.Disabled);
            }
        }
        await chime.chooseVideoInputDevice(device);
        if (wasEnabled && chime.audioVideo) {
            await chime.audioVideo.startLocalVideoTile();
            setVideoStatus(VideoStatus.Enabled);
        }
    };

    return (
        <React.Fragment>
            {/* DEVICE CHOOSER */}
            <Modal
                title={t('title_device_chooser')}
                centered
                visible={showDeviceChooser}
                onOk={closeModalDevice}
                onCancel={closeModalDevice}
                footer={null}
            >
                {/* AUDIO INPUT */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '1rem',
                        padding: '1em 0',
                    }}
                >
                    <Microphone style={{ width: '24px' }} />
                    <select
                        className="deviceSelect minimal"
                        name="audioInput"
                        onChange={(e) => {
                            //console.log('Selezionato input audio', e.target.value);
                            handleChangeAudioInput(chime.audioInputDevices[e.target.value]);
                        }}
                    >
                        {chime.audioInputDevices.map((audio, index) => {
                            //console.log('Audio', index, audio);
                            const selected = chime.currentAudioInputDevice?.value === audio.value;
                            return (
                                <option key={`audioin_${index}`} value={index} selected={selected}>
                                    {audio.label}
                                </option>
                            );
                        })}
                    </select>
                </div>
                {/* AUDIO OUTPUT */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '1rem',
                        padding: '1em 0',
                    }}
                >
                    <Audio style={{ width: '24px' }} />
                    <select
                        className="deviceSelect minimal"
                        name="audioOutput"
                        onChange={(e) => {
                            //console.log('Selezionato output audio', e.target.value);
                            handleChangeAudioOutput(chime.audioOutputDevices[e.target.value]);
                        }}
                    >
                        {chime.audioOutputDevices.map((audio, index) => {
                            //console.log('Audio out', index, audio);
                            const selected = chime.currentAudioOutputDevice?.value === audio.value;
                            return (
                                <option key={`audioout_${index}`} value={index} selected={selected}>
                                    {audio.label}
                                </option>
                            );
                        })}
                    </select>
                </div>
                {/* VIDEO INPUT */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '1rem',
                        padding: '1em 0',
                    }}
                >
                    <Camera style={{ width: '24px' }} />
                    <select
                        className="deviceSelect minimal"
                        name="videoInput"
                        onChange={(e) => {
                            handleChangeVideoInput(chime.videoInputDevices[e.target.value]);
                        }}
                    >
                        {chime.videoInputDevices.map((video, index) => {
                            console.log('VideoInput', index, video);
                            const selected = chime.currentVideoInputDevice?.value === video.value;
                            return (
                                <option key={`videoin_${index}`} value={index} selected={selected}>
                                    {video.label}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </Modal>

            <Row id="footerBar" className="controls_wrapper" align="center">
                <Col span={2}>
                    <Settings
                        className="settingsIconStyle"
                        // style={{ color: 'white', background: '#121212', width: '45px' }}
                        onClick={openModalDevice}
                    />
                </Col>
                <Col span={2} offset={9}>
                    <div className="central_wrapper">
                        <div className="pointer btn" onClick={toggleMyVideo}>
                            {videoStatus === VideoStatus.Enabled ? (
                                <VideoEnabled style={{ width: '45px' }} />
                            ) : (
                                <VideoDisabled style={{ width: '45px' }} />
                            )}
                        </div>
                        <div className="pointer btn" onClick={leaveCall}>
                            <CallClose style={{ width: '45px' }} />
                        </div>
                        <div className="pointer btn" onClick={toggleMyAudio}>
                            {muted ? (
                                <AudioDisabled style={{ width: '45px' }} />
                            ) : (
                                <AudioEnabled style={{ width: '45px' }} />
                            )}
                        </div>
                    </div>
                </Col>
                <Col span={10} offset={1}>
                    <Row justify="end">
                        <Col style={{ display: 'flex', alignItems: 'center', fontSize: '20px' }}>
                            <Popover content={t('no_share_tt')} visible={showSharePopup} placement="leftBottom">
                                {!isContentShareEnabled && isShareActive
                                    ? t('presenting_text')
                                    : isContentShareEnabled && shareUser && !isShareActive
                                    ? `${shareUser.name} ${shareUser.surname}` + t('presenting')
                                    : null}
                            </Popover>
                        </Col>
                        <Col>
                            <div
                                className="pointer btn"
                                style={{ justifyContent: 'flex-end' }}
                                title={t('share_tt')}
                                onClick={toggleMySharing}
                            >
                                {console.log('---------> ', isShareActive, isContentShareEnabled)}
                                <ShareDesktop
                                    className={cx({
                                        sharingIconStyle: isShareActive,
                                        cantSharingIconStyle: !isShareActive && isContentShareEnabled,
                                        notSharingIconStyle: !isShareActive && !isContentShareEnabled,
                                    })}
                                    style={{ width: '45px' }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    );
}
