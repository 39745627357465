import _ from 'lodash';
import React from 'react';
import 'antd/dist/antd.css';
import './UploadFile.css';
import { withTranslation } from 'react-i18next';

import { Form, Input, Row, Col, Select, Button, notification } from 'antd';
import { Label } from 'semantic-ui-react';
import expoConfig from '../config/expo_config.json';
import expoClient from '../expoClient';

class UserEditor extends React.Component {
    constructor(props) {
        super(props);
        //this.callBackOnExit = props.callBackOnExit;
        this.parentIstance = props.parentInstance;
        this.state = {
            currentUser: props.currentUser,
            expouser: props.expouser,
            entityUsers: props.entityUsers,
            // userDataMod: {
            //   // used to populate "account" reducer when "Load" is clicked
            //   contactname_mod: props.currentUser ? props.currentUser.user.name : null,
            //   contactsurname_mod: props.currentUser ? props.currentUser.user.surname : null,
            //   contactbusinessrole_mod: props.currentUser ? props.currentUser.businessRole : null,
            //   // contactprofile_mod: props.currentUser ? this.fromNumberToStringProfile(props.currentUser.roleId): null,
            //   contactemail_mod: props.currentUser ? props.currentUser.user.email : null
            // }
        };
        this.userformRef = React.createRef();
        this.postUserData = this.postUserData.bind(this);
        this.company = props.company;
    }

    postUserData = async function (values) {
        const { t } = this.props;

        const userData = {
            businessRole: values.contactbusinessrole_mod,
            roleId: values.contactprofile_mod,
            user: {
                name: values.contactname_mod,
                surname: values.contactsurname_mod,
                email: values.contactemail_mod,
            },
        };
        let desc = '';
        try {
            let res = undefined;
            if (!this.props.currentUser) {
                desc = t('create_user_ok');
                if (this.props.isCompany) {
                    res = await expoClient.chambers.createCompanyUser(
                        this.state.expouser.chamber.id,
                        this.company.id,
                        userData,
                        this.state.expouser.token,
                    );
                } else {
                    res = await expoClient.chambers.createUser(
                        this.state.expouser.chamber.id,
                        userData,
                        this.state.expouser.token,
                    );
                }
            } else {
                desc = t('modify_user_ok');
                res = await expoClient.user.modify(this.props.currentUser.user.id, userData, this.state.expouser.token);
            }
            if (typeof res !== 'undefined' && res && res.status === 200) {
                notification.success({
                    key: 'postputUser',
                    message: desc,
                    placement: 'topRight',
                    duration: 7,
                });
            }
        } catch (error) {
            console.log('ERROR', error);
            console.error(error);
            const msg = _.capitalize(t('error'));
            notification.error({
                key: 'postputUserError',
                message: msg,
                description: t(`error_${error}`),
                placement: 'topRight',
                duration: 0,
            });
        } finally {
            this.parentIstance.closeShowUserModal();

            this.props.callBackForModifyUserData();
        }
    };
    fromNumberToStringProfile(roleId) {
        const { t } = this.props;

        switch (roleId) {
            case expoConfig.domain.role.admin:
                return t('user_data_role_admin');
            case expoConfig.domain.role.standard:
                return t('user_data_role_standard');
            default:
                return t('user_data_role_unknown');
        }
    }

    fromStringToNumberProfile(roleName) {
        const { t } = this.props;

        switch (roleName) {
            case t('user_data_role_admin'):
                return expoConfig.domain.role.admin;
            case t('user_data_role_standard'):
                return expoConfig.domain.role.standard;
            default:
                return expoConfig.domain.role.standard;
        }
    }

    render() {
        const { t } = this.props;
        const { Option } = Select;
        const { currentUser = null } = this.props;

        const userDataMod = {
            // used to populate "account" reducer when "Load" is clicked
            contactname_mod: currentUser ? currentUser.user.name : null,
            contactsurname_mod: currentUser ? currentUser.user.surname : null,
            contactbusinessrole_mod: currentUser ? currentUser.businessRole : null,
            contactemail_mod: currentUser ? currentUser.user.email : null,
        };

        if (currentUser) {
            userDataMod.contactprofile_mod = currentUser.roleId.toString();
        }

        const RenderProfile = ({ data }) => {
            return (
                <Row>
                    <Col className="charFontFamily" span="16" style={{ textAlign: 'left' }}>
                        {t(`user_data_role_${data.name}`)}
                    </Col>
                </Row>
            );
        };

        const profileObject = (
            <Col span={24}>
                <Form.Item
                    name="contactprofile_mod"
                    rules={[{ required: true, message: t('user_data_profile_required') }]}
                >
                    <Select
                        bordered={false}
                        style={{ width: '100%' }}
                        size="large"
                        placeholder={t('user_data_profile_select') + '*'}
                    >
                        {this.props.profiles.map((c) => (
                            <Option key={c.id} value={c.id.toString()}>
                                <RenderProfile data={c} />
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
        );
        return (
            <Row>
                <Col span="24">
                    <Row>
                        <Col span="24">
                            <Row style={{ marginTop: '10px' }}>
                                <Col span={24}>
                                    <Form
                                        ref={this.userformRef}
                                        name="userForm"
                                        onFinish={this.postUserData}
                                        initialValues={userDataMod}
                                    >
                                        <Row style={{ width: '100%' }}>
                                            <Col
                                                span={24}
                                                className="companyDataTitle charFontFamily"
                                                style={{ color: '#000' }}
                                            >
                                                {t('user_data_label')}
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '30px' }}>
                                            <Col span={24}>
                                                <Label
                                                    style={{ marginBottom: '45px', backgroundColor: 'white' }}
                                                    className="userSliderLabel charFontFamily"
                                                >
                                                    {t('company_contact_name')}*
                                                </Label>
                                                <Form.Item
                                                    name="contactname_mod"
                                                    rules={[{ required: true, message: t('user_data_name_required') }]}
                                                >
                                                    <Input
                                                        style={{
                                                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                            color: 'black',
                                                        }}
                                                        size="large"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Label
                                                    style={{ marginBottom: '45px', backgroundColor: 'white' }}
                                                    className="userSliderLabel charFontFamily"
                                                >
                                                    {t('company_contact_surname')}*
                                                </Label>
                                                <Form.Item
                                                    name="contactsurname_mod"
                                                    rules={[
                                                        { required: true, message: t('user_data_surname_required') },
                                                    ]}
                                                >
                                                    <Input
                                                        style={{
                                                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                            color: 'black',
                                                        }}
                                                        size="large"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Label
                                                    style={{ marginBottom: '45px', backgroundColor: 'white' }}
                                                    className="userSliderLabel charFontFamily"
                                                >
                                                    {t('company_contact_role')}
                                                </Label>
                                                <Form.Item name="contactbusinessrole_mod">
                                                    <Input
                                                        style={{
                                                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                            color: 'black',
                                                        }}
                                                        size="large"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Label
                                                    style={{ marginBottom: '45px', backgroundColor: 'white' }}
                                                    className="userSliderLabel charFontFamily"
                                                >
                                                    {t('company_contact_email')}*
                                                </Label>
                                                <Form.Item
                                                    name="contactemail_mod"
                                                    type="email"
                                                    rules={[
                                                        { required: true, message: t('user_data_email_required') },
                                                        { type: 'email', message: t('the_input_invalid_mail') },
                                                    ]}
                                                >
                                                    <Input
                                                        disabled={this.props.currentUser ? true : false}
                                                        style={{
                                                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                            color: 'black',
                                                        }}
                                                        size="large"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>{profileObject}</Row>
                                        <Row style={{ marginTop: '20px', height: '100px' }} justify="center">
                                            <Col>
                                                <Form.Item>
                                                    <Button
                                                        htmlType="submit"
                                                        disabled={false}
                                                        block
                                                        shape="round"
                                                        className="blackMode"
                                                    >
                                                        {t('company_data_button_save')}
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}
export default withTranslation()(UserEditor);
