import React from 'react';
import { Col, Row } from 'antd';
import './MeetingVideo.css';
import '../../css/flag-css.min.css';
import { ReactComponent as AudioDisabled } from '../../icons/audio-disabled.svg';
import { useTranslation } from 'react-i18next';
import EventLayout from './EventLayout';

const MeetingVideo = ({ vid, videoEntity, attendeeNum, sharing, layout, bgImg = null, pinCB }) => {
    const name = videoEntity ? videoEntity.name : null;
    const surname = videoEntity ? videoEntity.surname : null;
    const role = videoEntity ? videoEntity.role : null;
    const initials = videoEntity ? videoEntity.initials : null;
    let entity = videoEntity ? videoEntity.entity : null;
    let nation = videoEntity ? videoEntity.nation : null;
    const color = videoEntity ? videoEntity.color : 'transparent';
    const hasVideo = videoEntity ? videoEntity.tileId !== null : false;
    const muted = videoEntity ? videoEntity.muted : false;
    const volume = videoEntity ? videoEntity.volume : 0;
    const pinned = videoEntity ? videoEntity.pinned : false;

    const { t } = useTranslation();

    let fullname = name && surname ? name + ' ' + surname : null;
    fullname = fullname && vid === 'shared-video' ? fullname + t('presenting') : fullname;
    nation = nation ? nation.toLowerCase() : null;

    /*fullname = _.truncate(fullname, {
		  'length': 16
	  });
	  entity = _.truncate(entity, {
		  'length': 25
	  });*/

    const elem_id = vid !== 'local-video' && vid !== 'shared-video' ? 'other-video-' + vid : vid;

    const vSpan = hasVideo ? 24 : 0;
    const scale =
        sharing || layout === EventLayout.LAYOUTS.BORDER
            ? 'xxsmall'
            : window.innerWidth < 500
            ? 'xxxsmall'
            : window.innerWidth < 900
            ? 'small'
            : attendeeNum < 3
            ? 'xxlarge'
            : attendeeNum < 4
            ? 'xlarge'
            : attendeeNum < 6
            ? 'large'
            : attendeeNum < 8
            ? 'medium'
            : attendeeNum < 11
            ? 'small'
            : attendeeNum < 14
            ? 'xsmall'
            : 'xxsmall';

    //const hasToBlinkAction = speaking ? 'blink .1s step-end infinite alternate' : null;

    const volRad = Math.round(15 + volume * 85) + '%';

    //const meetVideoBG = vid === 'shared-video' ? 'transparent' : '#121212';

    const onPinClick = (e) => {
        console.log("I'm pinned ", e.target.getAttribute('value'));
    };

    if (!pinCB) pinCB = onPinClick;

    const setPin = !['local-video', 'shared-video', 0, 1].includes(vid) && layout === EventLayout.LAYOUTS.BORDER;

    const pin = setPin ? (
        <img
            className="videoPin"
            onClick={pinCB}
            value={vid}
            alt={'pin ' + vid}
            src={require(pinned ? '../../icons/pinOn.png' : '../../icons/pin.png')}
        />
    ) : null;

    let volumeSize = '30px';
    let volClass = 'videoFooterVolume';
    let plateClass = 'videoFooterPlate';
    let nameClass = 'videoName';

    const smallPlate = sharing || [EventLayout.LAYOUTS.MOBILE_V, EventLayout.LAYOUTS.MOBILE_H].includes(layout);

    if (smallPlate) {
        volumeSize = '15px';
        volClass = 'videoFooterVolumeSharing';
        plateClass = 'videoFooterPlateSharing';
        nameClass = 'videoNameSharing';
    }

    plateClass += vid === 'shared-video' ? (window.innerWidth < 500 ? ' isSharer isMobile' : ' isSharer') : '';

    const share_style = {
        width: '100%',
        height: 'auto',
    };

    if (layout === EventLayout.LAYOUTS.TILED && vid === 'shared-video') {
        share_style.width = 'auto';
        share_style.height = '100%';
    }

    const onVideoLoaded = (e) => {
        if (vid === 'shared-video') {
            if (layout === EventLayout.LAYOUTS.MOBILE_V) {
                const otherColumn = document.getElementById('other-column');
                if (otherColumn) otherColumn.style.height = 'calc(100% - ' + e.currentTarget.offsetHeight + 'px)';
            } else if ([EventLayout.LAYOUTS.TILED, EventLayout.LAYOUTS.MOBILE_H].includes(layout)) {
                const videoStream = document.getElementById(vid + '-stream');
                const sharedVideo = document.getElementById('shared-video');

                if (videoStream && sharedVideo) {
                    const v = e.currentTarget;
                    const v_ratio = v.videoWidth / v.videoHeight;
                    const s_ratio = sharedVideo.offsetWidth / sharedVideo.offsetHeight;

                    if (v_ratio > s_ratio) {
                        videoStream.style.width = '100%';
                        videoStream.style.height = 'auto';
                    } else {
                        videoStream.style.height = '100%';
                        videoStream.style.width = 'auto';
                    }
                }
            }
        } else if (elem_id.includes('other-video-')) {
            const videoStream = document.getElementById(elem_id + '-stream');

            if (videoStream) {
                const v = e.currentTarget;

                if (v.videoWidth / v.videoHeight > 1) {
                    videoStream.style.width = '100%';
                    videoStream.style.height = 'auto';
                } else {
                    videoStream.style.height = '100%';
                    videoStream.style.width = 'auto';
                }
            }
        }
    };

    /*if ([EventLayout.LAYOUTS.MOBILE_V].includes(layout)) {
		  if (sharing) {
			  share_style['width'] = 'auto';
			  share_style['height'] = '100%';
		  } else {
			  share_style['width'] = '100%';
			  share_style['height'] = 'auto';
		  }
	  } else if ([EventLayout.LAYOUTS.TILED].includes(layout)) {
		  share_style['width'] = '100%';
		  share_style['height'] = 'auto';
	  }*/

    const sub_name = entity && role ? role + ', ' + entity : entity ? entity : '';

    return (
        <Row style={{ height: '100%' }}>
            {pin}
            <Col id={elem_id} className="call-video" span={vSpan} style={{ display: vSpan === 0 ? 'none' : 'flex' }}>
                <video
                    id={elem_id + '-stream'}
                    className="videoEmbed"
                    style={share_style}
                    onLoadedData={onVideoLoaded}
                />
            </Col>
            <Col
                id={elem_id + '-ph'}
                span={vSpan === 0 && !bgImg ? 24 : 0}
                className="call-video-ph"
                style={{ display: vSpan === 0 && !bgImg ? 'flex' : 'none' }}
            >
                <div className={'circularPH ' + scale} style={{ border: '2px solid' + color }}>
                    <p className="attendeeName" style={{ color: color }}>
                        {videoEntity !== null && name !== null ? initials : ''}
                    </p>
                </div>
            </Col>
            <Col align="center" span={vSpan === 0 && bgImg ? 24 : 0}>
                <img alt="event cover" style={{ height: '100%' }} src={require('../../images/event_cover.jpg')} />
            </Col>
            <Col span={24} className="videoFooter">
                <div
                    className={volClass}
                    style={{
                        display: vid === 'shared-video' ? 'none' : muted ? 'flex' : 'none',
                    }}
                >
                    <AudioDisabled style={{ width: volumeSize }} />
                </div>
                <div
                    className={volClass}
                    style={{
                        display: vid === 'shared-video' ? 'none' : muted ? 'none' : 'flex',
                    }}
                >
                    <div style={{ width: volumeSize, height: volumeSize }}>
                        <div className="videoFooterVolumeIndicator" style={{ width: volRad, height: volRad }} />
                    </div>
                </div>
                <div className={plateClass}>
                    <div className={nameClass} title={fullname ? fullname : ''}>
                        {fullname ? fullname : ''}
                    </div>
                    <div className="videoAgency" style={{ display: smallPlate ? 'none' : 'block' }}>
                        <p style={{ float: 'left', fontSize: '10px' }} title={sub_name}>
                            <span className={nation ? `flag flag-${nation}` : ''} style={{ marginRight: '5px' }} />
                            {sub_name}
                        </p>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default MeetingVideo;
