import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Switch, Typography } from 'antd';
import { Card, Grid, Icon, Image, List } from 'semantic-ui-react';

import visitor from '../images/visitor.png';

const EventEntityAttendeesCard = ({ attendees, editMode = false, chamberUser = false, creatorUserId = null }) => {
    const { t } = useTranslation();
    const { Text } = Typography;
    const renderEntitiesCard = () =>
        attendees && attendees.length > 0 ? (
            <Grid className="masonry" columns={2} key="1">
                {/* ordino le entity per tipo (camere prima delle companies) e nome */}
                {_.sortBy(attendees, ['entityTypeId', 'name'], ['asc', 'asc']).map((entity) => {
                    //console.log("renderCard", entity);
                    return renderCard(entity);
                })}
            </Grid>
        ) : null;

    const renderCard = (entity) =>
        entity ? (
            <Grid.Column key={entity.entityId}>
                <Card fluid className="selected-company">
                    <Card.Content>
                        <Image
                            className="square"
                            floated="right"
                            size="tiny"
                            src={entity.logoPath ? entity.logoPath : visitor}
                        />
                        <Card.Header style={{ fontSize: '20px' }}>
                            {' '}
                            {entity.description === 'guest' ? t('event_external_guest_list') : entity.name}
                        </Card.Header>
                        <Card.Meta>
                            {' '}
                            {entity.country
                                ? `${entity.addressCity} - ${entity.country.code.toUpperCase()}`
                                : null}{' '}
                        </Card.Meta>
                    </Card.Content>
                    <Card.Content>
                        <List key={`listheader_${entity.entityId}`}>
                            <List.Item key="listItem_title">
                                <List.Icon name="users" />
                                <List.Content>{t('event_guest_list')}</List.Content>
                            </List.Item>
                        </List>
                    </Card.Content>
                    <Card.Content>
                        <List key={`list_${entity.entityId}`}>{renderUsers(entity)}</List>
                    </Card.Content>
                </Card>
            </Grid.Column>
        ) : null;

    const renderUsers = (entity) =>
        entity
            ? entity.users.map((u) => {
                  if (entity.attendees) {
                      const invited = entity.attendees.filter((user) => user.id === u.id).length > 0;
                      return renderUser(u, entity.entityId, invited);
                  }
                  return renderUser(u, entity.entityId);
              })
            : null;

    const renderUser = (user, entityId, invited = true) =>
        user ? (
            <List.Item key={`listItem_${user.id}`}>
                {editMode ? (
                    <>
                        <Form.Item
                            label={`${user.name} ${user.surname}`}
                            name={`entity_${entityId}_user_${user.id}`}
                            valuePropName="aria-checked"
                            style={{ marginBottom: '0' }}
                            initialValue={invited}
                        >
                            <Switch size="small" defaultChecked={invited} disabled={creatorUserId === user.id} />
                        </Form.Item>
                        <List.Content style={{ paddingBottom: '10px', fontStyle: 'italic', fontWeight: 'lighter' }}>
                            {user.businessRole ? `(${user.businessRole} - ${user.email})` : `(${user.email})`}
                        </List.Content>
                    </>
                ) : chamberUser || invited ? ( //In lettura vedo solo gli invitati
                    <>
                        <List.Content floated="right">
                            {invited ? <Icon name="checkmark" color="green" /> : <Icon name="lock" color="red" />}
                        </List.Content>
                        <List.Content style={{ paddingBottom: '5px', wordWrap: 'break-word' }}>
                            <p style={{ paddingBottom: '0' }}>
                                <strong>
                                    {user.name} {user.surname} {user.companyName ? ' - ' + user.companyName : null}{' '}
                                </strong>
                            </p>
                            <p style={{ fontStyle: 'italic' }}>
                                {user.businessRole ? `(${user.businessRole} - ${user.email})` : `(${user.email})`}
                            </p>
                        </List.Content>
                        {user.link && chamberUser ? (
                            <List.Content style={{ paddingBottom: '20px', wordWrap: 'break-word' }}>
                                <Text copyable>{user.link}</Text>
                            </List.Content>
                        ) : null}
                    </>
                ) : null}
            </List.Item>
        ) : null;

    return renderEntitiesCard();
};
export default EventEntityAttendeesCard;
