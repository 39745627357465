import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddMedia } from '../icons/aggiungi.svg';
import ConfirmModal from './ConfirmModal';
import { ReactComponent as AddImage } from '../icons/addimage.svg';
import { Button } from 'antd';

import './EditSaveDiscardIconsImage.css';

const EditIconsImage = (props) => {
    const { t } = useTranslation();
    const { enabled, editMode, callBackOnEdit, cssPositionClass } = props;

    const clickOnEdit = () => {
        callBackOnEdit();
    };

    console.log('EditSaveDiscardIcons: enabled, editMode', enabled, editMode);
    const editModeLogoButton = enabled ? (
        !editMode ? (
            <div key="1" className="button-media" onClick={() => clickOnEdit()}>
                <AddImage className="upload-icon-media" />
                <div className="container-icon-media">
                    <AddMedia className="icon-plus-media" />
                    <span style={{ fontSize: '20px' }}> {t('image_media').toUpperCase()} </span>
                </div>
            </div>
        ) : null
    ) : null;

    return (
        <div id="container" className={cssPositionClass}>
            {editModeLogoButton}
        </div>
    );
};
export default EditIconsImage;
