import _ from 'lodash';

import React, { useEffect, useRef, useState } from 'react';
import { Parallax } from 'react-parallax';
import { Button, Card, Carousel, Col, Divider, List, Row } from 'antd';
import { EyeOutlined, GlobalOutlined, LeftOutlined, RightOutlined, TeamOutlined } from '@ant-design/icons';

import { ReactComponent as Exclusivity } from '../icons/esclusività.svg';
import { ReactComponent as Brokerage } from '../icons/intermediazione.svg';
import { ReactComponent as Involvement } from '../icons/coinvolgimento.svg';
import { ReactComponent as Metric } from '../icons/metrica.svg';
import { ReactComponent as Continuity } from '../icons/continuità.svg';

import { useTranslation } from 'react-i18next';
import { useAuth0 } from '../react-auth0-spa';

import history from '../utils/history';
import location from '../utils/location';
import { ReactComponent as ArrowLeft } from '../icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../icons/arrow-right.svg';
import expoClient from '../expoClient';
import expoConfig from '../config/expo_config.json';

import CookieConsent from 'react-cookie-consent';

import './NoAuthPage.css';

const NoAuthPage = () => {
    const { loginWithRedirect } = useAuth0();
    const spotCarousel = useRef();
    const joinedCarousel = useRef();
    const videoLiveCarousel = useRef();
    //const modalVideoLiveCarousel = useRef();
    const { t } = useTranslation();
    const chamberSubdomain = location.detectSubdomain();
    const [chamber, setChamber] = useState(null);
    const [companies, setCompanies] = useState(null);
    const [lives, setLives] = useState(null);
    //const [showModalLive, setShowModalLive] = useState(false);
    //const [liveToShowIndex, setLiveToShowIndex] = useState(0);
    const [showCompaniesArrow, setShowCompaniesArrow] = useState(true);
    const [showLivesArrow, setShowLivesArrow] = useState(true);

    useEffect(() => {
        const init = async (subdomain) => {
            if (subdomain === null) return null;

            const chamberResult = await expoClient.chambers.getBySubdomain(subdomain);
            console.log('readChamber', chamberResult.data);
            const chamber = chamberResult.data[0];
            setChamber(chamber);
            const companiesRes = await expoClient.chambers.lastJoinedCompanies(chamber.id);
            if (companiesRes.status === 200 && companiesRes.data)
                setCompanies(companiesRes.data.slice(0, expoConfig.homepage.max_joined));

            const livesRes = await expoClient.chambers.getLastItems(chamber.id, expoConfig.domain.showcaseitem.live);
            if (livesRes.status === 200 && livesRes.data) {
                const shuffleLive = _.shuffle(livesRes.data);
                setLives(shuffleLive.slice(0, expoConfig.homepage.max_lives));
            }
        };
        if (chamber === null) {
            init(chamberSubdomain);
        }
        if (companies || lives) getArrows(); // Forziamo ricalcolo della visibilità arrows carousel
        console.log('NoAuthPage.useEffect', chamber, companies, lives);
    }, [chamber, companies, lives]);

    const data = [
        {
            icon: <EyeOutlined className="icon" style={{ color: '#df272d' }} />,
            title: (
                <p className="datatitle">
                    <strong> {t('title_visibilty_and_interaction')} </strong>
                </p>
            ),
            text: <p className="datatext"> {t('subtitle_visibility_and_interaction')} </p>,
        },
        {
            icon: <GlobalOutlined className="icon" style={{ color: '#df272d' }} />,
            title: (
                <p className="datatitle">
                    <strong> {t('title_brokerage')} </strong>
                </p>
            ),
            text: (
                <p
                    className="datatext"
                    dangerouslySetInnerHTML={{
                        __html: t(
                            'subtitle_brokerage1',
                            { 0: chamber ? chamber.subdomain.toUpperCase() : '' },
                            {
                                interpolation: { escapeValue: false },
                            },
                        ),
                    }}
                ></p>
            ),
        },
        {
            icon: <TeamOutlined className="icon" style={{ color: '#df272d' }} />,
            title: (
                <p className="datatitle">
                    {' '}
                    <strong> {t('title_match_b2b')} </strong>{' '}
                </p>
            ),
            text: (
                <p
                    className="datatext"
                    dangerouslySetInnerHTML={{
                        __html: t(
                            'subtitle_match_b2b_1',
                            { 0: chamber ? chamber.subdomain.toUpperCase() : '' },
                            {
                                interpolation: { escapeValue: false },
                            },
                        ),
                    }}
                ></p>
            ),
        },
    ];

    const caratteristiche = [
        {
            title: (
                <p className="carattitle">
                    {' '}
                    <strong> {t('title_exclusivity')}</strong>{' '}
                </p>
            ),
            text: <p className="carattext"> {t('description_exclusivity')} </p>,
            elenco: <Exclusivity className="icon-section-whyjoin"> </Exclusivity>,
        },
        {
            title: (
                <p className="carattitle">
                    {' '}
                    <strong> {t('title_brokerage_why_exportlounge')}</strong>{' '}
                </p>
            ),
            text: <p className="carattext"> {t('description_brokerage_why_description')} </p>,
            elenco: <Brokerage className="icon-section-whyjoin" />,
        },

        {
            title: (
                <p className="carattitle">
                    {' '}
                    <strong> {t('title_involvement')} </strong>{' '}
                </p>
            ),
            text: <p className="carattext"> {t('description_involvement')} </p>,
            elenco: <Involvement className="icon-section-whyjoin" />,
        },

        {
            title: (
                <p className="carattitle">
                    {' '}
                    <strong> {t('title_metric')} </strong>{' '}
                </p>
            ),
            text: <p className="carattext"> {t('description_metric')} </p>,
            elenco: <Metric className="icon-section-whyjoin" />,
        },

        {
            title: (
                <p className="carattitle">
                    {' '}
                    <strong> {t('title_continuity')}</strong>{' '}
                </p>
            ),
            text: <p className="carattext"> {t('description_continuity')} </p>,
            elenco: <Continuity className="icon-section-whyjoin" />,
        },
    ];

    const settingSpotCarousel = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    dots: false,
                },
            },
        ],
        appendDots: (dots) => (
            <div>
                <ul style={{ marginBottom: '-10px' }}> {dots} </ul>
            </div>
        ),
        customPaging: (i) => (
            <div
                style={{
                    width: '20px',
                    height: '20px',
                    margin: '10px',
                    borderRadius: '50%',
                    backgroundColor: '#df2727',
                    opacity: 0.7,
                }}
            ></div>
        ),
    };

    const settingLiveCarousel = {
        default: {
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },

            {
                breakpoint: 1210,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const settingCompaniesCarousel = {
        default: {
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            },
        },
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1210,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const doLogin = async () => {
        await loginWithRedirect({});
        history.push('/');
    };

    /*
    const openModalLive = index => {
        setLiveToShowIndex(index);
        setShowModalLive(true);
    }
    */

    /*
    const modalLiveBeforeChange = (from, to) => {
        const myCarousel = document.getElementById("modalLiveCarousel");
        let elencoVideo = myCarousel.querySelectorAll(".slick-slide");
        if (elencoVideo) {
            for (let i = 0; i < elencoVideo.length; i++) {
                let videoToMute = elencoVideo[i].getElementsByClassName("liveHook")[0];
                videoToMute.muted = true;
                videoToMute.pause();
            }
        }
    }
    */

    /*
    const modalLiveAfterChange = (current) => {
        const myCarousel = document.getElementById("modalLiveCarousel");
        let vTo = myCarousel.querySelectorAll(".slick-active")[0].getElementsByClassName("liveHook")[0];
        if (vTo) {
            vTo.muted = false;
            vTo.play();
        }
    }
    */

    const getArrows = () => {
        let attualBreakpoint = null;
        settingCompaniesCarousel.responsive.forEach((element) => {
            if (element.breakpoint > window.innerWidth)
                if (attualBreakpoint === null || attualBreakpoint > element.breakpoint) {
                    attualBreakpoint = element.breakpoint;
                }
        });
        let attualConfiguration = attualBreakpoint
            ? settingCompaniesCarousel.responsive.find((e) => e.breakpoint === attualBreakpoint)
            : settingCompaniesCarousel.default;

        setShowCompaniesArrow(
            companies && attualConfiguration ? companies.length > attualConfiguration.settings.slidesToShow : false,
        );

        attualBreakpoint = null;
        attualConfiguration = null;
        settingLiveCarousel.responsive.forEach((element) => {
            if (element.breakpoint > window.innerWidth)
                if (attualBreakpoint === null || attualBreakpoint > element.breakpoint)
                    attualBreakpoint = element.breakpoint;
        });
        attualConfiguration = attualBreakpoint
            ? settingLiveCarousel.responsive.find((e) => e.breakpoint === attualBreakpoint)
            : settingLiveCarousel.default;
        setShowLivesArrow(
            lives && attualConfiguration ? lives.length > attualConfiguration.settings.slidesToShow : false,
        );
    };

    window.addEventListener('resize', getArrows);

    return chamberSubdomain === null ? (
        <iframe
            src="https://www.exportlounge.com"
            title="ExportLOUNGE"
            style={{ width: '100%', height: '100vh', border: '0 solid' }}
        />
    ) : (
        <>
            <section style={{ backgroundColor: '#fff', maxWidth: '100%', margin: '0 auto' }}>
                <section
                    className="sezione-carosello"
                    style={{
                        backgroundImage: chamber
                            ? `url(${expoConfig.static.url}/entities/${chamber.entityId}/cover.jpg)`
                            : '',
                    }}
                >
                    <Row
                        className="carousel-homepage"
                        align="middle"
                        style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.45)',
                        }}
                    >
                        <Col span={3}>
                            <Button
                                className="carousel-arrow"
                                shape="circle"
                                style={{ width: '25px' }}
                                onClick={() => spotCarousel.current.prev()}
                            >
                                {' '}
                                <LeftOutlined className="arrows-ant-carousel-spot" />
                            </Button>
                        </Col>
                        <Col span={18}>
                            <Carousel ref={spotCarousel} autoplay={true} {...settingSpotCarousel}>
                                <div>
                                    <div className="container-text-slider">
                                        <div className="text-slider">
                                            <strong>
                                                {t(
                                                    chamberSubdomain
                                                        ? `${chamberSubdomain}_home_page_spot1`
                                                        : 'home_page_spot1',
                                                )}
                                            </strong>
                                        </div>
                                        <div className="subtitle_text_slider">
                                            <strong>
                                                {t(
                                                    chamberSubdomain
                                                        ? `${chamberSubdomain}_home_page_subtitle_spot1`
                                                        : 'home_page_subtitle_spot1',
                                                )}
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="container-text-slider">
                                        <div className="text-slider">
                                            <strong>
                                                {t(
                                                    chamberSubdomain
                                                        ? `${chamberSubdomain}_home_page_spot2`
                                                        : 'home_page_spot2',
                                                )}
                                            </strong>
                                        </div>
                                        <div className="subtitle_text_slider">
                                            <strong>
                                                {t(
                                                    chamberSubdomain
                                                        ? `${chamberSubdomain}_home_page_subtitle_spot2`
                                                        : 'home_page_subtitle_spot2',
                                                )}
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="container-text-slider">
                                        <div className="text-slider">
                                            <strong>
                                                {t(
                                                    chamberSubdomain
                                                        ? `${chamberSubdomain}_home_page_spot3`
                                                        : 'home_page_spot3',
                                                )}
                                            </strong>
                                        </div>
                                        <div className="subtitle_text_slider">
                                            <strong>
                                                {t(
                                                    chamberSubdomain
                                                        ? `${chamberSubdomain}_home_page_subtitle_spot3`
                                                        : 'home_page_subtitle_spot3',
                                                )}
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </Col>
                        <Col span={3}>
                            <Button
                                className="carousel-arrow"
                                shape="circle"
                                onClick={() => spotCarousel.current.next()}
                            >
                                {' '}
                                <RightOutlined className="arrows-ant-carousel-spot" style={{ align: 'center' }} />
                            </Button>
                        </Col>
                    </Row>
                </section>

                {lives ? (
                    <section>
                        {/* <Modal key="modalLive"
                            open={showModalLive}
                            onClose={() => setShowModalLive(false)}
                            style={{ backgroundColor: 'transparent', textAlign: 'center', boxShadow: 'none' }}
                            onMount={() => modalLiveAfterChange(0)}
                        >
                            <Row align="middle">
                                <Col span={2} >
                                    <ArrowModalLeft className="arrow-modal-live" onClick={() => modalVideoLiveCarousel.current.prev()} />
                                </Col>
                                <Col
                                    span={20} id="modalLiveCarousel" >
                                    <Carousel autoplay={false} autoplaySpeed={60000} ref={modalVideoLiveCarousel} dots={false}
                                        initialSlide={liveToShowIndex}
                                        beforeChange={(from, to) => modalLiveBeforeChange(from, to)}
                                        afterChange={(to) => modalLiveAfterChange(to)}
                                        className={"carouselModelLive"}
                                        style={{ backgroundColor: 'transparent !important' }}
                                    >
                                        {
                                            lives.map((l, index) =>
                                                <div style={{ minHeight: "100%" }}>
                                                    <video style={{ width: '100%', maxHeight: '90vh', objectFit: 'contain' }} key={l.id} className="liveHook" id={`live_${index}`} name={l.id} type="video/mp4" src={l.bannerPath} alt={l.name} muted loop />
                                                </div>)
                                        }
                                    </Carousel>
                                </Col>
                                <Col span={2}>
                                    <ArrowModalRight className="arrow-modal-live" onClick={() => modalVideoLiveCarousel.current.next()} />
                                </Col>
                            </Row>
                        </Modal> */}

                        <Row>
                            <Col xl={24} xs={24} md={24} lg={24}>
                                <div className="div">
                                    <h1 className="titolo-live"> {t('live_from_companies')} </h1>
                                    <Carousel
                                        ref={videoLiveCarousel}
                                        dots={false}
                                        slidesToShow={lives.length > 3 ? 3 : lives.length}
                                        slidesToScroll={1}
                                        {...settingLiveCarousel}
                                    >
                                        {lives.map((l, index) => (
                                            <div key={l.id}>
                                                <div className="live-container-company">
                                                    <div className="live-container-position">
                                                        <video
                                                            className="live-comp"
                                                            src={l.logoPath ? l.logoPath : l.bannerPath}
                                                            autoPlay={true}
                                                            loop
                                                            muted
                                                        />
                                                    </div>
                                                </div>
                                                <div className="video-live-div">
                                                    <h2 className="video-live-company"> {l.entity.name} </h2>
                                                </div>
                                            </div>
                                        ))}
                                    </Carousel>
                                    {showLivesArrow ? (
                                        <div style={{ marginTop: '30px' }}>
                                            <ArrowLeft
                                                className="arrow-iscritti"
                                                onClick={() => videoLiveCarousel.current.prev()}
                                            />
                                            <ArrowRight
                                                className="arrow-iscritti"
                                                onClick={() => videoLiveCarousel.current.next()}
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                    </section>
                ) : null}

                {companies ? (
                    <section>
                        <div className="div">
                            <h1 className="titolo"> {t('last_adhesions')} </h1>
                            <Carousel
                                autoplay={true}
                                ref={joinedCarousel}
                                slidesToShow={companies.length > 4 ? 4 : companies.length}
                                dots={false}
                                {...settingCompaniesCarousel}
                            >
                                {companies.map((c) => (
                                    <div key={c.id}>
                                        <div className="companies-container-company">
                                            <div className="companies-container-position">
                                                <img
                                                    src={c.logoPath}
                                                    alt={c.name}
                                                    title={c.name}
                                                    style={{ width: '100%', height: '100%' }}
                                                />
                                            </div>
                                        </div>
                                        <div className="companies-name-div">
                                            <h2 className="companies_name_paragraph"> {c.name} </h2>
                                        </div>
                                    </div>
                                ))}
                            </Carousel>

                            {showCompaniesArrow ? (
                                <>
                                    <div style={{ marginTop: '30px' }}>
                                        <ArrowLeft
                                            className="arrow-iscritti"
                                            onClick={() => joinedCarousel.current.prev()}
                                        />
                                        <ArrowRight
                                            className="arrow-iscritti"
                                            onClick={() => joinedCarousel.current.next()}
                                        />
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </section>
                ) : null}

                <section>
                    <Parallax
                        className="parallax"
                        blur={1}
                        bgImage={require('../images/smartmockups1.jpg')}
                        bgImageAlt={t('button_explore_expo')}
                        strength={500}
                    >
                        <div className="mbr-overlay" style={{ textAlign: 'center' }}>
                            <div style={{ display: 'block' }}>
                                <h1 className="h1-parallax">
                                    {' '}
                                    <strong>
                                        {' '}
                                        {t('explore_expo_homepage', {
                                            0: chamber ? chamber.subdomain.toUpperCase() : '',
                                        }).toUpperCase()}
                                    </strong>{' '}
                                </h1>
                                <p className="p-parallax"> {t('subtitle_explore_expo')} </p>
                                <a href="#">
                                    <Button
                                        className="homepageBtn-login"
                                        type="primary"
                                        onClick={() => history.push('/companiesjoined')}
                                    >
                                        {t('button_explore_expo').toUpperCase()}
                                    </Button>
                                </a>
                            </div>
                        </div>
                    </Parallax>
                </section>

                <Row>
                    <Col>
                        <section>
                            <div className="div">
                                <h1 className="titolo">
                                    {' '}
                                    {t('first_module_title', {
                                        0: chamber ? chamber.subdomain.toUpperCase() : '',
                                    })}
                                </h1>

                                <p
                                    className="paragrafo"
                                    dangerouslySetInnerHTML={{
                                        __html: t(
                                            'description_first_module1',
                                            {
                                                0: chamber ? chamber.country.name : ' ',
                                                1: chamber ? chamber.subdomain.toUpperCase() : '',
                                            },
                                            {
                                                interpolation: { escapeValue: false },
                                            },
                                        ),
                                    }}
                                ></p>
                            </div>
                            <div className="div-aderire">
                                <List
                                    grid={{
                                        gutter: 16,
                                        xl: 3,
                                        xxl: 3,
                                        xs: 1,
                                        lg: 2,
                                        md: 1,
                                        sm: 1,
                                    }}
                                    dataSource={data}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <Row align="middle">
                                                <Col offset={1} span={2}>
                                                    {item.icon}
                                                </Col>
                                                <Col span={4} />
                                                <Col align="start" span={16}>
                                                    {item.title}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={6} />
                                                <Col span={16} offset={1} style={{ marginTop: '20px' }}>
                                                    {item.text}
                                                </Col>
                                            </Row>
                                            <Card style={{ border: 'none' }}> </Card>
                                        </List.Item>
                                    )}
                                />
                            </div>
                        </section>
                    </Col>
                </Row>

                <Row>
                    <Col xl={24} xs={24} md={24} lg={24}>
                        <section className="section-caratteristiche">
                            <div className="div">
                                <h1 className="titolo" style={{ color: 'white' }}>
                                    {' '}
                                    {t('title_why_join')}
                                </h1>

                                <p
                                    className="paragrafo"
                                    style={{ color: 'white' }}
                                    dangerouslySetInnerHTML={{
                                        __html: t(
                                            'description_why_exportlounge1',
                                            { 0: chamber ? chamber.subdomain.toUpperCase() : '' },
                                            {
                                                interpolation: { escapeValue: false },
                                            },
                                        ),
                                    }}
                                ></p>
                            </div>
                            <div className="div-divider">
                                <Divider style={{ marginBottom: '0' }} />
                                <List
                                    size="small"
                                    dataSource={caratteristiche}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <>
                                                <Row align="middle" className="row-elenco-whyjoin">
                                                    <Col style={{ marginRight: '20px' }}>{item.elenco}</Col>
                                                    <Col
                                                        span={20}
                                                        style={{
                                                            padding: '0',
                                                            marginTop: '15px',
                                                            marginBottom: '15px',
                                                        }}
                                                    >
                                                        {item.title}
                                                        {item.text}
                                                    </Col>
                                                </Row>
                                            </>
                                        </List.Item>
                                    )}
                                />
                            </div>
                        </section>
                    </Col>
                </Row>

                <Row>
                    <Col xl={24} xs={24} md={24} lg={24} sm={24}>
                        <section style={{ marginTop: '-40px' }}>
                            <div style={{ textAlign: 'center', marginBottom: '40px' }}> </div>
                            <Parallax
                                blur={1}
                                bgImage={require('../images/smartmockups.jpg')}
                                bgImageAlt="smartmockups"
                                strength={750}
                            >
                                <div className="mbr-overlay" style={{ textAlign: 'center' }}>
                                    <div style={{ display: 'block' }}>
                                        <h1 className="h1-parallax">
                                            {' '}
                                            <strong>
                                                {' '}
                                                {t('join_expo_homepage', {
                                                    0: chamber ? chamber.subdomain.toUpperCase() : '',
                                                }).toUpperCase()}{' '}
                                            </strong>{' '}
                                        </h1>
                                        <p className="p-parallax"> {t('fill_out_the_form')} </p>
                                        <Button
                                            className="homepageBtn"
                                            type="primary"
                                            onClick={() => history.push('/signup')}
                                        >
                                            {t('button_subscribe_to_the_portal').toUpperCase()}
                                        </Button>
                                    </div>
                                </div>
                            </Parallax>
                        </section>
                    </Col>
                </Row>

                {/* molto probabilmente servirà questa parte in futuro*/}
                {/*
             <section style={{backgroundColor: '#e8e8e8'}}>

                <div className="div-card" >
                <Row>
                        <Col xl={24} xs={23} md={24} lg={24}>
                            <h1 className="titolo" style={{marginBottom:'50px', marginTop: '50px'}}>  PIANI DI ADESIONE A {t("app_title_1")}<strong>{t("app_title_2")}</strong> - {chamber ? chamber.subdomain.toUpperCase() : '' } </h1>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xl={6} xs={24} md={12} lg={12} span={6}>
                            <Card bordered={true} className="homepage-card">
                                <Col>
                                    <h1 className="card-h1"> <strong> FREE </strong> </h1>
                                    <ul>
                                        <li className="card-p" style={{textAlign:'left'}}>
                                            Visualizzazione elenco aziende iscritte
                                        </li>
                                    </ul>
                                </Col>
                            </Card>
                        </Col>
                        <Col xl={6} xs={24} md={12} lg={12} span={6} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                            <Card bordered={true} className="homepage-card">
                                <Col>
                                    <h1 className="card-h1"> <strong> PREMIUM </strong> </h1>
                                    <ul>
                                        <li className="card-p" style={{textAlign:'left'}}>
                                            Visualizzazione elenco aziende iscritte
                                        </li>
                                        <li className="card-p" style={{textAlign:'left'}}>
                                            Richieste di B2B all’ente
                                        </li>
                                        <li className="card-p" style={{textAlign:'left'}}>
                                            Business Matching Score
                                        </li>
                                    </ul>
                                </Col>
                            </Card>
                        </Col>
                        <Col xl={6} xs={24} md={12} lg={12} span={6} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                            <Card bordered={true} className="homepage-card">
                                <Col>
                                     <h1 className="card-h1"> <strong> BUSINESS </strong> </h1>
                                    <ul>
                                        <li className="card-p" style={{textAlign:'left'}}>
                                            Visualizzazione elenco aziende iscritte
                                        </li>
                                        <li className="card-p" style={{textAlign:'left'}}>
                                            Richieste di B2B all’ente
                                        </li>
                                        <li className="card-p" style={{textAlign:'left'}}>
                                            Business Matching Score
                                        </li>
                                        <li className="card-p" style={{textAlign:'left'}}>
                                            Visualizzazione vetrina aziende
                                        </li>
                                    </ul>
                                </Col>
                            </Card>
                        </Col>
                        <Col xl={6} xs={24} md={12} lg={12} span={6} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                            <Card bordered={true} className="homepage-card" >
                                <Col>
                                     <h1 className="card-h1"> <strong> EXECUTIVE </strong> </h1>
                                    <ul>
                                        <li className="card-p" style={{textAlign:'left'}}>
                                            Visualizzazione elenco aziende iscritte
                                        </li>
                                        <li className="card-p" style={{textAlign:'left'}}>
                                            Richieste di B2B all’ente
                                        </li>
                                        <li className="card-p" style={{textAlign:'left'}}>
                                            Business Matching Score
                                        </li>
                                        <li className="card-p" style={{textAlign:'left'}}>
                                            Visualizzazione vetrina aziende
                                        </li>
                                        <li className="card-p" style={{textAlign:'left'}}>
                                            Visualizzazione vetrine prodotti
                                        </li>
                                        <li className="card-p" style={{textAlign:'left'}}>
                                            Contatto diretto aziende
                                        </li>
                                    </ul>
                                </Col>
                            </Card>
                        </Col>
                    </Row>
                </div>

                </section> */}
            </section>
        </>
    );
};
export default NoAuthPage;
