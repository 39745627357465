import React from 'react';
import Button from './Button';
import PropTypes from 'prop-types';

import { Divider } from 'antd';

const PricingSlot = (props) => {
    const {
        //planId,
        //highlighted,
        highlightColor,
        //onClick,
        buttonClass,
        buttonText,
        shouldDisplayButton,
        backgroundColor,
        border,
        subscriptionText,
    } = props;

    return (
        <div
            //onClick={(clickEvent) => onClick(planId, clickEvent)}
            className="pricing-slot basic-border"
            style={{ backgroundColor: backgroundColor, border: border }}
        >
            <div className="price">
                <div className="basic-header">
                    {props.title}
                    <Divider className="divider" />
                    <div className="basic-tag">
                        <b>{props.priceText}</b>
                    </div>
                    <p style={{ fontSize: '15px', fontWeight: '300' }}>{props.subscriptionText}</p>
                </div>
                <ul>{props.children}</ul>
                {shouldDisplayButton && (
                    <div className="grey">
                        <Button
                            onClick={props.onButtonClick}
                            color={highlightColor}
                            className={buttonClass ? buttonClass : 'button-submit'}
                        >
                            {buttonText}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};
export default PricingSlot;

PricingSlot.propTypes = {
    //planId: PropTypes.number,
    //highlighted: PropTypes.bool,
    //onClick: PropTypes.func,
    onButtonClick: PropTypes.func,
    title: PropTypes.string,
    priceText: PropTypes.string,
    buttonClass: PropTypes.string,
    buttonText: PropTypes.string,
    children: PropTypes.node,
    highlightColor: PropTypes.string,
    shouldDisplayButton: PropTypes.bool,
    backgroundColor: PropTypes.string,
    border: PropTypes.string,
    subscriptionText: PropTypes.string,
};

PricingSlot.defaultProps = {
    //highlighted: false,
    highlightColor: '#fff',
    shouldDisplayButton: true,
    backgroundColor: '#fff',
    border: 'none',
};
