import { useContext, useEffect, useState } from 'react';

import getChimeContext from '../context/getChimeContext';

export default function useAttendee(attendeeId) {
    const chime = useContext(getChimeContext());
    const [attendee, setAttendee] = useState(chime ? chime.roster[attendeeId] : {});

    useEffect(() => {
        let previousRosterAttendee = null;
        const callback = (newRoster) => {
            const rosterAttendee = newRoster[attendeeId] ? { ...newRoster[attendeeId] } : null;

            // We don't subscribe to volume and signal strength changes.
            // The VideoNameplate component that uses this hook will re-render only when muted status change.
            if (rosterAttendee) {
                if (
                    !previousRosterAttendee ||
                    //previousRosterAttendee.name !== rosterAttendee.name ||
                    previousRosterAttendee.muted !== rosterAttendee.muted
                ) {
                    setAttendee(rosterAttendee);
                }
            }
            previousRosterAttendee = rosterAttendee;
        };
        if (chime) chime.subscribeToRosterUpdate(callback);
        return () => {
            if (chime) chime.unsubscribeFromRosterUpdate(callback);
        };
    }, []);
    return attendee;
}
