import React from 'react';
import './ImageEditor.css';
import './EntityInfoPanel.css';
import { Row, Col, Typography, Progress, notification, Input, Button, Space } from 'antd';
import './VideoEditor.css';
import './DocumentEditor.css';
import ConfirmModal from './ConfirmModal';

import { ReactComponent as UploadDocument } from '../icons/add_document.svg';
import { ReactComponent as AddMedia } from '../icons/aggiungi.svg';
import expoConfig from '../config/expo_config.json';
import TextArea from 'antd/lib/input/TextArea';

class DocumentEditor extends React.Component {
    constructor(props) {
        super(props);
        this.localEntityTmp = props.entity;
        this.editModeCallBackParent = props.setEditModeCallBackParent;
        this.closeModalCallBack = props.closeModalCallBack;
        this.isEditable = props.isEditable;
        this.state = {
            edtiMode: false,
            globalEditState: props.globalEditState,
            statusUpload: props.statusUpload,
            percent: props.percent,
            file: null,
            title: '',
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.globalEditState !== this.props.globalEditState)
            this.setState({ globalEditState: this.props.globalEditState });
        if (prevProps.percent !== this.props.percent) this.setState({ percent: this.props.percent });
        if (prevProps.statusUpload !== this.props.statusUpload)
            this.setState({ statusUpload: this.props.statusUpload });
    }

    setFileInState = (file) => {
        if (file) {
            if (file.name) {
                if (
                    !file.name.toUpperCase().includes('.PPT') &&
                    !file.name.toUpperCase().includes('.PDF') &&
                    !file.name.toUpperCase().includes('.DOC') &&
                    !file.name.toUpperCase().includes('.DOCS')
                ) {
                    const { t } = this.props;
                    const desc = t('uploading_error_format_document_description');
                    const message = t('uploading_error_format_message');
                    notification.error({
                        key: 'wrongFormatfile',
                        description: desc,
                        message: message,
                        placement: 'topRight',
                        duration: 12,
                    });
                    return;
                }
            }
            if (file.size) {
                if ((file.size / (1024 * 1000)).toFixed(2) > expoConfig.domain.document.maxsize) {
                    const { t } = this.props;
                    const desc =
                        t('uploading_error_size_description') +
                        ' ' +
                        expoConfig.domain.document.maxsize +
                        '(' +
                        expoConfig.domain.document.unit +
                        ')';
                    const message = t('uploading_error_size_message');
                    notification.error({
                        key: 'toolargefile',
                        description: desc,
                        message: message,
                        placement: 'topRight',
                        duration: 12,
                    });
                    return;
                }
            }
            this.setState({ edtiMode: true });
            this.setState({ showButtons: true });
            this.setState({ file: file });
            // this.uploadWithFormData(file);
        }
    };

    uploadWithFormData = () => {
        const formData = new FormData();
        formData.append('file', this.state.file);
        // formData.append("description", this.state.desc);
        this.editModeCallBackParent(false, true, false, formData, this.state.title);
    };

    clickFileChooser() {
        document.getElementById('documentInputFile').click();
    }

    closeModal() {
        this.closeModalCallBack();
    }

    changedTitle = (newTitle) => {
        this.setState({ title: newTitle });
    };

    render() {
        const { t } = this.props;
        const { Paragraph } = Typography;
        const toWriteInActionPar =
            this.state.percent > 0
                ? this.state.statusUpload === 'normal' || this.state.statusUpload === 'active'
                    ? t('uploading_document')
                    : t('error_uploading_file')
                : t('uploading_document_suggestion');
        const toWriteInFileName = this.state.file ? t('uploading_file_name').toUpperCase() : ' ';
        const toWriteInFileName1 = this.state.file ? this.state.file.name : '';
        const toWriteInFileSize = this.state.file ? t('uploading_file_size').toUpperCase() : '';
        const toWriteInFileSize1 = this.state.file
            ? ' ' + (this.state.file.size / (1024 * 1000)).toFixed(2) + ' MB'
            : '';
        const uploadButtonClass = this.state.statusUpload !== 'exception' ? 'uploadButton_black' : 'uploadButton_red';
        const actionParClass =
            this.state.statusUpload !== 'exception_doc' ? 'actionPar_black_doc' : 'actionPar_red_doc';

        const uploadButtonOrCircle =
            this.state.statusUpload !== 'normal' && this.state.statusUpload !== 'exception' ? (
                <div key="1" className="button-media">
                    <Progress
                        type="circle"
                        percent={this.state.percent}
                        status={this.state.statusUpload}
                        style={{ padding: '30px' }}
                    />
                </div>
            ) : !this.state.file ? (
                <div key="1" className="button-media" onClick={this.clickFileChooser}>
                    <UploadDocument className={uploadButtonClass} />
                    <div className="container-icon-media">
                        <AddMedia className="icon-plus-media" />
                        <span style={{ fontSize: '20px' }}>{t('document_media').toUpperCase()}</span>
                    </div>
                </div>
            ) : (
                <div key="1" className="button-media-no-hover">
                    <UploadDocument style={{ cursor: 'standard' }} className={uploadButtonClass} />
                </div>
            );

        return (
            <>
                <div className="modal-media videoEditor">
                    <div style={{ marginBottom: '0px', padding: '22px 0px' }} className="modal-media">
                        <b>{t('new_document_content').toUpperCase()}</b>
                    </div>
                </div>
                <Row style={{ display: 'flex', width: '100%' }}>
                    <div className="container-icon-upload" style={{ width: '100%' }}>
                        {!this.state.file ? (
                            <Col xl={{ span: 24 }} className="col-media-insert col-center">
                                {uploadButtonOrCircle}
                            </Col>
                        ) : (
                            <Col lg={{ span: 12 }} xs={{ span: 24 }} className=" col-flex-end">
                                {uploadButtonOrCircle}
                            </Col>
                        )}

                        <Row align="middle">
                            <Col span="24" offset="0" align="middle">
                                <input
                                    id="documentInputFile"
                                    type="file"
                                    hidden
                                    name="file"
                                    className="inputTextVideoContainer"
                                    accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                    onChange={(e) => this.setFileInState(e.target.files[0])}
                                />
                            </Col>
                        </Row>
                        {/*  {!this.state.file ? <Row style={{ width: '100%' }} align="middle">
                    <Col span="24" offset="0" align="middle">
                        <Paragraph id='actionPar' className={actionParClass}>{toWriteInActionPar}</Paragraph>
                    </Col>
                    </Row> : null} */}
                        <div>
                            <Col
                                xl={{ span: 12 }}
                                xs={{ span: 24 }}
                                className="col-media-text"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    maxWidth: '100%',
                                }}
                            >
                                <Paragraph id="chosenFile" style={{ fontSize: '20px', wordBreak: 'break-word' }}>
                                    <b>{toWriteInFileName}</b> <br></br> {toWriteInFileName1}{' '}
                                </Paragraph>
                                <Paragraph id="chosenSize" style={{ fontSize: '20px', wordBreak: 'break-word' }}>
                                    <b>{toWriteInFileSize}</b> <br></br> {toWriteInFileSize1}
                                </Paragraph>
                            </Col>
                        </div>
                    </div>
                </Row>
                <div className="container-media-insert">
                    <span style={{ fontSize: '20px' }}>
                        <b>{t('media_title').toUpperCase()}</b>
                    </span>
                    <TextArea
                        style={{ margin: '20px 0px', fontSize: '18px' }}
                        placeholder={t('new_document_label_description') + '*'}
                        onChange={(e) => this.changedTitle(e.target.value)}
                    ></TextArea>
                </div>
                <div className="button_operation_request_media">
                    <Row justify="center" align="middle">
                        <Col className="two-button-distance">
                            <ConfirmModal onOk={() => this.closeModalCallBack()} message={t('ask_for_close_live')}>
                                <Button block shape="round" className="blackMode button-request" htmlType="button">
                                    {t('button_cancel').toUpperCase()}
                                </Button>
                            </ConfirmModal>
                        </Col>
                        <Col className="two-button-distance">
                            <Button
                                className="button-hover-green button-request"
                                shape="round"
                                onClick={() => this.uploadWithFormData()}
                                disabled={this.state.title === '' || this.state.file === null}
                            >
                                {' '}
                                {t('publish_company').toUpperCase()}{' '}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
export default DocumentEditor;
