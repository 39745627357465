import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Check } from '../../icons/check-1.svg';
import { ReactComponent as CheckOut } from '../../icons/x.svg';

const PricingDetail = (props) => {
    const { active } = props;

    return (
        <li style={{ color: active ? 'black' : 'grey' }}>
            {active ? <Check className="icon-check-checkout" /> : <CheckOut className="icon-check-checkout" />}
            {props.children}
        </li>
    );
};
export default PricingDetail;

PricingDetail.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.node,
};

PricingDetail.defaultProps = {
    active: true,
};
