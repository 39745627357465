import { MeetingSessionStatusCode } from 'amazon-chime-sdk-js';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import getChimeContext from '../context/getChimeContext';
import getMeetingStatusContext from '../context/getMeetingStatusContext';
import MeetingStatus from '../enums/MeetingStatus';
import expoClient from '../expoClient';

export default function MeetingStatusProvider(props) {
    const MeetingStatusContext = getMeetingStatusContext();
    const { tokens, children } = props;
    const chime = useContext(getChimeContext());
    const [meetingStatus, setMeetingStatus] = useState(MeetingStatus.Loading);
    const [callUuid, setCallUuid] = useState(null);
    const audioElement = useRef(null);

    const availableColors = [
        '#4AA072',
        '#754AA0',
        '#A04A6E',
        '#934AA0',
        '#614AA0',
        '#4A61A0',
        '#4A8FA0',
        '#4AA05B',
        '#6EA04A',
        '#A0A04A',
        '#A0754A',
        '#A04A4A',
        '#4AA072',
        '#75A04A',
        '#4AA06E',
        '#93A04A',
    ];

    function buildColorAttendeeMap(b2bInfo) {
        const myColor = { token: b2bInfo.user.token, color: availableColors[0] };
        const otherColor = b2bInfo.attendees.map((a, i) => {
            return { token: a.token, color: availableColors[i + 1] };
        });
        return [myColor, ...otherColor];
    }

    const updateB2BInfo = async () => {
        const b2bResponse = await expoClient.meeting.getInfo(tokens[0], tokens[1]);
        setMeetingStatus((prevValue) => ({
            ...prevValue,
            b2bInfo: b2bResponse.data,
        }));
        const color = buildColorAttendeeMap(b2bResponse.data);
        setMeetingStatus((prevValue) => ({
            ...prevValue,
            colorMap: color,
        }));

        return b2bResponse.data;
    };

    useEffect(() => {
        const start = async (tokens) => {
            try {
                const cUuid = uuidv4();
                setCallUuid(cUuid);
                const b2bResponse = await expoClient.meeting.joinCall(tokens[0], tokens[1], cUuid);
                const colorMap = buildColorAttendeeMap(b2bResponse.data);
                await chime.initializeMeetingSession(b2bResponse.data);
                setMeetingStatus({
                    meetingStatus: MeetingStatus.Succeeded,
                    b2bInfo: b2bResponse.data,
                    colorMap: colorMap,
                    updateB2B: updateB2BInfo,
                    callUuid: cUuid,
                });
                if (chime.audioVideo) {
                    chime.audioVideo.addObserver({
                        audioVideoDidStop: (sessionStatus) => {
                            if (sessionStatus.statusCode() === MeetingSessionStatusCode.AudioCallEnded) {
                                chime.leaveRoom();
                            }
                        },
                    });
                }
                await chime.joinRoom(audioElement.current);
            } catch (error) {
                // eslint-disable-next-line
                console.error(error);
                setMeetingStatus({
                    meetingStatus: MeetingStatus.Failed,
                    errorMessage: error.message,
                });
            }
        };
        start(tokens);
    }, []);

    return (
        <MeetingStatusContext.Provider value={meetingStatus}>
            {/* eslint-disable-next-line */}
            <audio ref={audioElement} style={{ display: 'none' }} />
            {children}
        </MeetingStatusContext.Provider>
    );
}
