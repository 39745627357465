import { Col, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import EditSaveDiscardIcons from './EditSaveDiscardIcons';
import { useTranslation } from 'react-i18next';
import expoConfig from '../config/expo_config.json';
import './ItemInfoPanel.css';

const ItemInfoPanel = ({ item, setEditModeCallBackParent, globalEditState, isEditable, itemType }) => {
    const [editMode, setEditMode] = useState(false);
    const [tempName, setTempName] = useState(null);
    const [tempDescription, setTempDescription] = useState(null);
    const { t } = useTranslation();
    let placeHolderName =
        parseInt(itemType) === expoConfig.domain.showcaseitem.product ? t('product_name') : t('portfolio_name');
    let placeHolderDescription =
        parseInt(itemType) === expoConfig.domain.showcaseitem.product
            ? t('product_description')
            : t('portfolio_description');
    let localItemTmp = item;

    useEffect(() => {
        console.log('ItemInfoPanel.useEffect');
    }, []);

    //Funzioni CallBack per EditSaveDiscard Component
    const setSaveCallBack = () => {
        setEditMode(false);
        //Notifico al Parent che ho salvato e non sono più in editing cosi lo notifica agli altri componenti
        if (tempName != null) localItemTmp.name = tempName;
        if (tempDescription != null) localItemTmp.description = tempDescription;
        setEditModeCallBackParent(false, true, false);
    };

    const setDiscardCallBack = () => {
        setEditMode(false);
        //Discardo le modifiche prendendo la entity iniziale
        //Notifico al Parent che ho salvato e non sono più in editing cosi lo notifica agli altri componenti
        setTempName(null);
        setTempDescription(null);
        setEditModeCallBackParent(false, false, true);
    };

    const setEditModeCallBack = () => {
        setEditMode(true);
        //Notifico al Parent che ho salvato e non sono più in editing cosi lo notifica agli altri componenti
        //e gli passo pure la compagnia/camera perchè potrebbe essere anche cambiata.
        setEditModeCallBackParent(true, false, false);
    };

    //Funzioni CallBack per la modifica dei vari componenti e mi tengo in pancia i nuovi valori
    //finche non si fa salva e allora mando al server la nuova entity
    const changedName = (newName) => {
        setTempName(newName);
    };

    const changedDescription = (newDescription) => {
        setTempDescription(newDescription);
    };

    const enabled = globalEditState ? editMode : true;
    const editButton = isEditable ? (
        <EditSaveDiscardIcons
            showButtons={true}
            enabled={enabled}
            editMode={editMode}
            callBackOnEdit={setEditModeCallBack}
            callBackOnSave={setSaveCallBack}
            callBackOnDiscard={setDiscardCallBack}
            cssPositionClass="editingButtonStandardPositionBlock"
        />
    ) : null;

    const textAreaOrParag = item ? (
        editMode ? (
            <Row>
                <Col span="24">
                    <Input.TextArea
                        rows={14}
                        maxLength={750}
                        placeholder={placeHolderDescription}
                        style={{ Height: '245px', fontSize: '20px' }}
                        onChange={(e) => changedDescription(e.target.value)}
                        defaultValue={item.description}
                    ></Input.TextArea>
                </Col>
            </Row>
        ) : (
            <pre className="wrap" style={{ Height: '245px', fontSize: '20px' }}>
                {item.description && item.description !== '' ? item.description : placeHolderDescription}
            </pre>
        )
    ) : null;

    const inputOrSpanName = item ? (
        editMode ? (
            <Row>
                <Col span="24">
                    <Input
                        className="inputField"
                        style={{ fontWeight: '600', fontSize: '26px', lineHeight: '1.35' }}
                        onChange={(e) => changedName(e.target.value)}
                        placeholder={placeHolderName}
                        defaultValue={item.name && item.name !== '' ? item.name : ''}
                    ></Input>
                </Col>
            </Row>
        ) : (
            <span style={{ fontWeight: '600', fontSize: '26px', lineHeight: '1.35' }}>
                {item.name && item.name !== '' ? item.name : placeHolderName}
            </span>
        )
    ) : null;

    return item ? (
        <Row className="item-description-info-panel">
            <Col span="24">
                {inputOrSpanName}
                <Row style={{ padding: '35px 0 0 0' }}>
                    <Col span="24" align="start">
                        {textAreaOrParag}
                    </Col>
                </Row>
            </Col>
            {editButton}
        </Row>
    ) : null;
};
export default ItemInfoPanel;
