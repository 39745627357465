import './ConfirmModal.css';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Space } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const ConfirmModal = (props) => {
    const { t } = useTranslation();
    const [modalVisible, setModalVisible] = useState(false);
    const [textAreaValue, setTextAreaValue] = useState('');

    const handleModalOk = () => {
        setModalVisible(false);
        if (props.onOk) {
            if (props.messageArea) {
                props.onOk(textAreaValue);
            } else {
                props.onOk();
            }
        }
    };
    const handleModalCancel = () => {
        setModalVisible(false);
        if (props.onCancel) {
            props.onCancel();
        }
    };
    const handleModalOnClick = () => {
        setModalVisible(true);
        if (props.onClick) {
            props.onClick();
        }
    };
    const handleTextAreaChange = (event) => {
        setTextAreaValue(event.target.value);
    };
    return (
        <>
            <Modal
                visible={modalVisible}
                title={
                    props.title ? (
                        props.title
                    ) : (
                        <Space>
                            <QuestionCircleOutlined style={{ fontSize: '24px' }} /> {t('warning').toUpperCase()}
                        </Space>
                    )
                }
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                footer={[
                    props.scrollToRopAfterOk ? (
                        <a key="1" href="#" className="scrollToTop">
                            <Button onClick={handleModalOk}>{t('yes').toUpperCase()}</Button>
                        </a>
                    ) : (
                        <Button key="1" onClick={handleModalOk}>
                            {t('yes').toUpperCase()}
                        </Button>
                    ),
                    <Button key="2" onClick={handleModalCancel}>
                        {t('no').toUpperCase()}
                    </Button>,
                ]}
            >
                <p style={{ fontSize: '16px' }}>{props.message}</p>

                {props.messageArea ? (
                    <textarea className="textAreaConfirmModal" defaultValue="" onChange={handleTextAreaChange} />
                ) : null}
            </Modal>
            <div onClick={handleModalOnClick} style={{ cursor: 'pointer' }}>
                {props.children}
            </div>
        </>
    );
};
export default ConfirmModal;
