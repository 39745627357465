import React, { useState, useRef, useEffect } from 'react';

import expoConfig from '../config/expo_config.json';
import expoClient from '../expoClient';
import location from '../utils/location';

import { Grid, Card, Image, List, Icon } from 'semantic-ui-react';

import { useTranslation } from 'react-i18next';

import { Input, Button, Row, Col, Radio } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import './CompaniesJoined.css';

const CompaniesJoined = () => {
    const { t } = useTranslation();
    const { Search } = Input;
    const chamberSubdomain = location.detectSubdomain();

    const [chamber, setChamber] = useState(null);
    const [lastJoined, setLastJoined] = useState(true);
    const [companies, setCompanies] = useState(null);
    const [countries, setCountries] = useState([]);
    const [countryId, setCountryId] = useState(null);

    useEffect(() => {
        const init = async (subdomain) => {
            if (subdomain === null) return null;

            const chamberResult = await expoClient.chambers.getBySubdomain(subdomain);
            console.log('readChamber', chamberResult.data);
            const chamber = chamberResult.data[0];
            setChamber(chamber);
            const companiesRes = await expoClient.chambers.lastJoinedCompanies(chamber.id);
            if (companiesRes.status === 200 && companiesRes.data) {
                setCompanies(companiesRes.data.slice(0, expoConfig.homepage.max_joined));
                setLastJoined(true);
            }
            const coutriesRes = await expoClient.chambers.getCountries(chamber.id);
            if (coutriesRes.status === 200 && coutriesRes.data) {
                setCountries(coutriesRes.data);
            }
        };
        if (chamber === null) {
            init(chamberSubdomain);
        }
        console.log('useEffect', companies);
    }, [chamber, companies]);

    const handleSearch = async (text = '') => {
        console.log('Execute the search', chamber.id, countryId, text);
        const searchRes = await expoClient.chambers.searchCompanies(chamber.id, countryId, text);
        if (searchRes.status === 200) {
            setCompanies(searchRes.data);
            setLastJoined(false);
            return;
        }
        setCompanies(null);
    };

    const changeCountryButton = (value) => {
        //console.log('radio checked', newValue);
        setCountryId(value);
    };

    return (
        <>
            <section>
                <div
                    className="header-companies-joined"
                    style={{
                        backgroundImage: chamber
                            ? `url(${expoConfig.static.url}/entities/${chamber.entityId}/cover.jpg)`
                            : '',
                    }}
                >
                    <Row align="middle" justify="center" className="contain-row-header-companies-joined" style={{}}>
                        <Col>
                            <h1 className="title-header-companies-joined">
                                <strong>
                                    {t('app_title_1')}
                                    <strong>
                                        {t('app_title_2')}-{chamber ? chamber.subdomain.toUpperCase() : ''}
                                    </strong>
                                </strong>
                            </h1>
                            <p className="subtitle-header-companies-joined"> {t('title-registered-companies')} </p>
                        </Col>
                    </Row>
                </div>

                <div style={{ height: '200px', backgroundColor: '#fff', paddingTop: '50px' }}>
                    <Row align="middle">
                        <Col xl={{ offset: 9, span: 6 }} xs={{ offset: 1, span: 22 }} md={{ offset: 4, span: 16 }}>
                            <Search
                                id="input-search"
                                className="input-search-companies"
                                style={{ height: '43px' }}
                                placeholder={t('button_search')}
                                onSearch={(value) => handleSearch(value)}
                            />
                        </Col>
                    </Row>

                    <Row align="middle" justify="center" style={{ paddingTop: '20px' }}>
                        <Col>
                            <Radio.Group
                                defaultValue={0}
                                onChange={(e) => changeCountryButton(e.target.value)}
                                style={{ fontSize: '16px', textTransform: 'uppercase', color: 'black' }}
                            >
                                {countries &&
                                    countries.map((c) => (
                                        <Radio
                                            key={`radio_${c.id}`}
                                            className="radio-button-companies-search"
                                            value={c.id}
                                        >
                                            {t(`country_${c.code.toUpperCase()}`)}
                                        </Radio>
                                    ))}
                                <Radio className="radio-button-companies-search" value={0}>
                                    {t('all_companies')}
                                </Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                </div>

                {companies ? (
                    <div className="div-companies-last-joined">
                        <p
                            style={{
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                fontSize: '16px',
                                marginBottom: '30px',
                            }}
                        >
                            {lastJoined ? (
                                <strong>{t('last_adhesions')} </strong>
                            ) : (
                                <strong>
                                    {t('search_result_companies')} {companies.length}
                                </strong>
                            )}
                        </p>

                        <Grid stackable columns={3}>
                            {companies.map((c) => (
                                <Grid.Column
                                    key={`company_${c.id}`}
                                    tablet={8}
                                    mobile={16}
                                    computer={5}
                                    style={{ display: 'flex', justifyContent: 'center' }}
                                >
                                    <Card fluid className="card-companies-joined">
                                        <Card.Content>
                                            <Image className="square" floated="right" size="tiny" src={c.logoPath} />
                                            <Card.Header style={{ fontSize: '20px', marginTop: '5px' }}>
                                                {' '}
                                                <strong>{c.name}</strong>{' '}
                                            </Card.Header>
                                            <Card.Meta style={{ fontSize: '18px' }}>{`${
                                                c.country.name
                                            } - ${c.country.code.toUpperCase()}`}</Card.Meta>
                                        </Card.Content>
                                        <Card.Content>
                                            <List /*key={`listheader_${entity.entityId}`}*/>
                                                <List.Item key="listItem_title">
                                                    <List.Icon name="map marker" />
                                                    <List.Content style={{ fontSize: '18px' }}>
                                                        {' '}
                                                        {c.addressCity}{' '}
                                                    </List.Content>
                                                </List.Item>
                                            </List>
                                        </Card.Content>
                                    </Card>
                                </Grid.Column>
                            ))}
                        </Grid>
                    </div>
                ) : null}
            </section>
        </>
    );
};

export default CompaniesJoined;
