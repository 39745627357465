import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//import "./grid.css";
import './index.css';

const PricingTable = (props) => {
    const childrenWithProps = React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
            highlightColor: props.highlightColor,
        }),
    );

    const { t } = useTranslation();
    return (
        <>
            <div className="pricing-table">
                {childrenWithProps}
                {/*
      <div className="Grid">{childrenWithProps}</div>
      */}
            </div>
        </>
    );
};
export default PricingTable;

PricingTable.propTypes = {
    highlightColor: PropTypes.string,
    children: PropTypes.node,
};

PricingTable.defaultProps = {
    highlightColor: '#fff',
};
