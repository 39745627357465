import is from 'is_js';
import _ from 'lodash';

import React, { useEffect, useState } from 'react';
import { Result, Form, Input, Row, Col, Button, notification, Modal, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import { useAuth0 } from '../../react-auth0-spa';
import ChimeProvider from '../../providers/ChimeProvider';
import MeetingStatusProvider from '../../providers/MeetingStatusProvider';
import B2BCall from './B2BCall';
import MeetingAction from '../../enums/MeetingAction';
import expoConfig from '../../config/expo_config.json';
import { QuestionCircleOutlined } from '@ant-design/icons';
import history from '../../utils/history';
import expoClient from '../../expoClient';

import { ReactComponent as VideoDisabled } from '../../icons/video-disabled.svg';
import { ReactComponent as AudioDisabled } from '../../icons/audio-disabled.svg';

import './B2BRouter.css';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const B2BRouter = () => {
    const { isAuthenticated, expoUser, loading, loginWithRedirect } = useAuth0();
    const [modalVisible, setModalVisible] = useState(true);

    const { t } = useTranslation();

    const [eventImage, setEventImage] = useState(null);
    const [eventTitle, setEventTitle] = useState(null);
    const [disableEnter, setDisableEnter] = useState(true);
    const [alreadyOnline, setAlreadyOnline] = useState(null);
    const [forceCallLogin, setForceCallLogin] = useState(null);
    let { joinToken } = useParams(null);
    const action = useQuery().get('action');
    const tokens = joinToken.split('-');
    const noToken = tokens.length === 0;
    const [b2bLogin, setB2bLogin] = useState(tokens.length === 1);
    //   const b2bLogin = tokens.length === 1;
    const showWarning = is.mobile() || is.tablet();

    const [submittingData, setSubmittingData] = useState(false);

    useEffect(() => {
        const start = async () => {
            if (isAuthenticated) {
                try {
                    console.log('B2BRouter.useEffect build meeting url');
                    const urlRes = await expoClient.meeting.getUrlWithUserIdAndRoomToken(
                        tokens[0],
                        expoUser.user.id,
                        expoUser.token,
                    );

                    window.location.href = urlRes.data;
                    // var res = urlRes.data.split("-");
                    // tokens[1] = res[res.length - 1];
                    // setB2bLogin(false);
                    // user["link"] = urlRes.data;
                } catch {
                    //user["link"] = null;
                    console.log('B2BRouter.useEffect getUrlWithUserId error');
                    let companyName = 'N/A';
                    if (expoUser && expoUser.entityTypeId === expoConfig.domain.entity_type.chamber) {
                        const chamberRes = await expoClient.chambers.get(expoUser.entityId, expoUser.token);
                        companyName = chamberRes.data.name;
                    } else {
                        const companyRes = await expoClient.company.get(expoUser.entityId, expoUser.token);
                        companyName = companyRes.data.name;
                    }

                    const userData = {
                        name: expoUser.user.name,
                        surname: expoUser.user.surname,
                        email: expoUser.user.email,
                        businessRole: expoUser.businessRole ? expoUser.businessRole : null,
                        companyName: companyName,
                    };
                    postGuestData(userData);
                }
            }

            try {
                const b2bResponse = await expoClient.meeting.getCallInfo(tokens[0]);
                console.log('B2BLeave.useEffect b2bResponse', b2bResponse.data);
                setEventImage(b2bResponse.data.image);
                setEventTitle(b2bResponse.data.event);
            } catch (error) {
                // eslint-disable-next-line
                console.error(error);
            }
        };
        const checkIfAlreadyOnline = async () => {
            const isOnlineResponse = await expoClient.meeting.isAttendeeOnline(tokens[0], tokens[1]);
            setAlreadyOnline(isOnlineResponse.data);
        };

        if (!loading && !tokens[1]) {
            start();
        } else if (!loading) {
            checkIfAlreadyOnline();
        }
    }, [loading]);

    const postGuestData = async function (values) {
        const guestData = {
            name: values.name,
            surname: values.surname,
            email: values.email,
            businessRole: values.businessRole ? values.businessRole : null,
            companyName: values.companyName,
        };
        try {
            let tokenRoom = tokens[0];
            let url = await expoClient.meeting.createGuest(tokenRoom, guestData);
            window.location.replace(url.data);
        } catch (error) {
            console.error(error);
            const msg = _.capitalize(t('error'));
            let desc = t('modify_data_nok');
            notification.error({
                key: 'postRegistrationError',
                message: msg,
                description: desc,
                placement: 'topRight',
                duration: 0,
            });
        } finally {
            setSubmittingData(false);
        }
    };

    const doLogin = async () => {
        if (window.location.pathname.includes('/event/') && !window.location.pathname.includes('/event/leave')) {
            localStorage.setItem(expoConfig.pageToRedirect, window.location.pathname);
        } else {
            localStorage.setItem(expoConfig.pageToRedirect, '/');
        }
        await loginWithRedirect({});
        //history.push("/");
    };

    const checkValues = (changed, values) => {
        const fields = ['name', 'surname', 'email', 'companyName'];
        let disable = false;
        fields.forEach((f) => {
            if (f in values && values[f]) {
                disable = disable || values[f].trim().length === 0;
                if (f === 'email') {
                    disable = disable || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values[f].trim());
                }
            } else {
                disable = true;
            }
        });

        setDisableEnter(disable);
    };

    const handleModalOk = () => {
        setModalVisible(false);
        setForceCallLogin(true);
    };
    const handleModalCancel = () => {
        setModalVisible(false);
        setForceCallLogin(false);
    };

    return loading ? (
        <h1>Loading...</h1>
    ) : action === MeetingAction.Leave ? (
        <h1>Sei uscito dalla call tanti saluti e baci!</h1>
    ) : showWarning ? (
        <Result status="warning" title={t('warning_no_mobile_for_meeting')} />
    ) : noToken ? (
        <Result status="error" title={_.capitalize(t('warning_invalid_url_meeting'))} />
    ) : b2bLogin ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="b2b-call-login">
                <div className="b2b-call-login-image">
                    <img style={{ width: '640px' }} src={eventImage} alt="event logo" />
                    {/* <Row justify="center" style={{marginTop: '310px'}}>
                        <Col>
                            <VideoDisabled style={{ width: '45px'}}> </VideoDisabled>
                        </Col>
                        <Col offset={1}>
                            <AudioDisabled style={{ width: '45px' }}> </AudioDisabled>
                        </Col>
                 </Row> */}
                </div>
                <Form onFinish={postGuestData} onValuesChange={checkValues}>
                    <div className="b2b-call-link-unico">
                        <p>{eventTitle}</p>

                        <Form.Item
                            style={{ width: '400px' }}
                            name="name"
                            rules={[{ required: true, message: t('user_data_name_required') }]}
                        >
                            <Input size="large" placeholder={t('company_contact_name') + '*'} />
                        </Form.Item>
                        <Form.Item
                            style={{ width: '400px' }}
                            name="surname"
                            rules={[{ required: true, message: t('user_data_surname_required') }]}
                        >
                            <Input size="large" placeholder={t('company_contact_surname') + '*'} />
                        </Form.Item>
                        <Form.Item
                            style={{ width: '400px' }}
                            name="email"
                            rules={[
                                { required: true, message: t('user_data_email_required') },
                                { type: 'email', message: t('the_input_invalid_mail') },
                            ]}
                        >
                            <Input size="large" placeholder={t('company_contact_email') + '*'} />
                        </Form.Item>
                        <Form.Item
                            style={{ width: '400px' }}
                            name="companyName"
                            rules={[{ required: true, message: t('signup_required_company_name') }]}
                        >
                            <Input size="large" placeholder={t('company_name') + '*'} />
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" disabled={disableEnter} block className="b2b-call-button-next">
                                {t('button-b2_call_login_enter')}
                            </Button>
                        </Form.Item>
                    </div>
                    <Row className="b2b-call-div-button" align="middle">
                        <Col span={24}>{t('b2bcall-or-login')}</Col>
                        <Col>
                            <Form.Item>
                                <Button block className="b2b-call-button-accedi" onClick={() => doLogin()}>
                                    {t('login')}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    ) : alreadyOnline == true && !forceCallLogin ? (
        <Modal
            visible={modalVisible}
            title={
                <Space>
                    <QuestionCircleOutlined style={{ fontSize: '24px' }} /> {t('warning').toUpperCase()}
                </Space>
            }
            onOk={handleModalOk}
            onCancel={handleModalCancel}
            footer={[
                <Button key="1" onClick={handleModalOk}>
                    {t('button_b2b_call_login_use_here').toUpperCase()}
                </Button>,
                <Button key="2" onClick={handleModalCancel}>
                    {t('button_b2b_call_login_close').toUpperCase()}
                </Button>,
            ]}
        >
            <p style={{ fontSize: '16px' }}>{t('link_already_used')}</p>
        </Modal>
    ) : (alreadyOnline == true && forceCallLogin) || alreadyOnline == false ? (
        <ChimeProvider>
            <MeetingStatusProvider tokens={tokens}>
                <B2BCall />
            </MeetingStatusProvider>
        </ChimeProvider>
    ) : null;
};
export default B2BRouter;
