import React from 'react';
import './ImageEditor.css';
import './EntityInfoPanel.css';
import { Row, Col, Typography, Progress, notification, Button } from 'antd';
import './VideoEditor.css';
import { ReactComponent as AddMedia } from '../icons/aggiungi.svg';
import TextArea from 'antd/lib/input/TextArea';
import ConfirmModal from './ConfirmModal';
import { ReactComponent as UploadButton } from '../icons/addvideo.svg';
import expoConfig from '../config/expo_config.json';
import { withTranslation } from 'react-i18next';

class VideoEditor extends React.Component {
    constructor(props) {
        super(props);
        this.localEntityTmp = props.entity;
        this.editModeCallBackParent = props.setEditModeCallBackParent;
        this.closeModalCallBack = props.closeModalCallBack;
        this.isEditable = props.isEditable;
        this.state = {
            edtiMode: false,
            globalEditState: props.globalEditState,
            statusUpload: props.statusUpload,
            percent: props.percent,
            file: null,
            desc: '',
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.globalEditState !== this.props.globalEditState)
            this.setState({ globalEditState: this.props.globalEditState });
        if (prevProps.percent !== this.props.percent) this.setState({ percent: this.props.percent });
        if (prevProps.statusUpload !== this.props.statusUpload)
            this.setState({ statusUpload: this.props.statusUpload });
    }

    setFileInState = (file) => {
        if (file) {
            if (file.name) {
                if (
                    !file.name.toUpperCase().endsWith('MP4') &&
                    !file.name.toUpperCase().endsWith('.M4V') &&
                    !file.name.toUpperCase().endsWith('.WEBM') &&
                    !file.name.toUpperCase().endsWith('OGM') &&
                    !file.name.toUpperCase().endsWith('OGV')
                ) {
                    const { t } = this.props;
                    const desc = t('uploading_error_format_description');
                    const message = t('uploading_error_format_message');
                    notification.error({
                        key: 'wrongFormatfile',
                        description: desc,
                        message: message,
                        placement: 'topRight',
                        duration: 12,
                    });
                    return;
                }
            }
            if (file.size) {
                if ((file.size / (1024 * 1000)).toFixed(2) > expoConfig.domain.video.maxsize) {
                    const { t } = this.props;
                    const desc =
                        t('uploading_error_size_description') +
                        ' ' +
                        expoConfig.domain.video.maxsize +
                        '(' +
                        expoConfig.domain.video.unit +
                        ')';
                    const message = t('uploading_error_size_message');
                    notification.error({
                        key: 'toolargefile',
                        description: desc,
                        message: message,
                        placement: 'topRight',
                        duration: 12,
                    });
                    return;
                }
            }
            this.setState({ edtiMode: true });
            this.setState({ showButtons: true });
            this.setState({ file: file });
            this.uploadWithFormData(file);
        }
    };

    uploadWithFormData = (file) => {
        const formData = new FormData();
        formData.append('file', file);
        // formData.append("description", this.state.desc);
        this.editModeCallBackParent(false, true, false, formData);
    };

    clickFileChooser() {
        document.getElementById('videoInputFile').click();
    }

    closeModal() {
        this.closeModalCallBack();
    }

    render() {
        const { t } = this.props;
        const { Paragraph } = Typography;
        const toWriteInActionPar = this.state.file
            ? this.state.statusUpload === 'normal' || this.state.statusUpload === 'active'
                ? t('uploading_file')
                : t('error_uploading_file')
            : t('uploading_file_suggestion');
        const toWriteInFileName = this.state.file ? t('uploading_file_name') : '';
        const toWriteInFileName1 = this.state.file ? this.state.file.name : '';
        const toWriteInFileSize = this.state.file ? t('uploading_file_size') : '';
        const toWriteInFileSize1 = this.state.file
            ? ' ' + (this.state.file.size / (1024 * 1000)).toFixed(2) + ' MB'
            : '';
        const uploadButtonClass = this.state.statusUpload !== 'exception' ? 'uploadButton_black' : 'uploadButton_red';
        const actionParClass = this.state.statusUpload !== 'exception' ? 'actionPar_black' : 'actionPar_red';

        const uploadButtonOrCircle =
            this.state.statusUpload !== 'normal' && this.state.statusUpload !== 'exception' ? (
                <Col md={{ span: 12 }} xs={{ span: 24 }} className=" col-flex-end">
                    <div key="1" className="button-media">
                        <Progress
                            type="circle"
                            style={{ padding: '30px' }}
                            percent={this.state.percent}
                            status={this.state.statusUpload}
                        />
                    </div>
                </Col>
            ) : (
                <>
                    <Row style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                        <Col xl={{ span: 24 }} className="col-media-insert">
                            <div key="1" className="button-media" onClick={this.clickFileChooser}>
                                <UploadButton className="upload-icon-media" />
                                <div className="container-icon-media">
                                    <AddMedia className="icon-plus-media" />
                                    <span style={{ fontSize: '20px' }}> {t('video').toUpperCase()} </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </>
            );

        return (
            <>
                <div
                    className="videoEditor"
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '30px' }}
                >
                    <input
                        id="videoInputFile"
                        type="file"
                        hidden
                        name="file"
                        className="inputTextVideoContainer"
                        accept="video/mp4,video/webm,video/ogg"
                        onChange={(e) => this.setFileInState(e.target.files[0])}
                    />
                    <Row>
                        {uploadButtonOrCircle}
                        {this.state.statusUpload !== 'normal' ? (
                            <Col md={{ span: 12 }} xs={{ span: 24 }} className="col-media-text">
                                <Paragraph
                                    id="chosenFile"
                                    style={{ fontSize: '20px', Height: '150px', wordBreak: 'break-word' }}
                                >
                                    <b>{toWriteInFileName}</b>
                                    <br></br>
                                    {toWriteInFileName1}
                                </Paragraph>
                                <Paragraph
                                    id="chosenSize"
                                    style={{ fontSize: '20px', Height: '150px', wordBreak: 'break-word' }}
                                >
                                    <b>{toWriteInFileSize}</b>
                                    <br></br>
                                    {toWriteInFileSize1}
                                </Paragraph>
                            </Col>
                        ) : null}
                    </Row>
                </div>
                <div className="button_operation_request_media">
                    <Row justify="center" align="middle">
                        <Col style={{ display: 'flex', justifyContent: 'center', margin: '10px' }}>
                            <ConfirmModal onOk={() => this.closeModalCallBack()} message={t('ask_for_close_live')}>
                                <Button block shape="round" className="blackMode button-request" htmlType="button">
                                    {t('button_cancel').toUpperCase()}
                                </Button>
                            </ConfirmModal>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
export default withTranslation()(VideoEditor);
