import React, { useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import locationUtil from '../utils/location';

const LanguageDropdown = (props) => {
    //parametri del componente
    const { languageList } = props;

    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);
    const subdomain = locationUtil.detectSubdomain();

    const changeLanguage = (e, lng) => {
        i18n.changeLanguage(lng);
        setLanguage(lng);
        console.log('---> event: ', e);
        e.preventDefault();
    };

    const getAdditionalLanguages = (languageList) => {
        const ret = [];

        if (languageList) {
            languageList.forEach((o, i) => {
                let keyId = 3 + i;
                ret.push(
                    <Menu.Item key={keyId}>
                        <span className={'flag ' + o.flag + ' flags'} onClick={(e) => changeLanguage(e, o.code)} />
                    </Menu.Item>,
                );
            });
        }
        return ret;
    };

    const menu = (
        <Menu>
            <Menu.Item key="1">
                <span className="flag flag-ita flags" onClick={(e) => changeLanguage(e, 'it-IT')} />
            </Menu.Item>
            <Menu.Item key="2">
                <span className="flag flag-gbr flags" onClick={(e) => changeLanguage(e, 'en-EN')} />
            </Menu.Item>
            {getAdditionalLanguages(languageList)}
        </Menu>
    );

    return (
        <Dropdown overlay={menu} overlayClassName="languageMode">
            {language === 'it-IT' ? (
                <span className="flag flag-ita flags" />
            ) : language === 'ru-RU' ? (
                <span className="flag flag-rus flags" />
            ) : language === 'vt-VT' ? (
                <span className="flag flag-vnm flags" />
            ) : language === 'uz-UZ' ? (
                <span className="flag flag-uzb flags" />
            ) : (
                <span className="flag flag-gbr flags" />
            )}
        </Dropdown>
    );
};
export default LanguageDropdown;
