import React from 'react';
import axios from 'axios';
import { DokaOverlay, toURL } from '../lib/react-doka';
import './ImageEditor.css';
import './EntityInfoPanel.css';
import EditSaveDiscardIcons from './EditSaveDiscardIcons';
import { withTranslation } from 'react-i18next';
import { Input } from 'antd';

class ImageEditor extends React.Component {
    constructor(props) {
        super(props);
        this.localEntityTmp = props.entity;
        this.formRef = props.form;
        this.updateCallback = props.updateCallback;
        this.editModeCallBackParent = props.setEditModeCallBackParent;
        this.isEditable = props.isEditable;
        this.className = props.className ? props.className : 'ImageEditor';
        this.idInputFile = props.idInputFile;
        this.isMedia = props.isMedia;
        //Variabile che dice se il salvataggio avviene diretto alla done
        //oppure se bisogna aspettare che si clicchi il bottone verde
        this.enableSave = props.enableSave;
        this.state = {
            imageUrl: props.imageUrl,
            edtiMode: false,
            globalEditState: props.globalEditState,
            showButtons: true,
            banner: {
                enabled: false,
                image: props.imageUrl,
                src: props.imageUrl,
                //image: null,
                //src: null,
                srcPrev: null,
                crop: {
                    aspectRatio: 0.6267,
                    center: {
                        x: 0.5,
                        y: 0.5,
                    },
                },
            },
            identity: props.entity && props.entity.id ? props.entity.id : null,
        };
        this.handleDokaConfirmBanner = this.handleDokaConfirmBanner.bind(this);
        this.handleDokaCancelBanner = this.handleDokaCancelBanner.bind(this);
        this.handleToggleBannerEditor = this.handleToggleBannerEditor.bind(this);
        this.handleFileChangeBanner = this.handleFileChangeBanner.bind(this);
        this.setSaveCallBack = this.setSaveCallBack.bind(this);
        this.setDiscardCallBack = this.setDiscardCallBack.bind(this);
        this.setEditModeCallBack = this.setEditModeCallBack.bind(this);
        this.callBackFromSave = this.callBackFromSave.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.globalEditState !== this.props.globalEditState)
            this.setState({ globalEditState: this.props.globalEditState });
        if (prevProps.entity !== this.props.entity) this.setState({ identity: this.props.entity.id });
        if (prevProps.imageUrl !== this.props.imageUrl) {
            this.setState({
                banner: {
                    ...this.state.banner,
                    image: this.props.imageUrl,
                    src: this.props.imageUrl,
                },
            });
            this.setState({ imageUrl: this.props.imageUrl });
        }
    }

    setSaveCallBack(fromDoka) {
        //Se arrivo dopo un salvataggio su doka
        if (fromDoka) {
            if (!this.formRef || !this.formRef.current || typeof this.formRef.current === 'undefined') {
                if (this.isMedia) {
                    this.localEntityTmp.imageBase64 = this.state.imageUrl;
                } else {
                    this.localEntityTmp.bannerImageBase64 = this.state.imageUrl;
                }
                if (this.editModeCallBackParent && typeof this.editModeCallBackParent !== 'undefined') {
                    if (!this.enableSave) {
                        this.editModeCallBackParent(false, true, false, this.state.identity);
                    }
                }
            } else {
                //Sono nell'evento
                this.formRef.current.setFieldsValue({ file: this.state.imageUrl });
                if (this.updateCallback) {
                    this.updateCallback(this.state.imageUrl !== null);
                }
                if (this.editModeCallBackParent && typeof this.editModeCallBackParent !== 'undefined') {
                    this.editModeCallBackParent(false, true, false, this.state.identity);
                }
            }
            //Se non sono abilitati i bottoni di save/discard metto la matita di modifica
            if (!this.enableSave) this.setState({ edtiMode: false });
        } else {
            if (this.editModeCallBackParent && typeof this.editModeCallBackParent !== 'undefined') {
                this.editModeCallBackParent(false, true, false, this.state.identity);
            }
            this.setState({ edtiMode: false });
        }
    }

    callBackFromSave() {
        this.setSaveCallBack(false);
    }

    setDiscardCallBack() {
        this.setState({ edtiMode: false });
        if (this.editModeCallBackParent && typeof this.editModeCallBackParent !== 'undefined') {
            this.editModeCallBackParent(false, false, true, this.state.identity);
        }
    }

    setEditModeCallBack() {
        this.clickFileChooser();
    }

    clickFileChooser() {
        document.getElementById(this.idInputFile).click();
    }

    componentDidMount() {
        this.getBase64FromUrl(this.state.imageUrl).then((b64) => {
            this.setState({ imageUrl: b64 });
            if (this.formRef && this.formRef.current)
                this.formRef.current.setFieldsValue({
                    file: b64,
                });
        });
    }

    getBase64FromUrl(url) {
        return axios.get(url, { responseType: 'arraybuffer' }).then((response) => {
            let image = btoa(
                new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''),
            );
            return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
        });
    }

    /**
     * Profile Banner
     */
    handleToggleBannerEditor() {
        this.setState({
            banner: {
                ...this.state.banner,
                enabled: !this.state.banner.enabled,
            },
        });
    }

    handleFileChangeBanner(e) {
        if (!e.target.files.length) {
            this.setDiscardCallBack();
            return;
        }
        //Faccio sparire i bottoni per non farli schiacciare prima del done o cancel della libreria
        this.setState({
            banner: {
                ...this.state.banner,
                srcPrev: this.state.banner.src,
                src: e.target.files[0],
                enabled: true,
            },
            edtiMode: true,
            showButtons: false,
        });
        if (this.editModeCallBackParent && typeof this.editModeCallBackParent !== 'undefined') {
            this.editModeCallBackParent(true, false, false, this.state.identity);
        }
    }

    handleDokaConfirmBanner(output) {
        this.setState({
            banner: {
                ...this.state.banner,
                srcPrev: null,
                image: output.file,
                crop: output.data.crop,
                enabled: false,
            },
            showButtons: true,
        });

        this.getBase64(output.file, (imageUrl) => {
            this.setState({
                imageUrl: imageUrl,
            });
            //Se sono chiamato dalla insert Event mi allora gli setto il campo
            if (this.formRef && this.formRef.current && typeof this.formRef.current !== 'undefined') {
                this.formRef.current.setFieldsValue({
                    file: imageUrl,
                });
                if (this.updateCallback) this.updateCallback(imageUrl !== null);
            }
            this.setSaveCallBack(true);
        });
    }

    handleDokaCancelBanner() {
        this.setState({
            banner: {
                ...this.state.banner,
                src: this.state.banner.srcPrev || this.state.banner.src,
                srcPrev: null,
                enabled: false,
            },
            showButtons: true,
        });
        this.setDiscardCallBack();
    }

    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    render() {
        const { banner } = this.state;
        const { t } = this.props;

        //Se il component globale è in edit il mio component deve essere abilitato se il suo stato è in edit altrimenti deve essere disabilitato.
        const enabled = this.state.globalEditState ? (this.state.edtiMode ? true : false) : true;
        const cssPositionClass = this.state.showButtons
            ? 'editingButtonStandardPositionBlock'
            : 'editingButtonStandardPositionNone';

        const editButton = this.props.isEditable ? (
            <EditSaveDiscardIcons
                showButtons={this.state.showButtons}
                enabled={enabled}
                editMode={this.state.edtiMode}
                callBackOnEdit={this.setEditModeCallBack}
                callBackOnSave={this.callBackFromSave}
                callBackOnDiscard={this.setDiscardCallBack}
                cssPositionClass={cssPositionClass}
            />
        ) : (
            <div></div>
        );
        const inputImage = this.props.isEditable ? (
            <Input
                id={this.idInputFile}
                type="file"
                style={{ display: 'none' }}
                accept="image/*"
                onChange={this.handleFileChangeBanner}
            />
        ) : (
            <div></div>
        );

        return (
            <div className={this.className}>
                <DokaOverlay
                    labelButtonReset={t('labelButtonReset')}
                    labelButtonCancel={t('labelButtonCancel')}
                    labelButtonConfirm={t('labelButtonConfirm')}
                    labelStatusAwaitingImage={t('labelStatusAwaitingImage')}
                    labelStatusLoadingImage={t('labelStatusLoadingImage')}
                    labelStatusLoadImageError={t('labelStatusLoadImageError')}
                    labelStatusProcessingImage={t('labelStatusProcessingImage')}
                    labelColorBrightness={t('labelColorBrightness')}
                    labelColorContrast={t('labelColorContrast')}
                    labelColorExposure={t('labelColorExposure')}
                    labelColorSaturation={t('labelColorSaturation')}
                    labelResizeWidth={t('labelResizeWidth')}
                    labelResizeHeight={t('labelResizeHeight')}
                    labelResizeApplyChanges={t('labelResizeApplyChanges')}
                    labelCropInstructionZoom={t('labelCropInstructionZoom')}
                    labelButtonCropZoom={t('labelButtonCropZoom')}
                    labelButtonCropRotateLeft={t('labelButtonCropRotateLeft')}
                    labelButtonCropRotateRight={t('labelButtonCropRotateRight')}
                    labelButtonCropRotateCenter={t('labelButtonCropRotateCenter')}
                    labelButtonCropFlipHorizontal={t('labelButtonCropFlipHorizontal')}
                    labelButtonCropFlipVertical={t('labelButtonCropFlipVertical')}
                    labelButtonCropAspectRatio={t('labelButtonCropAspectRatio')}
                    labelButtonCropToggleLimit={t('labelButtonCropToggleLimit')}
                    labelButtonCropToggleLimitEnable={t('labelButtonCropToggleLimitEnable')}
                    labelButtonCropToggleLimitDisable={t('labelButtonCropToggleLimitDisable')}
                    labelButtonUtilCrop={t('labelButtonUtilCrop')}
                    labelButtonUtilFilter={t('labelButtonUtilFilter')}
                    labelButtonUtilColor={t('labelButtonUtilColor')}
                    labelButtonUtilResize={t('labelButtonUtilResize')}
                    labelButtonUtilMarkup={t('labelButtonUtilMarkup')}
                    labelButtonUtilSticker={t('labelButtonUtilSticker')}
                    labelMarkupTypeRectangle={t('labelMarkupTypeRectangle')}
                    labelMarkupTypeEllipse={t('labelMarkupTypeEllipse')}
                    labelMarkupTypeText={t('labelMarkupTypeText')}
                    labelMarkupTypeLine={t('labelMarkupTypeLine')}
                    labelMarkupSelectFontSize={t('labelMarkupSelectFontSize')}
                    labelMarkupSelectFontFamily={t('labelMarkupSelectFontFamily')}
                    labelMarkupSelectLineDecoration={t('labelMarkupSelectLineDecoration')}
                    labelMarkupSelectLineStyle={t('labelMarkupSelectLineStyle')}
                    labelMarkupSelectShapeStyle={t('labelMarkupSelectShapeStyle')}
                    labelMarkupRemoveShape={t('labelMarkupRemoveShape')}
                    labelMarkupToolSelect={t('labelMarkupToolSelect')}
                    labelMarkupToolDraw={t('labelMarkupToolDraw')}
                    labelMarkupToolLine={t('labelMarkupToolLine')}
                    labelMarkupToolText={t('labelMarkupToolText')}
                    labelMarkupToolRect={t('labelMarkupToolRect')}
                    labelMarkupToolEllipse={t('labelMarkupToolEllipse')}
                    className="banner"
                    style={{ height: '100%' }}
                    utils={['crop']}
                    crop={banner.crop}
                    src={banner.src}
                    enabled={banner.enabled}
                    onConfirm={this.handleDokaConfirmBanner}
                    onCancel={this.handleDokaCancelBanner}
                >
                    <img id="bannerImage" src={toURL(banner.image)} alt="" />
                    {inputImage}
                </DokaOverlay>
                {editButton}
            </div>
        );
    }
}
export default withTranslation()(ImageEditor);
