import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'antd';

const RosterLine = ({ data, color, you }) => {
    const { t } = useTranslation();

    const fullname = `${data.name} ${data.surname} ${you ? t('self-roster') : ''}`;

    return (
        <Row className="rosterLine" align="middle">
            <Col span={5} align="middle">
                <div className="attendeeCircular" style={{ backgroundColor: color }}>
                    <p className="attendeeInitials">{data.initials}</p>
                </div>
            </Col>
            <Col span={19}>
                <p className="attendeeNameRoster">{fullname}</p>
                <p className="attendeeCompanyRoster">{data.entity}</p>
            </Col>
        </Row>
    );
};

export default RosterLine;
