import React, { useEffect, useState } from 'react';
import { Col, Row, Typography } from 'antd';
import { useAuth0 } from '../react-auth0-spa';
import timezones from '../config/timezone.json';
import expoClient from '../expoClient';
import moment from 'moment';
// eslint-disable-next-line no-unused-vars
import momentTimezone from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import './EventInfoPanel.css';

const EventInfoPanel = ({ form, formValues, eventEntity }) => {
    const [event, setEvent] = useState(null);
    const { isAuthenticated, expoUser, loading } = useAuth0();
    const { t } = useTranslation();

    useEffect(() => {
        const mapForm = (form) => {
            let event = null;
            if (form) {
                event = {
                    name: form.getFieldValue('eventname'),
                    venue: form.getFieldValue('eventvenue'),
                    description: form.getFieldValue('eventdescription'),
                    dateFrom: form.getFieldValue('eventrangedate') && form.getFieldValue('eventrangedate')[0],
                    dateTo: form.getFieldValue('eventrangedate') && form.getFieldValue('eventrangedate')[1],
                    timezone: form.getFieldValue('eventtimezone')
                        ? form.getFieldValue('eventtimezone')
                        : timezones.default,
                    meetingUrl: null,
                };
            }
            return event;
        };
        const mapEvent = async (e, t) => {
            //console.log("EventInfoPanel.mapEvent", e, t);
            let meetingUrl = null;
            let meetingUrlGuest = null;
            if (e && e.roomId) {
                try {
                    const urlResponse = await expoClient.meeting.getUrl(e.roomId, t);
                    meetingUrl = urlResponse.data;
                    var res = meetingUrl.split('-');
                    meetingUrlGuest = meetingUrl.replace('-' + res[res.length - 1], '');
                } catch (error) {
                    console.log('Error reading meetingUrl', error);
                }
            }
            setEvent({
                name: e.name,
                venue: e.addressVenue,
                description: e.description,
                dateFrom: moment(e.startTime).tz(e.timeZone),
                dateTo: moment(e.endTime).tz(e.timeZone),
                timezone: timezones.values[e.timeZone],
                meetingUrl: meetingUrl,
                meetingUrlGuest: meetingUrlGuest,
            });
        };
        if (form) {
            //Sono  in inserimento
            setEvent(mapForm(form));
        } else if (isAuthenticated && expoUser && !loading) {
            if (eventEntity) {
                //Sono in consultazione/editing
                //Recupero l'eventuale url di partecipazione evento per l'utente
                mapEvent(eventEntity, expoUser.token);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, formValues, isAuthenticated, expoUser, loading, eventEntity]);

    const { Text } = Typography;
    const renderEvent = (event) => {
        //console.log("EventInfoPanel.render", event)
        return (
            <Row className="expo-event">
                <Col span="24">
                    <Row>
                        <Col span="24" align="start">
                            <span style={{ fontWeight: '600', fontSize: '26px', lineHeight: '1.35' }}>
                                {event.name}
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="24" align="start">
                            <Text>{event.venue}</Text>
                        </Col>
                    </Row>

                    <Row style={{ paddingTop: '15px' }}>
                        <Col span="24" align="start">
                            <div style={{ fontSize: '14px/20px' }}>
                                <strong>{t('event_date_label')}:</strong>{' '}
                                {event.dateFrom && event.dateFrom.format('DD.MM.YY')} {t('event_to_label')}{' '}
                                {event.dateTo && event.dateTo.format('DD.MM.YY')}
                            </div>
                            <div style={{ fontSize: '14px/20px' }}>
                                <strong>{t('event_time_label')}:</strong>{' '}
                                {event.dateFrom && event.dateFrom.format('hh.mm a')} {t('event_to_label')}{' '}
                                {event.dateTo && event.dateTo.format('hh.mm a')}
                                &nbsp;[{event.timezone}]
                            </div>
                            <div style={{ fontSize: '14px/20px' }}>
                                <strong>{t('event_venue_label')}:</strong> {event.venue}
                            </div>
                            {event.meetingUrl ? (
                                <div className="invitation-link" style={{ margin: '35px 0', padding: '20px' }}>
                                    <p>
                                        <strong>{t('event_url_label')}:</strong>
                                    </p>
                                    <p>
                                        <a
                                            href={`${event.meetingUrl}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ wordWrap: 'break-word' }}
                                        >
                                            {event.meetingUrl}
                                        </a>
                                    </p>

                                    <p>
                                        <strong>{t('event_url_label_guest')}:</strong>
                                    </p>
                                    <p>
                                        <a
                                            href={`${event.meetingUrlGuest}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ wordWrap: 'break-word' }}
                                        >
                                            {event.meetingUrlGuest}
                                        </a>
                                    </p>
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                    <Row>
                        <Col span="24" align="start">
                            <div style={{ fontSize: '14px' }}>
                                <strong>{t('event_description_label')}:</strong>
                            </div>
                            {/* <Paragraph style={{maxHeight: "245px", fontSize: "14px"}}> */}
                            <pre className="wrap" style={{ Height: '245px', fontSize: '14px' }}>
                                {event.description}
                            </pre>
                            {/* </Paragraph> */}
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    };

    return event ? renderEvent(event) : null;
};
export default EventInfoPanel;
