import classNames from 'classnames/bind';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ViewMode from '../../enums/ViewMode';
import styles from './RemoteVideo.css';
import Avatar from './Avatar';
import VideoNameplate from './VideoNameplate';
import Size from '../../enums/Size';
import getMeetingStatusContext from '../../context/getMeetingStatusContext';

const cx = classNames.bind(styles);

export default function RemoteVideo(props) {
    const { t } = useTranslation();
    const {
        counter,
        viewMode,
        enabledVideo,
        enabledAvatar,
        videoElementRef,
        attendeeData,
        attendeeId,
        isContentShareEnabled,
    } = props;

    //const [muted, setMuted] = useState(true);
    //const chime = useContext(getChimeContext());
    const { colorMap } = useContext(getMeetingStatusContext());

    const avatarText = attendeeData ? `${attendeeData.name[0]}${attendeeData.surname[0]}`.toUpperCase() : '';
    const tokenColor = attendeeData ? colorMap.find((c) => c.token === attendeeData.token) : { color: '#FFF' };

    return (
        <div
            className={cx('remoteVideo', {
                roomMode: viewMode === ViewMode.Room,
                screenShareMode: viewMode === ViewMode.ScreenShare,
                enabled: enabledVideo || enabledAvatar,
                [`grid-position-${counter}`]: enabledVideo || enabledAvatar,
            })}
        >
            <video muted ref={videoElementRef} className={cx('video', { enabled: enabledVideo })} />
            <Avatar
                scale={isContentShareEnabled ? 'large' : 'xlarge'}
                content={avatarText}
                active={enabledAvatar}
                color={tokenColor ? tokenColor.color : '#FFF'}
            />
            {attendeeId ? (
                <VideoNameplate
                    viewMode={viewMode}
                    size={Size.Large}
                    //muted={muted}
                    isContentShareEnabled={isContentShareEnabled}
                    attendeeId={attendeeId}
                    attendeeData={attendeeData}
                />
            ) : null}
        </div>
    );
}
