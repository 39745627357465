import React, { useEffect, useState } from 'react';
import { Button, Col, Empty, Input, Row } from 'antd';
import { Card, Icon, Image } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '../react-auth0-spa';
import expoConfig from '../config/expo_config.json';
import auth0Config from '../config/auth_config.json';
import expoClient from '../expoClient';
import history from '../utils/history';

const SearchCompanies = ({ location }) => {
    const { chamber } = location.state;
    const { isAuthenticated, expoUser, getTokenSilently } = useAuth0();
    const { t } = useTranslation();
    const { Search } = Input;
    const [token, setToken] = useState();
    const [companies, setCompanies] = useState();

    useEffect(() => {
        const readToken = async () => {
            const token = await getTokenSilently(auth0Config.audience);
            console.log('SearchCompanies.userEffect readtoken', token);
            if (token) {
                handleSearch('', token);
                setToken(token);
            }
        };
        if (isAuthenticated && expoUser) {
            readToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, expoUser]);

    const handleSearch = async (text = '', authToken = token) => {
        const ita = expoUser.entityTypeId === expoConfig.domain.entity_type.c_ita;
        const countryId = (ita ? -1 : 1) * expoConfig.domain.nation.ita;
        console.log('Execute the search', chamber.id, countryId, text, authToken);
        const searchRes =
            text.trim().length === 0
                ? await expoClient.chambers.getCompanies(chamber.id, countryId, authToken)
                : await expoClient.chambers.searchCompanies(chamber.id, countryId, text, authToken);
        if (searchRes.status === 200) {
            setCompanies(searchRes.data);
            return;
        }
        setCompanies(null);
    };

    return (
        <>
            <Row key="1" style={{ borderLeft: '1px solid #000', borderRight: '1px solid #000' }}>
                <Col key="1" span={24} align="center">
                    <Row>
                        <Col span="24" align="center">
                            <img src={chamber.logoPath} alt="chamber logo" style={{ width: '250px' }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col offset={8} span={8}>
                            <Search
                                id="input-search"
                                placeholder="input search text"
                                onSearch={(value) => handleSearch(value)}
                                style={{ width: '410px' }}
                            />
                        </Col>
                    </Row>
                    <Row style={{ padding: '35px 0' }}>
                        <Col offset={10} span={4}>
                            <Button
                                block
                                shape="round"
                                className="blackMode"
                                htmlType="button"
                                style={{ width: '150px' }}
                                onClick={() => handleSearch(document.getElementById('input-search').value)}
                            >
                                {t('search_companies').toUpperCase()}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row key="2">
                <Col span="24">
                    <div className="whiteMode panelHeaderContainer">
                        <div className="blackMode panelHeader"></div>
                    </div>
                </Col>
            </Row>
            <Row key="3">
                <Col offset={4} span="16">
                    <SearchResult chamber={chamber} companies={companies} token={token} />
                </Col>
            </Row>
        </>
    );
};
export default SearchCompanies;

const SearchResult = ({ chamber, companies, token }) => {
    const { t } = useTranslation();
    if (!companies) companies = [];

    const noCompanies = (
        <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{ height: 120 }}
            description={t('search_companies_no_result')}
        />
    );

    const handleCompaniesClick = (id) =>
        history.push('/company', {
            entityId: id,
            token: token,
            chamber: chamber,
        });

    const renderSearch = () => (
        <Card.Group itemsPerRow={3} centered>
            {companies.map((c, i) => (
                <Card key={c.id} onClick={() => handleCompaniesClick(c.id)}>
                    <Card.Content>
                        <Image floated="right" size="tiny" src={c.logoPath} />
                        <Card.Header>{c.name}</Card.Header>
                        <Card.Meta>
                            <p style={{ color: '#000' }}>{`${c.country.name} (${c.country.code})`}</p>
                        </Card.Meta>
                    </Card.Content>
                    <Card.Content extra>
                        <Icon name="point" />
                        {c.addressCity}
                    </Card.Content>
                </Card>
            ))}
            ;
        </Card.Group>
    );

    return companies.length > 0 ? renderSearch() : noCompanies;
};
