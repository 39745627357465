import React from 'react';

import { useTranslation } from 'react-i18next';

import './CookiePolicy.css';

const CookiePolicy = () => {
    const { t } = useTranslation();

    return (
        <>
            <section style={{ backgroundColor: '#fff', height: '100%' }}>
                <div className="div-cookie-policy">
                    <h1 className="title-cookie-policy">
                        {' '}
                        <strong> {t('cookie_policy')} </strong>{' '}
                    </h1>
                    <p className="paragraph-cookie-policy"> {t('paragraph_cookie_policy')} </p>
                    <p className="paragraph-cookie-policy"> {t('paragraph_cookie_policy1')} </p>
                    <ul>
                        <li className="paragraph-cookie-policy"> {t('cookie_policy_list1')} </li>
                        <p className="paragraph-cookie-policy">
                            {' '}
                            {t('cookie_policy_activities')}
                            <p className="paragraph-cookie-policy"> {t('cookie_policy_preference')} </p>
                        </p>
                    </ul>

                    <ul>
                        <li className="paragraph-cookie-policy"> {t('other_type_of_cookie')} </li>
                        <p className="paragraph-cookie-policy">
                            {' '}
                            {t('other_type_of_cookie1')}
                            <p className="paragraph-cookie-policy"> {t('other_type_of_cookie2')} </p>
                        </p>
                    </ul>

                    <ul>
                        <li className="paragraph-cookie-policy"> {t('protection_from_spam')} </li>
                        <li className="paragraph-cookie-policy"> {t('statistics')} </li>
                    </ul>

                    <h1 lassName="subtitle-cookie-policy">
                        {' '}
                        <strong> {t('installation_of_cookies')} </strong>{' '}
                    </h1>
                    <p className="paragraph-cookie-policy"> {t('paragraph_installation_cookies')} </p>

                    <h1 lassName="subtitle-cookie-policy">
                        {' '}
                        <strong> {t('data_controller')} </strong>{' '}
                    </h1>
                    <p className="paragraph-cookie-policy">
                        {' '}
                        {t('spx_lab')}
                        <p className="paragraph-cookie-policy">
                            {' '}
                            {t('address_spx_lab')}
                            <p className="paragraph-cookie-policy"> {t('owner_email_address')} </p>{' '}
                        </p>{' '}
                    </p>

                    <p className="paragraph-cookie-policy"> {t('paragraph_cookie_policy2')} </p>
                </div>
            </section>
        </>
    );
};
export default CookiePolicy;
