import _ from 'lodash';
import React, { Component } from 'react';
import {
    Carousel,
    Form,
    Input,
    Button,
    Row,
    Col,
    Typography,
    Select,
    notification,
    Spin,
    Result,
    Checkbox,
} from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Label, Icon, Card, Image, Transition, Pagination } from 'semantic-ui-react';

import AccordionTopics from './AccordionTopics';

import { withTranslation } from 'react-i18next';

import location from '../utils/location';
import expoConfig from '../config/expo_config.json';
import expoClient from '../expoClient';
import './Signup.css';
import ConfirmModal from './ConfirmModal';
import { ReactComponent as SendMail } from '../icons/send-mail.svg';
import history from '../utils/history';

class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // entity lette dalle API
            countries: [],
            subdomainChamber: null,
            chambers: [],
            nation: null,
            // per carousel
            currentPage: 1,
            disableForwardPageOne: true,
            disableForwardPageTwo: false,
            disableSubmit: true,
            // per accordion
            topics: [],
            activeIndex: [],
            // per card pager
            activePage: 1,
            // verifiche step 2
            totTagSelected: 0,
            minTagSelected: 0,
            maxTagSelected: expoConfig.signup.max_tag_selected,
            notifyTooManyTag: false,
            showModal: false,
            // spin loading
            submittingData: false,
            descriptionColor: 'red',
        };
        //Serve per aggiornare questo flag dai componenti figli
        this.handleDisableSubmit = this.handleDisableSubmit.bind(this);
        this.formRef = React.createRef();
        this.carouselRef = React.createRef();
        this.postData = this.postData.bind(this);
        //Detect sottodominio
        this.subdomain = location.detectSubdomain();
    }

    handleDisableSubmit(disableSubmit) {
        this.setState({
            disableSubmit: disableSubmit,
        });
    }

    componentDidMount() {
        expoClient.topics
            .get()
            .then((res) => this.setState({ topics: res.data }))
            .catch((err) => console.info('getTopics: ' + err));

        if (this.subdomain) {
            expoClient.chambers
                .getBySubdomain(this.subdomain)
                .then((res) => {
                    this.setState({
                        subdomainChamber: res.data[0],
                        chambers: [res.data[0]],
                        disableSubmit: false,
                    });
                    const initValue = {};
                    initValue['card_'.concat(res.data[0].id.toString(10))] = res.data[0].id.toString(10);
                    this.setState({ formInitialValues: initValue });
                    expoClient.chambers
                        .getCountries(res.data[0].id)
                        .then((res) => {
                            this.setState({ countries: res.data });
                        })
                        .catch((err) => console.info('getCoutries: ' + err));
                    /*
                    expoClient.countries
                        .get(res.data[0].country.id)
                        .then((res) => {
                            this.setState({ countries: res.data });
                        })
                        .catch((err) => console.info('getCoutries: ' + err));
                    */
                })
                .catch((err) => console.info('getChamberBySubdomain: ' + err));

            console.dir('signup', this.state);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log('Signup.componentDidUpdate', prevProps, prevState, this.state);
        //Mostro notifica se il flag disableForwardPageTwo è cambiato in true e non sto mostrando la notifica
        const show =
            this.state.disableForwardPageTwo &&
            !this.state.notifyTooManyTag &&
            prevState.disableForwardPageTwo !== this.state.disableForwardPageTwo;
        //Nascondo notifica se il flag disableForwardPageTwo è cambiato in false e sto mostrando la notifica
        const hide =
            !this.state.disableForwardPageTwo &&
            this.state.notifyTooManyTag &&
            prevState.disableForwardPageTwo !== this.state.disableForwardPageTwo;
        if (show) this.notifyTagSelected();
        if (hide) {
            //Questo metodo non scatena la onClose del notify, aggiorno lo stato da qui
            notification.close('tooManyTags');
            this.setState({ notifyTooManyTag: false });
        }
        //Se non ho subdomain e la nazione è cambiata devo aggiornare le camere di commercio
        if (this.subdomain === null && this.state.nation !== prevState.nation && this.state.nation) {
            expoClient.chambers
                .getByCountry(this.state.nation)
                .then((res) => this.setState({ chambers: res.data }))
                .catch((err) => console.info('getChambers: ' + err));
        }
    }

    notifyTagSelected = function () {
        if (!this.state.notifyTooManyTag) {
            const { t } = this.props;
            notification.error({
                key: 'tooManyTags',
                message: _.capitalize(t('error')),
                description: t('signup_error_too_many_tag', { 0: this.state.maxTagSelected }),
                placement: 'bottomRight',
                duration: 0,
                onClose: () => this.setState({ notifyTooManyTag: false }),
            });
            this.setState({ notifyTooManyTag: true });
        }
    };

    postData = async function (values) {
        this.setState({ submittingData: true });
        const t = withTranslation();
        const signupData = {
            company: {
                vatNumber: values.companyvatnumber,
                name: values.companyname,
                addressVenue: values.companyvenue,
                zipCode: values.companyzipcode,
                province: values.companyprovince,
                addressCity: values.companycity,
                website: values.companywebsite,
                phone: values.companyphone,
                countryId: parseInt(values.companycountry),
                description: values.companydescription,
                email: values.companycontactemail,
            },
            user: {
                name: values.companycontactname,
                surname: values.companycontactsurname,
                email: values.companycontactemail,
            },
        };

        signupData.tagIdList = Object.keys(values)
            .filter((k) => k.startsWith('tag_') && values[k])
            .map((v) => parseInt(v.slice(4)));
        signupData.chamberIdList = Object.keys(values)
            .filter((k) => k.startsWith('card_') && values[k])
            .map((v) => parseInt(v.slice(5)));

        try {
            let res = await expoClient.signup.post(signupData);
            if (typeof res !== 'undefined') {
                this.carouselRef.current.goTo(3);
            }
        } catch (error) {
            const msg = _.capitalize(t('error'));
            const desc = t('signup_error_post');
            notification.error({
                key: 'postRegistrationError',
                message: msg,
                description: desc,
                placement: 'topRight',
                duration: 0,
            });
        } finally {
            this.setState({ submittingData: false });
        }

        // console.log('Signup.submit', signupData);
    };

    render() {
        const { t } = this.props;
        const { Title } = Typography;
        const { Option } = Select;
        const {
            countries,
            subdomainChamber,
            chambers,
            activePage,
            topics,
            disableForwardPageOne,
            disableForwardPageTwo,
            disableSubmit,
            totTagSelected,
            minTagSelected,
            maxTagSelected,
        } = this.state;

        //console.log("render:", formInitialValues);
        const RenderCountry = ({ data }) => {
            return (
                <Row>
                    <Col span="16">{t(`country_${data.code}`)}</Col>
                    <Col span="8" style={{ textAlign: 'right' }}>
                        {data.code}
                    </Col>
                </Row>
            );
        };

        const handleFormChange = () => {
            const hasErrors = this.formRef.current?.getFieldsError().some(({ errors }) => errors.length);
            console.log('Signup.formChange', hasErrors);
        };

        const checkValues = async (changed, values) => {
            const fieldsStepOne = [
                'companyname',
                'companydescription',
                'companyvenue',
                'companyprovince',
                'companycity',
                'companycountry',
                'companyphone',
                'companyvatnumber',
                'companycontactname',
                'companycontactsurname',
                'companycontactemail',
                'companywebsite',
            ];
            try {
                await this.formRef.current?.validateFields(fieldsStepOne);
            } catch (err) {
                // console.log('Error validating field', err);
            } finally {
                let disable = this.formRef.current?.getFieldsError(fieldsStepOne).some(({ errors }) => errors.length);
                const companyDescription = this.formRef.current.getFieldValue('companydescription');
                if (companyDescription && companyDescription.trim().length >= 150) {
                    this.setState({ descriptionColor: 'black' });
                } else {
                    this.setState({ descriptionColor: 'red' });
                }
                if (!disable) {
                    if (!this.formRef.current.getFieldValue('privacy')) {
                        disable = true;
                    }
                }
                this.setState({ disableForwardPageOne: disable });
            }
            /*
            let disable = false;
            fieldsStepOne.forEach((f) => {
                if (f in values && values[f]) {
                    disable = disable || values[f].trim().length === 0;

                    if (f === 'companycontactemail') {
                        disable = disable || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values[f].trim());
                    }
                    if (f === 'companydescription') {
                        disable = disable || values[f].trim().length < 150;

                        if (values[f].trim().length >= 150) {
                            this.setState({ descriptionColor: 'black' });
                        }
                        if (values[f].trim().length < 150) {
                            this.setState({ descriptionColor: 'red' });
                        }
                    }
                } else {
                    disable = true;
                }
            });

            if (!disable) {
                if (!values['privacy']) {
                    disable = true;
                }
            }

            if (disable) {
                const err = this.formRef.current.getFieldError('companywebsite');
                console.log('Signup.checkValues website', err);
                disable = err && err.length > 0;
            }
            */
            this.setState({ nation: values.companycountry });
            let counterTagSelected = Object.keys(values).filter((k) => k.startsWith('tag_') && values[k]).length;
            this.setState({
                totTagSelected: counterTagSelected,
                disableForwardPageTwo: counterTagSelected > maxTagSelected,
            });
        };

        const chambersChunck = _.chunk(chambers, expoConfig.signup.max_card_in_page);
        const handleModalOk = () => {
            this.carouselRef.current.next();
        };

        return (
            <Spin
                spinning={this.state.submittingData}
                indicator={<Loading3QuartersOutlined spin style={{ fontSize: '100px', color: '#000' }} />}
            >
                <Row align="middle">
                    <Col span={24}>
                        {this.state.currentPage < 4 ? (
                            <div className="panelHeaderContainer panelTitle">
                                {t('signup') + ` ${this.state.currentPage}/3`}
                            </div>
                        ) : null}
                    </Col>
                    <Col span={24}>
                        <Form
                            ref={this.formRef}
                            name="signupForm"
                            onValuesChange={checkValues}
                            onFinish={this.postData}
                            // onFieldsChange={handleFormChange}
                        >
                            <Carousel
                                key="innerCarousel"
                                dots={false}
                                effect="fade"
                                ref={this.carouselRef}
                                afterChange={(p) => this.setState({ currentPage: p + 1 })}
                            >
                                <div>
                                    <div style={{ textAlign: 'center' }}>
                                        <Title level={4}>{t('signup_title_step_1')}</Title>
                                    </div>
                                    <Row className="signup-carousel-page">
                                        <Col span="24">
                                            <Row gutter={[8, 0]}>
                                                <Col xl={{ span: 6, offset: 5 }} xs={{ span: 20, offset: 2 }}>
                                                    <Row>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                name="companyname"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: t('signup_required_company_name'),
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    size="large"
                                                                    placeholder={
                                                                        t('signup_placeholder_company_name') + '*'
                                                                    }
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                name="companyvenue"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: t('signup_required_company_venue'),
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    size="large"
                                                                    placeholder={t('signup_placeholder_company_venue')}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                name="companyzipcode"
                                                                rules={[
                                                                    { message: t('signup_required_company_zip_code') },
                                                                ]}
                                                            >
                                                                <Input
                                                                    size="large"
                                                                    placeholder={t(
                                                                        'signup_placeholder_company_zip_code',
                                                                    )}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                name="companycity"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: t('signup_required_company_city'),
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    size="large"
                                                                    placeholder={t('signup_placeholder_company_city')}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={24}></Col>
                                                    </Row>
                                                </Col>
                                                <Col xl={{ span: 6, offset: 2 }} xs={{ span: 20, offset: 2 }}>
                                                    <Form.Item
                                                        name="companydescription"
                                                        rules={[
                                                            {
                                                                min: 150,
                                                                required: true,
                                                                message: t('signup_required_company_description'),
                                                            },
                                                        ]}
                                                    >
                                                        <Input.TextArea
                                                            style={{ fontSize: 16, color: this.state.descriptionColor }}
                                                            size="large"
                                                            maxLength={750}
                                                            rows={8}
                                                            placeholder={t('signup_placeholder_company_description')}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[8, 0]}>
                                                <Col
                                                    xl={{ span: 6, offset: 5, order: 0 }}
                                                    xs={{ span: 20, offset: 2, order: 1 }}
                                                >
                                                    <Form.Item
                                                        name="companyprovince"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t('signup_required_company_province'),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            size="large"
                                                            placeholder={t('signup_placeholder_company_province')}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col
                                                    xl={{ span: 6, offset: 2, order: 0 }}
                                                    xs={{ span: 20, offset: 2, order: 3 }}
                                                >
                                                    <Form.Item
                                                        name="companycontactemail"
                                                        type="email"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t('signup_required_company_contact_email'),
                                                            },
                                                            { type: 'email', message: t('the_input_invalid_mail') },
                                                        ]}
                                                    >
                                                        <Input
                                                            size="large"
                                                            placeholder={t('signup_placeholder_company_contact_email')}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col
                                                    xl={{ span: 6, offset: 5, order: 0 }}
                                                    xs={{ span: 20, offset: 2, order: 2 }}
                                                >
                                                    <Form.Item
                                                        name="companycountry"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t('signup_required_company_country'),
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            allowClear
                                                            bordered={false}
                                                            style={{ width: '100%' }}
                                                            size="large"
                                                            placeholder={t('signup_placeholder_company_country')}
                                                        >
                                                            {countries.map((c) => (
                                                                <Option key={c.id} value={c.id.toString()}>
                                                                    <RenderCountry data={c} />
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col
                                                    xl={{ span: 6, offset: 2, order: 0 }}
                                                    xs={{ span: 20, offset: 2, order: 4 }}
                                                >
                                                    <Form.Item
                                                        name="companycontactname"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t('signup_required_company_contact_name'),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            size="large"
                                                            placeholder={t('signup_placeholder_company_contact_name')}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[8, 0]}>
                                                <Col
                                                    xl={{ span: 6, offset: 5, order: 0 }}
                                                    xs={{ span: 20, offset: 2, order: 2 }}
                                                >
                                                    <Form.Item
                                                        name="companyphone"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t('signup_required_company_phone'),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            size="large"
                                                            placeholder={t('signup_placeholder_company_phone')}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col
                                                    xl={{ span: 6, offset: 2, order: 0 }}
                                                    xs={{ span: 20, offset: 2, order: 1 }}
                                                >
                                                    <Form.Item
                                                        name="companycontactsurname"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t('signup_required_company_contact_surname'),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            size="large"
                                                            placeholder={t(
                                                                'signup_placeholder_company_contact_surname',
                                                            )}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[8, 0]}>
                                                <Col xl={{ span: 6, offset: 5 }} xs={{ span: 20, offset: 2 }}>
                                                    <Form.Item
                                                        name="companyvatnumber"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t('signup_required_company_vatnumber'),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            size="large"
                                                            placeholder={t('signup_placeholder_company_vatnumber')}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xl={{ span: 6, offset: 2 }} xs={{ span: 20, offset: 2 }}>
                                                    <Form.Item
                                                        name="companywebsite"
                                                        rules={[
                                                            {
                                                                type: 'url',
                                                                // required: true,
                                                                message: t('signup_invalid_url'),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            size="large"
                                                            placeholder={t('signup_placeholder_company_website')}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={{ offset: 5 }} xs={{ span: 18, offset: 3 }}>
                                            <Form.Item name="privacy" valuePropName="checked" required="true">
                                                <Checkbox className="checkbox-privacy-signup">
                                                    {' '}
                                                    {t('checkbox_privacy_policy')}{' '}
                                                    <a
                                                        className="checkbox-privacy"
                                                        rel="noopener noreferrer"
                                                        href="https://www.exportlounge.com/privacypolicy.html"
                                                        target="_blank"
                                                    >
                                                        {' '}
                                                        <u>{t('privacy_policy')}</u>{' '}
                                                    </a>{' '}
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify="center">
                                        <Form.Item>
                                            <a href="#" className="scrollToTop">
                                                <Button
                                                    disabled={disableForwardPageOne}
                                                    block
                                                    shape="round"
                                                    className="blackMode button-request"
                                                    htmlType="button"
                                                    onClick={() => this.carouselRef.current.next()}
                                                >
                                                    {t('signup_button_forward').toUpperCase()}
                                                </Button>
                                            </a>
                                        </Form.Item>
                                    </Row>
                                </div>
                                <div>
                                    <Row justify="center" gutter={[8, 24]}>
                                        <Col xl={{ span: 10 }} xs={{ span: 22, offset: 1 }}>
                                            <Title level={4}>{t('signup_title_step_2')}</Title>
                                        </Col>
                                    </Row>
                                    <Row className="signup-carousel-page">
                                        <Col xl={{ span: 12, offset: 6 }} xs={{ span: 20, offset: 2 }}>
                                            <AccordionTopics topics={topics} t={t} />
                                        </Col>
                                    </Row>
                                    <Row justify="center" align="middle">
                                        <Col className="two-button-distance">
                                            <a href="#" className="scrollToTop">
                                                <Button
                                                    block
                                                    shape="round"
                                                    className="blackMode button-request"
                                                    htmlType="button"
                                                    onClick={() => this.carouselRef.current.prev()}
                                                >
                                                    {t('signup_button_back').toUpperCase()}
                                                </Button>
                                            </a>
                                        </Col>
                                        <Col className="two-button-distance">
                                            {!(totTagSelected > minTagSelected) ? (
                                                <ConfirmModal
                                                    onOk={handleModalOk}
                                                    message={t('signup_warning_no_tag')}
                                                    scrollToRopAfterOk={true}
                                                >
                                                    <a href="#" className="scrollToTop">
                                                        <Button
                                                            disabled={disableForwardPageTwo}
                                                            block
                                                            shape="round"
                                                            className="blackMode button-request"
                                                            htmlType="button"
                                                        >
                                                            {t('signup_button_forward').toUpperCase()}
                                                        </Button>
                                                    </a>
                                                </ConfirmModal>
                                            ) : (
                                                <a href="#" className="scrollToTop">
                                                    <Button
                                                        disabled={disableForwardPageTwo}
                                                        block
                                                        shape="round"
                                                        className="blackMode button-request"
                                                        htmlType="button"
                                                        onClick={handleModalOk}
                                                    >
                                                        {t('signup_button_forward').toUpperCase()}
                                                    </Button>
                                                </a>
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    <Row
                                        gutter={[8, 24]}
                                        style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
                                    >
                                        <Col>
                                            <Title level={4} style={{ textAlign: 'center' }}>
                                                {t(
                                                    this.subdomain
                                                        ? 'signup_title_step_3_subdomain'
                                                        : 'signup_title_step_3',
                                                )}
                                            </Title>
                                        </Col>
                                        <Col>
                                            <Row gutter={[8, 24]} justify="center">
                                                <Col span="24" xs={{ span: 22 }}>
                                                    {chambers.map((c) => (
                                                        <Form.Item
                                                            name={`card_${c.id}`}
                                                            key={c.id}
                                                            noStyle
                                                            initialValue={
                                                                subdomainChamber ? subdomainChamber.id.toString(10) : ''
                                                            }
                                                        >
                                                            <Input type="hidden" />
                                                        </Form.Item>
                                                    ))}
                                                    <CardChambers
                                                        chambers={chambersChunck[activePage - 1]}
                                                        form={this.formRef}
                                                        handleDisableSubmit={this.handleDisableSubmit}
                                                        selected={subdomainChamber ? [subdomainChamber.id] : []}
                                                    />
                                                </Col>
                                                {chambersChunck.length > 1 ? (
                                                    <Col span="24" style={{ textAlign: 'center' }}>
                                                        <Pagination
                                                            activePage={activePage}
                                                            totalPages={chambersChunck.length}
                                                            onPageChange={(e, d) =>
                                                                this.setState({ activePage: d.activePage })
                                                            }
                                                        />
                                                    </Col>
                                                ) : null}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row justify="center" align="middle">
                                        <Col className="two-button-distance">
                                            <Button
                                                block
                                                shape="round"
                                                className="blackMode button-request"
                                                htmlType="button"
                                                onClick={() => this.carouselRef.current.prev()}
                                            >
                                                {t('signup_button_back').toUpperCase()}
                                            </Button>
                                        </Col>
                                        <Col className="two-button-distance">
                                            <Button
                                                htmlType="submit"
                                                disabled={disableSubmit}
                                                block
                                                shape="round"
                                                className="blackMode button-request"
                                            >
                                                {t(
                                                    this.subdomain
                                                        ? 'signup_button_submit_subdomain'
                                                        : 'signup_button_submit',
                                                ).toUpperCase()}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    <div className="b2bLeave-text" style={{ flexDirection: 'column' }}>
                                        <SendMail
                                            style={{
                                                width: '140px',
                                                height: 'auto',
                                                fill: '#159A2E',
                                                marginBotton: '20px',
                                            }}
                                        />
                                        <p style={{ marginBottom: '5px' }}>
                                            <b>{t('request_forwarded').toUpperCase()}</b>
                                        </p>
                                        <p style={{ fontSize: '24px', color: '#262626', padding: '0px 40px' }}>
                                            <i>{t('outcome_request_by_mail')}</i>
                                        </p>
                                        <div className="b2bLeave-panel">
                                            <div className="b2bLeave-text" style={{ flexDirection: 'column' }}>
                                                <p>
                                                    <b>{t('discover_registered_companies').toUpperCase()}</b>
                                                </p>
                                                <p style={{ fontSize: '24px', color: '#262626', padding: '0px 40px' }}>
                                                    {t('signup_transaction_ok_subtitle')}
                                                </p>
                                            </div>
                                            <div className="b2bLeave-div-button">
                                                <Button
                                                    style={{ fontSize: '20px' }}
                                                    className="blackMode"
                                                    type="round"
                                                    key="homepage"
                                                    onClick={() => history.push('/CompaniesJoined')}
                                                >
                                                    {t('button_explore_expo').toUpperCase()}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </Form>
                    </Col>
                </Row>
            </Spin>
        );
    }
}
export default withTranslation()(Signup);

class CardChambers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null,
        };
        this.form = props.form;
        this.handleDisableSubmit = props.handleDisableSubmit;
        this.handleSelected.bind(this);
    }

    handleSelected(selected) {
        this.setState({
            selected: selected,
        });
    }

    render() {
        const cardClick = (e, card) => {
            const index = card.index;
            const { selected } = this.state.selected ? this.state : this.props;
            const newSelected =
                selected.indexOf(index) === -1 ? [...selected, index] : selected.filter((item) => item !== index);
            this.setState({
                selected: newSelected,
            });
            //Aggiorno il campo del form
            this.form.current.setFieldsValue({
                [`card_${index}`]: newSelected.indexOf(index) > -1 ? index : undefined,
            });
            //Aggiorno il flag disableSubmit del componente padre
            this.handleDisableSubmit(newSelected.length === 0);
        };

        const cards = [];
        const { chambers } = this.props;
        const { selected } = this.state.selected ? this.state : this.props;

        if (chambers) {
            chambers.forEach((c) => {
                const isSelected = selected.indexOf(c.id) > -1;
                cards.push(
                    <Card className="card-chamber-signup" onClick={cardClick} key={c.id} index={c.id}>
                        <Transition visible={isSelected} animation="fade" duration={400}>
                            <Label color="green" corner="right" icon="check" />
                        </Transition>
                        <Card.Content style={{ borderTop: '0px solid' }}>
                            <Image
                                floated="right"
                                style={{ width: '130px', top: '35%' }}
                                src={c.logoPath}
                                verticalAlign="middle"
                            />
                            <Card.Header>
                                {' '}
                                <p className="p-card-header">{c.name}</p>
                            </Card.Header>
                            <Card.Meta>
                                <p
                                    style={{ color: '#000', fontSize: '16px' }}
                                >{`${c.country.name} (${c.country.code})`}</p>
                            </Card.Meta>
                        </Card.Content>
                        <Card.Content
                            className="card-signup-chamber"
                            extra
                            style={{
                                color: '#fff',
                                background:
                                    'transparent linear-gradient(180deg, #707070 0%, #242424 100%) 0% 0% no-repeat padding-box',
                            }}
                        >
                            <Icon name="point" />
                            {c.addressCity}
                        </Card.Content>
                    </Card>,
                );
            });
        }

        return (
            <Card.Group itemsPerRow={3} centered>
                {cards}
            </Card.Group>
        );
    }
}
