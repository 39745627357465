import {
    AudioVideoFacade,
    ConsoleLogger,
    DefaultDeviceController,
    DefaultDOMWebSocketFactory,
    DefaultMeetingSession,
    DefaultModality,
    DefaultPromisedWebSocketFactory,
    DeviceChangeObserver,
    FullJitterBackoff,
    LogLevel,
    MeetingSession,
    MeetingSessionConfiguration,
    ReconnectingPromisedWebSocket,
} from 'amazon-chime-sdk-js';

import CamSettings from '../enums/CamSettings';
import throttle from 'lodash/throttle';
import { useTranslation } from 'react-i18next';

const ROSTER_THROTTLE_MS = 400;

export default class ChimeSdkWrapper {
    supportedChimeRegions = [
        { label: 'United States (N. Virginia)', value: 'us-east-1' },
        { label: 'Japan (Tokyo)', value: 'ap-northeast-1' },
        { label: 'Singapore', value: 'ap-southeast-1' },
        { label: 'Australia (Sydney)', value: 'ap-southeast-2' },
        { label: 'Canada', value: 'ca-central-1' },
        { label: 'Germany (Frankfurt)', value: 'eu-central-1' },
        { label: 'Sweden (Stockholm)', value: 'eu-north-1' },
        { label: 'Ireland', value: 'eu-west-1' },
        { label: 'United Kingdom (London)', value: 'eu-west-2' },
        { label: 'Italy (Milan)', value: 'eu-south-1' },
        { label: 'France (Paris)', value: 'eu-west-3' },
        { label: 'Brazil (São Paulo)', value: 'sa-east-1' },
        { label: 'United States (Ohio)', value: 'us-east-2' },
        { label: 'United States (N. California)', value: 'us-west-1' },
        { label: 'United States (Oregon)', value: 'us-west-2' },
        { label: 'South Africa (Cape Town)', value: 'af-south-1' },
        { label: 'India (Mumbai)', value: 'ap-south-1' },
        { label: 'South Corea (Seoul)', value: 'ap-northeast-2' },
    ];

    t = useTranslation();

    meetingSession = null;
    audioVideo = null;
    title = null;
    name = null;
    region = null;
    currentAudioInputDevice = null;
    currentAudioOutputDevice = null;
    currentVideoInputDevice = null;
    audioInputDevices = [];
    audioOutputDevices = [];
    videoInputDevices = [];
    devicesUpdatedCallbacks = [];
    roster = {};
    rosterUpdateCallbacks = [];
    configuration = null;
    messagingSocket = null;
    messageUpdateCallbacks = [];

    initializeSdkWrapper = async () => {
        this.meetingSession = null;
        this.audioVideo = null;
        this.title = null;
        this.name = null;
        this.region = null;
        this.currentAudioInputDevice = null;
        this.currentAudioOutputDevice = null;
        this.currentVideoInputDevice = null;
        this.audioInputDevices = [];
        this.audioOutputDevices = [];
        this.videoInputDevices = [];
        this.devicesUpdatedCallbacks = [];
        this.roster = {};
        this.rosterUpdateCallbacks = [];
        this.configuration = null;
        this.messagingSocket = null;
        this.messageUpdateCallbacks = [];
    };
    /*
     * ====================================================================
     * regions
     * ====================================================================
     */
    lookupClosestChimeRegion = async () => {
        let region;
        try {
            const response = await fetch(`https://nearest-media-region.l.chime.aws`, {
                method: 'GET',
            });
            const json = await response.json();
            if (json.error) {
                throw new Error(`${this.t('CreateOrJoin.serverError')}: ${json.error}`);
            }
            region = json.region;
        } catch (error) {
            this.logError(error);
        }
        return this.supportedChimeRegions.find(({ value }) => value === region) || this.supportedChimeRegions[0];
    };

    initializeMeetingSession = async (b2bInfo) => {
        const logger = new ConsoleLogger('SDK', LogLevel.ERROR);
        const deviceController = new DefaultDeviceController(logger);
        const configuration = new MeetingSessionConfiguration(b2bInfo.meeting, b2bInfo.attendee);

        this.meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);
        this.audioVideo = this.meetingSession.audioVideo;

        this.audioInputDevices = [];
        (await this.audioVideo.listAudioInputDevices()).forEach((mediaDeviceInfo) => {
            this.audioInputDevices.push({
                label: mediaDeviceInfo.label,
                value: mediaDeviceInfo.deviceId,
            });
        });
        this.audioOutputDevices = [];
        (await this.audioVideo.listAudioOutputDevices()).forEach((mediaDeviceInfo) => {
            this.audioOutputDevices.push({
                label: mediaDeviceInfo.label,
                value: mediaDeviceInfo.deviceId,
            });
        });
        this.videoInputDevices = [];
        (await this.audioVideo.listVideoInputDevices()).forEach((mediaDeviceInfo) => {
            this.videoInputDevices.push({
                label: mediaDeviceInfo.label,
                value: mediaDeviceInfo.deviceId,
            });
        });
        this.publishDevicesUpdated();
        this.audioVideo.addDeviceChangeObserver(this);

        this.audioVideo.realtimeSubscribeToAttendeeIdPresence((presentAttendeeId, present, externalUserId) => {
            if (!present) {
                //uscita dalla call
                delete this.roster[presentAttendeeId];
                this.publishRosterUpdate.cancel();
                this.publishRosterUpdate();
                return;
            }

            this.audioVideo.realtimeSubscribeToVolumeIndicator(
                presentAttendeeId,
                async (attendeeId, volume, muted, signalStrength, externalAttendeeId) => {
                    const baseAttendeeId = new DefaultModality(attendeeId).base();
                    if (baseAttendeeId !== attendeeId) {
                        // Don't include the content attendee in the roster.
                        //
                        // When you or other attendees share content (a screen capture, a video file,
                        // or any other MediaStream object), the content attendee (attendee-id#content) joins the session and
                        // shares content as if a regular attendee shares a video.
                        //
                        // For example, your attendee ID is "my-id". When you call meetingSession.audioVideo.startContentShare,
                        // the content attendee "my-id#content" will join the session and share your content.
                        return;
                    }

                    let shouldPublishImmediately = false;
                    if (!this.roster[attendeeId]) {
                        this.roster[attendeeId] = { externalAttendeeId: externalAttendeeId };
                    }
                    if (volume !== null) {
                        this.roster[attendeeId].volume = Math.round(volume * 100);
                    }
                    if (muted !== null) {
                        this.roster[attendeeId].muted = muted;
                    }
                    if (signalStrength !== null) {
                        this.roster[attendeeId].signalStrength = Math.round(signalStrength * 100);
                    }

                    /*
                    if (this.title && attendeeId && !this.roster[attendeeId].name) {
                        const response = await fetch(
                            `${getBaseUrl()}attendee?title=${encodeURIComponent(
                                this.title,
                            )}&attendee=${encodeURIComponent(attendeeId)}`,
                        );
                        const json = await response.json();
                        this.roster[attendeeId].name = json.AttendeeInfo.Name || '';
                        shouldPublishImmediately = true;
                    }
                    */

                    if (shouldPublishImmediately) {
                        this.publishRosterUpdate.cancel();
                    }
                    this.publishRosterUpdate();
                },
            );
        });
    };

    joinRoom = async (element) => {
        if (!element) {
            this.logError(new Error(`element does not exist`));
            return;
        }

        window.addEventListener('unhandledrejection', (event) => {
            this.logError(event.reason);
        });

        if (this.audioVideo) {
            const audioInputs = await this.audioVideo.listAudioInputDevices();
            if (audioInputs && audioInputs.length > 0 && audioInputs[0].deviceId) {
                this.currentAudioInputDevice = {
                    label: audioInputs[0].label,
                    value: audioInputs[0].deviceId,
                };
                await this.audioVideo.chooseAudioInputDevice(audioInputs[0].deviceId);
            }
            const audioOutputs = await this.audioVideo.listAudioOutputDevices();
            if (audioOutputs && audioOutputs.length > 0 && audioOutputs[0].deviceId) {
                this.currentAudioOutputDevice = {
                    label: audioOutputs[0].label,
                    value: audioOutputs[0].deviceId,
                };
                await this.audioVideo.chooseAudioOutputDevice(audioOutputs[0].deviceId);
            }
            const videoInputs = await this.audioVideo?.listVideoInputDevices();
            //console.log('ChimeSdkWrapper.videoInputDeviceList', videoInputs);
            if (videoInputs && videoInputs.length > 0 && videoInputs[0].deviceId) {
                this.currentVideoInputDevice = {
                    label: videoInputs[0].label,
                    value: videoInputs[0].deviceId,
                };
                //this.audioVideo.chooseVideoInputQuality(CamSettings.MEDIUM);
                console.log('ChimeSdkwrapper.videoInputDeviceList setCamera', CamSettings.MEDIUM);
                await this.audioVideo.chooseVideoInputDevice(null);
            }
            this.publishDevicesUpdated();

            this.audioVideo.bindAudioElement(element);
            this.audioVideo.start();
        }
    };

    leaveRoom = async () => {
        try {
            await this.audioVideo.stop();
            await this.audioVideo.stopContentShare();
        } catch (error) {
            this.logError(error);
        }

        this.initializeSdkWrapper();
    };

    /**
     * ====================================================================
     * Device
     * ====================================================================
     */

    chooseAudioInputDevice = async (device) => {
        try {
            await this.audioVideo.chooseAudioInputDevice(device.value);
            this.currentAudioInputDevice = device;
        } catch (error) {
            this.logError(error);
        }
    };

    chooseAudioOutputDevice = async (device) => {
        try {
            await this.audioVideo.chooseAudioOutputDevice(device.value);
            this.currentAudioOutputDevice = device;
        } catch (error) {
            this.logError(error);
        }
    };

    chooseVideoInputDevice = async (device, settings = CamSettings.LOW) => {
        try {
            if (settings) {
                const { width, height, frameRate, maxBandwidthKbps } = settings;
                this.audioVideo.chooseVideoInputQuality(width, height, frameRate, maxBandwidthKbps);
            }
            await this.audioVideo.chooseVideoInputDevice(device.value);
            this.currentVideoInputDevice = device;
        } catch (error) {
            this.logError(error);
        }
    };

    /**
     * ====================================================================
     * Observer methods
     * ====================================================================
     */

    audioInputsChanged(freshAudioInputDeviceList) {
        let hasCurrentDevice = false;
        this.audioInputDevices = [];
        freshAudioInputDeviceList.forEach((mediaDeviceInfo) => {
            if (this.currentAudioInputDevice && mediaDeviceInfo.deviceId === this.currentAudioInputDevice.value) {
                hasCurrentDevice = true;
            }
            this.audioInputDevices.push({
                label: mediaDeviceInfo.label,
                value: mediaDeviceInfo.deviceId,
            });
        });
        if (!hasCurrentDevice) {
            this.currentAudioInputDevice = this.audioInputDevices.length > 0 ? this.audioInputDevices[0] : null;
        }
        this.publishDevicesUpdated();
    }

    audioOutputsChanged(freshAudioOutputDeviceList) {
        let hasCurrentDevice = false;
        this.audioOutputDevices = [];
        freshAudioOutputDeviceList.forEach((mediaDeviceInfo) => {
            if (this.currentAudioOutputDevice && mediaDeviceInfo.deviceId === this.currentAudioOutputDevice.value) {
                hasCurrentDevice = true;
            }
            this.audioOutputDevices.push({
                label: mediaDeviceInfo.label,
                value: mediaDeviceInfo.deviceId,
            });
        });
        if (!hasCurrentDevice) {
            this.currentAudioOutputDevice = this.audioOutputDevices.length > 0 ? this.audioOutputDevices[0] : null;
        }
        this.publishDevicesUpdated();
    }

    videoInputsChanged(freshVideoInputDeviceList) {
        let hasCurrentDevice = false;
        this.videoInputDevices = [];
        freshVideoInputDeviceList.forEach((mediaDeviceInfo) => {
            if (this.currentVideoInputDevice && mediaDeviceInfo.deviceId === this.currentVideoInputDevice.value) {
                hasCurrentDevice = true;
            }
            this.videoInputDevices.push({
                label: mediaDeviceInfo.label,
                value: mediaDeviceInfo.deviceId,
            });
        });
        if (!hasCurrentDevice) {
            this.currentVideoInputDevice = this.videoInputDevices.length > 0 ? this.videoInputDevices[0] : null;
        }
        this.publishDevicesUpdated();
    }

    /**
     * ====================================================================
     * Subscribe and unsubscribe from SDK events
     * ====================================================================
     */

    subscribeToDevicesUpdated = (callback) => {
        this.devicesUpdatedCallbacks.push(callback);
    };

    unsubscribeFromDevicesUpdated = (callback) => {
        const index = this.devicesUpdatedCallbacks.indexOf(callback);
        if (index !== -1) {
            this.devicesUpdatedCallbacks.splice(index, 1);
        }
    };

    publishDevicesUpdated = () => {
        this.devicesUpdatedCallbacks.forEach((callback) => {
            callback({
                currentAudioInputDevice: this.currentAudioInputDevice,
                currentAudioOutputDevice: this.currentAudioOutputDevice,
                currentVideoInputDevice: this.currentVideoInputDevice,
                audioInputDevices: this.audioInputDevices,
                audioOutputDevices: this.audioOutputDevices,
                videoInputDevices: this.videoInputDevices,
            });
        });
    };

    subscribeToRosterUpdate = (callback) => {
        this.rosterUpdateCallbacks.push(callback);
    };

    unsubscribeFromRosterUpdate = (callback) => {
        const index = this.rosterUpdateCallbacks.indexOf(callback);
        if (index !== -1) {
            this.rosterUpdateCallbacks.splice(index, 1);
        }
    };

    publishRosterUpdate = throttle(() => {
        for (let i = 0; i < this.rosterUpdateCallbacks.length; i += 1) {
            const callback = this.rosterUpdateCallbacks[i];
            callback(this.roster);
        }
    }, ROSTER_THROTTLE_MS);

    subscribeToMessageUpdate = (callback) => {
        this.messageUpdateCallbacks.push(callback);
    };

    unsubscribeFromMessageUpdate = (callback) => {
        const index = this.messageUpdateCallbacks.indexOf(callback);
        if (index !== -1) {
            this.messageUpdateCallbacks.splice(index, 1);
        }
    };

    publishMessageUpdate = (message) => {
        for (let i = 0; i < this.messageUpdateCallbacks.length; i += 1) {
            const callback = this.messageUpdateCallbacks[i];
            callback(message);
        }
    };

    /**
     * ====================================================================
     * Utilities
     * ====================================================================
     */

    logError = (error) => {
        // eslint-disable-next-line
        console.error(error);
    };
}
