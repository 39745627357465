import './CompanyShowCase.css';
import '../css/flag-css.min.css';
import './EntityItemSlider.css';

import XtensibleGallery from './XtensibleGallery';
import expoClient from '../expoClient';
import expoConfig from '../config/expo_config.json';
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Space, Badge, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ImageEditor from './ImageEditor';
import EntityInfoPanel from './EntityInfoPanel.js';
import ChamberUserSlider from './ChamberUserSlider.js';
import IconAndLabel from './IconAndLabel';
import TopBar from './TopBar';
import { useAuth0 } from '../react-auth0-spa';
import defaultCompanyImage from '../images/default-company.jpg';
import history from '../utils/history';
import locationUtil from '../utils/location';
import { ReactComponent as NewEvent } from '../icons/new-event.svg';
import { ReactComponent as ApproveButton } from '../icons/check.svg';
import { ReactComponent as MembersButton } from '../icons/member.svg';
import { ReactComponent as IconRequestB2B } from '../icons/requestb2b.svg';
import { ReactComponent as SuspendCompany } from '../icons/sospese.svg';
import { ReactComponent as Draft } from '../icons/draft.svg';
import { ReactComponent as SettingsButton } from '../icons/datiaziendali.svg';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Analytics from './Analytics';

const ChamberShowCase = ({ location }) => {
    //const [homepage, setHomepage] = useState();
    //const [company, setCompany] = useState(null);
    //const [currentMediaId, setCurrentMediaId] = useState(null);
    //const [displayDataPage, setDisplayDataPage] = useState('none');
    //const [index, setIndex] = useState(null);

    const { isAuthenticated, expoUser, loading } = useAuth0();
    const [chamber, setChamber] = useState();
    const [isEditable, setIsEditable] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [currentDataPage, setCurrentDataPage] = useState(-1);
    const [companies, setCompanies] = useState(null);
    const [displayDataPage, setDisplayDataPage] = useState('none');
    const [chamberUsers, setChamberUsers] = useState(null);
    const [events, setEvents] = useState([]);
    const [showEventDeleteModal, setShowEventDeleteModal] = useState(false);
    const [eventToDelete, setEventToDelete] = useState(null);
    const [unviewedContactRequests, setUnviewedContactRequests] = useState(false);
    const [contactRequestsNumber, setContactRequestsNumber] = useState(1);
    const [selectedMenu, setSelectedMenu] = useState(null);
    const [pageLoading, setPageLoading] = useState(true);

    const { t } = useTranslation();

    if (location && location.state && location.state.items) {
        if (companies === null) {
            setCompanies(location.state.items);
        }
    }

    //let initialPageLocal = 0;

    const normalizeToEntity = (companyList, token) => {
        if (!companyList) return null;
        const entityList = [];
        companyList.forEach((c) => {
            const e = {
                id: c.id,
                name: c.name,
                path: c.logoPath,
                token: token,
            };
            entityList.push(e);
        });
        return entityList;
    };

    useEffect(() => {
        const init = async () => {
            const isChamber = expoUser.entityTypeId === expoConfig.domain.entity_type.chamber;
            const isChamberAdmin = isChamber && expoUser.roleId === expoConfig.domain.role.admin;
            setIsEditable(isChamberAdmin);

            if (expoUser.chamber && expoUser.chamber.items) {
                //expoUser ha tutte le info non serve ricavarsele
                //Dato che gli eventi sono editabili li rileggo ogni volta dal server
                const eRes = await expoClient.chambers.getEvents(expoUser.chamber.id, null, expoUser.token);
                expoUser.chamber.items.event = eRes.data;
                setChamber(expoUser.chamber);
                setEvents(expoUser.chamber.items.event);
                const usersRes = await expoClient.chambers.getUsers(expoUser.chamber.id, expoUser.token);
                setChamberUsers(usersRes.data);
            } else {
                //Per qualche motivo non ho chamber in expoUser la rileggo dal DB
                if (isChamber) {
                    //sono utente camera recupero l'info dall'entityid dello user
                    const chamberRes = await expoClient.chambers.get(expoUser.entityId, expoUser.token);
                    expoUser.chamber = chamberRes.data;
                } else {
                    //Questo caso non dovrebbe più succedere, valutare se eliminare

                    //sono utente compagnia leggo tutte le camere di mia competenza
                    //e verifico se il sottodomio corrisponde a una di queste
                    console.log('Chamber page for company', expoUser.token);
                    const companyRes = await expoClient.company.get(expoUser.entityId, expoUser.token);
                    const company = companyRes.data;
                    const chambersRes = await expoClient.company.getChambers(company.id, expoUser.token);
                    const chambers = chambersRes.data;
                    const subdomain = locationUtil.detectSubdomain();
                    if (subdomain) {
                        //Verifico se la camera definita nel subdomain è fra quelle di competenza
                        const selChambers = chambers.filter((c) => c.subdomain === subdomain);
                        if (selChambers) expoUser.chamber = selChambers[0];
                    }
                }
                //Non ho ancora impostato gli items nella camera (primo accesso a questa pagina)
                if (!expoUser.chamber.items) {
                    expoUser.chamber.items = {};
                    const cItaRes = await expoClient.chambers.getCompanies(
                        expoUser.chamber.id,
                        expoConfig.domain.nation.ita,
                        expoUser.token,
                    );
                    expoUser.chamber.items.italy = normalizeToEntity(cItaRes.data, expoUser.token);
                    const cNoItaRes = await expoClient.chambers.getCompanies(
                        expoUser.chamber.id,
                        -1 * expoConfig.domain.nation.ita,
                        expoUser.token,
                    );
                    expoUser.chamber.items.noItaly = normalizeToEntity(cNoItaRes.data, expoUser.token);
                    const eRes = await expoClient.chambers.getEvents(expoUser.chamber.id, null, expoUser.token);
                    expoUser.chamber.items.event = eRes.data;
                    const counters = await expoClient.chambers.countCompanies(expoUser.chamber.id, expoUser.token);
                    expoUser.chamber.pendingCounter = counters.data.pending;
                    expoUser.chamber.suspendedCounter = counters.data.suspended;
                    expoUser.chamber.draftedCounter = counters.data.active;
                }
                setChamber(expoUser.chamber);
                setEvents(expoUser.chamber.items.event ? expoUser.chamber.items.event : []);
                const usersRes = await expoClient.chambers.getUsers(expoUser.chamber.id, expoUser.token);
                setChamberUsers(usersRes.data);
            }
            const requestCount = await expoClient.chambers.getContactRequestsCount(expoUser.chamber.id, expoUser.token);
            setUnviewedContactRequests(requestCount.data.someRequested);
            setContactRequestsNumber(requestCount.data.num);

            var img = document.getElementById('bannerImage');
            if (img) {
                handleResize();
            }
            console.log('ChamberShocase expoUser', expoUser, expoUser.chamber, expoUser.chamber.items);
            setPageLoading(false);
        };

        if (isAuthenticated && expoUser && !loading) {
            init();
        }

        window.addEventListener('resize', handleResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, expoUser, loading]);

    const handleResize = () => {
        console.log('resized to: ', window.innerWidth, 'x', window.innerHeight);
        var img = document.getElementById('bannerImage');
        var w = window.innerWidth;
        if (document.getElementById('descriptionText') != null) {
            if (w < 1200) {
                document.getElementById('descriptionText').style.height = 'auto';
            } else if (img) {
                var height = img.clientHeight;
                document.getElementById('descriptionText').style.height = height + 'px';
            }
        }
    };

    const posNewtData = async () => {
        try {
            await expoClient.chambers.modify(chamber.id, chamber, expoUser.token);
        } catch (error) {
            console.error(error);
        } finally {
        }
    };

    const setEditModeCallBack = (isEdit, changed) => {
        setEditMode(isEdit);
        if (changed) {
            posNewtData();
        }
    };

    const onVieItemCallBackEvent = (eventId) => {
        history.push('/eventpage', {
            event: events.filter((p) => p.id === eventId)[0],
            eventList: events,
        });
    };

    const insertEvent = () => history.push('/eventinsert');

    const transitToDataPageFromToolBar = (e) => {
        transitToDataPage(e, -1);
    };

    const transitToDataPage = (e, pageToGo) => {
        if (e) e.stopPropagation();
        if (pageToGo === currentDataPage || pageToGo < 0) {
            setDisplayDataPage('none');
            setCurrentDataPage(-1);
        } else {
            setDisplayDataPage('block');
            setCurrentDataPage(pageToGo);
        }
    };

    const refreshUsers = async () => {
        const usersRes = await expoClient.chambers.getUsers(expoUser.chamber.id, expoUser.token);
        setChamberUsers(usersRes.data);
        console.log('ChamberShocase expoUser', expoUser, expoUser.chamber, expoUser.chamber.items);
    };
    const goToCompanyItaly = (companyId) => {
        goToCompany(companyId, expoConfig.domain.entity_type.c_ita);
    };

    const goToCompanyForeigns = (companyId) => {
        goToCompany(companyId, expoConfig.domain.entity_type.c_other);
    };

    const goToCompany = (companyId, companyType) => {
        let indice = null;
        let companyList =
            companyType === expoConfig.domain.entity_type.c_ita
                ? expoUser.entityTypeId !== expoConfig.domain.entity_type.chamber
                    ? expoUser.chamber.items.italy.filter((item) => item.id !== expoUser.entityId)
                    : expoUser.chamber.items.italy
                : expoUser.entityTypeId !== expoConfig.domain.entity_type.chamber
                ? expoUser.chamber.items.noItaly.filter((item) => item.id !== expoUser.entityId)
                : expoUser.chamber.items.noItaly;

        for (let i = 0; i < companyList.length; i++) {
            if (companyList[i].id === companyId) {
                indice = i;
                break;
            }
        }
        history.push('/company', {
            companyId: companyId,
            index: indice,
            items: companyList,
        });
    };

    const getItalians = () => {
        //const camera = chamber ? chamber : expoUser.chamber
        if (
            chamber &&
            expoUser &&
            expoUser.chamber &&
            expoUser.chamber.items &&
            expoUser.chamber.items.italy.length > 0
        ) {
            let toReturn = expoUser.chamber.items.italy.map((item) => ({
                id: item.id,
                name: item.name,
                bannerPath: item.path,
            }));
            return expoUser.entityTypeId !== expoConfig.domain.entity_type.chamber
                ? toReturn.filter((item) => item.id !== expoUser.entityId)
                : toReturn;
        }
        return null;
    };
    const italians = getItalians();

    const getForeigns = () => {
        //const camera = chamber ? chamber : expoUser.chamber
        if (
            chamber &&
            expoUser &&
            expoUser.chamber &&
            expoUser.chamber.items &&
            expoUser.chamber.items.noItaly.length > 0
        ) {
            return expoUser.chamber.items.noItaly.map((item) => ({
                id: item.id,
                name: item.name,
                bannerPath: item.path,
            }));
        }
        return null;
    };
    const foreigns = getForeigns();

    const showDeleteEventModal = (eventId) => {
        setEventToDelete(events.filter((e) => e.id === eventId)[0]);
        setShowEventDeleteModal(true);
    };

    const deleteEvent = async (eventId) => {
        try {
            //Cancello l'evento
            let res = await expoClient.event.delete(eventId, expoUser.token);
            if (typeof res !== 'undefined') {
                //Rileggo tutti gli eventi
                const eRes = await expoClient.chambers.getEvents(expoUser.chamber.id, null, expoUser.token);
                expoUser.chamber.items.event = eRes.data;
                //Reimposto events nello stato così da aggiornare il componente
                setEvents(eRes.data);
            }
        } catch (error) {
            console.error("errore imprevisto nella cancellazione dell'evento", error);
        } finally {
            setEventToDelete(null);
        }
    };

    const imageUrl =
        chamber && chamber.bannerPath && chamber.bannerPath !== '' ? chamber.bannerPath : defaultCompanyImage;

    const modal_title = (
        <Space>
            <QuestionCircleOutlined style={{ fontSize: '24px' }} /> {t('warning').toUpperCase()}
        </Space>
    );

    const yes_cb = () => {
        deleteEvent(eventToDelete ? eventToDelete.id : null);
        setShowEventDeleteModal(false);
    };

    const no_cb = () => {
        setShowEventDeleteModal(false);
    };

    const modal_footer = (
        <>
            <Button key="1" onClick={yes_cb}>
                {t('yes').toUpperCase()}
            </Button>
            <Button key="2" onClick={no_cb}>
                {t('no').toUpperCase()}
            </Button>
        </>
    );

    if (pageLoading) {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Space direction="horizontal">
                    <Spin size="large" />
                    <h1>{t('label_loading')}</h1>
                </Space>
            </div>
        );
    }

    return chamber ? (
        <>
            <Modal visible={showEventDeleteModal} title={modal_title} footer={modal_footer}>
                <p style={{ fontSize: '16px' }}>{t('event_delete_confirm')}</p>
            </Modal>
            <TopBar arrowsVisible={false} onClickCallBack={transitToDataPageFromToolBar}>
                <div className="blackLink topBarIconCol">
                    <Link
                        onClick={(e) => transitToDataPage(e, 2)}
                        style={{ display: 'flex' }}
                        className={currentDataPage === 2 ? 'icon-selected' : null}
                    >
                        <IconAndLabel
                            label={t('company_data_user_label_icon').toUpperCase()}
                            // onClick={(e) => transitToDataPage(e, 2)}
                        >
                            <MembersButton style={{ width: '30px' }} />
                        </IconAndLabel>
                    </Link>
                </div>
                <div className="blackLink topBarIconCol">
                    <Link
                        onClick={(e) => transitToDataPage(e, 3)}
                        className={currentDataPage === 3 ? 'icon-selected' : null}
                    >
                        <IconAndLabel
                            label={t('chamber_label_data').toUpperCase()}
                            onClick={(e) => transitToDataPage(e, 3)}
                        >
                            <SettingsButton style={{ width: '30px' }} />
                        </IconAndLabel>
                    </Link>
                </div>
                <div className="blackLink topBarIconCol">
                    <Link to={{ pathname: '/eventinsert' }} style={{ display: 'flex' }}>
                        <IconAndLabel label={t('chamber_create_event').toUpperCase()}>
                            <NewEvent className="dataIcons" style={{ width: '30px' }} />
                        </IconAndLabel>
                    </Link>
                </div>
                <div className="blackLink topBarIconCol">
                    <Link to={{ pathname: '/companiesacceptance' }} style={{ display: 'flex' }}>
                        <IconAndLabel label={t('button_approve').toUpperCase()}>
                            {expoUser.chamber.pendingCounter > 0 ? (
                                <Badge className="badge_request_b2b button_request_badge" status="Success">
                                    <ApproveButton className="dataIcons" style={{ width: '30px' }} />
                                </Badge>
                            ) : (
                                <ApproveButton className="dataIcons" style={{ width: '30px' }} />
                            )}
                        </IconAndLabel>
                    </Link>
                </div>
                <div className="blackLink topBarIconCol">
                    <Link to={{ pathname: '/requestb2b' }} style={{ display: 'flex' }}>
                        <IconAndLabel label={t('request_b2b').toUpperCase()}>
                            {unviewedContactRequests ? (
                                <Badge className="badge_request_b2b button_request_badge" status="processing">
                                    <IconRequestB2B className="dataIcons" style={{ width: '30px' }} />
                                </Badge>
                            ) : (
                                <IconRequestB2B className="dataIcons" style={{ width: '30px' }} />
                            )}
                        </IconAndLabel>
                    </Link>
                </div>
                <div className="blackLink topBarIconCol">
                    <Link to={{ pathname: '/companiesdrafted' }} style={{ display: 'flex' }}>
                        <IconAndLabel label={t('drafted_company').toUpperCase()}>
                            <Draft className="dataIcons" style={{ width: '30px' }} />
                        </IconAndLabel>
                    </Link>
                </div>
                <div className="blackLink topBarIconCol">
                    <Link to={{ pathname: '/companiesuspended' }} style={{ display: 'flex' }}>
                        <IconAndLabel label={t('suspended').toUpperCase()}>
                            <SuspendCompany className="dataIcons" style={{ width: '30px' }} />
                        </IconAndLabel>
                    </Link>
                </div>
            </TopBar>

            <Row className="companySection">
                <Col className="companyTop" span={24}>
                    <Row style={{ width: '100%', display: displayDataPage }}>
                        <Col span={24}>
                            <ChamberUserSlider
                                isEditable={isEditable}
                                callBackForModifyUserData={refreshUsers}
                                chamberUsers={chamberUsers}
                                toShow={currentDataPage}
                                chamber={chamber}
                                expouser={expoUser}
                            />
                        </Col>
                    </Row>
                    <Row className="companyTopRow">
                        <Col key="1" xl={12} xs={24}>
                            <ImageEditor
                                entity={chamber}
                                className="logoMode"
                                setEditModeCallBackParent={setEditModeCallBack}
                                globalEditState={editMode}
                                imageUrl={imageUrl}
                                isEditable={isEditable}
                                idInputFile={'idInputFileCompany'}
                                ImageEditor={false}
                                enableSave={false}
                                t={t}
                            />
                        </Col>
                        <Col
                            key="2"
                            xl={12}
                            xs={24}
                            id="descriptionText"
                            style={{
                                padding: '30px 0px',
                                justifyContent: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Analytics expouser={expoUser} t={t} />
                            {/* <EntityInfoPanel
                                setEditModeCallBackParent={setEditModeCallBack}
                                globalEditState={editMode}
                                chamber={chamber}
                                isEditable={isEditable}
                           />*/}
                        </Col>
                    </Row>
                </Col>
            </Row>
            {events ? (
                <Row style={{ backgroundColor: '#FFF' }}>
                    <Col className="companyTopRow" span={24}>
                        <XtensibleGallery
                            contents={events}
                            title={t('events')}
                            isEditable={isEditable}
                            useEmbeddedModal={false}
                            onDeleteItemCallBack={showDeleteEventModal}
                            onVieItemCallBack={onVieItemCallBackEvent}
                            onAddItemCallback={insertEvent}
                            idPrefix={chamber.id}
                            titleVisible={true}
                        />
                    </Col>
                </Row>
            ) : null}

            <div>
                {italians || foreigns ? (
                    <div
                        className="company"
                        style={{
                            minHeight: '550px',
                            paddingBottom: '100px',
                            background: '#E8E8E8 0 0 no-repeat padding-box',
                        }}
                    >
                        {italians && italians.length > 0 ? (
                            <XtensibleGallery
                                contents={italians}
                                onVieItemCallBack={goToCompanyItaly}
                                title={t('italian_companies')}
                                idPrefix={chamber.id}
                                titleVisible={true}
                            />
                        ) : null}
                        {foreigns && foreigns.length > 0 ? (
                            <XtensibleGallery
                                contents={foreigns}
                                onVieItemCallBack={goToCompanyForeigns}
                                title={
                                    t(expoUser.chamber.subdomain + '_companies') !==
                                    expoUser.chamber.subdomain + '_companies'
                                        ? t(expoUser.chamber.subdomain + '_companies')
                                        : t('foreign_companies')
                                }
                                idPrefix={chamber.id}
                                titleVisible={true}
                            />
                        ) : null}
                    </div>
                ) : null}
            </div>
        </>
    ) : null;
};
export default ChamberShowCase;
