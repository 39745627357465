import React, { Component } from 'react';
import { Row, Col, Divider } from 'antd';

import { withTranslation } from 'react-i18next';

import expoConfig from '../config/expo_config.json';
import expoClient from '../expoClient';
import './CompanyUsers.css';
import './CompanyData.css';

class CompanyData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            company: props.company,
            expouser: props.expouser,
            companyUsers: props.companyUsers,
            toShow: props.toShow,
            // entity lette dalle API
            countries: [],
            disableSubmit: true,
        };
    }

    componentDidMount() {
        expoClient.countries
            .get()
            .then((res) => {
                this.setState({ countries: res.data });
            })
            .catch((err) => console.info('getCoutries: ' + err));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.toShow !== this.props.toShow) {
            this.setState({ toShow: this.props.toShow });
        }
        if (prevProps.company !== this.props.company) {
            let companyNew = Object.assign({}, this.props.company);
            this.setState({ company: companyNew });
        }
        if (prevProps.expouser !== this.props.expouser) {
            this.setState({ expouser: this.props.expouser });
        }
        if (prevProps.companyUsers !== this.props.companyUsers) {
            this.setState({ companyUsers: this.props.companyUsers });
        }
    }

    renderRole(roleId) {
        const { t } = this.props;

        switch (roleId) {
            case expoConfig.domain.role.admin:
                return t('user_data_role_admin');
            case expoConfig.domain.role.standard:
                return t('user_data_role_standard');
            default:
                return t('user_data_role_unknown');
        }
    }

    fromNumberToStringProfile(roleId) {
        const { t } = this.props;

        switch (roleId) {
            case expoConfig.domain.role.admin:
                return t('user_data_role_admin');
            case expoConfig.domain.role.standard:
                return t('user_data_role_standard');
            default:
                return t('user_data_role_unknown');
        }
    }

    fromNumberToStringPlan(planId) {
        const { t } = this.props;
        switch (planId) {
            case expoConfig.domain.subscription_plan.PREMIUM:
                return t('user_data_plan_premium');
            case expoConfig.domain.subscription_plan.BUSINESS:
                return t('user_data_plan_business');
            case expoConfig.domain.subscription_plan.EXECUTIVE:
                return t('user_data_plan_executive');
            default:
                return t('user_data_plan_unknown');
        }
    }

    renderUser(data) {
        const businessRole = data.businessRole ? data.businessRole + ' - ' : null;
        const role =
            data.roleId === expoConfig.domain.role.admin
                ? '(' + this.fromNumberToStringProfile(data.roleId).toLowerCase() + ')'
                : null;
        return (
            <Row
                key={`${data.user.name}_${data.user.surname}`}
                style={{ width: '100%', marginTop: '10px', color: 'black' }}
            >
                <Col span={21}>
                    <Row style={{ textAlign: 'initial' }}>
                        <Col span={24}>
                            <span className="charFontFamily nameandsurname">
                                {data.user.name} {data.user.surname} {role}
                            </span>
                        </Col>
                    </Row>
                    <Row justify="initial" style={{ textAlign: 'initial' }}>
                        <Col span={24}>
                            <span className="charFontFamily mailandrole">
                                {businessRole}
                                {data.user.email}
                            </span>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }

    render() {
        const { t } = this.props;

        const currentCountry = this.state.company.country ? (
            <span>{t(`country_${this.props.company.country.code}`)}</span>
        ) : (
            ''
        );

        var data = new Date(this.state.company.createdAt);
        let creationDate = '';
        if (data) creationDate = data.toLocaleDateString();
        console.log('CompanyData.render ', this.state.company.createdAt, data, creationDate);

        return (
            <Row className="userSliderContainer">
                <Row style={{ width: '100%', marginTop: '30px', marginBottom: '50px' }}>
                    {/* <Col span="24">
                        <Row style={{ width: '100%' }}>
                            <Col span={24} className="companyDataTitleMainData charFontFamily">
                                {t('company_data_main_label')}
                            </Col>
                            <Col span={24} className="companyDataCreationDate charFontFamily">
                                <span>
                                    {t('company_data_signup_time')} {creationDate}
                                </span>
                            </Col>
                        </Row>
                        </Col> */}
                    <Col span={24} style={{ marginTop: '30px' }}>
                        <Row justify="center">
                            <Col xl={{ span: 6, offset: 0 }} md={{ span: 6, offset: 0 }} xs={{ span: 20, offset: 0 }}>
                                <Row
                                    style={{
                                        border: '4px solid #707070',
                                        borderRadius: '16px',
                                        backgroundColor: '#fff',
                                        boxShadow: '0px -4px 8px #00000029',
                                    }}
                                >
                                    <Col span={24} className="companyNameChamber">
                                        {this.state.company.name}
                                        <Divider
                                            className="divider-company"
                                            style={{ marginTop: '9px', marginBottom: '0px' }}
                                        />
                                    </Col>
                                    <Row style={{ padding: '40px' }}>
                                        <Col span={20}>
                                            <span className="companyOtherFields">
                                                {this.state.company.addressVenue} - {this.state.company.zipCode}
                                            </span>
                                        </Col>
                                        <Col span={20}>
                                            <span className="companyOtherFields">
                                                {this.state.company.addressCity} {currentCountry}
                                            </span>
                                        </Col>
                                        <Col span={20}>
                                            <span className="companyOtherFields companyOtherFieldsBold">
                                                {t('signup_placeholder_company_vatnumber_chamber')}:{' '}
                                            </span>
                                            <span className="companyOtherFields">{this.state.company.vatNumber} </span>
                                        </Col>
                                        <Col span={20}>
                                            <span className="companyOtherFields companyOtherFieldsBold">
                                                {t('signup_placeholder_company_website_chamber')}:{' '}
                                            </span>
                                            <a
                                                className="companyOtherFields link_website"
                                                href={this.state.company.website}
                                                target="_blank"
                                            >
                                                {' '}
                                                <u>{this.state.company.website}</u>
                                            </a>
                                        </Col>
                                        <Col span={20}>
                                            <span className="companyOtherFields companyOtherFieldsBold">
                                                {t('signup_placeholder_company_phone_chamber')}:{' '}
                                            </span>
                                            <span className="companyOtherFields">{this.state.company.phone}</span>
                                        </Col>
                                    </Row>
                                    {/*                                     <Col span={20}>
                                        <span className="companyOtherFields companyOtherFieldsBold">{t('signup_placeholder_company_phone_chamber')}: </span><span className="companyOtherFields">{this.fromNumberToStringPlan(this.props.expouser.registration.companyPlanId)}</span>
                                    </Col>
 */}{' '}
                                </Row>
                            </Col>
                            <Col
                                xl={{ span: 6, offset: 1 }}
                                md={{ span: 6 }}
                                xs={{ span: 20, offset: 0 }}
                                className="col-companyNameChamber"
                            >
                                <Row className="borderTopDown" justify="center">
                                    <Col span={24} className="companyNameChamber">
                                        {t('user_data_users').toUpperCase()}
                                        <Divider
                                            className="divider-company"
                                            style={{ marginTop: '9px', marginBottom: '0px' }}
                                        />
                                    </Col>
                                </Row>
                                <Row className="scrollbar-colored" style={{ marginBottom: '20px' }}>
                                    <Col span={24}>
                                        {this.state.companyUsers
                                            ? this.state.companyUsers.map((m, index) => this.renderUser(m))
                                            : null}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Row>
        );
    }
}
export default withTranslation()(CompanyData);
