import { useContext, useEffect, useState } from 'react';

import getChimeContext from '../context/getChimeContext';

export default function useRoster() {
    const chime = useContext(getChimeContext());
    const [roster, setRoster] = useState(chime && chime.roster ? chime.roster : {});
    useEffect(() => {
        const callback = (newRoster) => {
            setRoster({
                ...newRoster,
            });
        };
        if (chime) chime.subscribeToRosterUpdate(callback);
        return () => {
            if (chime) chime.unsubscribeFromRosterUpdate(callback);
        };
    }, []);
    return roster;
}
