import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';

import getChimeContext from '../../context/getChimeContext';
import getMeetingStatusContext from '../../context/getMeetingStatusContext';

import styles from './LocalVideo.css';
import Avatar from './Avatar';

const cx = classNames.bind(styles);

export default function LocalVideo({ classList, main }) {
    const [enabled, setEnabled] = useState(false);
    const chime = useContext(getChimeContext());
    const { b2bInfo, colorMap } = useContext(getMeetingStatusContext());

    const videoElement = useRef(null);
    const avatarText = b2bInfo ? `${b2bInfo.user.name[0]}${b2bInfo.user.surname[0]}`.toUpperCase() : '';
    useEffect(() => {
        if (chime && chime.audioVideo) {
            chime.audioVideo.addObserver({
                videoTileDidUpdate: (tileState) => {
                    console.log('LocalVideo.videoTileDidUpdate tileState:', tileState);
                    if (
                        !tileState.boundAttendeeId ||
                        !tileState.localTile ||
                        !tileState.tileId ||
                        !videoElement.current
                    ) {
                        return;
                    }
                    if (chime && chime.audioVideo) {
                        /*if (main) {
                            chime.audioVideo.bindVideoElement(tileState.tileId, videoElement.current);
                            setEnabled(tileState.active);
                        } else {*/
                        const isLocalVideoStarted = chime.audioVideo.hasStartedLocalVideoTile();
                        isLocalVideoStarted
                            ? chime.audioVideo.startVideoPreviewForVideoInput(videoElement.current)
                            : chime.audioVideo.stopVideoPreviewForVideoInput(videoElement.current);
                        setEnabled(isLocalVideoStarted);
                        //}
                    }
                },
            });
        }
    }, []);

    const tokenColor = colorMap.find((c) => c.token === b2bInfo.user.token);
    return (
        <div className={classList ? classList : 'localVideo'}>
            <video muted ref={videoElement} className={cx('video', { enabled })} />
            <Avatar
                content={avatarText}
                active={!enabled}
                color={tokenColor ? tokenColor.color : '#FFF'}
                scale={main ? 'xlarge' : 'small'}
            />
        </div>
    );
}
