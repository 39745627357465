import React, { useEffect, useState } from 'react';
import history from '../../utils/history';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import expoClient from '../../expoClient';

import { Row, Col } from 'antd';

import './B2BLeave.css';

const B2BLeave = () => {
    const { t } = useTranslation();

    let { joinToken } = useParams(null);

    const [eventImage, setEventImage] = useState(null);

    useEffect(() => {
        const start = async (joinToken) => {
            const tokens = joinToken.split('-');
            try {
                const b2bResponse = await expoClient.meeting.getInfo(tokens[0], tokens[1]);
                console.log('B2BLeave.useEffect b2bResponse', b2bResponse.data);
                setEventImage(b2bResponse.data.image);
            } catch (error) {
                // eslint-disable-next-line
                console.error(error);
            }
        };
        start(joinToken);
    }, []);

    return eventImage ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="b2bLeave-panel">
                <div className="b2bLeave-image">
                    <img style={{ width: '100%' }} src={eventImage} alt="event logo" />
                </div>
                <div className="b2bLeave-text">{t('outOfCall')}</div>
                <div className="b2bLeave-div-button">
                    <div className="b2bLeave-button" onClick={() => history.push(`/event/${joinToken}`)}>
                        {t('rejoin')}
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};
export default B2BLeave;
