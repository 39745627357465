import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';
import { Carousel, Col, notification, Row, Button } from 'antd';

import expoClient from '../expoClient';
import authorization from '../utils/authorization';
import expoConfig from '../config/expo_config.json';
import ItemInfoPanel from './ItemInfoPanel';
import XtensibleGallery from './XtensibleGallery';
import ImageEditor from './ImageEditor';
import ImageEditorModalTile from './ImageEditorModalTile';
import VideoEditor from './VideoEditor';
import TopBar from './TopBar';
import { ReactComponent as UploadButton } from '../icons/addvideo.svg';

import defaultItemImage from '../images/default-item.jpg';
import defaultMediaImage from '../images/default-media.jpg';
import { ReactComponent as ArrowLeft } from '../icons/arrow-modal-left.svg';
import { ReactComponent as ArrowRight } from '../icons/arrow-modal-right.svg';
import { ReactComponent as CloseButton } from '../icons/close.svg';
import { ReactComponent as ContactButton } from '../icons/mail.svg';
import { ReactComponent as AddImage } from '../icons/addimage.svg';
import { ReactComponent as AddMedia } from '../icons/aggiungi.svg';
import './ItemShowCase.css';
import ConfirmModal from './ConfirmModal';
import IconAndLabel from './IconAndLabel';
import { Pannellum, PannellumVideo } from 'pannellum-react';
import ReactGA from 'react-ga';
import TextArea from 'antd/lib/input/TextArea';
import EditSaveDiscardIconsImage from './EditSaveDiscardIconsImage';

const ItemShowCase = ({ location }) => {
    //Possibili parametri che ricevo in location
    const { expoUser, itemType, itemId } = location.state;

    const { t } = useTranslation();

    const [item, setItem] = useState(null);
    const [company, setCompany] = useState(null);
    const [showModalImage, setShowModalImage] = useState(false);
    const [showModalVideo, setShowModalVideo] = useState(false);
    const [showModalImage3D, setShowModalImage3D] = useState(false);
    const [showModalImage360, setShowModalImage360] = useState(false);
    const [currentMediaId, setCurrentMediaId] = useState(null);
    const [registrationStatus, setRegistrationStatus] = useState(0);

    //Variabili per la gestione del delete delle immagini che mi memorizzano
    //la posizione corrente nello stesso. Uso una variabile sola anche se i carousel sono
    //4 perchè i media comunque hanno id univoco e non rischio di cancellare qualcun altro.
    const [currentMediaInCarousels, setCurrentMediaInCarousels] = useState(0);
    const [editMode, setEditMode] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [showModalInsertImage, setShowModalInsertImage] = useState(false);
    const [newMedia, setNewMedia] = useState({});
    //const [showAddButtons, setShowAddButtons] = useState(false);
    const [loadingPercentage, setLoadingPercentage] = useState(false);
    const [statusUpload, setStatusUpload] = useState('normal');
    const [newVideo, setNewVideo] = useState({});
    const [showModalInsertVideo, setShowModalInsertVideo] = useState(false);
    const [showModalChooseImageVideo, setShowModalChooseImageVideo] = useState(false);

    //const { fromAuth0User } = useAuth0();
    //const [chamber, setChamber] = useState(chamber_);
    const [isChamberUser, setIsChamberUser] = useState(false);

    const placeHolderMediaDescription = t('new_media_description');
    let initialPageLocal = 0;

    useEffect(() => {
        const init = async () => {
            ReactGA.initialize(expoConfig.analyticsproperty.id);

            console.log('NewItemShowcase.useEffect', location.state);
            let isChamU = expoUser && expoUser.entityTypeId === expoConfig.domain.entity_type.chamber;
            setIsChamberUser(expoUser && expoUser.entityTypeId === expoConfig.domain.entity_type.chamber);

            //placeHolderName = (itemType === expoConfig.domain.showcaseitem.product) ? t('product_name') : t('portfolio_name');
            //placeHolderDescription = (itemType === expoConfig.domain.showcaseitem.product) ? t('product_description') : t('product_description');
            if (item === null && itemId) {
                const responseItem = await expoClient.showcase.get(itemId, expoUser.token);
                const newitem = responseItem.data;
                newitem.media = {};
                const cRes = await expoClient.company.get(newitem.companyId, expoUser.token);
                const c = cRes.data;

                let mediaRes = await expoClient.showcase.getMediaByType(
                    itemId,
                    expoConfig.domain.showcaseitemtype.image,
                    expoUser.token,
                );
                newitem.media.image = mediaRes.data;
                mediaRes = await expoClient.showcase.getMediaByType(
                    itemId,
                    expoConfig.domain.showcaseitemtype.video,
                    expoUser.token,
                );
                newitem.media.video = mediaRes.data;
                mediaRes = await expoClient.showcase.getMediaByType(
                    itemId,
                    expoConfig.domain.showcaseitemtype.TRE60,
                    expoUser.token,
                );
                newitem.media.TRE60 = mediaRes.data;
                mediaRes = await expoClient.showcase.getMediaByType(
                    itemId,
                    expoConfig.domain.showcaseitemtype.TRED,
                    expoUser.token,
                );
                newitem.media.TRED = mediaRes.data;
                const companyUser = expoUser.entityTypeId === c.entityTypeId && expoUser.entityId === newitem.companyId;
                const userAdmin = expoUser.roleId === expoConfig.domain.role.admin;
                setIsEditable(companyUser && userAdmin);
                setCompany(c);
                setItem(newitem);

                if (expoUser && c && expoUser.chamber && !isChamU) {
                    ReactGA.pageview(
                        '/chamber/' +
                            expoUser.chamber.id +
                            '/company/' +
                            c.id +
                            '/userCompany/' +
                            c.id +
                            '/user/' +
                            expoUser.id +
                            '/item/' +
                            itemId,
                    );
                } else if (isChamU && expoUser) {
                    ReactGA.pageview(
                        '/chamber/' +
                            expoUser.chamber.id +
                            '/company/' +
                            c.id +
                            '/userChamber/' +
                            expoUser.chamber.id +
                            '/user/' +
                            expoUser.id +
                            '/item/' +
                            itemId,
                    );
                }

                //setShowAddButtons(true);
            } else {
                const newitem = {
                    name: null,
                    description: null,
                    typeId: itemType,
                    companyId: expoUser.entityId,
                    bannerPath: null,
                    logoPath: null,
                    media: {
                        image: [],
                        video: [],
                        TRE60: [],
                        TRED: [],
                    },
                };
                setIsEditable(expoUser.roleId === expoConfig.domain.role.admin);
                setItem(newitem);
            }
            const img = document.getElementById('bannerImage');
            if (img) {
                img.onload = function () {
                    handleResize();
                };
            }
        };
        init();
        window.addEventListener('resize', handleResize);
    }, []);
    const handleResize = () => {
        console.log('resized to: ', window.innerWidth, 'x', window.innerHeight);
        const img = document.getElementById('bannerImage');
        const w = window.innerWidth;
        if (document.getElementById('descriptionText') != null) {
            if (w < 1200) {
                document.getElementById('descriptionText').style.height = 'auto';
            } else if (img) {
                const height = img.clientHeight;
                document.getElementById('descriptionText').style.height = height + 'px';
            }
        }
    };

    //Vecchie funzioni start
    const postNewData = async (idEntity) => {
        //se id === null vuol dire che devo creare altrimenti sono in modifica.
        if (
            item &&
            (item.id === null || typeof item.id === 'undefined') &&
            (idEntity === null || typeof idEntity === 'undefined')
        ) {
            try {
                let response = await expoClient.company.createItem(item.companyId, item, expoUser.token);
                if (typeof response !== 'undefined') {
                    let responseItem = await expoClient.showcase.get(response.data, expoUser.token);
                    let newItemLocal = responseItem.data;
                    newItemLocal.media = {
                        image: [],
                        video: [],
                        TRE60: [],
                        TRED: [],
                    };
                    let mediaRes = await expoClient.showcase.getMediaByType(
                        newItemLocal.id,
                        expoConfig.domain.showcaseitemtype.image,
                        expoUser.token,
                    );
                    newItemLocal.media.image = mediaRes.data;
                    mediaRes = await expoClient.showcase.getMediaByType(
                        newItemLocal.id,
                        expoConfig.domain.showcaseitemtype.video,
                        expoUser.token,
                    );
                    newItemLocal.media.video = mediaRes.data;
                    mediaRes = await expoClient.showcase.getMediaByType(
                        newItemLocal.id,
                        expoConfig.domain.showcaseitemtype.TRE60,
                        expoUser.token,
                    );
                    newItemLocal.media.TRE60 = mediaRes.data;
                    mediaRes = await expoClient.showcase.getMediaByType(
                        newItemLocal.id,
                        expoConfig.domain.showcaseitemtype.TRED,
                        expoUser.token,
                    );
                    newItemLocal.media.TRED = mediaRes.data;
                    setItem(newItemLocal);
                    //setShowAddButtons(true);
                }
            } catch (error) {
                console.error(error);
            } finally {
            }
        } else {
            try {
                if (item.id == null) item.id = idEntity;
                await expoClient.showcase.modifyItem(item.id, item, expoUser.token);
                refreshWholeItem();
            } catch (error) {
                console.error(error);
            } finally {
            }
        }
    };

    const { view_companies_showcase, b2b_request, full_showcase_edit } = authorization.getAutorizations(expoUser);
    const contactButtonLabel = t('chamber_request_contact_upper');
    const contactButtonToolbar =
        b2b_request && !isEditable && expoUser && expoUser.chamber && company ? (
            <ConfirmModal
                onOk={() => postRequestContact()}
                message={t('ask_for_request_confirmation', { 0: expoUser.chamber.name, 1: company.name })}
            >
                <IconAndLabel label={contactButtonLabel}>
                    <ContactButton className="dataIcons" style={{ width: '30px' }} />
                </IconAndLabel>
            </ConfirmModal>
        ) : null;
    const contactButton =
        !isEditable && expoUser && expoUser.chamber && company && !isChamberUser ? (
            <ConfirmModal
                onOk={() => postRequestContact()}
                message={t('ask_for_request_confirmation', { 0: expoUser.chamber.name, 1: company.name })}
            >
                <p className="askEventButton" style={{ cursor: 'pointer' }}>
                    {t('ask_for_b2b_event')}
                </p>
            </ConfirmModal>
        ) : null;
    const refreshWholeItem = async () => {
        if (item && item.id) {
            let responseItem = await expoClient.showcase.get(item.id, expoUser.token);
            let newItemLocal = responseItem.data;
            newItemLocal.media = {
                image: [],
                video: [],
                TRE60: [],
                TRED: [],
            };
            let mediaRes = await expoClient.showcase.getMediaByType(
                newItemLocal.id,
                expoConfig.domain.showcaseitemtype.image,
                expoUser.token,
            );
            newItemLocal.media.image = mediaRes.data;
            mediaRes = await expoClient.showcase.getMediaByType(
                newItemLocal.id,
                expoConfig.domain.showcaseitemtype.video,
                expoUser.token,
            );
            newItemLocal.media.video = mediaRes.data;
            mediaRes = await expoClient.showcase.getMediaByType(
                newItemLocal.id,
                expoConfig.domain.showcaseitemtype.TRE60,
                expoUser.token,
            );
            newItemLocal.media.TRE60 = mediaRes.data;
            mediaRes = await expoClient.showcase.getMediaByType(
                newItemLocal.id,
                expoConfig.domain.showcaseitemtype.TRED,
                expoUser.token,
            );
            newItemLocal.media.TRED = mediaRes.data;
            setItem(newItemLocal);
        }
    };
    const setEditModeCallBack = (isEdit, changed, fromDiscard, idEntity) => {
        setEditMode(isEdit);
        if (changed) {
            postNewData(idEntity);
        }
    };

    //Delete media start
    const posDeleteMedia = async (mediaType, idMedia) => {
        try {
            let prefix = '';
            switch (mediaType) {
                case expoConfig.domain.showcaseitemtype.image:
                    if (item && item.media && item.media.image.length <= 1) {
                        setShowModalImage(false);
                    }
                    prefix = 'image_';
                    break;
                case expoConfig.domain.showcaseitemtype.TRE60:
                    if (item && item.media && item.media.TRE60.length <= 1) {
                        setShowModalImage360(false);
                    }
                    prefix = 'image360_';
                    break;
                case expoConfig.domain.showcaseitemtype.TRED:
                    prefix = 'image3D_';
                    if (item && item.media && item.media.TRED.length <= 1) {
                        setShowModalImage3D(false);
                    }
                    break;
                case expoConfig.domain.showcaseitemtype.video:
                    prefix = 'video_';
                    if (item && item.media.video && item.media.video.length <= 1) {
                        setShowModalVideo(false);
                    }
                    break;
                default:
                    break;
            }
            let toCancel = idMedia;
            //se idMedia == null arrivo dallo slider full page e non dalla delete del tile
            if (toCancel == null) {
                const chosenImgOrVideo = document.getElementById(`${prefix}${currentMediaInCarousels}`);
                if (chosenImgOrVideo !== null) {
                    toCancel =
                        mediaType === expoConfig.domain.showcaseitemtype.video
                            ? chosenImgOrVideo.getAttribute('name')
                            : chosenImgOrVideo.name;
                }
            }
            let res = await expoClient.media.delete(toCancel, expoUser.token);
            if (typeof res !== 'undefined') {
                setCurrentMediaInCarousels(0);
                //forzo il refresh della lista dei media coinvolti
                //refreshMediaInItem(mediaType);
                refreshWholeItem();
            }
        } catch (error) {
            console.error('error in deleting media:', error);
        } finally {
        }
    };
    const deleteMediaImage = (mediaType, id) => {
        posDeleteMedia(mediaType, id);
    };
    //Delete media end

    //New Media start
    const postNewMediaData = async () => {
        if (item.id != null) {
            let itemNew = item;
            try {
                newMedia.showcaseItemId = item.id;
                await expoClient.showcase.createMedia(item.id, newMedia, expoUser.token);
                //forzo il refresh della lista dei media coinvolti
                refreshWholeItem();
                //refreshMediaInItem(newMedia.typeId);
            } catch (error) {
                console.error('postNewMediaData:', error);
            } finally {
            }
            setItem(itemNew);
            setShowModalInsertImage(false);
        }
    };
    const changeFileData = (isEdit, changed, fromDiscard) => {
        setEditMode(isEdit);
        if (changed) {
            postNewMediaData();
        }
        //Faccio sparire la modale
        if (fromDiscard) setShowModalInsertImage(false);
    };

    //Funzioni CallBack per la modifica dei vari componenti e mi tengo in pancia i nuovi valori
    const changedMediaDescription = (description) => {
        newMedia.description = description;
    };
    const setShowModalInsertImageFromPlus = (mediatype) => {
        closeModalChooseImageVideo();
        if (item && item.id != null) {
            let newMediaLocal = {
                typeId: mediatype,
                path: null,
                imageBase64: null,
                name: null,
                description: null,
            };
            setNewMedia(newMediaLocal);
            setShowModalInsertImage(true);
        }
    };
    //New Media End

    //Gestione new Video start
    const posNewVideoData = async (formData) => {
        //location.state.token;
        //await expoClient.company.modify(company, token);
        try {
            setStatusUpload('active');
            setLoadingPercentage(0);
            let res = await expoClient.showcase.createVideo(
                item.id,
                formData,
                newVideo.typeId ? newVideo.typeId : null,
                expoUser.token,
                setLoadingPercentage,
            );
            if (typeof res !== 'undefined') {
                closeModalInsertVideo();
                const desc = t('uploading_ok_notification');
                notification.success({
                    key: 'postCreateLiveOk',
                    message: desc,
                    placement: 'topRight',
                    duration: 7,
                });
                //item = await expoClient.company.getShowcaseItems(item.companyId,response.data,expoUser.token);
                refreshWholeItem();
            }
        } catch (error) {
            console.error('posNewVideo:', error);
            setStatusUpload('exception');
        } finally {
        }
    };
    const setShowModalInsertVideoFromPlus = (e) => {
        let newVideoLocal = {
            //name: placeHolderName,
            description: '',
            video: null,
        };
        setNewVideo(newVideoLocal);
        setShowModalInsertVideo(true);
    };
    const setShowModalInsertVideo360FromPlus = (e) => {
        closeModalChooseImageVideo();
        let newVideoLocal = {
            //name: placeHolderName,
            description: '',
            video: null,
            typeId: expoConfig.domain.showcaseitemtype.TRE60,
        };
        setNewVideo(newVideoLocal);
        setShowModalInsertVideo(true);
    };
    const setShowModalChooseImageVideoPlus = (e) => {
        setShowModalChooseImageVideo(true);
    };

    const changeFileDataVideo = (isEdit, changed, fromDiscard, formData, typeId) => {
        setEditMode(isEdit);
        if (changed) {
            posNewVideoData(formData, typeId);
        }
        //Faccio sparire la modale
        if (fromDiscard) closeModalInsertVideo();
    };
    const closeModalInsertVideo = () => {
        setShowModalInsertVideo(false);
        setLoadingPercentage(0);
        setStatusUpload('normal');
    };
    const closeModalInsertImage = () => {
        setShowModalInsertImage(false);
    };
    const closeModalChooseImageVideo = () => {
        setShowModalChooseImageVideo(false);
    };
    const closeVideo = () => {
        setShowModalVideo(false);
    };
    const closeElement360 = () => {
        setShowModalImage360(false);
    };

    //Funzione di handling change in Media
    const handleAfterChangeMedia = (id, prefix) => {
        setCurrentMediaInCarousels(id);
    };
    const setShowModalWrapper = (openclose, mediaId, mediaType) => {
        setCurrentMediaId(mediaId);
        switch (mediaType) {
            case expoConfig.domain.showcaseitemtype.image:
                setShowModalImage(openclose);
                break;
            case expoConfig.domain.showcaseitemtype.TRE60:
                setShowModalImage360(openclose);
                break;
            case expoConfig.domain.showcaseitemtype.TRED:
                setShowModalImage3D(openclose);
                break;
            case expoConfig.domain.showcaseitemtype.video:
                setShowModalVideo(openclose);
                break;
            default:
                break;
        }
    };

    const computeInitial = (m, index) => {
        if (m.id === currentMediaId) {
            initialPageLocal = index;
        }
    };

    const carouselImageRef = useRef();
    const carouselImage3DRef = useRef();
    //	const carouselVideoRef = useRef();
    // const carouselImage360Ref = useRef();

    /* 	const handleBeforeChange = (from, to) => {
			const myCarousel = document.getElementById("modalVideoCarousel");
			let elencoVideo = myCarousel.querySelectorAll(".slick-slide");
			if (elencoVideo) {
				for (let i = 0; i < elencoVideo.length; i++) {
					let videoToMute = elencoVideo[i].getElementsByClassName("videoHook")[0];
					videoToMute.muted = true;
					videoToMute.pause();
				}
			}
		}

		const handleAfterChange = (current) => {
			handleAfterChangeMedia(current, "video_")
			const myCarousel = document.getElementById("modalVideoCarousel");
			let vTo = myCarousel.querySelectorAll(".slick-active")[0].getElementsByClassName("videoHook")[0];
			if (vTo) {
				vTo.muted = false;
				vTo.play();
			}
		} */
    //Vecchie funzioni end

    const getMediaImages = () => {
        if (item && item.media && item.media.image.length > 0) return item.media.image;
        return null;
    };
    const images = getMediaImages();

    const getMediaVideos = () => {
        if (item && item.media && item.media.video.length > 0) return item.media.video;
        return null;
    };
    const videos = getMediaVideos();

    const getMediaTRE60 = () => {
        if (item && item.media && item.media.TRE60.length > 0) return item.media.TRE60;
        return null;
    };
    const TRE60 = getMediaTRE60();

    const getMediaTRED = () => {
        if (item && item.media && item.media.TRED.length > 0) return item.media.TRED;
        return null;
    };
    const TRED = getMediaTRED();

    const onVieItemCallBackImages = (idImage) => {
        setShowModalWrapper(true, idImage, expoConfig.domain.showcaseitemtype.image);
    };

    const onAddItemCallbackImages = () => {
        setShowModalInsertImageFromPlus(expoConfig.domain.showcaseitemtype.image);
    };

    const onDeleteItemCallBackImages = (idImage) => {
        deleteMediaImage(expoConfig.domain.showcaseitemtype.image, idImage);
    };

    const onVieItemCallBack3D = (idImage) => {
        setShowModalWrapper(true, idImage, expoConfig.domain.showcaseitemtype.TRED);
    };

    const onAddItemCallback3D = () => {
        setShowModalInsertImageFromPlus(expoConfig.domain.showcaseitemtype.TRED);
    };

    const onDeleteItemCallBack3D = (idImage) => {
        deleteMediaImage(expoConfig.domain.showcaseitemtype.TRED, idImage);
    };

    const onVieItemCallBack360 = (idImage) => {
        setShowModalWrapper(true, idImage, expoConfig.domain.showcaseitemtype.TRE60);
    };

    const onAddItemCallback360 = () => {
        setShowModalChooseImageVideoPlus(expoConfig.domain.showcaseitemtype.TRE60);
    };
    const onAddItemCallback360Image = () => {
        setShowModalInsertImageFromPlus(expoConfig.domain.showcaseitemtype.TRE60);
    };
    const onDeleteItemCallBack360 = (idImage) => {
        deleteMediaImage(expoConfig.domain.showcaseitemtype.TRE60, idImage);
    };

    const onVieItemCallBackVideos = (videoId) => {
        setShowModalWrapper(true, videoId, expoConfig.domain.showcaseitemtype.video);
    };

    const onAddItemCallbackVideos = () => {
        setShowModalInsertVideoFromPlus();
    };

    const onDeleteItemCallBackVideos = (videoId) => {
        deleteMediaImage(expoConfig.domain.showcaseitemtype.video, videoId);
    };

    const closeVideoInsertModal = isEditable ? (
        <ConfirmModal onOk={() => closeModalInsertVideo()} message={t('ask_for_close_live')}>
            <CloseButton className="exitButtonOnLoading" />
        </ConfirmModal>
    ) : null;

    const closeImageInsertModal = isEditable ? (
        <ConfirmModal onOk={() => closeModalInsertImage()} message={t('ask_for_close_live')}>
            <CloseButton className="exitButtonOnLoading" />
        </ConfirmModal>
    ) : null;

    const closeImageVideoChooseModal = isEditable ? (
        <ConfirmModal onOk={() => closeModalChooseImageVideo()} message={t('ask_for_close_live')}>
            <CloseButton className="exitButtonOnLoading" />
        </ConfirmModal>
    ) : null;

    //Gestione richiesta contatto B2B
    const postRequestContact = async () => {
        console.log('postRequestContact company ', expoClient.company);
        try {
            await expoClient.chambers.requestContact(expoUser.chamber.id, company.id, expoUser.token);
        } catch (error) {
            console.error('postContactRequest:', error);
        } finally {
        }
    };

    const imageUrl = item && item.bannerPath && item.bannerPath !== '' ? item.bannerPath : defaultItemImage;

    if (showModalImage && item && item.media && item.media.image)
        item.media.image.map((m, index) => computeInitial(m, index));
    if (showModalImage360 && item && item.media && item.media.TRE60)
        item.media.TRE60.map((m, index) => computeInitial(m, index));
    if (showModalImage3D && item && item.media && item.media.TRED)
        item.media.TRED.map((m, index) => computeInitial(m, index));
    if (showModalVideo && item && item.media && item.media.video)
        item.media.video.map((m, index) => computeInitial(m, index));

    const chosenVideo =
        item && item.media && item.media.video ? item.media.video.find((l) => l.id === currentMediaId) : null;
    const modal_video =
        item && chosenVideo ? (
            <Modal
                key="modalVideo"
                open={showModalVideo}
                onClose={() => setShowModalVideo(false)}
                trigger={null}
                header={null}
                actions={null}
                style={{ backgroundColor: 'transparent' }}
                closeOnDimmerClick={true}
                closeOnEscape={false}
                closeOnDocumentClick={false}
                closeOnTriggerMouseLeave={false}
                closeOnTriggerClick={false}
            >
                <Modal.Header style={{ backgroundColor: 'transparent' }} />
                <Modal.Content style={{ backgroundColor: 'transparent' }}>
                    <video key={chosenVideo.id} className="videoHook" src={chosenVideo.path} autoPlay loop />
                    <CloseButton className="exitButtonOnLoading" onClick={() => closeVideo()} />
                </Modal.Content>
            </Modal>
        ) : null;

    const chosenElement360 =
        item && item.media && item.media.TRE60 ? item.media.TRE60.find((l) => l.id === currentMediaId) : null;
    const modal_element360 =
        item && chosenElement360 ? (
            <Modal
                key="modalImage360"
                open={showModalImage360}
                onClose={() => setShowModalImage360(false)}
                trigger={null}
                header={null}
                actions={null}
                style={{ backgroundColor: 'transparent' }}
                closeOnDimmerClick={true}
                closeOnEscape={false}
                closeOnDocumentClick={false}
                closeOnTriggerMouseLeave={false}
                closeOnTriggerClick={false}
            >
                <Modal.Header style={{ backgroundColor: 'transparent' }} />
                <Modal.Content style={{ backgroundColor: 'transparent' }}>
                    {chosenElement360.path.includes('mp4') ? (
                        <PannellumVideo
                            width="100%"
                            height="765px"
                            video={chosenElement360.path}
                            autoLoad
                            loop
                            muted={false}
                            play
                            title={t('product_360_hint_video')}
                            pitch={10}
                            yaw={10}
                            autoRotate={true}
                        >
                            <Pannellum.Hotspot type="info" pitch={0} yaw={0} text={t('product_360_hint_video')} />
                        </PannellumVideo>
                    ) : (
                        <Pannellum
                            width="100%"
                            height="765px"
                            image={chosenElement360.path}
                            autoLoad
                            showZoomCtrl={false}
                            title={t('product_360_hint_photo')}
                            pitch={10}
                            yaw={10}
                            autoRotate={true}
                        >
                            <Pannellum.Hotspot type="info" pitch={0} yaw={0} text={t('product_360_hint_photo')} />
                        </Pannellum>
                    )}
                    <CloseButton className="exitButtonOnLoading" onClick={() => closeElement360()} />
                </Modal.Content>
            </Modal>
        ) : null;

    return (
        <>
            {item ? (
                <Modal
                    key="modalImage"
                    open={showModalImage}
                    onClose={() => setShowModalImage(false)}
                    style={{ backgroundColor: 'transparent', width: '85vw' }}
                >
                    <Row align="middle">
                        <Col span={2} align="end">
                            <ArrowLeft
                                style={{ cursor: 'pointer', width: '60px' }}
                                onClick={() => carouselImageRef.current.prev()}
                            />
                        </Col>
                        <Col span={20}>
                            <Carousel
                                afterChange={(to) => handleAfterChangeMedia(to, 'image_')}
                                initialSlide={initialPageLocal}
                                ref={carouselImageRef}
                                dots={false}
                                style={{ width: '85vw !important' }}
                            >
                                {item.media.image.map((m, index) => (
                                    <Row>
                                        <Row className="porfolioModalDiv">
                                            <img
                                                key={m.id}
                                                id={`image_${index}`}
                                                name={m.id}
                                                src={m.path}
                                                alt={m.name}
                                                style={{ width: '100%', objectFit: 'contain', height: '100%' }}
                                            />
                                        </Row>
                                        <Row>
                                            <Col span="24">
                                                <textarea
                                                    disabled={true}
                                                    className="textAreaPorfolio"
                                                    defaultValue={m.description}
                                                    style={{
                                                        display:
                                                            m.description && m.description !== '' ? 'block' : 'none',
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Row>
                                ))}
                            </Carousel>
                        </Col>
                        <Col span={2}>
                            <ArrowRight
                                style={{ cursor: 'pointer', margin: '0 auto', width: '60px' }}
                                onClick={() => carouselImageRef.current.next()}
                            />
                        </Col>
                    </Row>
                </Modal>
            ) : null}
            {item ? (
                <Modal
                    key="modalImage3D"
                    open={showModalImage3D}
                    onClose={() => setShowModalImage3D(false)}
                    onMount={() => handleAfterChangeMedia(0, 'image3D_')}
                    trigger={null}
                    header={null}
                    actions={null}
                    style={{ backgroundColor: 'transparent', width: '85vw' }}
                >
                    <Row align="middle">
                        <Col span="2" align="end">
                            <ArrowLeft
                                style={{ cursor: 'pointer', width: '60px' }}
                                onClick={() => carouselImage3DRef.current.prev()}
                            />
                        </Col>
                        <Col span="20">
                            <Carousel
                                afterChange={(to) => handleAfterChangeMedia(to, 'image3D_')}
                                initialSlide={initialPageLocal}
                                ref={carouselImage3DRef}
                                dots={false}
                                style={{ width: '85vw !important' }}
                            >
                                {item.media.TRED.map((m, index) => (
                                    <Row>
                                        <Row className="porfolioModalDiv">
                                            <img
                                                key={m.id}
                                                id={`image3D_${index}`}
                                                name={m.id}
                                                src={m.path}
                                                alt={m.name}
                                                style={{ width: '100%', objectFit: 'contain', height: '100%' }}
                                            />
                                        </Row>
                                        <Row>
                                            <Col span="24">
                                                <textarea
                                                    disabled={true}
                                                    className="textAreaPorfolio"
                                                    defaultValue={m.description}
                                                    style={{
                                                        display:
                                                            m.description && m.description !== '' ? 'block' : 'none',
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Row>
                                ))}
                            </Carousel>
                        </Col>
                        <Col span="2">
                            <ArrowRight
                                style={{ margin: '0 auto', width: '60px' }}
                                onClick={() => carouselImage3DRef.current.next()}
                            />
                        </Col>
                    </Row>
                </Modal>
            ) : null}
            {modal_element360}
            {modal_video}
            {item ? (
                <Modal
                    key="modalInsertImage"
                    className="modal-insert-image"
                    open={showModalInsertImage}
                    trigger={null}
                    header={null}
                    actions={null}
                    style={{ border: '4px solid #707070', borderRadius: '16px' }}
                >
                    <Modal.Header className="modal-media">{t('new_content_media').toUpperCase()}</Modal.Header>
                    {closeImageInsertModal}

                    <Row align="middle" style={{ width: '100%' }}>
                        <ImageEditorModalTile
                            setEditModeCallBackParent={changeFileData}
                            entity={newMedia}
                            on
                            globalEditState={false}
                            isEditable={true}
                            idInputFile={'idInputFileMedia'}
                            isMedia={true}
                            enableSave={true}
                        >
                            <div style={{ padding: '20px 50px 0px', width: '100%' }}>
                                <span style={{ fontSize: '20px' }}>
                                    <b> {t('description')} </b>
                                </span>
                                <TextArea
                                    style={{ margin: '20px 0px', fontSize: '18px' }}
                                    maxLength={180}
                                    onChange={(e) => changedMediaDescription(e.target.value)}
                                    defaultValue={newMedia.description}
                                    placeholder={placeHolderMediaDescription}
                                ></TextArea>
                            </div>
                        </ImageEditorModalTile>
                    </Row>
                </Modal>
            ) : null}
            {item ? (
                <Modal
                    key="modalInsertVideo"
                    className="modal-insert-video"
                    open={showModalInsertVideo}
                    closeOnDimmerClick={false}
                    closeOnEscape={false}
                    closeOnDocumentClick={false}
                    closeOnTriggerMouseLeave={false}
                    closeOnTriggerClick={false}
                    trigger={null}
                    header={null}
                    actions={null}
                    style={{ border: '4px solid #707070', borderRadius: '16px' }}
                >
                    <Modal.Header className="modal-media"> {t('new_content_media').toUpperCase()} </Modal.Header>

                    <VideoEditor
                        className="ImageEditorMedia"
                        setEditModeCallBackParent={changeFileDataVideo}
                        entity={newVideo}
                        percent={loadingPercentage}
                        globalEditState={false}
                        statusUpload={statusUpload}
                        imageUrl={defaultMediaImage}
                        isEditable={true}
                        idInputFile={'idInputFileMedia'}
                        enableSave={true}
                        closeModalCallBack={closeModalInsertVideo}
                    />

                    {closeVideoInsertModal}
                </Modal>
            ) : null}
            {item ? (
                <Modal
                    key="modalChooseImageVideo"
                    className="modal-choose-image-video"
                    open={showModalChooseImageVideo}
                    closeOnDimmerClick={false}
                    closeOnEscape={false}
                    closeOnDocumentClick={false}
                    closeOnTriggerMouseLeave={false}
                    closeOnTriggerClick={false}
                    trigger={null}
                    header={null}
                    actions={null}
                    style={{ border: '4px solid #707070', borderRadius: '16px' }}
                >
                    <Modal.Header className="modal-media">
                        {t('new_content_media').toUpperCase()}
                        {closeImageVideoChooseModal}
                    </Modal.Header>
                    <div style={{ display: 'flex', justifyContent: 'center', margin: '50px 0px' }}>
                        <div key="1" className="button-media" onClick={onAddItemCallback360Image}>
                            <AddImage className="upload-icon-media" />
                            <div className="container-icon-media">
                                <AddMedia className="icon-plus-media" />
                                <span style={{ fontSize: '20px' }}> {t('image_360').toUpperCase()} </span>
                            </div>
                        </div>
                        <div key="2" className="button-media" onClick={setShowModalInsertVideo360FromPlus}>
                            <UploadButton className="upload-icon-media" />
                            <div className="container-icon-media">
                                <AddMedia className="icon-plus-media" />
                                <span style={{ fontSize: '20px' }}> {t('video_360').toUpperCase()} </span>
                            </div>
                        </div>
                    </div>
                    <div className="button_operation_request_media">
                        <Row justify="center" align="middle">
                            <Col style={{ display: 'flex', justifyContent: 'center', margin: '10px' }}>
                                <ConfirmModal
                                    onOk={() => closeModalChooseImageVideo()}
                                    message={t('ask_for_close_live')}
                                >
                                    <Button block shape="round" className="blackMode button-request" htmlType="button">
                                        {t('button_cancel').toUpperCase()}
                                    </Button>
                                </ConfirmModal>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            ) : null}
            <TopBar back={true}>
                {!isEditable && company && !isChamberUser ? (
                    <div className="blackLink topBarIconCol">{contactButtonToolbar}</div>
                ) : null}
            </TopBar>
            {item ? (
                <Row className="companySection">
                    <Col className="companyTop" span={24}>
                        <Row key="1" className="companyTopRow">
                            <Col key="1" xl={12} xs={24}>
                                <ImageEditor
                                    entity={item}
                                    className="logoMode"
                                    setEditModeCallBackParent={setEditModeCallBack}
                                    globalEditState={editMode}
                                    imageUrl={imageUrl}
                                    isEditable={isEditable}
                                    idInputFile={'idInputFileItem'}
                                    isMedia={false}
                                    enableSave={false}
                                />
                            </Col>
                            <Col key="2" xl={12} xs={24} id="descriptionText" style={{ overflow: 'auto' }}>
                                <ItemInfoPanel
                                    setEditModeCallBackParent={setEditModeCallBack}
                                    globalEditState={editMode}
                                    isEditable={isEditable}
                                    item={item}
                                    itemType={item.typeId}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col className="companyDetails" span={24}>
                        <Row>
                            {isEditable || images ? (
                                <Col span={24}>
                                    <XtensibleGallery
                                        isEditable={isEditable}
                                        contents={getMediaImages()}
                                        onAddItemCallback={view_companies_showcase ? onAddItemCallbackImages : null}
                                        onDeleteItemCallBack={
                                            view_companies_showcase ? onDeleteItemCallBackImages : null
                                        }
                                        onVieItemCallBack={onVieItemCallBackImages}
                                        title={t('gallery')}
                                        idPrefix={item.id}
                                        titleVisible={true}
                                        titleDesc={true}
                                    />
                                </Col>
                            ) : null}
                            {isEditable || videos ? (
                                <Col span={24}>
                                    <XtensibleGallery
                                        previewpath="previewPath"
                                        imagepath="path"
                                        isEditable={isEditable}
                                        contents={getMediaVideos()}
                                        onAddItemCallback={view_companies_showcase ? onAddItemCallbackVideos : null}
                                        onDeleteItemCallBack={
                                            view_companies_showcase ? onDeleteItemCallBackVideos : null
                                        }
                                        onVieItemCallBack={view_companies_showcase ? onVieItemCallBackVideos : null}
                                        title={t('video')}
                                        idPrefix={item.id}
                                        titleVisible={false}
                                        titleDesc={true}
                                    />
                                </Col>
                            ) : null}
                            {isEditable || TRE60 ? (
                                <Col span={24}>
                                    <XtensibleGallery
                                        previewpath="previewPath"
                                        imagepath="path"
                                        isEditable={isEditable}
                                        contents={getMediaTRE60()}
                                        onAddItemCallback={full_showcase_edit ? onAddItemCallback360 : null}
                                        onDeleteItemCallBack={full_showcase_edit ? onDeleteItemCallBack360 : null}
                                        onVieItemCallBack={full_showcase_edit ? onVieItemCallBack360 : null}
                                        title={t('360')}
                                        idPrefix={item.id}
                                        titleVisible={true}
                                        titleDesc={true}
                                    />
                                </Col>
                            ) : null}
                            {isEditable || TRED ? (
                                <Col span={24}>
                                    <XtensibleGallery
                                        isEditable={isEditable}
                                        contents={getMediaTRED()}
                                        onAddItemCallback={full_showcase_edit ? onAddItemCallback3D : null}
                                        onDeleteItemCallBack={full_showcase_edit ? onDeleteItemCallBack3D : null}
                                        onVieItemCallBack={full_showcase_edit ? onVieItemCallBack3D : null}
                                        title={t('3D')}
                                        idPrefix={item.id}
                                        titleVisible={true}
                                        titleDesc={true}
                                    />
                                </Col>
                            ) : null}
                            {!isEditable && !isChamberUser ? (
                                <Col className="askEvent" span={24}>
                                    {contactButton}
                                </Col>
                            ) : null}
                        </Row>
                    </Col>
                </Row>
            ) : null}
        </>
    );
};
export default ItemShowCase;
