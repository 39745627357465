import expoConfig from '../config/expo_config.json';

const getAutorizations = (expoUser) => {
    let isChamberUser = expoUser && expoUser.entityTypeId === expoConfig.domain.entity_type.chamber;
    let toReturn = {};
    if (expoUser && expoUser.auth) {
        //Authorizations
        toReturn = {
            view_companies_showcase:
                isChamberUser ||
                (expoUser.auth &&
                    expoUser.auth.filter((p) => p.functionId === expoConfig.domain.function.view_companies_showcase)
                        .length > 0),
            b2b_request:
                isChamberUser ||
                (expoUser.auth &&
                    expoUser.auth.filter((p) => p.functionId === expoConfig.domain.function.b2b_request).length > 0),
            basic_showcase_edit:
                isChamberUser ||
                (expoUser.auth &&
                    expoUser.auth.filter((p) => p.functionId === expoConfig.domain.function.basic_showcase_edit)
                        .length > 0),
            business_matching_score:
                isChamberUser ||
                (expoUser.auth &&
                    expoUser.auth.filter((p) => p.functionId === expoConfig.domain.function.business_matching_score)
                        .length > 0),
            view_products_showcase:
                isChamberUser ||
                (expoUser.auth &&
                    expoUser.auth.filter((p) => p.functionId === expoConfig.domain.function.view_products_showcase)
                        .length > 0),
            full_showcase_edit:
                isChamberUser ||
                (expoUser.auth &&
                    expoUser.auth.filter((p) => p.functionId === expoConfig.domain.function.full_showcase_edit).length >
                        0),
            contact_companies:
                isChamberUser ||
                (expoUser.auth &&
                    expoUser.auth.filter((p) => p.functionId === expoConfig.domain.function.contact_companies).length >
                        0),
        };
    } else {
        toReturn = {
            view_companies_showcase: isChamberUser,
            b2b_request: isChamberUser,
            basic_showcase_edit: isChamberUser,
            business_matching_score: isChamberUser,
            view_products_showcase: isChamberUser,
            full_showcase_edit: isChamberUser,
            contact_companies: isChamberUser,
        };
    }
    return toReturn;
};

export default {
    getAutorizations,
};
