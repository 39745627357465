const Layout = {
    TILED: 'tiled',
    BORDER: 'border',
    MOBILE_H: 'mobileH',
    MOBILE_V: 'mobileV',
    OUT: 'out',
};

class EventLayout {
    static get LAYOUTS() {
        return Layout;
    }
}

export default EventLayout;
