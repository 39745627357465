import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Collapse, Divider, Row, Spin, notification, Badge, Space } from 'antd';
import { Label } from 'semantic-ui-react';
import { Loading3QuartersOutlined, CaretRightOutlined } from '@ant-design/icons';
import { ReactComponent as CheckApproved } from '../icons/salva.svg';
import { ReactComponent as Pending } from '../icons/pending2.svg';
import { ReactComponent as User } from '../icons/utente.svg';
import { ReactComponent as Edit } from '../icons/desc.svg';
import { ReactComponent as SuspendCompany } from '../icons/sospese.svg';
import { ReactComponent as Draft } from '../icons/draft.svg';
import { ReactComponent as NewEvent } from '../icons/new-event.svg';
import { ReactComponent as IconRequestB2B } from '../icons/requestb2b.svg';
import { ReactComponent as ApproveButton } from '../icons/check.svg';
import IconAndLabel from './IconAndLabel';
import expoClient from '../expoClient';
import './CompaniesAcceptance.css';
import ConfirmModal from './ConfirmModal';
import { useAuth0 } from '../react-auth0-spa';
import expoConfig from '../config/expo_config.json';
import { Link } from 'react-router-dom';
import TopBar from './TopBar';

const CompaniesAcceptance = ({ location }) => {
    const { t } = useTranslation();
    const [companies, setCompanies] = useState(null);
    const [submittingData, setSubmittingData] = useState(false);
    const { isAuthenticated, expoUser, loading } = useAuth0();
    const [chamber, setChamber] = useState();
    const { Panel } = Collapse;
    const [currentDataPage, setCurrentDataPage] = useState(-1);
    const [displayDataPage, setDisplayDataPage] = useState('none');
    const [contactRequestsNumber, setContactRequestsNumber] = useState(1);
    const [unviewedContactRequests, setUnviewedContactRequests] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    useEffect(() => {
        const init = async () => {
            const isChamber = expoUser.entityTypeId === expoConfig.domain.entity_type.chamber;
            //Per qualche motivo non ho camber in expoUser la rileggo dal DB
            if (isChamber) {
                //sono utente camera recupero l'info dall'entityid dello user
                const chamberRes = await expoClient.chambers.get(expoUser.entityId, expoUser.token);
                expoUser.chamber = chamberRes.data;
            }

            if (expoUser.chamber) {
                //expoUser ha tutte le info non serve ricavarsele
                //Dato che gli eventi sono editabili li rileggo ogni volta dal server
                setChamber(expoUser.chamber);

                loadCompanies();
                if (!expoUser.chamber.items) {
                    const counters = await expoClient.chambers.countCompanies(expoUser.chamber.id, expoUser.token);
                    expoUser.chamber.pendingCounter = counters.data.pending;
                    expoUser.chamber.suspendedCounter = counters.data.suspended;
                    expoUser.chamber.draftedCounter = counters.data.active;
                }
                const requestCount = await expoClient.chambers.getContactRequestsCount(
                    expoUser.chamber.id,
                    expoUser.token,
                );
                setUnviewedContactRequests(requestCount.data.someRequested);
                setContactRequestsNumber(requestCount.data.num);
            }
            setPageLoading(false);
        };
        if (!loading && isAuthenticated && expoUser && companies === null) {
            init();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submittingData, isAuthenticated, expoUser, loading]);

    const loadCompanies = async () => {
        const cListRes = await expoClient.chambers.getPendingCompanies(expoUser.chamber.id, expoUser.token);
        const companies = cListRes.data;
        console.log('CompaniesAcceptance.useEffect ', companies);
        //Per via delle operazioni asyncrone sono costretto ad eseguire due volte il ciclo for
        for (const c of companies) {
            const { data } = await expoClient.chambers.getTags(expoUser.chamber.id, c.id, expoUser.token);
            c.tags = data;
        }
        for (const c of companies) {
            const { data } = await expoClient.user.getByEntityId(c.entityId, expoUser.token);
            c.user = data[0];
        }

        setCompanies(companies);
    };

    const postData = async (company) => {
        setSubmittingData(true);
        //chiamare Expoclient accept
        //se tutto ok
        const c = company;
        const acceptRes = await expoClient.chambers.acceptCompany(chamber.id, company.id, expoUser.token);
        if (typeof acceptRes !== 'undefined') {
            //console.log("Risposta rest acceptCompany", acceptRes);
            if ((c.approved = acceptRes.status === 200)) {
                const desc = t('company_approved_succes');
                notification.success({
                    key: 'approved',
                    message: desc,
                    placement: 'topRight',
                    duration: 7,
                });
                setSubmittingData(false);
                loadCompanies();
            }
        }
    };

    const hide = async (company) => {
        //chiamare Expoclient accept
        //se tutto ok
        try {
            const hideRes = await expoClient.chambers.archiveCompany(chamber.id, company.id, expoUser.token);
            if (typeof hideRes !== 'undefined') {
                //console.log("Risposta rest acceptCompany", acceptRes);
                if (hideRes.status === 200) {
                    const desc = t('request_archived_ok');
                    notification.success({
                        key: 'archived',
                        message: desc,
                        placement: 'topRight',
                        duration: 7,
                    });
                    loadCompanies();
                }
            }
        } catch (error) {
            console.error(error);
            const msg = t('error');
            let desc = t('request_archived_nok');
            notification.error({
                key: 'archivedError',
                message: msg,
                description: desc,
                placement: 'topRight',
                duration: 0,
            });
        }
    };

    const handleOk = (e) => {
        //console.log("handleOK", e);
        postData(e);
    };

    const handleArchivia = (e) => {
        //console.log("handleOK", e);
        hide(e);
    };

    const transitToDataPageFromToolBar = (e) => {
        transitToDataPage(e, -1);
    };

    const transitToDataPage = (e, pageToGo) => {
        if (e) e.stopPropagation();
        if (pageToGo === currentDataPage || pageToGo < 0) {
            setDisplayDataPage('none');
            setCurrentDataPage(-1);
        } else {
            setDisplayDataPage('block');
            setCurrentDataPage(pageToGo);
        }
    };
    /*  const printAddress = e => {
        let address = e.addressVenue + ', ' + e.addressCity + ', ';
        address += e.zipCode ? e.zipCode + ', ' : '';
        address += e.province ? e.province + ', ' : '';
        address += e.country.name;
        return address;
    }*/
    const cards = [];
    if (companies) {
        for (const e of companies) {
            console.log('Company ', e);
            cards.push(
                <div key={e.id} className="contain-company-acceptance">
                    <div className="company-name">
                        {e.name}
                        <Divider className="divider-company" style={{ margin: '5px 0px 10px' }} />
                    </div>
                    <div className="company_description_header">
                        <Row align="middle">
                            <Col
                                xl={{ span: 16 }}
                                xs={{ span: 24 }}
                                lg={{ span: 16 }}
                                sm={{ span: 16 }}
                                style={{ color: '#000000', fontSize: '16px' }}
                            >
                                {/* 
                                <h2 className="panding_company_name">
                                    <strong>{e.name}</strong>
                                </h2> */}
                                <div>
                                    {e.addressVenue} {'-'} {e.zipCode ? e.zipCode : null}
                                </div>
                                <div>
                                    {e.addressCity}
                                    {', '} {e.province ? e.province : null} {' - '} {e.country.name}
                                </div>
                                <div>
                                    <strong>
                                        {t('P.iva')}
                                        {':'}
                                    </strong>{' '}
                                    {e.vatNumber}
                                </div>
                                <div>
                                    <strong>
                                        {t('phone')}
                                        {':'}
                                    </strong>{' '}
                                    {e.phone}
                                </div>
                                <div>
                                    {e.website ? (
                                        <span>
                                            <strong>
                                                {t('website')}
                                                {':'}
                                            </strong>{' '}
                                            <a className="link_website" href={e.website} target="_blank">
                                                {' '}
                                                <u>{e.website}</u>
                                            </a>
                                        </span>
                                    ) : null}
                                </div>
                            </Col>
                            {e.approved ? (
                                <Col
                                    xl={{ span: 5, offset: 3 }}
                                    xs={{ span: 24 }}
                                    lg={{ span: 6, offset: 2 }}
                                    sm={{ span: 6, offset: 2 }}
                                    align="center"
                                >
                                    <div className="contain-text-icon" style={{ color: '#159A2E' }}>
                                        <CheckApproved
                                            width="65px"
                                            height="65px"
                                            stroke="#159A2E"
                                            fill="#159A2E"
                                            strokeWidth="1"
                                        />{' '}
                                        {t('approve_company_label_approved')}
                                    </div>
                                </Col>
                            ) : (
                                <Col
                                    xl={{ span: 5, offset: 3 }}
                                    xs={{ span: 24 }}
                                    lg={{ span: 6, offset: 2 }}
                                    sm={{ span: 6, offset: 2 }}
                                    align="center"
                                >
                                    <div className="contain-text-icon" style={{ color: '#E37B44' }}>
                                        <Pending
                                            width="65px"
                                            height="65px"
                                            stroke="#E37B44"
                                            fill="#E37B44"
                                            strokeWidth="1"
                                        />
                                        {t('chamber_pending')}
                                    </div>
                                </Col>
                            )}
                            {/* {printAddress(e)}*/}
                        </Row>
                    </div>

                    <Collapse
                        bordered={false}
                        defaultActiveKey={['1']}
                        expandIcon={({ isActive }) => (
                            <CaretRightOutlined className="icon-arrow" rotate={isActive ? 90 : 0} />
                        )}
                        className="collapse-card-approvazione"
                    >
                        <Panel header={t('button_explore_expo')} id={e.id + '_panel'}>
                            <div className="company_description_label">
                                <Label className="label-info">
                                    <User className="icon-card-info-company" />
                                    {t('administrator')}
                                </Label>
                            </div>

                            <div className="company_description">
                                <div>
                                    <strong>
                                        {e.user.user.name} {e.user.user.surname}
                                    </strong>
                                </div>
                                <div>{e.email}</div>
                            </div>

                            <div className="company_description_label">
                                <Label className="label-info">
                                    <Edit className="icon-card-info-company" />
                                    <strong>{t('description')}</strong>
                                </Label>
                            </div>

                            <div className="company_description">
                                {/* <Col span={24}>
                                    {e.createdAt ? (<Row gutter={[10, 0]} style={{ paddingBottom: "10px" }}>
                                        <Label className="label-info">
                                            {t('approve_company_label_registration_date')}
                                        </Label>
                                        <div className="card_row">
                                            {moment(e.createdAt).format('DD.MM.YY')}
                                        </div>
                                         </Row>) : null}
                                </Col> */}

                                {/*} {e.province ? (<Row gutter={[8, 0]} style={{ paddingBottom: "10px" }}>
                                        <div className="card_row">

                                    </div>
                                    </Row>) : null} */}

                                {/*} <Row gutter={[8, 0]} style={{ paddingBottom: "10px" }}>
                                        <div className="card_row">
                                            {e.user.email}
                                        </div>
                                    </Row> */}

                                {e.description}

                                <Row>
                                    <Col span="24" align="start" style={{ marginTop: '20px' }}>
                                        {/*<Space style={{display: 'block'}} align="start" size="middle"> */}
                                        {e.tags &&
                                            e.tags.map((tag, index) => (
                                                <Label className="expo_tag" key={tag.id}>
                                                    {t(`${tag.name}`)}
                                                </Label>
                                            ))}
                                        {/*} </Space>*/}
                                    </Col>
                                </Row>
                            </div>
                        </Panel>
                    </Collapse>
                    <div
                        className="approve_button"
                        style={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
                    >
                        <Row justify="center">
                            <Col className="two-button-distance">
                                <ConfirmModal
                                    message={
                                        <div>
                                            <p>{t('request_archive_company_confirm_text')}</p>
                                        </div>
                                    }
                                    onOk={() => handleArchivia(e)}
                                >
                                    <Button block shape="round" className="blackMode" htmlType="button">
                                        {t('button_archives_request_b2b').toUpperCase()}
                                    </Button>
                                </ConfirmModal>
                            </Col>
                            <Col className="two-button-distance">
                                <ConfirmModal
                                    message={
                                        <div>
                                            <p>{t('company_approval_confirm_text')}</p>
                                            <p>
                                                <b>{e ? e.name : ''}</b>
                                            </p>
                                        </div>
                                    }
                                    onOk={() => handleOk(e)}
                                >
                                    {!e.approved ? (
                                        <Button block shape="round" className="blackMode" htmlType="button">
                                            {t('button_approve').toUpperCase()}
                                        </Button>
                                    ) : (
                                        <Button block shape="round" htmlType="button" disabled className="blackMode">
                                            {t('approve_company_label_approved')}
                                        </Button>
                                    )}
                                </ConfirmModal>
                            </Col>
                        </Row>
                    </div>
                </div>,
            );
        }
    }

    if (pageLoading) {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Space direction="horizontal">
                    <Spin size="large" />
                    <h1>{t('label_loading')}</h1>
                </Space>
            </div>
        );
    }

    return companies ? (
        <>
            <TopBar arrowsVisible={false} onClickCallBack={transitToDataPageFromToolBar}>
                <div className="blackLink topBarIconCol">
                    <Link to={{ pathname: '/eventinsert' }} style={{ display: 'flex' }}>
                        <IconAndLabel label={t('chamber_create_event').toUpperCase()}>
                            <NewEvent className="dataIcons" style={{ width: '30px' }} />
                        </IconAndLabel>
                    </Link>
                </div>

                <div className="blackLink topBarIconCol">
                    <Link
                        to={{ pathname: '/companiesacceptance' }}
                        style={{ display: 'flex' }}
                        className="icon-selected"
                    >
                        <IconAndLabel label={t('button_approve').toUpperCase()}>
                            {expoUser.chamber.pendingCounter > 0 ? (
                                <Badge className="badge_request_b2b button_request_badge" status="Success">
                                    <ApproveButton style={{ width: '30px' }} />
                                </Badge>
                            ) : (
                                <ApproveButton style={{ width: '30px' }} />
                            )}
                        </IconAndLabel>
                    </Link>
                </div>
                <div className="blackLink topBarIconCol">
                    <Link to={{ pathname: '/requestb2b' }} style={{ display: 'flex' }}>
                        <IconAndLabel label={t('request_b2b').toUpperCase()}>
                            {unviewedContactRequests ? (
                                <Badge className="badge_request_b2b button_request_badge" status="processing">
                                    <IconRequestB2B className="dataIcons" style={{ width: '30px' }} />
                                </Badge>
                            ) : (
                                <IconRequestB2B className="dataIcons" style={{ width: '30px' }} />
                            )}
                        </IconAndLabel>
                    </Link>
                </div>
                <div className="blackLink topBarIconCol">
                    <Link to={{ pathname: '/companiesdrafted' }} style={{ display: 'flex' }}>
                        <IconAndLabel label={t('drafted_company').toUpperCase()}>
                            <Draft className="dataIcons" style={{ width: '30px' }} />
                        </IconAndLabel>
                    </Link>
                </div>
                <div className="blackLink topBarIconCol">
                    <Link to={{ pathname: '/companiesuspended' }} style={{ display: 'flex' }}>
                        <IconAndLabel label={t('suspended').toUpperCase()}>
                            <SuspendCompany className="dataIcons" style={{ width: '30px' }} />
                        </IconAndLabel>
                    </Link>
                </div>
            </TopBar>
            <Spin
                spinning={submittingData}
                indicator={<Loading3QuartersOutlined spin style={{ fontSize: '100px', color: '#000' }} />}
            >
                <div className="section-shadow">
                    <Row className="company_acceptance card_container">
                        <Col span="24">
                            <Row key="1" gutter={[8, 24]} justify="center">
                                <Col xl={{ span: 12 }} xs={{ span: 24 }} lg={{ span: 16 }} sm={{ span: 20 }}>
                                    <Row gutter={[8, 24]}>
                                        {companies.length > 0 ? (
                                            <Col span="24">{cards}</Col>
                                        ) : (
                                            <Col span="24">
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <div className="b2bLeave-panel">
                                                        <div className="b2bLeave-image">
                                                            {expoUser && expoUser.chamber ? (
                                                                <img
                                                                    style={{ width: '100%' }}
                                                                    alt="camera cover"
                                                                    src={expoUser.chamber.bannerPath}
                                                                />
                                                            ) : null}
                                                        </div>
                                                        <div
                                                            className="b2bLeave-text"
                                                            style={{ flexDirection: 'column' }}
                                                        >
                                                            <p
                                                                style={{
                                                                    fontSize: '24px',
                                                                    color: '#262626',
                                                                    padding: '0px 40px',
                                                                }}
                                                            >
                                                                <b>{t('label_empty_list_companies')}</b>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Spin>
        </>
    ) : null;
};
export default CompaniesAcceptance;
