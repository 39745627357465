import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';

import { useTranslation } from 'react-i18next';
import { useAuth0 } from '../react-auth0-spa';
import locationUtil from '../utils/location';
import expoConfig from '../config/expo_config.json';
import expoClient from '../expoClient';
import './Footer.css';

const Footer = () => {
    const { isAuthenticated, expoUser, loading } = useAuth0();
    const { t } = useTranslation();
    const [chamber, setChamber] = useState(null);

    useEffect(() => {
        const init = async () => {
            const subd = locationUtil.detectSubdomain();
            if (isAuthenticated && expoUser) {
                //sono autenticato
                if (expoUser.entityTypeId === expoConfig.domain.entity_type.chamber) {
                    //console.log("Footer per utente camera autenticato", expoUser.chamber, subdomain);
                    if (expoUser.chamber) {
                        setChamber(expoUser.chamber);
                    } else {
                        const cRes = await expoClient.chambers.get(expoUser.entityId, expoUser.token);
                        setChamber(cRes.data);
                    }
                }
                if (
                    expoUser.entityTypeId === expoConfig.domain.entity_type.c_ita ||
                    expoUser.entityTypeId === expoConfig.domain.entity_type.c_other
                ) {
                    //console.log("Footer per utente azienda autenticato", expoUser.chamber, subd);
                    if (expoUser.chamber) {
                        setChamber(expoUser.chamber);
                    } else if (subd) {
                        const companyRes = await expoClient.company.get(expoUser.entityId, expoUser.token);
                        const company = companyRes.data;
                        const chambersRes = await expoClient.company.getChambers(company.id, expoUser.token);
                        //Verifico se la camera definita nel subdomain è fra quelle di competenza
                        const selChambers = chambersRes.data.filter((c) => c.subdomain === subd);
                        if (selChambers) {
                            setChamber(selChambers[0]);
                        }
                    } else {
                        console.log('Footer no chamber detected ... not good');
                    }
                }
            } else {
                //verifico eventuale sottodominio
                //console.log("Footer pagina pubblica (NoAuth)", subd);
                const subdomainRes = await expoClient.chambers.getBySubdomain(subd);
                if (subdomainRes.data && subdomainRes.data.length === 1) {
                    setChamber(subdomainRes.data[0]);
                } else {
                    console.log('Footer no chamber detected ... not good');
                }
            }
        };

        if (!loading) {
            init();
        }
    }, [isAuthenticated, expoUser, loading]);

    const render = () => {
        return chamber ? (
            <>
                <div style={{ backgroundColor: '#e8e8e8', height: '100px' }}></div>
                <Row id="expoFooter" align="middle" className="expo-footer">
                    <Col offset={1} span={4} align="center">
                        <img src={chamber.logoPath} className="chamber-logo" alt="logo_icham" />
                    </Col>
                    <Col
                        xl={{ span: 5, offset: 13 }}
                        md={{ span: 6, offset: 12 }}
                        xs={{ span: 8, offset: 10 }}
                        className="chamber-info"
                    >
                        <p>
                            <strong>{`${chamber.name}`}</strong>
                        </p>
                        <p>{`${chamber.addressVenue}`}</p>
                        <p>
                            <strong>{t('telephone')}</strong> {`${chamber.phone}`}{' '}
                        </p>
                        <a
                            href="https://www.exportlounge.com/privacypolicy"
                            rel="noopener noreferrer"
                            className="footer-privacy"
                            target="_blank"
                        >
                            {' '}
                            {t('privacy_policy')}{' '}
                        </a>
                    </Col>
                </Row>
            </>
        ) : (
            <Row align="middle" className="expo-footer">
                <Col span={24}></Col>
            </Row>
        );
    };

    //Se finito il processo di autenticazione
    return !loading ? render(chamber) : null;
};
export default Footer;
