import { DefaultModality } from 'amazon-chime-sdk-js';
import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import getChimeContext from '../../context/getChimeContext';
import getMeetingStatusContext from '../../context/getMeetingStatusContext';
import './ContentVideo.css';

ContentVideo.propTypes = {
    onContentShareEnabled: PropTypes.func.isRequired,
    onStopSelfContentShare: PropTypes.func.isRequired,
};

export default function ContentVideo(props) {
    let { b2bInfo } = useContext(getMeetingStatusContext());
    const { onContentShareEnabled, onStopSelfContentShare, myShareVideo } = props;
    const [myTileId, setMyTileId] = useState(null);
    const [enabled, setEnabled] = useState(false);
    const [shareUser, setShareUser] = useState(null);

    const chime = useContext(getChimeContext());
    const videoElement = useRef(null);

    // Note that this useEffect takes no dependency (an empty array [] as a second argument).
    // Thus, calling props.onContentShareEnabled in the passed functon will point to an old prop
    // even if a parent component passes a new prop. See comments for the second useEffect.
    useEffect(() => {
        const contentTileIds = new Set();
        if (chime && chime.audioVideo) {
            chime.audioVideo.addObserver({
                videoTileDidUpdate: (tileState) => {
                    if (!tileState.boundAttendeeId || !tileState.isContent || !tileState.tileId) {
                        return;
                    }

                    const modality = new DefaultModality(tileState.boundAttendeeId);
                    //console.log('ContentVideo.videoTileDidUpdate modality', modality);
                    if (
                        modality.base() === chime.meetingSession.configuration.credentials.attendeeId &&
                        modality.hasModality(DefaultModality.MODALITY_CONTENT)
                    ) {
                        //L'utente stesso sta condividendo mi salvo il tileId per poi gestire la chiusura dello share
                        setMyTileId(tileState.tileId);
                        chime.audioVideo.bindVideoElement(tileState.tileId, myShareVideo.current);
                        return;
                    }

                    chime.audioVideo.bindVideoElement(tileState.tileId, videoElement.current);
                    if (tileState.active) {
                        const shareUser = b2bInfo.attendees.find(
                            (attendee) => attendee.token === tileState.boundExternalUserId,
                        );
                        contentTileIds.add(tileState.tileId);
                        setShareUser(shareUser);
                        setEnabled(true);
                    } else {
                        contentTileIds.delete(tileState.tileId);
                        if (contentTileIds.size === 0) setShareUser(null);
                        setEnabled(contentTileIds.size > 0);
                    }
                },
                videoTileWasRemoved: (tileId) => {
                    if (contentTileIds.has(tileId)) {
                        contentTileIds.delete(tileId);
                    }
                    if (contentTileIds.size === 0) setShareUser(null);
                    setEnabled(contentTileIds.size > 0);
                },
            });
        }
    }, []);

    // Per notificare il termine della mia condivisione al bottone di share
    // devo richiamare onStopSelfContentShare
    useEffect(() => {
        if (chime && chime.audioVideo && myTileId !== null) {
            chime.audioVideo.addContentShareObserver({
                contentShareDidStop: () => {
                    onStopSelfContentShare();
                    setMyTileId(null);
                },
            });
        }
    }, [myTileId]);

    // Call props.onContentShareEnabled in this useEffect. Also, this useEffect does not depend on
    // props.onContentShareEnabled to avoid an unnecessary execution. Whenever the function
    // is invoked per enabled change, it will reference the latest onContentShareEnabled.
    useEffect(() => {
        console.log('ContentVideo.useEffect enabled', enabled);
        onContentShareEnabled(enabled, shareUser);
    }, [enabled]);

    return (
        <div className="contentVideo">
            <video muted ref={videoElement} className="video" />
        </div>
    );
}
