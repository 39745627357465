import _ from 'lodash';
import React, { Component } from 'react';
import { Form, Input, Row, Col, notification, Button, Divider } from 'antd';
import { Label } from 'semantic-ui-react';

import { withTranslation } from 'react-i18next';

import expoConfig from '../config/expo_config.json';
import expoClient from '../expoClient';
import UserEditor from './UserEditor.js';
import { Modal } from 'semantic-ui-react';
import './CompanyUsers.css';

import { ReactComponent as AddMemberIcon } from '../icons/addmember.svg';
import { ReactComponent as ModifyMemberIcon } from '../icons/modifymember.svg';
import { ReactComponent as CloseButton } from '../icons/close.svg';
import ConfirmModal from './ConfirmModal';
import Analytics from './Analytics';

class ChamberUserSlider extends Component {
    constructor(props) {
        super(props);
        const { t } = this.props;
        this.state = {
            chamber: props.chamber,
            expouser: props.expouser,
            chamberUsers: props.chamberUsers,
            toShow: props.toShow,
            isEditable: props.isEditable,
            isChamber: props.expouser.entityTypeId === expoConfig.domain.entity_type.chamber,
            disableModifyMySelfButton: true,
            // entity lette dalle API
            countries: [],
            profiles: [
                { id: expoConfig.domain.role.admin, name: 'admin' },
                { id: expoConfig.domain.role.standard, name: 'standard' },
            ],
            showUserEditor: null,
            userToEdit: false,
            userData: {
                // used to populate "account" reducer when "Load" is clicked
                contactname: props.expouser.user.name,
                contactsurname: props.expouser.user.surname,
                contactbusinessrole: props.expouser.businessRole,
                contactprofile: this.fromNumberToStringProfile(props.expouser.roleId),
                contactemail: props.expouser.user.email,
            },
            userEditorTitle: t('user_data_insert_label'),
        };

        //Serve per aggiornare questo flag dai componenti figli
        this.handleDisableSubmit = this.handleDisableSubmit.bind(this);
        this.mySelfformRef = React.createRef();
        this.postMySelfData = this.postMySelfData.bind(this);
        this.postData = this.postData.bind(this);
        //Detect sottodominio
    }

    handleDisableSubmit(disableSubmit) {
        this.setState({
            disableSubmit: disableSubmit,
        });
    }

    componentDidMount() {
        expoClient.countries
            .get()
            .then((res) => {
                this.setState({ countries: res.data });
            })
            .catch((err) => console.info('getCoutries: ' + err));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.toShow !== this.props.toShow) {
            this.setState({ toShow: this.props.toShow });
        }
        if (prevProps.chamber !== this.props.chamber) {
            let chamberNew = Object.assign({}, this.props.chamber);
            this.setState({ chamber: chamberNew });
        }
        if (prevProps.expouser !== this.props.expouser) {
            this.setState({ expouser: this.props.expouser });
            this.setState({
                userData: {
                    contactname: this.props.expouser.user.name,
                    contactsurname: this.props.expouser.user.surname,
                    contactbusinessrole: this.props.expouser.businessRole,
                    contactprofile: this.fromNumberToStringProfile(this.props.expouser.roleId),
                    contactemail: this.props.expouser.user.email,
                },
            });
        }
        if (prevProps.chamberUsers !== this.props.chamberUsers) {
            this.setState({ chamberUsers: this.props.chamberUsers });
        }
    }

    postMySelfData = async function (values) {
        const { t } = this.props;
        this.setState({ submittingData: true });
        const userData = {
            businessRole: values.contactbusinessrole,
            roleId: parseInt(this.fromStringToNumberProfile(values.contactprofile)),
            user: {
                name: values.contactname,
                surname: values.contactsurname,
                email: this.state.expouser.user.email,
            },
        };
        try {
            await expoClient.user.modify(this.state.expouser.user.id, userData, this.state.expouser.token);
            const desc = t('modify_user_ok');
            notification.success({
                key: 'postputUser',
                message: desc,
                placement: 'topRight',
                duration: 7,
            });
            this.refreshMyUser(values);
        } catch (error) {
            console.error(error);
            const msg = _.capitalize(t('error'));
            let desc = t('modify_user_nok');
            notification.error({
                key: 'postRegistrationError',
                message: msg,
                description: desc,
                placement: 'topRight',
                duration: 0,
            });
        } finally {
            this.setState({ submittingData: false });
            this.props.callBackForModifyUserData();
        }
    };

    postData = async function (values) {
        const { t } = this.props;
        this.setState({ submittingData: true });
        const c = this.state.chamber;
        c.vatNumber = values.chambervatnumber;
        c.name = values.chambername;
        c.addressVenue = values.chambervenue;
        c.zipCode = values.chamberzipcode;
        c.province = values.chamberprovince;
        c.addressCity = values.chambercity;
        c.website = values.chamberwebsite;
        c.phone = values.chamberphone;
        c.countryId = parseInt(this.state.chamber.countryId);
        c.description = this.state.chamber.description;
        c.email = this.state.chamber.email;
        this.setState({ chamber: c });

        try {
            await expoClient.chambers.modify(this.state.chamber.id, this.state.chamber, this.state.expouser.token);
            let desc = t('modify_data_ok');
            notification.success({
                key: 'postputUser',
                message: desc,
                placement: 'topRight',
                duration: 7,
            });
        } catch (error) {
            console.error(error);
            const msg = _.capitalize(t('error'));
            let desc = t('modify_data_nok');
            notification.error({
                key: 'postRegistrationError',
                message: msg,
                description: desc,
                placement: 'topRight',
                duration: 0,
            });
        } finally {
            this.setState({ submittingData: false });
        }
    };

    refreshMyUser(values) {
        this.setState({
            userData: {
                // used to populate "account" reducer when "Load" is clicked
                contactname: values.contactname,
                contactsurname: values.contactsurname,
                contactbusinessrole: values.contactbusinessrole,
                contactprofile: values.contactprofile,
                contactemail: this.state.expouser.user.email,
            },
        });
    }

    fromNumberToStringProfile(roleId) {
        const { t } = this.props;

        switch (roleId) {
            case expoConfig.domain.role.admin:
                return t('user_data_role_admin');
            case expoConfig.domain.role.standard:
                return t('user_data_role_standard');
            default:
                return t('user_data_role_unknown');
        }
    }

    fromStringToNumberProfile(roleName) {
        const { t } = this.props;

        switch (roleName) {
            case t('user_data_role_admin'):
                return expoConfig.domain.role.admin;
            case t('user_data_role_standard'):
                return expoConfig.domain.role.standard;
            default:
                return expoConfig.domain.role.standard;
        }
    }

    renderUser(toRender) {
        const businessRole = toRender.businessRole ? toRender.businessRole + ' - ' : null;
        const role =
            toRender.roleId === expoConfig.domain.role.admin
                ? '(' + this.fromNumberToStringProfile(toRender.roleId).toLowerCase() + ')'
                : null;
        const buttonModify =
            this.state.expouser.user.id !== toRender.user.id &&
            this.state.expouser.roleId === expoConfig.domain.role.admin &&
            this.state.isEditable ? (
                <Col span={1} offset={2}>
                    <ModifyMemberIcon
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '10px',
                            width: '25px',
                            cursor: 'pointer',
                        }}
                        onClick={() => this.clickedOnModify(toRender)}
                    ></ModifyMemberIcon>
                </Col>
            ) : null;
        return (
            <Row key={toRender.user.email} style={{ width: '100%', marginTop: '10px' }}>
                <Col span={21}>
                    <Row style={{ textAlign: 'initial' }}>
                        <Col span={24}>
                            <span className="charFontFamily nameandsurname">
                                {toRender.user.name} {toRender.user.surname} {role}
                            </span>
                        </Col>
                    </Row>
                    <Row justify="initial" style={{ textAlign: 'initial' }}>
                        <Col span={24}>
                            <span className="charFontFamily mailandrole">
                                {businessRole}
                                {toRender.user.email}
                            </span>
                        </Col>
                    </Row>
                </Col>
                {buttonModify}
            </Row>
        );
    }

    clickedOnAdd() {
        const { t } = this.props;
        this.setState({ userToEdit: null });
        this.setState({ showUserEditor: true });
        this.setState({ userEditorTitle: t('user_data_insert_label') });
    }

    clickedOnModify(chosenUser) {
        const { t } = this.props;
        this.setState({ userToEdit: chosenUser });
        this.setState({ showUserEditor: true });
        this.setState({ userEditorTitle: t('user_data_modify_label') });
    }

    setShowModalUserEditor(valore) {
        this.setState({ showUserEditor: valore });
    }

    closeShowUserModal() {
        this.setState({ showUserEditor: false });
    }

    render() {
        const { t } = this.props;

        const closeUserModal = (
            <ConfirmModal onOk={() => this.closeShowUserModal()} message={t('user_data_ask_for_close')}>
                <CloseButton className="exitButtonOnLoading" />
            </ConfirmModal>
        );

        /* 
                const RenderProfile = ({ data }) => {
                    return (
                        <Row>
                            <Col className="charFontFamily" span="16" style={{ textAlign: 'left' }}>{t(`user_data_role_${data.name}`)}</Col>
                        </Row>
                    )
                }; */

        /*         const currentProfile = this.state.chamber.country ? <Row>
                    <Col span="16" style={{ textAlign: 'left' }}>{this.fromNumberToStringProfile(this.state.expouser.roleId)}</Col>
                </Row> : ""; */

        const profileObject = (
            /*           this.state.isEditable ?
                      <Col span={24}>
                          <Form.Item name="contactprofile" >
                              <Select defaultValue={currentProfile} allowClear bordered={false} style={{ width: '100%' }}
                                  size="large"
                              >
                                  {profiles.map(c => <Option key={c.id} value={c.id.toString()}><RenderProfile data={c} /></Option>)}
                              </Select>
                          </Form.Item> </Col> : */

            <Col span={24}>
                <Label className="userSliderLabel charFontFamily">{t('company_contact_profile')}</Label>
                <Form.Item name="contactprofile">
                    <Input
                        disabled={true}
                        style={{ background: '#fff 0% 0% no-repeat padding-box', color: 'black' }}
                        size="large"
                    />
                </Form.Item>
            </Col>
        );

        const visibleSecond = this.props.toShow === 2;
        const visibleFirst = this.props.toShow === 1;
        const visibleThird = this.props.toShow === 3;

        const chamberData = {
            chambervatnumber: this.state.chamber.vatNumber,
            chambername: this.state.chamber.name,
            chambervenue: this.state.chamber.addressVenue,
            chamberzipcode: this.state.chamber.zipCode,
            chamberprovince: this.state.chamber.province,
            chambercity: this.state.chamber.addressCity,
            chamberwebsite: this.state.chamber.website,
            chamberphone: this.state.chamber.phone,
            chambercountry: this.state.chamber.countryId.toString(),
            chambercountrycode:
                t(`country_${this.state.chamber.country.code}`) + ' ' + '(' + this.state.chamber.country.code + ')',
            chamberdescription: this.state.chamber.description,
            chambercontactemail: this.state.chamber.email,
        };

        return (
            <Row className="userSliderContainer">
                <Modal
                    key="modalUserEditor"
                    open={this.state.showUserEditor}
                    onClose={() => this.closeShowUserModal()}
                    trigger={null}
                    header={null}
                    actions={null}
                    closeOnDimmerClick={false}
                    closeOnEscape={false}
                    closeOnDocumentClick={false}
                    closeOnTriggerMouseLeave={false}
                    closeOnTriggerClick={false}
                    className="modalUserEditor"
                >
                    <Row>
                        <Col
                            style={{
                                width: '100%',
                                height: '80px',
                                backgroundColor: '#111111',
                                color: 'white',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <div className="companyDataTitle" style={{ color: 'white' }}>
                                {this.state.userEditorTitle}
                            </div>
                        </Col>

                        <Col xl={{ span: 12, offset: 6 }} xs={{ span: 20, offset: 2 }}>
                            <UserEditor
                                parentInstance={this}
                                profiles={this.state.profiles}
                                currentUser={this.state.userToEdit}
                                expouser={this.state.expouser}
                                callBackForModifyUserData={this.props.callBackForModifyUserData}
                            />
                        </Col>
                        {closeUserModal}
                    </Row>
                </Modal>
                <Row style={{ width: '100%', margin: '50px 0px' }}>
                    <Col span={24}>
                        {visibleSecond ? (
                            <div>
                                <Row>
                                    <Col span="24">
                                        <Row>
                                            <Col span="24">
                                                <Row justify="center">
                                                    <Col
                                                        xl={{ span: 7, offset: 0 }}
                                                        lg={{ span: 20, offset: 0 }}
                                                        xs={{ span: 20, offset: 0 }}
                                                        style={{
                                                            border: '4px solid #707070',
                                                            borderRadius: '16px',
                                                            backgroundColor: '#fff',
                                                            boxShadow: '0px -4px 8px #00000029',
                                                        }}
                                                    >
                                                        <Row
                                                            justify="center"
                                                            style={{
                                                                width: '100%',
                                                                background:
                                                                    'transparent linear-gradient(180deg, #707070 0%, #212121 100%) 0% 0% no-repeat padding-box',
                                                                borderTopLeftRadius: '10px',
                                                                borderTopRightRadius: '10px',
                                                            }}
                                                        >
                                                            <Col span={24} className="companyDataTitle charFontFamily">
                                                                {t('user_data_users').toUpperCase()}
                                                            </Col>
                                                            <Divider className="divider-company" />
                                                        </Row>
                                                        <Row className="scrollbar-colored">
                                                            <Col span={24}>
                                                                {this.state.chamberUsers
                                                                    ? this.state.chamberUsers.map((m, index) =>
                                                                          this.renderUser(m),
                                                                      )
                                                                    : null}
                                                            </Col>
                                                        </Row>
                                                        {this.state.isEditable ? (
                                                            <Row
                                                                justify="center"
                                                                style={{ margin: '40px 0px 25px 0px' }}
                                                            >
                                                                {this.state.expouser.roleId ===
                                                                expoConfig.domain.role.admin ? (
                                                                    <Button
                                                                        className="blackMode"
                                                                        shape="round"
                                                                        onClick={() => this.clickedOnAdd()}
                                                                    >
                                                                        {' '}
                                                                        {t('user_data_insert')}{' '}
                                                                    </Button>
                                                                ) : null}
                                                            </Row>
                                                        ) : null}
                                                    </Col>
                                                    {this.state.isChamber ? (
                                                        <Col
                                                            xl={{ span: 7, offset: 1 }}
                                                            lg={{ span: 20, offset: 0 }}
                                                            xs={{ span: 20, offset: 0 }}
                                                        >
                                                            <Form
                                                                className="form-chamber"
                                                                ref={this.mySelfformRef}
                                                                name="mySelfForm"
                                                                onFinish={this.postMySelfData}
                                                                initialValues={this.state.userData}
                                                            >
                                                                <Row
                                                                    className="rowChamberDataTitle"
                                                                    style={{ width: '100%' }}
                                                                    justify="center"
                                                                >
                                                                    <Col
                                                                        span={24}
                                                                        className="companyDataTitle charFontFamily"
                                                                    >
                                                                        {t('user_data_main_label').toUpperCase()}
                                                                    </Col>
                                                                    <Divider className="divider-company" />
                                                                </Row>
                                                                <Row style={{ marginTop: '30px' }} justify="center">
                                                                    <Col span={20}>
                                                                        <Label
                                                                            style={{ marginBottom: '45px' }}
                                                                            className="userSliderLabel charFontFamily"
                                                                        >
                                                                            {t('company_contact_name')}
                                                                        </Label>
                                                                        <Form.Item
                                                                            name="contactname"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: t(
                                                                                        'user_data_name_required',
                                                                                    ),
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input
                                                                                disabled={!this.state.isEditable}
                                                                                style={{
                                                                                    background:
                                                                                        '#fff 0% 0% no-repeat padding-box',
                                                                                    color: 'black',
                                                                                }}
                                                                                size="large"
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                                <Row justify="center">
                                                                    <Col span={20}>
                                                                        <Label
                                                                            style={{ marginBottom: '45px' }}
                                                                            className="userSliderLabel charFontFamily"
                                                                        >
                                                                            {t('company_contact_surname')}
                                                                        </Label>
                                                                        <Form.Item
                                                                            name="contactsurname"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: t(
                                                                                        'user_data_surname_required',
                                                                                    ),
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input
                                                                                disabled={!this.state.isEditable}
                                                                                style={{
                                                                                    background:
                                                                                        '#fff 0% 0% no-repeat padding-box',
                                                                                    color: 'black',
                                                                                }}
                                                                                size="large"
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                                <Row justify="center">
                                                                    <Col span={20}>
                                                                        <Label
                                                                            style={{ marginBottom: '45px' }}
                                                                            className="userSliderLabel charFontFamily"
                                                                        >
                                                                            {t('company_contact_role')}
                                                                        </Label>
                                                                        <Form.Item name="contactbusinessrole">
                                                                            <Input
                                                                                disabled={!this.state.isEditable}
                                                                                style={{
                                                                                    background:
                                                                                        '#fff 0% 0% no-repeat padding-box',
                                                                                    color: 'black',
                                                                                }}
                                                                                size="large"
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                                <Row justify="center">
                                                                    <Col span={20}>
                                                                        <Label
                                                                            style={{ marginBottom: '45px' }}
                                                                            className="userSliderLabel charFontFamily"
                                                                        >
                                                                            {t('company_contact_email')}
                                                                        </Label>
                                                                        <Form.Item
                                                                            name="contactemail"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: t(
                                                                                        'user_data_email_required',
                                                                                    ),
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input
                                                                                disabled={true}
                                                                                style={{
                                                                                    background:
                                                                                        '#fff 0% 0% no-repeat padding-box',
                                                                                    color: 'black',
                                                                                }}
                                                                                size="large"
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                                <Row justify="center">
                                                                    <Col span={20}>{profileObject}</Col>
                                                                </Row>
                                                                <Row style={{ marginTop: '50px' }} justify="center">
                                                                    {this.state.isEditable ? (
                                                                        <Col align="center" span={24}>
                                                                            <Form.Item>
                                                                                <Button
                                                                                    htmlType="submit"
                                                                                    disabled={false}
                                                                                    block
                                                                                    shape="round"
                                                                                    className="blackMode"
                                                                                >
                                                                                    {t('company_data_button_save')}
                                                                                </Button>
                                                                            </Form.Item>
                                                                        </Col>
                                                                    ) : null}
                                                                </Row>
                                                            </Form>
                                                        </Col>
                                                    ) : null}
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        ) : null}

                        {visibleThird ? (
                            <Row justify="center">
                                <Col xl={{ span: 14 }} xs={{ span: 20 }}>
                                    <Form
                                        style={{
                                            border: '4px solid #707070',
                                            borderRadius: '16px',
                                            backgroundColor: '#fff',
                                            boxShadow: '0px -4px 8px #00000029',
                                        }}
                                        ref={this.dataFormRef}
                                        name="dataForm"
                                        onFinish={this.postData}
                                        initialValues={chamberData}
                                    >
                                        <Row className="rowCompanyDataTitle" style={{ width: '100%' }}>
                                            <Col span={24} className="companyDataTitleMainData charFontFamily">
                                                {t('company_data_main_label').toUpperCase()}
                                            </Col>
                                            <Divider className="divider-company" />
                                        </Row>
                                        <Row style={{ marginTop: '30px' }}>
                                            <Col span={24}>
                                                <Row align="middle">
                                                    <Col
                                                        xl={{ span: 10, offset: 1 }}
                                                        md={{ span: 8, offset: 3 }}
                                                        xs={{ span: 20, offset: 2 }}
                                                    >
                                                        <Label
                                                            style={{ marginBottom: '45px' }}
                                                            className="userSliderLabel charFontFamily"
                                                        >
                                                            {t('signup_placeholder_company_name') + '*'}
                                                        </Label>
                                                        <Form.Item
                                                            name="chambername"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t('signup_required_company_name'),
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                disabled={!this.state.isEditable}
                                                                style={{
                                                                    background: '#fff 0% 0% no-repeat padding-box',
                                                                    color: 'black',
                                                                }}
                                                                className="companyDataInput"
                                                                size="large"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xl={{ span: 10, offset: 2 }}
                                                        md={{ span: 8, offset: 3 }}
                                                        xs={{ span: 20, offset: 2 }}
                                                    >
                                                        <Label
                                                            style={{ marginBottom: '45px' }}
                                                            className="userSliderLabel charFontFamily"
                                                        >
                                                            {t('signup_placeholder_company_country')}
                                                        </Label>
                                                        <Form.Item
                                                            name="chambercountrycode"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t('signup_required_company_country'),
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                disabled={true}
                                                                style={{
                                                                    background: '#fff 0% 0% no-repeat padding-box',
                                                                    color: 'black',
                                                                }}
                                                                size="large"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row align="bottom">
                                                    <Col
                                                        xl={{ span: 10, offset: 1 }}
                                                        md={{ span: 8, offset: 3 }}
                                                        xs={{ span: 20, offset: 2 }}
                                                    >
                                                        <Label
                                                            style={{ marginBottom: '45px' }}
                                                            className="userSliderLabel charFontFamily"
                                                        >
                                                            {t('signup_placeholder_company_venue')}
                                                        </Label>
                                                        <Form.Item
                                                            name="chambervenue"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t('signup_required_company_venue'),
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                maxLength="100"
                                                                disabled={!this.state.isEditable}
                                                                style={{
                                                                    background: '#fff 0% 0% no-repeat padding-box',
                                                                    color: 'black',
                                                                    fontSize: '16px',
                                                                }}
                                                                size="large"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xl={{ span: 10, offset: 2 }}
                                                        md={{ span: 8, offset: 3 }}
                                                        xs={{ span: 20, offset: 2 }}
                                                    >
                                                        <Label
                                                            style={{ marginBottom: '45px' }}
                                                            className="userSliderLabel charFontFamily"
                                                        >
                                                            {t('signup_placeholder_company_phone')}
                                                        </Label>
                                                        <Form.Item
                                                            name="chamberphone"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t('signup_required_company_phone'),
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                disabled={!this.state.isEditable}
                                                                style={{
                                                                    background: '#fff 0% 0% no-repeat padding-box',
                                                                    color: 'black',
                                                                }}
                                                                size="large"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col
                                                        xl={{ span: 10, offset: 1 }}
                                                        md={{ span: 8, offset: 3 }}
                                                        xs={{ span: 20, offset: 2 }}
                                                    >
                                                        <Label
                                                            style={{ marginBottom: '45px' }}
                                                            className="userSliderLabel charFontFamily"
                                                        >
                                                            {t('signup_placeholder_company_zip_code')}
                                                        </Label>
                                                        <Form.Item
                                                            name="chamberzipcode"
                                                            rules={[{ message: t('signup_required_company_zip_code') }]}
                                                        >
                                                            <Input
                                                                disabled={!this.state.isEditable}
                                                                style={{
                                                                    background: '#fff 0% 0% no-repeat padding-box',
                                                                    color: 'black',
                                                                }}
                                                                size="large"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xl={{ span: 10, offset: 2 }}
                                                        md={{ span: 8, offset: 3 }}
                                                        xs={{ span: 20, offset: 2 }}
                                                    >
                                                        <Label
                                                            style={{ marginBottom: '45px' }}
                                                            className="userSliderLabel charFontFamily"
                                                        >
                                                            {t('signup_placeholder_company_email').concat(' *')}
                                                        </Label>
                                                        <Form.Item
                                                            name="chambercontactemail"
                                                            rules={[
                                                                { required: true, message: t('signup_required_mail') },
                                                            ]}
                                                        >
                                                            <Input
                                                                disabled={!this.state.isEditable}
                                                                style={{
                                                                    background: '#fff 0% 0% no-repeat padding-box',
                                                                    color: 'black',
                                                                }}
                                                                size="large"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col
                                                        xl={{ span: 10, offset: 1 }}
                                                        md={{ span: 8, offset: 3 }}
                                                        xs={{ span: 20, offset: 2 }}
                                                    >
                                                        <Label
                                                            style={{ marginBottom: '45px' }}
                                                            className="userSliderLabel charFontFamily"
                                                        >
                                                            {t('signup_placeholder_company_city')}
                                                        </Label>
                                                        <Form.Item
                                                            name="chambercity"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t('signup_required_company_city'),
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                disabled={!this.state.isEditable}
                                                                style={{
                                                                    background: '#fff 0% 0% no-repeat padding-box',
                                                                    color: 'black',
                                                                }}
                                                                size="large"
                                                                placeholder={t('signup_placeholder_company_city')}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xl={{ span: 10, offset: 2 }}
                                                        md={{ span: 8, offset: 3 }}
                                                        xs={{ span: 20, offset: 2 }}
                                                    >
                                                        <Label
                                                            style={{ marginBottom: '45px' }}
                                                            className="userSliderLabel charFontFamily"
                                                        >
                                                            {t('signup_placeholder_company_website')}
                                                        </Label>
                                                        <Form.Item
                                                            name="chamberwebsite"
                                                            rules={[
                                                                {
                                                                    type: 'url',
                                                                    message: t('signup_required_company_website'),
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                disabled={!this.state.isEditable}
                                                                style={{
                                                                    background: '#fff 0% 0% no-repeat padding-box',
                                                                    color: 'black',
                                                                }}
                                                                size="large"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        {this.state.isEditable ? (
                                            <Row style={{ marginTop: '40px' }} justify="center">
                                                <Col>
                                                    <Form.Item>
                                                        <a href="#" className="scrollToTop">
                                                            <Button
                                                                htmlType="submit"
                                                                block
                                                                shape="round"
                                                                className="blackMode"
                                                            >
                                                                {t('company_data_button_save')}
                                                            </Button>
                                                        </a>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        ) : null}
                                    </Form>
                                </Col>
                            </Row>
                        ) : null}
                    </Col>
                </Row>
            </Row>
        );
    }
}

export default withTranslation()(ChamberUserSlider);
