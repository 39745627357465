import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Drawer, Popover, Row, notification } from 'antd';
import { EllipsisOutlined, MenuOutlined } from '@ant-design/icons';

import { useAuth0 } from '../react-auth0-spa';
import history from '../utils/history';
import locationUtil from '../utils/location';
import expoConfig from '../config/expo_config.json';
import expoClient from '../expoClient';
import LanguageDropdown from './LanguageDropdown';
import './NavBarUser.css';

const NavBarUser = () => {
    const { isAuthenticated, expoUser, loading, loginWithRedirect, logout } = useAuth0();
    const { t } = useTranslation();

    const [chambers, setChambers] = useState();
    const [selectedChamber, setSelectedChamber] = useState();
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [registrationLoaded, setRegistrationLoaded] = useState(false);
    //TODO controllare perchè a volte restituisce le aziende con tutti i dati, a volte solo con quelli elencati
    useEffect(() => {
        const normalizeToEntity = (companyList, token) => {
            if (!companyList) return null;
            const entityList = [];
            companyList.forEach((c) => {
                const e = {
                    id: c.id,
                    name: c.name,
                    bannerPath: c.bannerPath,
                    logoPath: c.logoPath,
                    path: c.logoPath,
                    token: token,
                    matchingScore: c.matchingScore,
                };
                entityList.push(e);
            });
            return entityList;
        };

        const setup = async () => {
            if (isAuthenticated && expoUser) {
                expoUser.complete = false;
                let chamber = null;
                //Se user è camera allora leggo dal server la sua camera
                if (expoUser.entityTypeId === expoConfig.domain.entity_type.chamber) {
                    const cRes = await expoClient.chambers.get(expoUser.entityId, expoUser.token);
                    chamber = cRes.data;
                    setChambers(null);
                    //console.log("NavBarUser useEffect setup user chamber", expoUser);
                    //Se user non è camera allora carico il burger menu con le camere
                } else if (
                    expoUser.entityTypeId === expoConfig.domain.entity_type.c_ita ||
                    expoUser.entityTypeId === expoConfig.domain.entity_type.c_other
                ) {
                    const companyRes = await expoClient.company.get(expoUser.entityId, expoUser.token);
                    const company = companyRes.data;
                    if (company.statusId == expoConfig.domain.registration.suspended) {
                        doLogoutBecauseBlocked();
                    }

                    const chambersRes = await expoClient.company.getChambers(company.id, expoUser.token);
                    setChambers(chambersRes.data);
                    const subdomain = locationUtil.detectSubdomain();
                    if (subdomain) {
                        //Verifico se la camera definita nel subdomain è fra quelle di competenza
                        const selChambers = chambersRes.data.filter((c) => c.subdomain === subdomain);
                        if (selChambers) {
                            chamber = selChambers[0];
                        }
                    }

                    if (chamber) {
                        //conosciamo la camera leggo il piano sottoscritto, autorizzazioni e dettagli sottoscrizione
                        const regResponse = await expoClient.chambers.getRegistration(
                            chamber.id,
                            expoUser.entityId,
                            expoUser.token,
                        );
                        console.log('NavBarUser.useEffect got registration data', regResponse.data);
                        expoUser.registration = regResponse.data;
                        if (expoUser.registration.companyPlanId) {
                            //Autorizzazioni legate al piano sottoscritto
                            const authResponse = await expoClient.auth.get(
                                expoUser.registration.companyPlanId,
                                expoUser.token,
                            );
                            console.log('NavBarUser.useEffect got auth data', authResponse.data);
                            expoUser.auth = authResponse.data;
                        }
                        if (expoUser.registration.subscriptionId) {
                            //Info della sottoscrizione fatta
                            const subsResponse = await expoClient.subscription.get(
                                expoUser.registration.subscriptionId,
                                expoUser.token,
                            );
                            console.log('NavBarUser.useEffect got subscription data', subsResponse.data);
                            expoUser.subscription = subsResponse.data;
                        }
                        setRegistrationLoaded(false);
                        setRegistrationLoaded(true);
                    } else {
                        //camera ancora da selezionare, ci penserà la homepage.js ad obbligare l'utente nella scelta
                        console.log('NavBarUser.useEffect no chamber detected for company user', expoUser);
                    }
                } else {
                    console.error('c%EntityType unknown', 'color:red');
                    setChambers(null);
                    console.error('NavBarUser useEffect setup user unkown');
                }
                if (chamber) {
                    //Se ho individuato la camera ne completo l'inizializzazione
                    chamber.items = {};
                    const cItaRes = await expoClient.chambers.getCompanies(
                        chamber.id,
                        expoConfig.domain.nation.ita,
                        expoUser.token,
                    );
                    chamber.items.italy = normalizeToEntity(cItaRes.data, expoUser.token);
                    const cNoItaRes = await expoClient.chambers.getCompanies(
                        chamber.id,
                        -1 * expoConfig.domain.nation.ita,
                        expoUser.token,
                    );
                    chamber.items.noItaly = normalizeToEntity(cNoItaRes.data, expoUser.token);
                    const eRes = await expoClient.chambers.getEvents(chamber.id, null, expoUser.token);
                    chamber.items.event = eRes.data;
                    const counters = await expoClient.chambers.countCompanies(chamber.id, expoUser.token);
                    chamber.pendingCounter = counters.data.pending;
                    chamber.suspendedCounter = counters.data.suspended;
                    chamber.draftedCounter = counters.data.active;
                }
                console.log('NavBarUser useEffect chamber:', chamber);
                setSelectedChamberGlobally(chamber);
                expoUser.complete = true;
                goToPay();
            } else {
                //Non sono autenticato
                setChambers(null);
                setSelectedChamberGlobally(null);
                const subdomain = locationUtil.detectSubdomain();
                if (subdomain) {
                    //Verifico se sono sul dominio di una camera
                    const subdomainRes = await expoClient.chambers.getBySubdomain(subdomain);
                    if (subdomainRes.data && subdomainRes.data.length === 1) {
                        setSelectedChamberGlobally(subdomainRes.data[0]);
                        if (
                            window.location.pathname != '/' &&
                            window.location.pathname != '/signup' &&
                            // window.location.pathname != '/pricing' &&
                            !window.location.pathname.includes('/event') &&
                            !window.location.pathname.includes('/companiesjoined')
                        ) {
                            doLogin();
                        }
                        if (window.location.href.includes('blocked') && window.location.href.includes('unauthorized')) {
                            alertUserBlocked();
                        }
                    } else {
                        //Redirect
                        window.location.replace(window.location.href.replace(subdomain, 'www'));
                    }
                }
                console.log('NavBarUser useEffect setup no user logged in');
            }
        };
        if (!loading) {
            setup();
            console.log('NavBarUser useEffect after setup', expoUser);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, expoUser, loading]);

    // useEffect(() => {

    // 	if (expoUser && expoUser.complete)
    // 		goToPay();
    // }, [registrationLoaded]);

    const alertUserBlocked = async () => {
        const msg = t('warning');
        let desc = t('user_blocked');
        notification.error({
            key: 'userBlobkedError',
            message: msg,
            description: desc,
            placement: 'topRight',
            duration: 10,
        });
    };
    const setSelectedChamberGlobally = (chamberToSet) => {
        setSelectedChamber(chamberToSet);
        if (expoUser) {
            expoUser.chamber = chamberToSet;
        } else {
            console.error('setSelectedChamberGlobally: utente non loggato ha selezionato una camera');
        }
    };

    const doLogin = async () => {
        if (
            (window.location.pathname.includes('/event/') && !window.location.pathname.includes('/event/leave')) ||
            window.location.pathname.includes('/requestb2b') ||
            window.location.pathname.includes('/companiesacceptance')
        ) {
            localStorage.setItem(expoConfig.pageToRedirect, window.location.pathname);
        } else {
            localStorage.setItem(expoConfig.pageToRedirect, '/');
        }
        await loginWithRedirect({});
        //history.push("/");
    };

    const doLogout = async () => {
        await logout({
            returnTo: window.location.origin,
        });
        history.push('/');
    };

    const doLogoutBecauseBlocked = async () => {
        await logout({
            returnTo: window.location.origin + '?error=unauthorized&error_description=user%20is%20blocked',
        });
        history.push('/?error=unauthorized&error_description=user%20is%20blocked');
    };
    const loginButton = isAuthenticated ? (
        <span className="headerButton_p" onClick={() => doLogout()}>
            {t('logout')}
        </span>
    ) : (
        <span className="headerButton_p" onClick={() => doLogin()}>
            {t('login')}
        </span>
    );

    const buildChamberUrl = (chamber) => {
        let chamberUrl = `${window.location.protocol}//${window.location.host}/`;
        const actualSubdomain = locationUtil.detectSubdomain();
        if (actualSubdomain === null && window.location.host.startsWith('localhost')) {
            // gestione adhoc per lo sviluppo in locale
            chamberUrl = `${window.location.protocol}//${chamber.subdomain}.${window.location.host}/`;
        } else if (actualSubdomain && chamber.subdomain && actualSubdomain !== chamber.subdomain) {
            chamberUrl = chamberUrl.replace(actualSubdomain, chamber.subdomain);
        }
        return chamberUrl;
    };

    const goToHome = () => {
        if (!expoUser) {
            history.push('/');
        } else {
            const isChamber = expoUser.entityTypeId === expoConfig.domain.entity_type.chamber;
            if (isChamber) {
                history.push('/chamber');
            } else {
                history.push('/company');
                history.go();
            }
        }
    };

    const goToPay = () => {
        if (
            isAuthenticated &&
            expoUser.entityTypeId != expoConfig.domain.entity_type.chamber &&
            expoUser.registration &&
            (!expoUser.registration.subscriptionId || expoUser.registration.expired)
        ) {
            history.push('/subscription');
        }
    };

    const renderContent = (c) =>
        c.subdomain ? (
            <Col span={24} key={c.id} className="burger-menu-item expo-burger-item">
                <a key={c.id} href={buildChamberUrl(c)}>
                    <Row align="middle" style={{ height: '100%' }}>
                        <Col className="expo-burger-item-image partial-border" span={8}>
                            <img src={c.logoPath} style={{ width: '100px' }} alt="chamber logo" />
                        </Col>
                        <Col
                            className="expo-burger-item-text partial-border"
                            span={16}
                            style={{ backgroundColor: '#ccc' }}
                        >
                            <div>
                                <strong>{c.name}</strong>
                                <br />
                                {c.country.name}
                            </div>
                        </Col>
                    </Row>
                </a>
            </Col>
        ) : null;

    const getChamberList = () => {
        return <Row justify="center">{chambers.map((c, index) => renderContent(c))}</Row>;
    };

    const getMenu = (
        <>
            {/*
            {!isAuthenticated ? (
                <span className="headerButton_p" onClick={() => history.push('/pricing')}>
                    {t('pricing').toUpperCase()}
                </span>
            ) : null}
						*/}
            {!isAuthenticated ? (
                <span className="headerButton_p" onClick={() => history.push('/signup')}>
                    {t('signup')}
                </span>
            ) : null}
            {loginButton}
            <span className="headerButton_p">
                {expoUser && expoUser.chamber ? (
                    <LanguageDropdown className="headerButton_p" languageList={expoUser.chamber.languageDtoList} />
                ) : selectedChamber ? (
                    <LanguageDropdown className="headerButton_p" languageList={selectedChamber.languageDtoList} />
                ) : null}
            </span>
        </>
    );

    const drawerClose = () => setDrawerVisible(false);

    return (
        <>
            <Row className="headerBar blackLink" id="headerBar" align="middle" justify="start">
                <Col
                    className="headerLogo"
                    xs={{ span: 12, offset: 1 }}
                    md={{ span: 10, offset: 0 }}
                    lg={{ span: 6, offset: 0 }}
                    onClick={() => {
                        goToHome();
                    }}
                    style={{ cursor: 'pointer' }}
                >
                    {selectedChamber ? (
                        <img src={selectedChamber.logoPath} alt={'chamber logo'} style={{ width: '100%' }} />
                    ) : null}
                </Col>
                {/*<Col className="chamberName" xs={0} md={0} lg={10}/>*/}
                <Col xs={11} md={14} lg={18}>
                    <div className="headerBarButtons">
                        {chambers && isAuthenticated ? (
                            <div
                                className="headerButton"
                                onClick={() => {
                                    setDrawerVisible(!drawerVisible);
                                }}
                            >
                                <MenuOutlined />
                            </div>
                        ) : null}
                        <div className="headerButton headerButtonLG">
                            {expoUser && expoUser.chamber ? (
                                <LanguageDropdown
                                    className="headerButton_p"
                                    languageList={expoUser.chamber.languageDtoList}
                                />
                            ) : selectedChamber ? (
                                <LanguageDropdown
                                    className="headerButton_p"
                                    languageList={selectedChamber.languageDtoList}
                                />
                            ) : null}
                        </div>
                        <div className="headerButton headerButtonLG">{loginButton}</div>
                        {!isAuthenticated ? (
                            <div className="headerButton headerButtonLG" onClick={() => history.push('/signup')}>
                                <span className="headerButton_p">{t('signup')}</span>
                            </div>
                        ) : null}
                        {/*
                        {!isAuthenticated ? (
                            <div className="headerButton headerButtonLG" onClick={() => history.push('/pricing')}>
                                <span className="headerButton_p">{t('pricing').toUpperCase()}</span>
                            </div>
                        ) : null}
												*/}
                        <Popover
                            overlayClassName="burger-menu-popover"
                            content={getMenu}
                            placement="bottom"
                            trigger="click"
                        >
                            <div className="headerButton headerButtonXS">
                                <EllipsisOutlined className="ellipsis-icon-menu" />
                            </div>
                        </Popover>
                    </div>
                </Col>
            </Row>
            <Drawer
                className="expo-drawer-header"
                placement="right"
                closable={false}
                mask={true}
                visible={drawerVisible}
                onClose={drawerClose}
                width={window.innerWidth <= 768 ? '100%' : '400px'}
                bodyStyle={{ padding: 0 }}
            >
                {chambers ? getChamberList() : null}
            </Drawer>
        </>
    );
};
export default NavBarUser;
