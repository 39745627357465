import React from 'react';
import history from '../utils/history';
import { useTranslation } from 'react-i18next';

import './PageNotFound.scss';

const PageNotFound = () => {
    const { t } = useTranslation();
    return (
        <div class="scene-container">
            <div class="scene">
                <div class="box">
                    <div class="box__face front">4</div>
                    <div class="box__face back">0</div>
                    <div class="box__face right">4</div>
                    <div class="box__face left">0</div>
                    <div class="box__face top">0</div>
                    <div class="box__face bottom">0</div>
                </div>
                <div class="shadow"></div>
            </div>
            <div class="desc">
                <h2>{t('404')}</h2>
                <button onClick={() => history.push('/')}>HOME PAGE</button>
            </div>
        </div>
    );
};
export default PageNotFound;
