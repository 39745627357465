import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import './XtensibleGallery.css';
import '../css/flag-css.min.css';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { Col, Row } from 'antd';
import XtensibleGalleryTile from './XtensibleGalleryTile';
import ArrowLR from './ArrowLR';
import { ReactComponent as Plus } from '../icons/plus.svg';
// import Swiper core and required components
import { Swiper, SwiperSlide } from 'swiper/react';

const XtensibleGallery = (props) => {
    //parametri del componente
    const {
        title, //Titolo visibile nell'accordion
        contents, //Gli elementi che mostro nel componente
        user,
        isEditable,
        isSelectable,
        selection, //Gli elementi selezionati
        selectionCallback,
        onAddItemCallback,
        onDeleteItemCallBack,
        onVieItemCallBack,
        idPrefix,
        imagepath,
        previewpath,
        titleVisible, //Visibilità del titolo sotto ogni elemento contenuto nel componente,
        titleDesc, // se il titolo è visibile, con questo flag si sceglie di visualizzare la descrizione invece che il nome
        useEmbeddedModal = true,
        onDownloadItemCallBack,
    } = props;

    const [innerWidth, setInnerWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setInnerWidth(window.innerWidth);
    };

    React.useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    //Variabili di stato
    const [open, setOpen] = useState(false);
    //Se ricevo il set di item selezionati li imposto altrimenti nessuna selezione
    const [selectedEntitiesId, setSelectedEntitiesId] = useState(selection ? selection.map((s) => s.id) : []);

    const openCurtain = (e) => {
        e.stopPropagation();
        if (innerWidth > 500) {
            const xgh = e.currentTarget;
            xgh.classList.toggle('xg_header_open');
            const arrow = xgh.querySelector('div[class*="main-arrow"]');
            arrow.classList.toggle('main-arrow-down');
            arrow.querySelector('path').setAttribute('fill', 'white');
            arrow.firstChild.classList.toggle('rotated');
            const xgc = xgh.parentNode.querySelector('div[class*="xg_content"]');
            xgc.classList.toggle('xg_content_open');
            setOpen(!open);
        }
    };

    const addButtonHook = () => {
        if (onAddItemCallback) {
            onAddItemCallback();
        }
    };

    const handleClick = (itemId) => {
        if (isSelectable) {
            //console.log("XTensibleGallery click", itemId, selectedEntitiesId);
            const idSelection = selectedEntitiesId.includes(itemId)
                ? selectedEntitiesId.filter((id) => id !== itemId)
                : _.concat(selectedEntitiesId, itemId);
            setSelectedEntitiesId(idSelection);
            //console.log("XTensibleGallery click new selection", idSelection);
            if (selectionCallback) {
                //selectionCallback(contents.filter(c => selection.includes(c.id)));
                const allItems = [...selection, ...contents];
                //console.log("XTensibleGallery click returning", allItems, idSelection);
                selectionCallback(allItems.filter((c) => idSelection.includes(c.id)));
            }
        }
    };

    const plusButton =
        isEditable && onAddItemCallback ? (
            <span onClick={() => addButtonHook()}>
                <Plus className="xg_plus_button" />
            </span>
        ) : null;

    const getElements = (cont) => {
        const ret = [];

        const idOfTileContainerPrefix = title + '_' + idPrefix;
        if (cont) {
            cont.forEach((e, i) => {
                let idOfTile = idOfTileContainerPrefix + '' + i;
                ret.push(
                    <Col key={i} xxl={6} xl={8} lg={12} md={12} className="xg_tile">
                        <div style={{ width: '320px' }} onClick={() => handleClick(e.id)}>
                            <XtensibleGalleryTile
                                isEditable={isEditable}
                                isSelected={selectedEntitiesId.includes(e.id)}
                                onDeleteItemCallBack={onDeleteItemCallBack}
                                onVieItemCallBack={onVieItemCallBack}
                                content={e}
                                user={user}
                                imagepath={imagepath}
                                previewpath={previewpath}
                                videoId={idOfTile}
                                titleVisible={titleVisible}
                                titleDesc={titleDesc}
                                useEmbeddedModal={useEmbeddedModal}
                                onDownloadItemCallBack={onDownloadItemCallBack}
                            />
                        </div>
                    </Col>,
                );
                var video = document.getElementById(idOfTile);
                //console.log("getting video:",video);
                if (video) {
                    //console.log("Loading video:",video);
                    video.load();
                }
            });
        }
        return ret;
    };

    const getElementsMobile = (cont) => {
        const ret = [];

        const idOfTileContainerPrefix = title + '_' + idPrefix;
        if (cont) {
            cont.forEach((e, i) => {
                let idOfTile = idOfTileContainerPrefix + '' + i;
                ret.push(
                    <SwiperSlide onClick={() => handleClick(e.id)}>
                        <XtensibleGalleryTile
                            isEditable={isEditable}
                            isSelected={selectedEntitiesId.includes(e.id)}
                            onDeleteItemCallBack={onDeleteItemCallBack}
                            onVieItemCallBack={onVieItemCallBack}
                            content={e}
                            user={user}
                            imagepath={imagepath}
                            previewpath={previewpath}
                            videoId={idOfTile}
                            titleVisible={titleVisible}
                            useEmbeddedModal={useEmbeddedModal}
                            onDownloadItemCallBack={onDownloadItemCallBack}
                        />
                    </SwiperSlide>,
                );
                var video = document.getElementById(idOfTile);
                //console.log("getting video:",video);
                if (video) {
                    //console.log("Loading video:",video);
                    video.load();
                }
            });
            //Aggiunta ultima slide finta per permettere la visualizzazione dell'ultimo vero in posizione centrale.
            ret.push(
                <SwiperSlide>
                    <div></div>
                </SwiperSlide>,
            );
        }
        return ret;
    };

    const isMobile = innerWidth <= 500;

    return (
        <Row align="middle" className={open ? 'xg_main' : 'xg_main xg_main_close'}>
            <Col span={24} className="xg_header" onClick={openCurtain}>
                <Row className="xg_header_row" align="middle">
                    <Col xl={{ span: 22 }} md={{ span: 20 }} xs={{ span: 20 }} className="xg_title">
                        <p className="xg_title_p">{title}</p>
                    </Col>
                    <Col xl={{ span: 1 }} md={{ span: 2 }} xs={{ span: 2 }}>
                        {' '}
                        {plusButton}
                    </Col>
                    {!isMobile ? (
                        <Col xl={{ span: 1 }} md={{ span: 2 }} className="xg_arrow">
                            <ArrowLR direction="right" align="right" />
                        </Col>
                    ) : null}
                </Row>
            </Col>

            {!isMobile ? (
                <Col span={24} className="xg_content">
                    <Row align="middle">{getElements(contents)}</Row>
                </Col>
            ) : (
                <Col span={24} className="xg_content">
                    <Swiper
                        slidesPerView={2}
                        spaceBetween={innerWidth < 360 ? 200 : 250}
                        //onSwiper={(swiper) => console.log(swiper)}
                        //onSlideChange={() => console.log('slide change')}
                    >
                        {getElementsMobile(contents)}
                    </Swiper>
                </Col>
            )}
        </Row>
    );
};
export default XtensibleGallery;
