import './CompanyShowCase.css';
import '../css/flag-css.min.css';
import './EntityItemSlider.css';
import './Signup.css';

import is from 'is_js';
import XtensibleGallery from './XtensibleGallery';
import { Label, Modal } from 'semantic-ui-react';
import expoClient from '../expoClient';
import expoConfig from '../config/expo_config.json';
import authorization from '../utils/authorization';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Carousel, Col, Form, notification, Row, Badge } from 'antd';
import { useTranslation } from 'react-i18next';
import ImageEditorModal from './ImageEditorModal';
import ImageEditorModalTile from './ImageEditorModalTile';
import ImageEditor from './ImageEditor';
import VideoEditor from './VideoEditor';
import DocumentEditor from './DocumentEditor';
import EntityInfoPanel from './EntityInfoPanel.js';
import TopBar from './TopBar';
import { ReactComponent as ArrowModalLeft } from '../icons/arrow-modal-left.svg';
import { ReactComponent as ArrowModalRight } from '../icons/arrow-modal-right.svg';
import { ReactComponent as RegistrationButton } from '../icons/status.svg';
import { ReactComponent as MembersButton } from '../icons/member.svg';
import { ReactComponent as ProfileAccessButton } from '../icons/profiliaccesso.svg';
import { ReactComponent as SuspendCompany } from '../icons/sospese.svg';
import { ReactComponent as SettingsButton } from '../icons/datiaziendali.svg';
import { ReactComponent as CloseButton } from '../icons/close.svg';
import { ReactComponent as MyShowCaseButton } from '../icons/myshowcase.svg';
import { ReactComponent as MatchingIcon } from '../icons/match-company.svg';
import defaultMediaImage from '../images/default-media.jpg';
import { useAuth0 } from '../react-auth0-spa';
import defaultCompanyImage from '../images/default-company.jpg';
import defaultLogoImage from '../images/insert-logo.jpg';
import history from '../utils/history';
import locationUtil from '../utils/location';
import CompanyUsers from './CompanyUsers';
import CompanyData from './CompanyData';
import { ReactComponent as ContactButton } from '../icons/mail.svg';
import { ReactComponent as EditIcon } from '../icons/edit.svg';
import ConfirmModal from './ConfirmModal';
import AccordionTopics from './AccordionTopics';
import { Link } from 'react-router-dom';
import IconAndLabel from './IconAndLabel';
import ReactGA from 'react-ga';
import { ReactComponent as IconRequestB2B } from '../icons/requestb2b.svg';
import TextArea from 'antd/lib/input/TextArea';
import ModalSuspendedCompanies from './ModalSuspendedCompanies';
import { LogoutOutlined } from '@ant-design/icons';

const CompanyShowCase = ({ location }) => {
    const [isEditable, setIsEditable] = useState(false);
    const [isCompanyUser, setIsCompanyUser] = useState(false);
    const { isAuthenticated, expoUser, loading, logout } = useAuth0();
    const [company, setCompany] = useState(null);
    const [showModalVideo, setShowModalVideo] = useState(false);
    const [currentPortfolioInCarousels, setCurrentPortfolioInCarousels] = useState(0);
    //const [currentLiveInCarousels, setCurrentLiveInCarousels] = useState(0);
    const [showModalPortfolio, setShowModalPortfolio] = useState(false);
    const [showModalInsertPortfolio, setShowModalInsertPortfolio] = useState(false);
    const [showModalSuspendCompany, setShowModalSuspendCompany] = useState(false);
    const [newPortfolio, setNewPortfolio] = useState({});
    const [newLive, setNewLive] = useState({});
    const [showModalInsertLive, setShowModalInsertLive] = useState(false);
    const [registrationStatus, setRegistrationStatus] = useState(0);
    const [editMode, setEditMode] = useState(false);
    const [loadingPercentage, setLoadingPercentage] = useState(false);
    const [statusUpload, setStatusUpload] = useState('normal');
    const [currentMediaId, setCurrentMediaId] = useState(null);
    const [companyUsers, setCompanyUsers] = useState(null);
    const [myCompany, setMyCompany] = useState(null);
    const [currentDataPage, setCurrentDataPage] = useState(-1);
    const [displayDataPageChamber, setDisplayDataPageChamber] = useState('none');
    const [displayDataPage, setDisplayDataPage] = useState('none');
    const [newDocument, setNewDocument] = useState({});
    const [showModalInsertDocument, setShowModalInsertDocument] = useState(false);
    const [index, setIndex] = useState(null);
    const [companies, setCompanies] = useState(null);
    const [isChamberUser, setIsChamberUser] = useState(false);
    const [showModifyTags, setShowModifyTags] = useState(false);
    const [topics, setTopics] = useState(null);
    const [deSelected, setDeselected] = useState([]);
    const [requestContactMessage, setRequestContactMessage] = useState('');
    const [requestAdviceMessage, setRequestAdviceMessage] = useState('');
    const [unviewedContactRequests, setUnviewedContactRequests] = useState(false);
    const [contactRequestsNumber, setContactRequestsNumber] = useState(1);
    const [tempSuspensionMessage, setTempSuspensionMessage] = useState('');
    const [suspensionMessageColor, setSuspensionMessageColor] = useState('red');
    const [disableSuspensionButton, setDisableSuspensionButton] = useState(true);
    const { t } = useTranslation();

    //carouselVideoRef = useRef();
    const carouselPortfolioModalRef = useRef();
    const tagFormRef = useRef();

    let initialCompanyId = null;
    if (location && location.state && location.state.items) {
        const localIndex = location.state.index ? location.state.index : 0;
        if (companies === null) {
            //console.log("Setting state.index and state.companies", localIndex, location.state.items);
            setIndex(localIndex);
            setCompanies(location.state.items);
        }
    }
    if (location && location.state && location.state.companyId) {
        initialCompanyId = location.state.companyId;
    }

    let initialPageLocal = 0;
    let placeHolderPortfolioDescription = t('new_portfolio_description');
    let placeHolderSuspendCompanyDescription = t('motivation_suspend_company') + '*';

    const readCompany = async (currentCompany) => {
        //console.log('CompanyShowcase.readCompany', currentCompany);
        setIsChamberUser(expoUser && expoUser.entityTypeId === expoConfig.domain.entity_type.chamber);

        //Non mettere solo index per il controllo con null perchè bisogna che con zero passi se no
        //quando si fa tutto il giro dello slider non funziona con indice zero.
        const companyId = currentCompany
            ? currentCompany
            : index !== null && companies
            ? companies[index].id
            : initialCompanyId
            ? initialCompanyId
            : expoUser.entityId;

        //console.log('CompanyShowcase.readCompany companyEntityId', companyId);

        const cRes = await expoClient.company.get(companyId, expoUser.token);
        const c = cRes.data;
        const isCompanyUser = expoUser ? expoUser.entityTypeId === c.entityTypeId && expoUser.entityId === c.id : false;

        setIsCompanyUser(isCompanyUser);
        const isEditableLoc = expoUser ? isCompanyUser && expoUser.roleId === expoConfig.domain.role.admin : false;

        setIsEditable(isEditableLoc);

        const usersRes = await expoClient.user.getByEntityId(c.entityId, expoUser.token);
        setCompanyUsers(usersRes.data);

        const liveRes = await expoClient.company.getShowcaseItems(
            c.id,
            expoConfig.domain.showcaseitem.live,
            expoUser.token,
        );
        const prodRes = await expoClient.company.getShowcaseItems(
            c.id,
            expoConfig.domain.showcaseitem.product,
            expoUser.token,
        );
        const portRes = await expoClient.company.getShowcaseItems(
            c.id,
            expoConfig.domain.showcaseitem.portfolio,
            expoUser.token,
        );
        const docRes = await expoClient.company.getShowcaseItems(
            c.id,
            expoConfig.domain.showcaseitem.document,
            expoUser.token,
        );

        c.items = {};
        c.items.live = liveRes.data;
        c.items.product = prodRes.data;
        c.items.portfolio = portRes.data;
        c.items.documents = docRes.data;
        //Per impostare gli eventi deve essere certo di avere la camera
        if (expoUser && expoUser.chamber) {
            const requestCount = await expoClient.chambers.getCompanyContactRequestsCount(
                expoUser.chamber.id,
                c.id,
                expoUser.token,
            );
            setUnviewedContactRequests(requestCount.data.someRequested);
            setContactRequestsNumber(requestCount.data.num);
        }
        //console.log('CompanyShowcase.readCompany completed company setup', c);

        if (!myCompany) {
            setMyCompany(c);
            //console.log('CompanyShowcase.readCompany with name, id, entityId', c.name, c.id, c.entityId);
            //const cRes = await expoClient.company.get(companyId, expoUser.token);
            //setMyCompany(cRes.data);
            //console.log('CompanyShowcase.readCompany got my company', cRes.data);
        }

        if (expoUser && !expoUser.chamber) {
            const chambersRes = await expoClient.company.getChambers(c.id, expoUser.token);
            const subdomain = locationUtil.detectSubdomain();
            if (subdomain) {
                //Verifico se la camera definita nel subdomain è fra quelle di competenza
                const selChambers = chambersRes.data.filter((c) => c.subdomain === subdomain);
                if (selChambers) {
                    expoUser.chamber = selChambers[0];
                    const requestCount = await expoClient.chambers.getCompanyContactRequestsCount(
                        selChambers[0].id,
                        c.id,
                        expoUser.token,
                    );
                    setUnviewedContactRequests(requestCount.data.someRequested);
                    setContactRequestsNumber(requestCount.data.num);
                }
            }
        }

        if (expoUser && expoUser.chamber && !expoUser.chamber.items) {
            const cItaRes = await expoClient.chambers.getCompanies(
                expoUser.chamber.id,
                expoConfig.domain.nation.ita,
                expoUser.token,
            );
            expoUser.chamber.items = {};
            expoUser.chamber.items.italy = cItaRes.data; //normalizeToEntity(cItaRes.data, expoUser.token);
            const cNoItaRes = await expoClient.chambers.getCompanies(
                expoUser.chamber.id,
                -1 * expoConfig.domain.nation.ita,
                expoUser.token,
            );
            expoUser.chamber.items.noItaly = cNoItaRes.data; //normalizeToEntity(cNoItaRes.data, expoUser.token);
            const eRes = await expoClient.chambers.getEvents(expoUser.chamber.id, companyId, expoUser.token);
            expoUser.chamber.items.event = eRes.data;
            //Se sono utente azienda imposto gli eventi e i tags in company
            if (isCompanyUser) {
                c.items.events = eRes.data;
            }
            const counters = await expoClient.chambers.countCompanies(expoUser.chamber.id, expoUser.token);
            expoUser.chamber.pendingCounter = counters.data.pending;
        }
        if (expoUser.chamber && isEditableLoc && c) {
            //richiedi lo stato
            const regRes = await expoClient.chambers.getRegistration(expoUser.chamber.id, c.id, expoUser.token);
            setRegistrationStatus(regRes.data.statusId);
        }
        if (expoUser.chamber && c.items) {
            //rileggo gli eventi dell'azienda potrebbero essere stati modificati
            const eRes = await expoClient.chambers.getEvents(expoUser.chamber.id, companyId, expoUser.token);
            c.items.events = eRes.data;
            //Se non ho ancora letto i TAG li prendo dal server
            if (!c.tags) {
                const tRes = await expoClient.chambers.getTags(expoUser.chamber.id, c.id, expoUser.token);
                c.tags = tRes.data;
            }
        }
        const topRes = await expoClient.topics.get();
        setTopics(topRes.data);
        setCompany(c);

        const img = document.getElementById('bannerImage');
        if (img) {
            img.onload = function () {
                handleResize();
            };
        }
        if (expoUser && !myCompany && expoUser.chamber && !isChamberUser) {
            ReactGA.pageview(
                '/chamber/' +
                    expoUser.chamber.id +
                    '/company/' +
                    c.id +
                    '/userCompany/' +
                    c.id +
                    '/user/' +
                    expoUser.id,
            );
        } else if (expoUser && myCompany && expoUser.chamber && !isChamberUser) {
            ReactGA.pageview(
                '/chamber/' +
                    expoUser.chamber.id +
                    '/company/' +
                    c.id +
                    '/userCompany/' +
                    myCompany.id +
                    '/user/' +
                    expoUser.id,
            );
        } else if (isChamberUser && expoUser) {
            ReactGA.pageview(
                '/chamber/' +
                    expoUser.chamber.id +
                    '/company/' +
                    c.id +
                    '/userChamber/' +
                    expoUser.chamber.id +
                    '/user/' +
                    expoUser.id,
            );
        }
    };

    useEffect(() => {
        ReactGA.initialize(expoConfig.analyticsproperty.id);
        //console.log('CompanyShowcase.useEffect');
        if (isAuthenticated && expoUser && !loading) {
            window.addEventListener('resize', handleResize);
            readCompany();
        }
    }, [isAuthenticated, expoUser, loading, index, companies, isChamberUser]);

    const handleResize = () => {
        const img = document.getElementById('bannerImage');
        const w = window.innerWidth;
        if (document.getElementById('descriptionText') != null) {
            if (w < 1200) {
                document.getElementById('descriptionText').style.height = 'auto';
            } else if (img) {
                const height = img.clientHeight;
                document.getElementById('descriptionText').style.height = height + 'px';
            }
        }
    };

    //Start old functions
    const computeInitial = (m, index_) => {
        if (m.id === currentMediaId) {
            initialPageLocal = index_;
        }
    };

    const refreshData = async () => {
        const usersRes = await expoClient.user.getByEntityId(company.entityId, expoUser.token);
        setCompanyUsers(usersRes.data);
        const cRes = await expoClient.company.get(expoUser.entityId, expoUser.token);
        setMyCompany(cRes.data);
    };

    const refreshCompanyPortfolio = async () => {
        if (company && company.id) {
            let companyNew = Object.assign({}, company);
            const portRes = await expoClient.company.getShowcaseItems(
                company.id,
                expoConfig.domain.showcaseitem.portfolio,
                expoUser.token,
            );
            companyNew.items.portfolio = portRes.data;
            setCompany(companyNew);
        }
    };

    const refreshCompanyLive = async () => {
        if (company && company.id) {
            let companyNew = Object.assign({}, company);
            const portRes = await expoClient.company.getShowcaseItems(
                company.id,
                expoConfig.domain.showcaseitem.live,
                expoUser.token,
            );
            companyNew.items.live = portRes.data;
            setCompany(companyNew);
        }
    };

    const refreshCompanyDocument = async () => {
        if (company && company.id) {
            let companyNew = Object.assign({}, company);
            const portRes = await expoClient.company.getShowcaseItems(
                company.id,
                expoConfig.domain.showcaseitem.document,
                expoUser.token,
            );
            companyNew.items.documents = portRes.data;
            setCompany(companyNew);
        }
    };

    const posNewtData = async () => {
        try {
            await expoClient.company.modify(company.id, company, expoUser.token);
            refreshData();
        } catch (error) {
            console.error(error);
        } finally {
        }
    };

    const setEditModeCallBack = (isEdit, changed) => {
        setEditMode(isEdit);
        if (changed) {
            posNewtData();
        }
    };

    //Funzione di handling change nel cambio  Portfolio della Modale
    const handleAfterChangePortfolio = (id, prefix) => {
        setCurrentPortfolioInCarousels(id);
    };

    const postDeletePortfolio = async (indexPort) => {
        try {
            let indice = null;
            if (indexPort) {
                indice = indexPort;
            } else {
                if (company.items.portfolio.length === 1) setShowModalPortfolio(false);
                let prefix = 'portfolio_';
                const chosenPortfolio = document.getElementById(`${prefix}${currentPortfolioInCarousels}`);
                if (chosenPortfolio) {
                    indice = chosenPortfolio.name;
                }
            }
            if (indice) {
                //setCurrentPortfolioInCarousels(chosenPortfolio.name);
                let res = await expoClient.showcase.delete(indice, expoUser.token);
                if (typeof res !== 'undefined') {
                    refreshCompanyPortfolio();
                }
            }
        } catch (error) {
            console.error('error in deleting portfolio:', error);
        } finally {
        }
    };
    const deletePortfolio = (indexPort) => {
        postDeletePortfolio(indexPort);
    };

    const postDeleteLive = async (liveIndex) => {
        try {
            //se live index esiste sono chiamato dal tile altrimenti
            //è una chiamata dallo slider full screen
            let indice = null;
            if (liveIndex) {
                indice = liveIndex;
            }
            //Rimettere ramo else se si ripristinano i carousel
            /*else {
				if (company.items.live.length === 1) setShowModalVideo(false);
				let prefix = 'video_';
				const chosenLive = document.getElementById(`${prefix}${currentLiveInCarousels}`);
				if (chosenLive)
					indice = chosenLive.getAttribute("name");
			}*/
            if (indice) {
                //setCurrentPortfolioInCarousels(chosenPortfolio.name);
                let res = await expoClient.showcase.delete(indice, expoUser.token);
                if (typeof res !== 'undefined') {
                    refreshCompanyLive();
                }
            }
        } catch (error) {
            console.error('error in deleting portfolio:', error);
        } finally {
        }
    };

    const deleteLive = (liveIndex) => {
        postDeleteLive(liveIndex);
    };

    const postDeleteDocument = async (documentIndex) => {
        try {
            let res = await expoClient.showcase.delete(documentIndex, expoUser.token);
            if (typeof res !== 'undefined') {
                refreshCompanyDocument();
            }
        } catch (error) {
            console.error('error in deleting document:', error);
        } finally {
        }
    };

    const deleteDocument = (documentIndex) => {
        postDeleteDocument(documentIndex);
    };

    //New Media start
    const posNewtPortfolioData = async () => {
        //location.state.token;
        //await expoClient.company.modify(company, token);
        try {
            let res = await expoClient.company.createItem(company.id, newPortfolio, expoUser.token);
            if (typeof res !== 'undefined') {
                //item = await expoClient.company.getShowcaseItems(item.companyId,response.data,location.state.token);
                refreshPortfolioInCompany();
            }
        } catch (error) {
            console.error('posNewtPortfolioData:', error);
        } finally {
        }
        setShowModalInsertPortfolio(false);
    };

    //Gestione new portfolio start
    const changeFileDataPortFolio = (isEdit, changed, fromDiscard) => {
        setEditMode(isEdit);
        if (changed) {
            posNewtPortfolioData();
        }
        //Faccio sparire la modale
        if (fromDiscard) setShowModalInsertPortfolio(false);
    };

    //Funzioni CallBack per la modifica dei vari componenti e mi tengo in pancia i nuovi valori
    const changedPortfolioDescription = (description) => {
        newPortfolio.description = description;
    };

    const refreshPortfolioInCompany = async () => {
        let companyNew = Object.assign({}, company);
        const portRes = await expoClient.company.getShowcaseItems(
            company.id,
            expoConfig.domain.showcaseitem.portfolio,
            expoUser.token,
        );
        companyNew.items.portfolio = portRes.data;
        setCompany(companyNew);
    };

    const setShowModalInsertPortfolioFromPlus = () => {
        let newPortfolioLocal = {
            //name: placeHolderName,
            description: '',
            typeId: expoConfig.domain.showcaseitem.portfolio,
            companyId: company.id,
            bannerPath: null,
            logoPath: null,
            media: {},
        };
        setNewPortfolio(newPortfolioLocal);
        setStatusUpload('normal');
        setShowModalInsertPortfolio(true);
    };
    //Gestione new portfolio end

    //Gestione new Live start

    const posNewtLiveData = async (formData) => {
        try {
            setStatusUpload('active');
            setLoadingPercentage(0);
            let res = await expoClient.company.createLive(company.id, formData, expoUser.token, setLoadingPercentage);
            if (typeof res !== 'undefined') {
                closeModalInsertLive();
                const desc = t('uploading_ok_notification');
                notification.success({
                    key: 'postCreateLiveOk',
                    message: desc,
                    placement: 'topRight',
                    duration: 7,
                });
                refreshCompanyLive();
            }
        } catch (error) {
            console.error('posNewLive:', error);
            setStatusUpload('exception');
        } finally {
        }
    };

    const setShowModalInsertLiveFromPlus = (e) => {
        let newLiveLocal = {
            //name: placeHolderName,
            description: '',
            video: null,
        };
        setNewLive(newLiveLocal);
        setShowModalInsertLive(true);
    };

    const changeFileDataLive = (isEdit, changed, fromDiscard, formData) => {
        setEditMode(isEdit);
        if (changed) {
            posNewtLiveData(formData);
        }
        //Faccio sparire la modale
        if (fromDiscard) closeModalInsertLive();
    };

    const closeModalInsertLive = () => {
        setShowModalInsertLive(false);
        setLoadingPercentage(0);
        setStatusUpload('normal');
    };

    const closeRunningLive = () => {
        setShowModalVideo(false);
    };

    //Gestione new Live end

    const goToMyShowCase = () => {
        setCompanies(null);
        setIndex(null);
        history.push('/company');
    };

    //Gestione richiesta contatto B2B controllo richieste già esistenti
    const checkRequestContact = async () => {
        try {
            let res = await expoClient.chambers.checkRequestContact(expoUser.chamber.id, company.id, expoUser.token);
            if (res.data) {
                setRequestContactMessage(t('ask_for_request_confirmation_already_made'));
            } else {
                setRequestContactMessage(
                    t('ask_for_request_confirmation', { 0: expoUser.chamber.name, 1: company.name }),
                );
            }
        } catch (error) {
            console.error('postContactRequest:', error);
        } finally {
        }
    };

    //Gestione richiesta contatto B2B controllo richieste già esistenti
    const checkRequestAdvice = async () => {
        try {
            // let res = await expoClient.chambers.checkRequestAdvice(expoUser.chamber.id, expoUser.token);
            // if (res.data) {
            // 	setRequestAdviceMessage(t('ask_for_request_confirmation_already_made'));
            // } else {
            setRequestAdviceMessage(t('ask_for_advice_confirmation', { 0: expoUser.chamber.name, 1: company.name }));
            // }
        } catch (error) {
            console.error('postAdviceRequest:', error);
        } finally {
        }
    };

    //Gestione richiesta contatto B2B
    const postRequestContact = async () => {
        try {
            await expoClient.chambers.requestContact(expoUser.chamber.id, company.id, expoUser.token);
            const desc = t('request_contact_ok');
            notification.success({
                key: 'postputUser',
                message: desc,
                placement: 'topRight',
                duration: 7,
            });
        } catch (error) {
            console.error('postContactRequest:', error);
            const msg = t('error');
            let desc = t('request_contact_nok');
            notification.error({
                key: 'postRegistrationError',
                message: msg,
                description: desc,
                placement: 'topRight',
                duration: 0,
            });
        } finally {
        }
    };

    //Gestione richiesta consulenza
    const postRequestAdvice = async (messageArea) => {
        try {
            await expoClient.chambers.requestAdvice(expoUser.chamber.id, messageArea, expoUser.token);
            const desc = t('request_contact_ok');
            notification.success({
                key: 'postputUser',
                message: desc,
                placement: 'topRight',
                duration: 7,
            });
        } catch (error) {
            console.error('postContactRequest:', error);
            const msg = t('error');
            let desc = t('request_contact_nok');
            notification.error({
                key: 'postRegistrationError',
                message: msg,
                description: desc,
                placement: 'topRight',
                duration: 0,
            });
        } finally {
        }
    };

    //Gestione richiesta contatto B2B
    const alertNoLogoNoBanner = async () => {
        const msg = t('warning');
        let desc = t('insert_banner_logo');
        notification.error({
            key: 'postRegistrationError',
            message: msg,
            description: desc,
            placement: 'topRight',
            duration: 10,
        });
    };

    const alertNoCompanyPublish = async () => {
        const msg = t('warning');
        let desc = t('company_unpublish_no_b2b', { 0: company.name });
        notification.error({
            key: 'postRegistrationError',
            message: msg,
            description: desc,
            placement: 'topRight',
            duration: 10,
        });
    };

    //Gestione setting registrazione start
    const postChangeRegistration = async () => {
        try {
            if (registrationStatus === expoConfig.domain.registration.active) {
                let res = await expoClient.chambers.publishCompany(expoUser.chamber.id, company.id, expoUser.token);
                if (typeof res !== 'undefined') {
                    setRegistrationStatus(expoConfig.domain.registration.published);
                }
            }
            if (registrationStatus === expoConfig.domain.registration.published) {
                let res = await expoClient.chambers.unPublishCompany(expoUser.chamber.id, company.id, expoUser.token);
                if (typeof res !== 'undefined') {
                    setRegistrationStatus(expoConfig.domain.registration.active);
                }
            }
        } catch (error) {
            console.error('postChangeRegistration:', error);
        } finally {
        }
        setShowModalInsertPortfolio(false);
    };
    //Gestione setting registrazione end

    //Gestione sospensione start
    const postSuspendRegistration = async () => {
        try {
            let message = document.getElementById('suspendMessage').value;
            let res = await expoClient.chambers.suspendCompany(
                expoUser.chamber.id,
                company.id,
                message,
                expoUser.token,
            );
            if (res.status === 200) {
                setRegistrationStatus(expoConfig.domain.registration.suspended);
                const desc = t('request_suspended_ok');
                notification.success({
                    key: 'suspended',
                    message: desc,
                    placement: 'topRight',
                    duration: 7,
                });
                history.push('/companiesuspended');
            }
        } catch (error) {
            console.error('postChangeRegistration:', error);
        } finally {
        }
        setShowModalSuspendCompany(false);
    };
    //Gestione sospensione end

    //Gestione modifica tags start
    const modifyTags = () => {
        setEditMode(true);
        setShowModifyTags(true);
    };

    const closeModifyTags = () => {
        setEditMode(false);
        setShowModifyTags(false);
        setDeselected([]);
    };

    const closeVideo = () => {
        setShowModalVideo(false);
    };

    const checkValuesTags = (changed, values) => {
        let tagSelected = Object.keys(values).filter((k) => k.startsWith('tag_') && values[k]);
        let deSelected_ = Object.keys(values)
            .filter((k) => k.startsWith('tag_') && !values[k])
            .map((v) => parseInt(v.slice(4)));

        let tagGlobalLenght = company.tags
            .map((v) => parseInt(v.id))
            .concat(tagSelected)
            .filter((v) => !deSelected_.includes(v)).length;
        setDeselected(deSelected_);

        if (tagGlobalLenght > expoConfig.signup.max_tag_selected) {
            const desc = t('signup_error_too_many_tag');
            notification.error({
                key: 'postCreateLiveOk',
                description: desc,
                placement: 'topRight',
                duration: 10,
            });
        }
    };

    const postDataTags = async (values) => {
        let tagSelected = Object.keys(values)
            .filter((k) => k.startsWith('tag_') && values[k])
            .map((v) => parseInt(v.slice(4)));
        let tagGlobal =
            deSelected.length !== 0
                ? company.tags
                      .map((v) => parseInt(v.id))
                      .concat(tagSelected)
                      .filter((v) => !deSelected.includes(v))
                : company.tags.map((v) => parseInt(v.id)).concat(tagSelected);

        if (tagGlobal.length > expoConfig.signup.max_tag_selected) {
            const desc = t('signup_error_too_many_tag');
            notification.error({
                key: 'postCreateLiveOk',
                description: desc,
                placement: 'topRight',
                duration: 10,
            });
            return;
        }

        try {
            let tagsToSend = {
                tagIdList: tagGlobal,
            };
            await expoClient.chambers.modifyTags(expoUser.chamber.id, company.id, tagsToSend, expoUser.token);
            //Refresh everything
        } catch (error) {
            console.error('error in creatings tags:', error);
        } finally {
            closeModifyTags();
            initialCompanyId = company.id;
            readCompany(company.id);
            window.scrollTo(0, 0);
        }
    };
    //Gestione modifica tags end

    //Gestione new Document start
    const postNewDocumentData = async (formData, title) => {
        try {
            setStatusUpload('active');
            setLoadingPercentage(0);
            let response = await expoClient.company.createDoc(
                company.id,
                formData,
                expoUser.token,
                setLoadingPercentage,
            );
            if (typeof response !== 'undefined') {
                let responseNewDocument = await expoClient.showcase.get(response.data[0], expoUser.token);
                let modifiedDocument = responseNewDocument.data;
                modifiedDocument.name = title;
                await expoClient.showcase.modifyItem(response.data[0], modifiedDocument, expoUser.token);
                closeModalInsertDocument();
                const desc = t('uploading_ok_notification');
                notification.success({
                    key: 'postCreateLiveOk',
                    message: desc,
                    placement: 'topRight',
                    duration: 7,
                });
                refreshCompanyDocument();
            }
        } catch (error) {
            console.error('posNewLDocument:', error);
            setStatusUpload('exception');
        } finally {
        }
    };

    const setShowModalInsertDocumentFromPlus = (e) => {
        let newDocumentLocal = {
            //name: placeHolderName,
            description: '',
            video: null,
        };
        setNewDocument(newDocumentLocal);
        setShowModalInsertDocument(true);
    };

    const changeFileDataDocument = (isEdit, changed, fromDiscard, formData, title) => {
        setEditMode(isEdit);
        if (changed) {
            postNewDocumentData(formData, title);
        }
        //Faccio sparire la modale
        if (fromDiscard) closeModalInsertDocument();
    };

    const closeModalInsertDocument = () => {
        setShowModalInsertDocument(false);
        setLoadingPercentage(0);
        setStatusUpload('normal');
    };

    const closeModalInsertPortfolio = () => {
        setShowModalInsertPortfolio(false);
    };

    const closeModalSuspendCompany = () => {
        setShowModalSuspendCompany(false);
    };

    //Funzioni CallBack per la modifica dei vari componenti e mi tengo in pancia i nuovi valori
    const handleAfterChangeMobile = (current) => {};

    const setShowModalPortfolioWrapper = (openclose, id) => {
        setCurrentMediaId(id);
        setShowModalPortfolio(openclose);
    };

    const setShowModalVideoWrapper = (openclose, id) => {
        setCurrentMediaId(id);
        setShowModalVideo(openclose);
    };

    const onAddItemCallbackLive = () => {
        setShowModalInsertLiveFromPlus();
    };
    const onDeleteItemCallBackLive = (idLive) => {
        deleteLive(idLive);
    };
    const onVieItemCallBackLive = (idLive) => {
        setShowModalVideoWrapper(true, idLive);
    };

    const onAddItemCallbackPortfolio = () => {
        setShowModalInsertPortfolioFromPlus();
    };
    const onDeleteItemCallbackPortfolio = (idPortfolio) => {
        deletePortfolio(idPortfolio);
    };
    const onVieItemCallBackPortfolio = (idPortfolio) => {
        setShowModalPortfolioWrapper(true, idPortfolio);
    };

    const onAddItemCallbackDocument = () => {
        setShowModalInsertDocumentFromPlus();
    };
    const onDeleteItemCallbackDocument = (idDocument) => {
        deleteDocument(idDocument);
    };
    const onVieItemCallBackDocument = (idDocument) => {};

    const onDownloadItemCallBackDocument = (documentName, documentTitle, extension) => {
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute('href', documentName);
        downloadAnchorNode.setAttribute('target', '_blank');
        downloadAnchorNode.setAttribute('download', documentTitle + '.' + extension);
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    };

    const onAddItemCallbackProduct = () => {
        history.push('/item', {
            expoUser: expoUser,
            itemType: expoConfig.domain.showcaseitem.product,
        });
    };

    const onDeleteItemCallbackProduct = (idProdotto) => {
        posDeleteProduct(idProdotto);
    };

    const onViewItemCallBackProduct = (idProdotto) => {
        history.push('/item', {
            itemId: idProdotto,
            expoUser: expoUser,
        });
    };

    const posDeleteProduct = async (idProdotto) => {
        try {
            let res = await expoClient.showcase.delete(idProdotto, expoUser.token);
            if (typeof res !== 'undefined') {
                refreshCompanyProduct();
            }
        } catch (error) {
            console.error('error in deleting media:', error);
        } finally {
        }
    };

    const refreshCompanyProduct = async () => {
        if (company && company.id) {
            let companyNew = Object.assign({}, company);
            const portRes = await expoClient.company.getShowcaseItems(
                company.id,
                expoConfig.domain.showcaseitem.product,
                expoUser.token,
            );
            companyNew.items.product = portRes.data;
            setCompany(companyNew);
        }
    };

    const transitToDataPageFromToolBar = (e) => {
        transitToDataPage(e, -1);
        if (displayDataPageChamber === 'block') setDisplayDataPageChamber('none');
    };

    const transitToDataPageChamber = (e) => {
        if (e) e.stopPropagation();
        if (displayDataPageChamber === 'none') setDisplayDataPageChamber('block');
        else setDisplayDataPageChamber('none');
        if (displayDataPage === 'block') {
            setDisplayDataPage('none');
            setCurrentDataPage(-1);
        }
    };

    const transitToDataPage = (e, pageToGo) => {
        if (e) e.stopPropagation();
        if (pageToGo === currentDataPage || pageToGo < 0) {
            setDisplayDataPage('none');
            setCurrentDataPage(-1);
        } else {
            setDisplayDataPage('block');
            setCurrentDataPage(pageToGo);
        }
        if (displayDataPageChamber === 'block') setDisplayDataPageChamber('none');
    };

    const goToCompanyItaly = (companyId) => {
        goToCompany(companyId, expoConfig.domain.entity_type.c_ita);
    };

    const goToCompanyForeigns = (companyId) => {
        goToCompany(companyId, expoConfig.domain.entity_type.c_other);
    };

    const goToCompany = (companyId, companyType) => {
        setDisplayDataPage('none');
        let indice = null;
        let companyList =
            companyType === expoConfig.domain.entity_type.c_ita
                ? expoUser.entityTypeId !== expoConfig.domain.entity_type.chamber
                    ? expoUser.chamber.items.italy.filter((item) => item.id !== expoUser.entityId)
                    : expoUser.chamber.items.italy
                : expoUser.entityTypeId !== expoConfig.domain.entity_type.chamber
                ? expoUser.chamber.items.noItaly.filter((item) => item.id !== expoUser.entityId)
                : expoUser.chamber.items.noItaly;

        for (let i = 0; i < companyList.length; i++) {
            if (companyList[i].id === companyId) {
                indice = i;
                break;
            }
        }

        if (indice !== null) {
            setCompanies(companyList);
            setIndex(indice);
        }

        initialCompanyId = companyId;
        readCompany(companyId);
        window.scrollTo(0, 0);
        //Metto nella history il cambio di company cosi funziona il back
        history.push('/company', {
            companyId: companyId,
            index: indice,
            items: companyList,
        });
    };

    const {
        view_companies_showcase,
        b2b_request,
        basic_showcase_edit,
        view_products_showcase,
    } = authorization.getAutorizations(expoUser);

    const imageUrl =
        company && company.bannerPath && company.bannerPath !== '' ? company.bannerPath : defaultCompanyImage;
    const logoUrl = company && company.logoPath && company.logoPath !== '' ? company.logoPath : defaultLogoImage;

    const matchingScore =
        company && company.matchingScore ? (
            <div className="matching_score_wrapper">
                <MatchingIcon className="matching_icon" style={{ marginRight: '2px' }} />
                <div className="matching_score_value">{Math.round(company.matchingScore)}</div>
            </div>
        ) : null;

    const closeLiveModal = isEditable ? (
        <ConfirmModal onOk={() => closeModalInsertLive()} message={t('ask_for_close_live')}>
            <CloseButton className="exitButtonOnLoading" />
        </ConfirmModal>
    ) : null;

    const closeRunningLiveModal = (
        <ConfirmModal onOk={() => closeRunningLive()} message={t('ask_for_close_live')}>
            <CloseButton className="exitButtonOnLoading" />
        </ConfirmModal>
    );

    const closeTagModal = isEditable ? (
        <ConfirmModal onOk={() => closeModifyTags()} message={t('ask_for_close_live')}>
            <CloseButton className="exitButtonOnLoading" />
        </ConfirmModal>
    ) : null;

    const closeVideoModal = <CloseButton className="exitButtonOnLoading" onClick={() => closeVideo()} />;

    const closeDocumentModal = isEditable ? (
        <ConfirmModal onOk={() => closeModalInsertDocument()} message={t('ask_for_close_live')}>
            <CloseButton className="exitButtonOnLoading" />
        </ConfirmModal>
    ) : null;

    const closePortfolioModal = isEditable ? (
        <ConfirmModal onOk={() => closeModalInsertPortfolio()} message={t('ask_for_close_live')}>
            <CloseButton className="exitButtonOnLoading" />
        </ConfirmModal>
    ) : null;

    if (showModalPortfolio && company && company.items && company.items.portfolio)
        company.items.portfolio.map((m, index_) => computeInitial(m, index_));
    if (showModalVideo && company && company.items && company.items.live)
        company.items.live.map((m, index_) => computeInitial(m, index_));

    let chamberName = '';
    if (expoUser && expoUser.chamber && typeof expoUser.chamber !== 'undefined') chamberName = expoUser.chamber.name;
    const askForRegConfirmation =
        expoUser && registrationStatus === expoConfig.domain.registration.active
            ? t('ask_for_publish', { 0: chamberName })
            : t('ask_for_unpublish', { 0: chamberName });
    const classNameRegButton =
        registrationStatus === expoConfig.domain.registration.active ? 'publishCompany' : 'unpublishCompany';
    const registrationLabel =
        isEditable &&
        (registrationStatus === expoConfig.domain.registration.active ||
            registrationStatus === expoConfig.domain.registration.published)
            ? registrationStatus === expoConfig.domain.registration.active
                ? t('publish_company').toUpperCase()
                : t('unpublish_company').toUpperCase()
            : null;
    const regButtonIco =
        isEditable &&
        (registrationStatus === expoConfig.domain.registration.active ||
            registrationStatus === expoConfig.domain.registration.published) ? (
            <RegistrationButton className={classNameRegButton} style={{ width: '30px' }} />
        ) : null;
    const regButtonIcoDisabled =
        isEditable &&
        (registrationStatus === expoConfig.domain.registration.active ||
            registrationStatus === expoConfig.domain.registration.published) ? (
            <RegistrationButton className="dataIconsDisabled" style={{ width: '30px' }} onClick={alertNoLogoNoBanner} />
        ) : null;
    const contactButtonLabel = t('chamber_request_contact_upper');
    const adviceButtonLabel = t('chamber_request_advice_upper');
    const myShowCaseButtonLabel = t('my_show_case');

    const contactButtonToolbar =
        !isEditable && expoUser && expoUser.chamber && company ? (
            registrationStatus === expoConfig.domain.registration.published ? (
                <ConfirmModal
                    onOk={() => postRequestContact()}
                    onClick={() => checkRequestContact()}
                    message={requestContactMessage} //t('ask_for_request_confirmation'
                >
                    <IconAndLabel label={contactButtonLabel}>
                        <ContactButton className="dataIcons" style={{ width: '30px' }} />
                    </IconAndLabel>
                </ConfirmModal>
            ) : (
                <IconAndLabel label={contactButtonLabel}>
                    <ContactButton
                        onClick={alertNoCompanyPublish}
                        className="dataIconsDisabled"
                        style={{ width: '30px' }}
                    />
                </IconAndLabel>
            )
        ) : null;

    const adviceButtonToolbar =
        b2b_request && expoUser && expoUser.chamber && company ? (
            registrationStatus === expoConfig.domain.registration.published ? (
                <ConfirmModal
                    onOk={postRequestAdvice}
                    onClick={() => checkRequestAdvice()}
                    message={requestAdviceMessage} //t('ask_for_request_confirmation'
                    messageArea={true} //t('ask_for_request_confirmation'
                >
                    <IconAndLabel label={adviceButtonLabel}>
                        <ContactButton className="dataIcons" style={{ width: '30px' }} />
                    </IconAndLabel>
                </ConfirmModal>
            ) : (
                <IconAndLabel label={adviceButtonLabel}>
                    <ContactButton
                        onClick={alertNoCompanyPublish}
                        className="dataIconsDisabled"
                        style={{ width: '30px' }}
                    />
                </IconAndLabel>
            )
        ) : null;

    const contactButton =
        b2b_request && !isEditable && expoUser && expoUser.chamber && company ? (
            registrationStatus === expoConfig.domain.registration.published ? (
                <ConfirmModal
                    onOk={() => postRequestContact()}
                    onClick={() => checkRequestContact()}
                    message={requestContactMessage}
                >
                    <p className="askEventButton" style={{ cursor: 'pointer' }}>
                        {t('ask_for_b2b_event')}
                    </p>
                </ConfirmModal>
            ) : (
                <p onClick={alertNoCompanyPublish} className="askEventButton">
                    {t('ask_for_b2b_event')}
                </p>
            )
        ) : null;

    const settingsButton = (
        <Link onClick={(e) => transitToDataPage(e, 0)} className={currentDataPage === 0 ? 'icon-selected' : null}>
            <IconAndLabel label={t('company_data_main_label').toUpperCase()} onClick={(e) => transitToDataPage(e, 0)}>
                <SettingsButton style={{ width: '30px' }} />
            </IconAndLabel>
        </Link>
    );

    const membersButton = (
        <Link onClick={(e) => transitToDataPage(e, 1)} className={currentDataPage === 1 ? 'icon-selected' : null}>
            <IconAndLabel
                label={t('company_data_user_label_icon').toUpperCase()}
                onClick={(e) => transitToDataPage(e, 1)}
            >
                <MembersButton style={{ width: '30px' }} />
            </IconAndLabel>
        </Link>
    );

    const profileAccessButton = (
        <Link onClick={(e) => transitToDataPage(e, 2)} className={currentDataPage === 2 ? 'icon-selected' : null}>
            <IconAndLabel label={t('company_data_profile_access_button_label').toUpperCase()}>
                <ProfileAccessButton className="dataIcons" style={{ width: '30px' }} />
            </IconAndLabel>
        </Link>
    );

    const suspendButton = (
        <Link onClick={() => setShowModalSuspendCompany(true)}>
            <IconAndLabel label={t('company_suspen_button_label')}>
                <SuspendCompany className="dataIcons" style={{ width: '30px' }} />
            </IconAndLabel>
        </Link>
    );
    const settingsButton2 = (
        <Link
            onClick={(e) => transitToDataPageChamber(e, 3)}
            className={currentDataPage === 3 ? 'icon-selected' : null}
        >
            <IconAndLabel
                label={t('company_data_main_label').toUpperCase()}
                onClick={(e) => transitToDataPageChamber(e)}
            >
                <SettingsButton className="dataIconsSettings" style={{ width: '30px' }} />
            </IconAndLabel>
        </Link>
    );

    const personalShowcaseButton = (
        <Link onClick={() => goToMyShowCase()}>
            <IconAndLabel label={myShowCaseButtonLabel}>
                <MyShowCaseButton className="dataIcons" style={{ width: '30px' }} />
            </IconAndLabel>
        </Link>
    );

    const publishButton =
        company &&
        (company.bannerPath || company.bannerImageBase64) &&
        (company.logoPath || company.logoImageBase64) &&
        company.description ? (
            <ConfirmModal onOk={() => postChangeRegistration()} message={askForRegConfirmation}>
                <IconAndLabel label={registrationLabel}>{regButtonIco}</IconAndLabel>
            </ConfirmModal>
        ) : (
            <IconAndLabel label={registrationLabel}>{regButtonIcoDisabled}</IconAndLabel>
        );

    const events = company && company.items && company.items.events ? company.items.events : [];

    const getLive = () => {
        if (company && company.items && company.items.live.length > 0) return company.items.live;
        return null;
    };
    const live = getLive();
    const getProducts = () => {
        if (company && company.items && company.items.product.length > 0) return company.items.product;
        return null;
    };
    const products = getProducts();
    const getPortfolio = () => {
        if (company && company.items && company.items.portfolio.length > 0) return company.items.portfolio;
        return null;
    };
    const portfolio = getPortfolio();
    const getDocuments = () => {
        if (company && company.items && company.items.documents && company.items.documents.length > 0)
            return company.items.documents;
        return null;
    };
    const documents = getDocuments();
    const getItalians = () => {
        //const camera = chamber ? chamber : expoUser.chamber
        //if (company && expoUser && expoUser.chamber && expoUser.chamber.items && expoUser.chamber.items.italy.length > 0) {
        if (company && expoUser.chamber && expoUser.chamber.items && expoUser.chamber.items.italy) {
            return expoUser.chamber.items.italy.filter((c) => c.id !== company.id);
            //return expoUser.entityTypeId !== expoConfig.domain.entity_type.chamber ? toReturn.filter(item => item.id !== expoUser.entityId) : toReturn;
        }
        return null;
    };
    const italians = getItalians();
    const getForeigns = () => {
        //const camera = chamber ? chamber : expoUser.chamber
        //if (expoUser && expoUser.chamber && expoUser.chamber.items && expoUser.chamber.items.noItaly.length > 0) {
        if (company && expoUser.chamber && expoUser.chamber.items && expoUser.chamber.items.noItaly) {
            return expoUser.chamber.items.noItaly.filter((c) => c.id !== company.id);
        }
        return null;
    };
    const foreigns = getForeigns();

    //Le frecce nella toolbar ci vanno solo se non sono una chamber ma sono in  non pubblica
    //oppure un'altra company e quindi non pubblica per cui isEditable è la giusta condizione
    const prevCompany = !isCompanyUser
        ? () => {
              let ind = companies !== null && index === 0 ? companies.length - 1 : index - 1;
              setIndex(ind);
              if (companies !== null && companies.length > 1) {
                  history.push('/company', {
                      companyId: companies[ind].id,
                      index: ind,
                      items: companies,
                  });
              }
          }
        : null;
    const nextCompany = !isCompanyUser
        ? () => {
              let ind = companies !== null && index === companies.length - 1 ? 0 : index + 1;
              setIndex(ind);
              if (companies !== null && companies.length > 1) {
                  history.push('/company', {
                      companyId: companies[ind].id,
                      index: ind,
                      items: companies,
                  });
              }
          }
        : null;

    let chosenVideo = null;
    const changedSuspensionMessage = (mess) => {
        setTempSuspensionMessage(mess);
        if (mess.length < 40) {
            if (suspensionMessageColor != 'red') {
                setSuspensionMessageColor('red');
            }
            setDisableSuspensionButton(true);
        } else {
            if (suspensionMessageColor != 'black') {
                setSuspensionMessageColor('black');
            }
            setDisableSuspensionButton(false);
        }
    };
    chosenVideo =
        company && company.items && company.items.live
            ? company.items.live.filter((l) => l.id === currentMediaId)[0]
            : null;

    const videoToShow = chosenVideo ? chosenVideo.bannerPath : null;

    return company ? (
        <>
            <Modal
                key="modalModifyTags"
                open={showModifyTags}
                onClose={() => closeModifyTags()}
                closeOnDimmerClick={false}
                closeOnEscape={false}
                closeOnDocumentClick={false}
                closeOnTriggerMouseLeave={false}
                closeOnTriggerClick={false}
                className={'modalTagResponsive'}
            >
                <Form ref={tagFormRef} name="modTagForm" onValuesChange={checkValuesTags} onFinish={postDataTags}>
                    <Row align="middle">
                        <Col key="1" xs={24} className="tagModalTitle">
                            <span>{t('company_modify_content')}</span>
                        </Col>
                        <Col key="2" span={24} className="tagModalSubTitle">
                            <span>{t('signup_title_step_2')}</span>
                        </Col>
                        <Col
                            key="3"
                            className="signup-carousel-page"
                            xl={{ span: 12, offset: 6 }}
                            xs={{ span: 22, offset: 1 }}
                            style={{ marginTop: '10px' }}
                        >
                            <AccordionTopics topics={topics} alreadySelectedTags={company.tags} t={t} />
                        </Col>
                        <Col
                            key="4"
                            xl={{ offset: 11, span: 3 }}
                            xs={{ span: 10, offset: 7 }}
                            md={{ span: 4, offset: 10 }}
                            align="middle"
                            style={{ fontSize: '11px', padding: '10px 0' }}
                        >
                            <Form.Item>
                                <Button htmlType="submit" block shape="round" className="blackMode">
                                    {t('company_data_button_save')}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {closeTagModal}
            </Modal>
            {
                <Modal
                    open={showModalVideo}
                    onClose={() => setShowModalVideo(false)}
                    onOpen={() => setShowModalVideo(true)}
                    closeOnDimmerClick={true}
                    closeOnEscape={false}
                    closeOnDocumentClick={false}
                    closeOnTriggerMouseLeave={false}
                    closeOnTriggerClick={false}
                    //onMount={() => handleAfterChangeMobile(0)}
                    style={{ backgroundColor: 'transparent' }}
                >
                    <Modal.Header style={{ backgroundColor: 'transparent' }} />
                    <Modal.Content style={{ backgroundColor: 'transparent' }}>
                        {chosenVideo ? (
                            <video
                                key={chosenVideo.id}
                                className="videoHook"
                                id={`video_${index}`}
                                src={videoToShow}
                                autoPlay
                                loop
                            />
                        ) : null}
                        {/* <Carousel autoplay={false} autoplaySpeed={60000} ref={carouselVideoRef} dots={false}
						initialSlide={initialPageLocal}
						style={{ backgroundColor: 'transparent !important', overflow: 'hidden' }}
						beforeChange={(from, to) => handleBeforeChange(from, to)}
						afterChange={(to) => handleAfterChange(to)}
						className={"carouselModelLive"}
					>

						 {company.items.live.map((m, index) => <video key={m.id} className="videoHook" id={`video_${index}`} name={m.id} type="video/mp4" src={m.bannerPath} alt={m.name} muted loop />)}
			</Carousel>*/}
                        {closeVideoModal}
                    </Modal.Content>
                </Modal>
            }
            <Modal
                key="modalPortfolio"
                open={showModalPortfolio}
                onClose={() => setShowModalPortfolio(false)}
                onMount={() => handleAfterChangePortfolio(0, 'portfolio_')}
                trigger={null}
                header={null}
                actions={null}
                style={{ backgroundColor: 'transparent', width: '85vw' }}
            >
                <Row align="middle">
                    <Col span="2" align="end">
                        <ArrowModalLeft
                            style={{ cursor: 'pointer', width: '60px' }}
                            onClick={() => carouselPortfolioModalRef.current.prev()}
                        />
                    </Col>
                    <Col span="20">
                        <Carousel
                            afterChange={(to) => handleAfterChangePortfolio(to, 'portfolio_')}
                            ref={carouselPortfolioModalRef}
                            initialSlide={initialPageLocal}
                            dots={false}
                            style={{ width: '85vw !important' }}
                        >
                            {company.items.portfolio.map((m, index) => (
                                <div key={m.id}>
                                    <Row className="porfolioModalDiv">
                                        <img
                                            className="imgPortfolio"
                                            id={`portfolio_${index}`}
                                            name={m.id}
                                            src={m.bannerPath}
                                            alt={m.name}
                                        />
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <textarea
                                                disabled={true}
                                                style={{
                                                    display: m.description && m.description !== '' ? 'block' : 'none',
                                                }}
                                                className="textAreaPorfolio"
                                                defaultValue={m.description}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </Carousel>
                    </Col>
                    <Col span="2">
                        <ArrowModalRight
                            style={{ cursor: 'pointer', margin: '0 auto', width: '60px' }}
                            onClick={() => carouselPortfolioModalRef.current.next()}
                        />
                    </Col>
                </Row>
            </Modal>
            <Modal
                className="modal-insert-portfolio"
                key="modalInsertPortfolio"
                open={showModalInsertPortfolio}
                /* per far chiudere modale cliccando al di fuori di essa */
                /* onClose={() => setShowModalInsertPortfolio(false)} */
                trigger={null}
                header={null}
                actions={null}
                style={{ border: '4px solid #707070', borderRadius: '16px' }}
            >
                <Modal.Header className="modal-media">{t('new_content_media').toUpperCase()}</Modal.Header>
                {closePortfolioModal}
                <Row align="middle" style={{ width: '100%' }}>
                    <ImageEditorModalTile
                        className="ImageEditorMedia"
                        setEditModeCallBackParent={changeFileDataPortFolio}
                        entity={newPortfolio}
                        on
                        globalEditState={false}
                        isEditable={true}
                        idInputFile={'idInputFileMedia'}
                        isMedia={false}
                        enableSave={true}
                    >
                        <div style={{ padding: '20px 50px 0px', width: '100%' }}>
                            <span style={{ fontSize: '20px' }}>
                                <b> {t('description')} </b>
                            </span>
                            <TextArea
                                maxLength={180}
                                style={{ margin: '20px 0px', fontSize: '18px' }}
                                onChange={(e) => changedPortfolioDescription(e.target.value)}
                                defaultValue={newPortfolio.description}
                                placeholder={placeHolderPortfolioDescription}
                            />
                        </div>
                    </ImageEditorModalTile>
                </Row>
            </Modal>
            <Modal
                className="modal-insert-live"
                key="modalInsertLive"
                open={showModalInsertLive}
                closeOnDimmerClick={false}
                closeOnEscape={false}
                closeOnDocumentClick={false}
                closeOnTriggerMouseLeave={false}
                closeOnTriggerClick={false}
                trigger={null}
                header={null}
                actions={null}
                style={{ border: '4px solid #707070', borderRadius: '16px' }}
            >
                <Modal.Header className="modal-media"> {t('new_content_media').toUpperCase()} </Modal.Header>
                <Row align="middle" style={{ width: '100%', height: '100%' }}>
                    <VideoEditor
                        className="ImageEditorMedia"
                        setEditModeCallBackParent={changeFileDataLive}
                        entity={newLive}
                        percent={loadingPercentage}
                        globalEditState={false}
                        statusUpload={statusUpload}
                        imageUrl={defaultMediaImage}
                        isEditable={true}
                        idInputFile={'idInputFileMedia'}
                        enableSave={true}
                        closeModalCallBack={closeModalInsertLive}
                        t={t}
                    />
                    {closeLiveModal}
                </Row>
            </Modal>
            <Modal
                className="modal-insert-document"
                key="modalInsertDocument"
                open={showModalInsertDocument}
                closeOnDimmerClick={false}
                closeOnEscape={false}
                closeOnDocumentClick={false}
                closeOnTriggerMouseLeave={false}
                closeOnTriggerClick={false}
                trigger={null}
                header={null}
                actions={null}
                style={{ border: '4px solid #707070', borderRadius: '16px' }}
            >
                <Row align="middle" style={{ display: 'flex', justifyContent: 'center' }}>
                    <DocumentEditor
                        className="ImageEditorMedia"
                        setEditModeCallBackParent={changeFileDataDocument}
                        entity={newDocument}
                        percent={loadingPercentage}
                        globalEditState={false}
                        statusUpload={statusUpload}
                        imageUrl={defaultMediaImage}
                        isEditable={true}
                        idInputFile={'idInputFileMedia'}
                        enableSave={true}
                        closeModalCallBack={closeModalInsertDocument}
                        t={t}
                    />
                    {closeDocumentModal}
                </Row>
            </Modal>
            <ModalSuspendedCompanies
                showModalSuspendCompany={showModalSuspendCompany}
                company={company}
                closeModalSuspendCompany={() => closeModalSuspendCompany}
            />

            <TopBar
                onClickCallBack={transitToDataPageFromToolBar}
                leftClick={prevCompany}
                rightClick={nextCompany}
                arrowsVisible={!isEditable}
            >
                {company ? (
                    isChamberUser ? (
                        <>
                            <div className="blackLink topBarIconCol">{settingsButton2}</div>
                            <div className="blackLink topBarIconCol">{profileAccessButton}</div>
                            <div className="blackLink topBarIconCol">{suspendButton}</div>
                        </>
                    ) : isEditable ? (
                        <>
                            <div className="blackLink topBarIconCol">{profileAccessButton}</div>
                            <div className="blackLink topBarIconCol">{settingsButton}</div>
                            <div className="blackLink topBarIconCol">{membersButton}</div>
                            <div className="blackLink topBarIconCol">
                                {contactRequestsNumber > 0 ? (
                                    <Link to={{ pathname: '/companyrequestb2b' }} style={{ display: 'flex' }}>
                                        <IconAndLabel label={t('request_b2b').toUpperCase()}>
                                            <Badge className="badge_request_b2b button_request_badge" status="Success">
                                                <IconRequestB2B className="dataIcons" style={{ width: '30px' }} />
                                            </Badge>
                                        </IconAndLabel>
                                    </Link>
                                ) : (
                                    <IconAndLabel label={t('request_b2b').toUpperCase()}>
                                        <IconRequestB2B className="dataIconsDisabled" style={{ width: '30px' }} />
                                    </IconAndLabel>
                                )}
                            </div>
                            <div className="blackLink topBarIconCol">{publishButton}</div>

                            <div className="blackLink topBarIconCol">{adviceButtonToolbar}</div>
                        </>
                    ) : isCompanyUser ? (
                        <>
                            <div className="blackLink topBarIconCol">{settingsButton}</div>
                            <div className="blackLink topBarIconCol">{membersButton}</div>
                        </>
                    ) : b2b_request ? (
                        <>
                            <div className="blackLink topBarIconCol">{personalShowcaseButton}</div>
                            <div className="blackLink topBarIconCol">{contactButtonToolbar}</div>
                        </>
                    ) : (
                        <div className="blackLink topBarIconCol">{personalShowcaseButton}</div>
                    )
                ) : null}
                {/*<Col span={24}>*/}
                {/* utente camera dentro pagina azienda  */}
                {/*company && isChamberUser ? (
                        <Row>
                            <Col style={{ margin: 'auto', textAlign: 'center', height: '50px' }}>
                                <Space direction="vertical" size={4}>
                                    <SettingsButton
                                        className="dataIconsSettings"
                                        style={{ width: '30px' }}
                                        onClick={(e) => transitToDataPageChamber(e)}
                                    />
                                    {t('company_data_main_label')}
                                </Space>
                            </Col>
                        </Row>
                    ) : null*/}
                {/* utente azienda amministratore dentro propria pagina azienda  */}
                {/* company && !isChamberUser && isEditable ? (
                        <Row>
                            <Col
                                xxl={{ span: 2, offset: 8 }}
                                xl={{ span: 2, offset: 6 }}
                                xs={{ span: 2, offset: 6 }}
                                md={{ span: 2, offset: 5 }}
                                sm={{ span: 2, offset: 5 }}
                                className="blackLink topBarIconCol"
                            >
                                {profileAccessButton}
                            </Col>
                            <Col
                                xxl={{ span: 2, offset: 0 }}
                                xl={{ span: 2, offset: 1 }}
                                xs={{ span: 2, offset: 1 }}
                                md={{ span: 2, offset: 2 }}
                                sm={{ span: 2, offset: 2 }}
                                className="blackLink topBarIconCol"
                            >
                                {settingsButton}
                            </Col>
                            <Col
                                xxl={{ span: 2, offset: 0 }}
                                xl={{ span: 2, offset: 1 }}
                                xs={{ span: 2, offset: 1 }}
                                md={{ span: 2, offset: 2 }}
                                sm={{ span: 2, offset: 2 }}
                                className="blackLink topBarIconCol"
                            >
                                {membersButton}
                            </Col>
                            <Col
                                xxl={{ span: 2, offset: 0 }}
                                xl={{ span: 2, offset: 1 }}
                                xs={{ span: 2, offset: 1 }}
                                md={{ span: 2, offset: 2 }}
                                sm={{ span: 2, offset: 2 }}
                                className="blackLink topBarIconCol"
                            >
                                {publishButton}
                            </Col>
                        </Row>
                    ) : null*/}
                {/* utente azienda standard dentro propria pagina azienda*/}
                {/* company && !isChamberUser && !isEditable && isCompanyUser ? (
                        <Row>
                            <Col
                                xl={{ span: 2, offset: 10 }}
                                xs={{ span: 2, offset: 8 }}
                                md={{ span: 2, offset: 9 }}
                                className="blackLink topBarIconCol"
                            >
                                {settingsButton}
                            </Col>
                            <Col
                                xl={{ span: 2, offset: 0 }}
                                xs={{ span: 2, offset: 4 }}
                                md={{ span: 2, offset: 2 }}
                                className="blackLink topBarIconCol"
                            >
                                {membersButton}
                            </Col>
                        </Row>
                    ) : null*/}
                {/* utente azienda standard dentro pagina di una altra azienda*/}
                {/* company && !isChamberUser && !isEditable && !isCompanyUser ? (
                        b2b_request ? (
                            <Row>
                                <Col
                                    xxl={{ span: 2, offset: 10 }}
                                    xl={{ span: 2, offset: 9 }}
                                    xs={{ span: 4, offset: 3 }}
                                    md={{ span: 2, offset: 7 }}
                                    className="blackLink topBarIconCol"
                                >
                                    <Link onClick={() => goToMyShowCase()}>
                                        <IconAndLabel label={myShowCaseButtonLabel}>
                                            <MyShowCaseButton className="dataIcons" style={{ width: '30px' }} />
                                        </IconAndLabel>
                                    </Link>
                                </Col>
                                <Col
                                    xxl={{ span: 2, offset: 0 }}
                                    xl={{ span: 2, offset: 2 }}
                                    xs={{ span: 4, offset: 10 }}
                                    md={{ span: 2, offset: 6 }}
                                    className="blackLink topBarIconCol"
                                >
                                    {contactButtonToolbar}
                                </Col>
                            </Row>
                        ) : (
                            <Row>
                                <Col
                                    className="blackLink topBarIconCol"
                                    style={{ margin: 'auto', textAlign: 'center', height: '50px' }}
                                >
                                    <Link onClick={() => goToMyShowCase()}>
                                        <IconAndLabel label={myShowCaseButtonLabel}>
                                            <MyShowCaseButton className="dataIcons" style={{ width: '30px' }} />
                                        </IconAndLabel>
                                    </Link>
                                </Col>
                            </Row>
                        )
                    ) : null*/}
                {/*</Col>*/}
            </TopBar>

            <Row className="companySection">
                <Col className="companyTop" span={24}>
                    <Row style={{ width: '100%', display: displayDataPage }}>
                        <CompanyUsers
                            isEditable={isEditable}
                            companyUsers={companyUsers}
                            toShow={currentDataPage}
                            company={company}
                            expouser={expoUser}
                            callBackForModifyUserData={refreshData}
                            isChamberUser={isChamberUser}
                            logout={logout}
                        />
                    </Row>
                    <Row style={{ width: '100%', display: displayDataPageChamber }}>
                        <CompanyData
                            isEditable={isEditable}
                            companyUsers={companyUsers}
                            toShow={currentDataPage}
                            company={company}
                            expouser={expoUser}
                        />
                    </Row>
                    <Row className="companyTopRow">
                        {matchingScore}
                        <Col key="1" xl={12} xs={24}>
                            <ImageEditor
                                entity={company}
                                className="logoMode"
                                setEditModeCallBackParent={setEditModeCallBack}
                                globalEditState={editMode}
                                imageUrl={imageUrl}
                                isEditable={isEditable}
                                idInputFile={'idInputFileCompany'}
                                ImageEditor={false}
                                enableSave={false}
                            />

                            <div className="profile">
                                <ImageEditorModal
                                    entity={company}
                                    setEditModeCallBackParent={setEditModeCallBack}
                                    globalEditState={editMode}
                                    imageUrl={logoUrl}
                                    isEditable={isEditable}
                                    idInputFile={'idInputFileCompanyLogo'}
                                    ImageEditor={false}
                                    enableSave={false}
                                    isLogo={true}
                                />
                            </div>
                        </Col>
                        <Col key="2" xl={12} xs={24} id="descriptionText" style={{ overflow: 'auto' }}>
                            <EntityInfoPanel
                                setEditModeCallBackParent={setEditModeCallBack}
                                globalEditState={editMode}
                                company={company}
                                isEditable={isEditable}
                            />
                        </Col>
                    </Row>
                </Col>

                <Col xl={24} xs={24} align="left" style={{ background: 'white' }}>
                    <div className="div-tag-company">
                        {company.tags &&
                            company.tags.map((tag, index) => (
                                <Label className="expo_tag" key={tag.id}>
                                    {t(`${tag.name}`)}
                                </Label>
                            ))}{' '}
                        {isEditable ? <EditIcon className="editIcon tag_company" onClick={() => modifyTags()} /> : null}
                    </div>
                </Col>

                <Col className="companyDetails" span={24}>
                    <Row>
                        {is.not.empty(events) ? (
                            <Col span={24}>
                                <XtensibleGallery
                                    contents={events}
                                    title={t('events')}
                                    isEditable={false}
                                    onVieItemCallBack={(eventId) => {
                                        history.push('/eventpage', {
                                            event: events.filter((e) => e.id === eventId)[0],
                                            eventList: events,
                                        });
                                        window.scrollTo(0, 0);
                                    }}
                                    titleVisible={true}
                                />
                            </Col>
                        ) : null}
                        {isEditable || live ? (
                            <Col span={24}>
                                <XtensibleGallery
                                    previewpath="logoPath"
                                    imagepath="bannerPath"
                                    isEditable={isEditable}
                                    onAddItemCallback={basic_showcase_edit ? onAddItemCallbackLive : null}
                                    onDeleteItemCallBack={basic_showcase_edit ? onDeleteItemCallBackLive : null}
                                    onVieItemCallBack={basic_showcase_edit ? onVieItemCallBackLive : null}
                                    contents={getLive()}
                                    title={t('live')}
                                    idPrefix={company.id}
                                    titleVisible={false}
                                />
                            </Col>
                        ) : null}
                        {isEditable || products ? (
                            <Col span={24}>
                                <XtensibleGallery
                                    isEditable={isEditable}
                                    onAddItemCallback={basic_showcase_edit ? onAddItemCallbackProduct : null}
                                    onDeleteItemCallBack={basic_showcase_edit ? onDeleteItemCallbackProduct : null}
                                    onVieItemCallBack={
                                        (isCompanyUser && basic_showcase_edit) ||
                                        (!isCompanyUser && view_products_showcase)
                                            ? onViewItemCallBackProduct
                                            : null
                                    }
                                    contents={getProducts()}
                                    title={t('products_services')}
                                    idPrefix={company.id}
                                    titleVisible={true}
                                />
                            </Col>
                        ) : null}
                        {isEditable || portfolio ? (
                            <Col span={24}>
                                <XtensibleGallery
                                    isEditable={isEditable}
                                    onAddItemCallback={basic_showcase_edit ? onAddItemCallbackPortfolio : null}
                                    onDeleteItemCallBack={basic_showcase_edit ? onDeleteItemCallbackPortfolio : null}
                                    onVieItemCallBack={onVieItemCallBackPortfolio}
                                    contents={getPortfolio()}
                                    title={t('portfolio')}
                                    idPrefix={company.id}
                                    titleVisible={true}
                                    titleDesc={true}
                                />
                            </Col>
                        ) : null}
                        {isEditable || documents ? (
                            <Col span={24}>
                                <XtensibleGallery
                                    isEditable={isEditable}
                                    onAddItemCallback={basic_showcase_edit ? onAddItemCallbackDocument : null}
                                    onDeleteItemCallBack={basic_showcase_edit ? onDeleteItemCallbackDocument : null}
                                    onVieItemCallBack={onVieItemCallBackDocument}
                                    onDownloadItemCallBack={basic_showcase_edit ? onDownloadItemCallBackDocument : null}
                                    contents={getDocuments()}
                                    title={t('documents')}
                                    titleVisible={false}
                                />
                            </Col>
                        ) : null}
                        {b2b_request && !isCompanyUser && !isChamberUser ? (
                            <Col className="askEvent" span={24}>
                                {contactButton}
                            </Col>
                        ) : null}
                    </Row>
                </Col>
            </Row>
            <div>
                {(italians && italians.length > 0) || (foreigns && foreigns.length > 0) ? (
                    <div
                        className="dark_grey company"
                        style={{
                            minHeight: '550px',
                            paddingBottom: '100px',
                            background: '#E8E8E8 0% 0% no-repeat padding-box',
                        }}
                    >
                        {italians && italians.length > 0 ? (
                            <XtensibleGallery
                                title={t('italian_companies')}
                                contents={italians}
                                onVieItemCallBack={view_companies_showcase ? goToCompanyItaly : null}
                                idPrefix={company.id}
                                imagepath="logoPath"
                                titleVisible={true}
                            />
                        ) : null}
                        {foreigns && foreigns.length > 0 ? (
                            <XtensibleGallery
                                title={t('foreign_companies')}
                                contents={foreigns}
                                onVieItemCallBack={view_companies_showcase ? goToCompanyForeigns : null}
                                idPrefix={company.id}
                                imagepath="logoPath"
                                titleVisible={true}
                            />
                        ) : null}
                    </div>
                ) : null}
            </div>
        </>
    ) : null;
};
export default CompanyShowCase;
