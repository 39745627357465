import React from 'react';
import { Col, Row } from 'antd';
import TopBarArrow from './TopBarArrow';
import history from '../../utils/history';

import './TopBar.css';

const TopBar = ({ arrowsVisible, leftClick, rightClick, onClickCallBack, back = false, children }) => {
    const renderArrow = (dir, onClick) => <TopBarArrow style={{ height: '35px' }} direction={dir} onClick={onClick} />;

    const onClickToolBar = () => {
        if (onClickCallBack) onClickCallBack();
        return null;
    };

    const renderLeftArrow = (onClick) =>
        onClick && arrowsVisible ? <Col span={1}>{renderArrow('left', onClick)}</Col> : null;

    const renderRightArrow = (onClick) =>
        onClick && arrowsVisible ? <Col span={1}>{renderArrow('right', onClick)}</Col> : null;

    const renderContent = (
        <Col
            xs={arrowsVisible ? 22 : back ? 12 : 24}
            sm={arrowsVisible ? 22 : back ? 16 : 24}
            md={arrowsVisible ? 22 : back ? 18 : 24}
            xxl={arrowsVisible ? 22 : back ? 20 : 24}
            style={{ height: '100%' }}
        >
            <Row align="middle" justify="center" style={{ height: '100%' }}>
                {children}
            </Row>
        </Col>
    );

    const backButton = back ? (
        <Col
            xs={6}
            sm={4}
            md={3}
            xxl={2}
            onClick={() => history.goBack()}
            className="el_button"
            style={{ display: 'flex', alignItems: 'center' }}
        >
            <TopBarArrow style={{ height: '35px' }} direction="left" />
            <strong className="back-arrow-top-bar">BACK</strong>
        </Col>
    ) : null;

    return (
        <Row className="topBar" align="middle" justify={back ? 'start' : 'center'} onClick={() => onClickToolBar()}>
            {backButton}
            {renderLeftArrow(leftClick)}
            {renderContent}
            {renderRightArrow(rightClick)}
        </Row>
    );
};

export default TopBar;

/*export default class extends Component {
    render() {
        const {
            arrowsVisible,
            leftClick,
            rightClick,
            onClickCallBack,
            children,
        } = this.props;

        const renderArrow = (dir, onClick) => <TopBarArrow direction={dir} onClick={onClick} />;

        const onClickToolBar = () => {
            if (onClickCallBack)  onClickCallBack();
            return null;
        }

        const renderLeftArrow = (onClick) =>
            onClick && arrowsVisible ? <Col xl={{span:1}} xs={{span:4}}>
                {renderArrow("left", onClick)}
            </Col> : null;

        const renderRightArrow = (onClick) =>
            onClick && arrowsVisible  ? <Col xl={{span:1}} xs={{span:4}}>
                {renderArrow("right", onClick)}
            </Col> : null;

        const renderContent = children => leftClick && rightClick ?
            <Col xl={{span:22}} xs={{span:16}}>
                {children}
            </Col> :
            children;

        console.log('Children:----->', children);

        return (
            <Row className='topBar' align="middle" onClick={() => onClickToolBar()}>
                {renderLeftArrow(leftClick)}
                {renderContent(children)}
                {renderRightArrow(rightClick)}
            </Row>);
    }
}*/
