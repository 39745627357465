import is from 'is_js';
import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation, withTranslation } from 'react-i18next';
import { Col, Drawer, Popover, Row, Space, Tabs } from 'antd';

import {
    ConsoleLogger,
    DefaultActiveSpeakerPolicy,
    DefaultDeviceController,
    DefaultMeetingSession,
    DefaultModality,
    LogLevel,
    MeetingSessionConfiguration,
} from 'amazon-chime-sdk-js';

import 'antd/dist/antd.css';
import './Event.css';
import expoClient from '../../expoClient';
//import Device from "./Device";
import { ReactComponent as VideoDisabled } from '../../icons/video-disabled.svg';
import { ReactComponent as VideoEnabled } from '../../icons/video-enabled.svg';
import { ReactComponent as AudioEnabled } from '../../icons/audio-enabled.svg';
import { ReactComponent as AudioDisabled } from '../../icons/audio-disabled.svg';
import { ReactComponent as CallClose } from '../../icons/call-close.svg';
import { ReactComponent as People } from '../../icons/people.svg';
import { ReactComponent as ShareDesktop } from '../../icons/share-desktop.svg';
//import {ReactComponent as Chat} from '../../icons/chat.svg';
//import {ReactComponent as Settings} from '../icons/settings.svg';
/* componenti custom */
import MeetingVideo from './MeetingVideo';
import RosterLine from './RosterLine';
import EventLayout from './EventLayout';

const { TabPane } = Tabs;

const Event = ({ location }) => {
    const tokenRoom = location.state.meetingInfo.tokenRoom;
    const tokenAttendee = location.state.meetingInfo.tokenAttendee;

    //const [activeTab, setActiveTab] = useState(1);
    //const [askToSpeak, setAskToSpeak] = useState(false);
    //const [audioInDevices, setAudioInDevices] = useState(null);
    //const [audioOutDevices, setAudioOutDevices] = useState(null);
    const [availableVideoIndexes, setAvailableVideoIndexes] = useState(null);
    //const [chooseDevicesVisible, setChooseDevicesVisible] = useState(false);
    const [chosenVideoDevice, setChosenVideoDevice] = useState(null);
    const [chosenAudioInDevice, setChosenAudioInDevice] = useState(null);
    const [chosenAudioOuDevice, setChosenAudioOuDevice] = useState(null);
    const [currentSharedTile, setCurrentSharedTile] = useState(null);
    const [drawerVisible, setDrawerVisible] = useState(false);
    //const [lastRecMsgTime, setLastRecMsgTime] = useState(0);
    //const [localName, setLocalName] = useState(null);
    const [meetingSession, setMeetingSession] = useState(null);
    //const [messages, setMessages] = useState([])
    const [mute, setMute] = useState(false); //TODO reset to false before commit
    const [myCurrentSharedTile, setMyCurrentSharedTile] = useState(null);
    const [roster, setRoster] = useState(null);
    const [showCamera, setShowCamera] = useState(false);
    //const [tileIdToMeetingVideo, setTileIdToMeetingVideo] = useState({});
    //const [triggerRender, setTriggerRender] = useState(false);
    //const [videoDevices, setVideoDevices] = useState(null);
    const [nextPinLoc, setNextPinLoc] = useState(0);
    const [user, setUser] = useState(null);
    //const [meeting, setMeeting] = useState(null);
    const [attendee, setAttendee] = useState(null);
    //const [attendees, setAttendees] = useState(null);
    //const [chamber, setChamber] = useState('');
    const [outOfCall, setOutOfCall] = useState(false);
    //const [eventImage, setEventImage] = useState('');
    const [shareVideoEntity, setShareVideoEntity] = useState({});
    const [showPopup, setShowPopup] = useState(false);
    //const [lockFooter, setLockFooter] = useState(false);

    /* header meeting */
    //const [chamberLogo, setChamberLogo] = useState(null);
    //const [eventTitle, setEventTitle] = useState(null);

    //Gestione Sessione
    const logger = new ConsoleLogger('EventLogs', LogLevel.ERROR);
    const deviceController = new DefaultDeviceController(logger);

    const videoPlaces = {
        LOCAL: 'local-video',
        OTHER0: 'other-video-0',
        OTHER1: 'other-video-1',
        OTHER2: 'other-video-2',
        OTHER3: 'other-video-3',
        OTHER4: 'other-video-4',
        OTHER5: 'other-video-5',
        OTHER6: 'other-video-6',
        OTHER7: 'other-video-7',
        OTHER8: 'other-video-8',
        OTHER9: 'other-video-9',
        OTHER10: 'other-video-10',
        OTHER11: 'other-video-11',
        OTHER12: 'other-video-12',
        OTHER13: 'other-video-13',
        OTHER14: 'other-video-14',
        OTHER15: 'other-video-15',
        SHARE: 'shared-video',
    };

    let rosterLocal = {};
    let availableVideoIndexesLocal = [
        { available: true, color: '#4AA072' },
        { available: true, color: '#754AA0' },
        { available: true, color: '#A04A6E' },
        { available: true, color: '#934AA0' },
        { available: true, color: '#614AA0' },
        { available: true, color: '#4A61A0' },
        { available: true, color: '#4A8FA0' },
        { available: true, color: '#4AA05B' },
        { available: true, color: '#6EA04A' },
        { available: true, color: '#A0A04A' },
        { available: true, color: '#A0754A' },
        { available: true, color: '#A04A4A' },
        { available: true, color: '#4AA072' },
        { available: true, color: '#75A04A' },
        { available: true, color: '#4AA06E' },
        { available: true, color: '#93A04A' },
    ];

    //Variabili Local necessarie perché dagli Observer
    //Chime sembra non sia possibile leggere correttamente
    //le variabili di stato react.
    let audioInputDevicesLocal = [];
    let audioOutputDevicesLocal = [];
    let videoInputLocal = [];
    let meetingSessionLocal = null;
    //let attendeeIdLocal = null;
    let currentSharedTileLocal = null;
    let myCurrentSharedTileLocal = null;
    let tileIdToMeetingVideoLocal = {};
    //let triggerRenderLocal = false;

    /*window.addEventListener("orientationchange", function(event) {
		console.log("the orientation of the device is now " + event.target.screen.orientation.angle);
	});*/

    const videoLayout =
        window.innerWidth < 500
            ? EventLayout.LAYOUTS.MOBILE_V
            : window.innerWidth < 900
            ? EventLayout.LAYOUTS.MOBILE_H
            : EventLayout.LAYOUTS.TILED;

    /*console.log('---> width: ', window.innerWidth)
	console.log('---> layout: ', videoLayout)*/

    //let lastRecMsgTimeLocal = 0;
    //let messagesLocal = [];
    //let userLocal = null;
    let meetingLocal = {};
    let attendeeLocal = {};
    let attendeesLocal = {};

    //let chamberLocal = '';
    //let chamberLogoLocal = null;
    //let eventLocal = '';
    let eventImageLocal = '';
    //let oldBarLocal = null;

    const { t } = useTranslation();

    const shareVideoEntityLocal = {};

    window.addEventListener('beforeunload', (ev) => {
        ev.preventDefault();
        return (ev.returnValue = 'Are you sure you want to close?');
    });

    const isMobile = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };

    function mouseMove(event) {
        if (event.clientY > window.innerHeight - 60 && !isMobile()) {
            clearTimeout(hideTimer);
            showBars();
        } else if (event.clientY > window.innerHeight * 0.65 || isMobile()) {
            clearTimeout(hideTimer);
            showBars();
            hideTimer = setTimeout(hideBars, 3000);
        }
    }

    function mouseOut(event) {
        /*clearTimeout(hideTimer);
		hideBars();*/
    }

    const mainLayout = document.getElementById('main-layout');
    if (mainLayout) {
        /*mainLayout.classList.add('layoutFullScreen');
		mainLayout.classList.remove('layout');*/
        mainLayout.addEventListener('mousemove', mouseMove);
        mainLayout.addEventListener('mouseout', mouseOut);
    }

    const startLocalTile = () => {
        setTimeout(() => {
            if (meetingSessionLocal != null && meetingSessionLocal.audioVideo) {
                meetingSessionLocal.audioVideo.startLocalVideoTile();
                meetingSessionLocal.audioVideo.startVideoPreviewForVideoInput(document.getElementById('video-preview'));
            }
        }, 200);
    };

    const updateRoster = (rosterEntity) => {
        /*
		let newRosterLocal = Object.assign({}, rosterLocal);
		newRosterLocal[rosterEntity.attendeeId] = rosterEntity;
		rosterLocal = newRosterLocal;
		setRoster(rosterLocal);
		*/
        rosterLocal[rosterEntity.attendeeId] = rosterEntity;
        setRoster(Object.assign({}, rosterLocal));
    };

    //Attenzione usare il roster in get perché viene chiamata in get durante la rendering
    const getEntityFromDivId = (videoIndex) => {
        // console.log("Event.getEntityFromDivId index, rooster", videoIndex, roster);
        if (roster === null) return null;
        return Object.values(roster).filter((e) => e.videoIndex === videoIndex)[0];
    };

    const getFirstAvailableVideoIndex = () => {
        const elemIndex = availableVideoIndexesLocal.findIndex((e) => e.available);

        if (elemIndex !== -1) {
            availableVideoIndexesLocal[elemIndex].available = false;
            setAvailableVideoIndexes(availableVideoIndexesLocal);
            return elemIndex;
        }

        return null;
    };

    const redrawRoster = (rost, ms) => {
        /*console.log('>>>>> rost: ', rost);*/

        Object.values(rost).forEach((rl) => {
            /*console.log('>>>>> rosterLine: ', rl);*/
            const v = document.getElementById('other-video-' + rl.videoIndex + '-stream');
            if (v) ms.audioVideo.bindVideoElement(rl.tileId, v);
        });
    };

    const freeAvailableVideoIndex = (index) => {
        if (index === 15 || (index < 15 && availableVideoIndexesLocal[index + 1].available))
            availableVideoIndexesLocal[index].available = true;
        else {
            const lastVideoId = availableVideoIndexesLocal.findIndex((e) => e.available) - 1;
            const lastVideo = Object.values(rosterLocal).find((e) => e.videoIndex === lastVideoId);
            rosterLocal[lastVideo.attendeeId].videoIndex = index;
            setRoster(Object.assign({}, rosterLocal));
            redrawRoster(rosterLocal, meetingSessionLocal);
            availableVideoIndexesLocal[lastVideoId].available = true;
        }

        setAvailableVideoIndexes(availableVideoIndexesLocal);
    };

    const activeSpeakerHandler = (attendeeIds) => {
        //Mette tutti a false e dopo mette ad active quello attivo
        Object.keys(rosterLocal).map((k) => (rosterLocal[k].active = false));
        for (const attendeeId of attendeeIds) {
            if (rosterLocal[attendeeId]) {
                rosterLocal[attendeeId].active = true;
                updateRoster(rosterLocal[attendeeId]);
                break; // only show the most active speaker
            }
        }
    };

    /*
	const sendMessage = () => {
		// console.log("SOno nella send message");
		new AsyncScheduler().start(() => {
			const textArea = document.getElementById('sendMessage');
			const textToSend = textArea.value.trim();
			if (!textToSend) {
				return;
			}
			textArea.value = '';
			meetingSession.audioVideo.realtimeSendDataMessage('chat', textToSend, 300000);

			const attId = meetingSession.configuration.credentials.attendeeId;
			const extUId = meetingSession.configuration.credentials.externalUserId;

			// echo the message to the handler
			dataMessageHandler(new DataMessage(Date.now(), 'chat', new TextEncoder().encode(textToSend), attId, extUId));
		});
	};
	*/

    /*
	const messageKeyDown = (e) => {
		const k = e.key || e.keyCode;

		// console.log("-----> Key: ", k);
		// console.log("---> Event: ", e);

		if (k === 13 || k === 'Enter') {
			if (e.shiftKey) {
				e.target.rows++;
				// console.log("aggiungi riga");
			} else {
				e.preventDefault();
				sendMessage();
				e.target.rows = 1;
				// console.log('reset righe');
			}
		}
	};
	*/

    const switchPlace = (id1, id2) => {
        const p1 = Object.values(roster).find((item) => item.videoIndex === id1);
        const p2 = Object.values(roster).find((item) => item.videoIndex === id2);
        console.log('Event.switchPlace !!WARNIG!! ', p1, p2);
        if (p1 && p2) {
            const newRoster = Object.assign({}, roster);
            const aux = newRoster[p1.attendeeId].videoIndex;
            newRoster[p1.attendeeId].videoIndex = newRoster[p2.attendeeId].videoIndex;
            newRoster[p2.attendeeId].videoIndex = aux;
            setRoster(newRoster, (r) => redrawRoster(r, meetingSession));
        }
    };

    const pinCallback = (videoElement) => {
        const vid = parseInt(videoElement.target.getAttribute('value'));
        /*console.log('fin qui ci siamo ', vid, nextPinLoc)*/
        switchPlace(vid, nextPinLoc);
        setNextPinLoc((nextPinLoc + 1) % 2);
    };

    /*
	const dataMessageHandler = (dataMessage) => {
		// console.log('sono nella datamessagehandler');
		// console.log(dataMessage.text());
		if (!dataMessage.throttled) {
			// const isSelf = dataMessage.senderAttendeeId === meetingSession.configuration.credentials.attendeeId;
			if (dataMessage.timestampMs <= lastRecMsgTimeLocal) return;
			lastRecMsgTimeLocal = dataMessage.timestampMs;
			setLastRecMsgTime(lastRecMsgTimeLocal);
			messagesLocal = messages;
			messagesLocal.push({
				'name': dataMessage.senderExternalUserId, 'message': dataMessage.text()
			});
			// console.log('----> qwerty: ', messagesLocal);
			// setMessages(messagesLocal);
		}
	}
	*/

    /*
	const setupDataMessage = () => {
		meetingSessionLocal.audioVideo.realtimeSubscribeToReceiveDataMessage('chat', dataMessageHandler);
	}
	*/

    const presenceObserver = (attendeeId, present, externalUserId) => {
        console.log('Event.presenceObserver attendeeId, present, rosterLocal', attendeeId, present, rosterLocal);
        const selfAttendeeId = meetingSessionLocal.configuration.credentials.attendeeId;
        if (selfAttendeeId === attendeeId) return;
        const isContentAttendee = new DefaultModality(attendeeId).hasModality(DefaultModality.MODALITY_CONTENT);
        if (isContentAttendee) return;
        if (present) {
            if (!rosterLocal[attendeeId]) {
                const index = getFirstAvailableVideoIndex();
                const attendeeData = attendeesLocal.find((e) => e.token === externalUserId);

                rosterLocal[attendeeId] = {
                    token: externalUserId,
                    name: attendeeData.name,
                    surname: attendeeData.surname,
                    role: attendeeData.businessRole,
                    initials: attendeeData.name[0] + attendeeData.surname[0],
                    entity: attendeeData.entityName,
                    nation: attendeeData.entityCountry,
                    tileId: null,
                    videoIndex: index,
                    color: availableVideoIndexesLocal[index].color,
                    attendeeId: attendeeId,
                    audioScore: 0,
                    active: false,
                    volume: 0,
                    muted: false,
                    signalStrength: 0,
                    pinned: false,
                };

                meetingSessionLocal.audioVideo.realtimeSubscribeToVolumeIndicator(
                    attendeeId,
                    async (attendeeId, volume, muted, signalStrength) => {
                        if (!rosterLocal[attendeeId]) return;
                        if (volume !== null) rosterLocal[attendeeId].volume = volume;
                        if (muted !== null) rosterLocal[attendeeId].muted = muted;
                        if (signalStrength !== null) rosterLocal[attendeeId].signalStrength = signalStrength;
                        setRoster(Object.assign({}, rosterLocal));
                    },
                );
            }
        } else {
            //const prevLen = Object.keys(rosterLocal).length;
            freeAvailableVideoIndex(rosterLocal[attendeeId].videoIndex);
            delete rosterLocal[attendeeId];
            /*
			if (Object.keys(rosterLocal).length === prevLen)
				return;
			*/
        }
        setRoster(Object.assign({}, rosterLocal));
    };

    const addTileToRoster = (tileId, attendeeId, externalUserId) => {
        console.log('Event.addTileToRoster rosterLocal, tileId, attendeeId', rosterLocal, tileId, attendeeId);
        console.log('Event.addTileToRoster rooster di attendee esiste?', is.existy(rosterLocal[attendeeId]));
        if (is.existy(rosterLocal[attendeeId])) {
            console.log('Event.addTileToRoster videoIndex di attendeeId', rosterLocal[attendeeId].videoIndex);
            rosterLocal[attendeeId].tileId = tileId;
            setRoster(Object.assign({}, rosterLocal));
            return rosterLocal[attendeeId].videoIndex;
        } else {
            const index = getFirstAvailableVideoIndex();
            const attendeeData = attendeesLocal.find((e) => e.token === externalUserId);

            rosterLocal[attendeeId] = {
                token: externalUserId,
                name: attendeeData.name,
                surname: attendeeData.surname,
                role: attendeeData.businessRole,
                initials: attendeeData.name[0] + attendeeData.surname[0],
                entity: attendeeData.entityName,
                nation: attendeeData.entityCountry,
                tileId: tileId,
                videoIndex: index,
                color: availableVideoIndexesLocal[index].color,
                attendeeId: attendeeId,
                audioScore: 0,
                active: false,
                volume: 0,
                muted: false,
                signalStrength: 0,
                pinned: false,
            };

            meetingSessionLocal.audioVideo.realtimeSubscribeToVolumeIndicator(
                attendeeId,
                async (attendeeId, volume, muted, signalStrength) => {
                    if (!rosterLocal[attendeeId]) return;
                    if (volume !== null) rosterLocal[attendeeId].volume = volume;
                    if (muted !== null) rosterLocal[attendeeId].muted = muted;
                    if (signalStrength !== null) rosterLocal[attendeeId].signalStrength = signalStrength;
                    setRoster(Object.assign({}, rosterLocal));
                },
            );

            return index;
        }
    };

    const releaseTileFromRoster = (tileId) => {
        //const newRoster = Object.assign({}, rosterLocal);
        const vid = Object.values(rosterLocal).find((e) => e.tileId === tileId);
        if (vid) {
            rosterLocal[vid.attendeeId].tileId = null;
            setRoster(Object.assign({}, rosterLocal));
        }
    };

    const observer = {
        audioVideoDidStart: () => {
            //console.log("Observer:audioVideoDidStart:");
        },
        audioVideoDidStartConnecting: (reconnecting) => {
            //console.log("Observer:audioVideoDidStartConnecting:",reconnecting);
        },
        audioVideoDidStop: (sessionStatus) => {
            //console.log("Observer:audioVideoDidStop:",sessionStatus);
        },
        connectionDidBecomePoor: () => {
            //console.log("Observer:connectionDidBecomePoor:");
        },
        connectionDidSuggestStopVideo: () => {
            //console.log("Observer:connectionDidSuggestStopVideo:");
        },
        connectionHealthDidChange: (connectionHealthData) => {
            //console.log("Observer:connectionHealthDidChange:",connectionHealthData);
        },
        estimatedDownlinkBandwidthLessThanRequired(estimatedBandwidth, requiredBandwidth) {
            //console.log("Observer:estimatedDownlinkBandwidthLessThanRequired:",estimatedBandwidth,requiredBandwidth);
        },
        metricsDidReceive: (clientMetricReport) => {
            //console.log("Observer:audioVideoDidStop:",clientMetricReport);
        },
        videoAvailabilityDidChange: (availability) => {
            //console.log("Observer:videoAvailabilityDidChange:",availability);
        },
        videoNotReceivingEnoughData: (receivingDataMap) => {
            //console.log("Observer:videoNotReceivingEnoughData:",receivingDataMap);
        },
        videoReceiveBandwidthDidChange(newBandwidthKbps, oldBandwidthKbps) {
            //console.log("Observer:videoReceiveBandwidthDidChange:",newBandwidthKbps, oldBandwidthKbps);
        },
        videoSendBandwidthDidChange(newBandwidthKbps, oldBandwidthKbps) {
            //console.log("Observer:videoSendBandwidthDidChange:",newBandwidthKbps, oldBandwidthKbps);
        },
        videoSendDidBecomeUnavailable: () => {
            //console.log("Observer:connectionDidSuggestStopVideo:");
        },
        videoSendHealthDidChange(bitrateKbps, packetsPerSecond) {
            //console.log("Observer:videoSendHealthDidChange:",bitrateKbps,packetsPerSecond);
        },
        videoTileDidUpdate: (tileState) => {
            console.log('Event.Observer.videoTileDidUpdate tileState, attendee', tileState, attendeeLocal);
            if (tileState === null) {
                console.error('Event.videoTileDidUpdate tileState is null exiting!');
                return;
            }
            if (!tileState.boundAttendeeId) return;
            if (meetingSessionLocal === null) return;
            if (tileState.boundVideoElement !== null) return;

            const selfAttendeeId = meetingSessionLocal.configuration.credentials.attendeeId;
            console.log(
                'Event.Observer.videoTileDidUpdate selfAttendeeId, boundedAttendeeId',
                selfAttendeeId,
                tileState.boundAttendeeId,
            );
            const modality = new DefaultModality(tileState.boundAttendeeId);
            if (modality.base() === selfAttendeeId && modality.hasModality(DefaultModality.MODALITY_CONTENT)) {
                // Si è aggiornato il 'mio' tile
                // don't bind one's own content
                myCurrentSharedTileLocal = tileState.tileId;
                setMyCurrentSharedTile(myCurrentSharedTileLocal);
                const sharePrev = document.getElementById('share-preview');
                meetingSessionLocal.audioVideo.bindVideoElement(tileState.tileId, sharePrev);
                return;
            }

            if (attendeeLocal.AttendeeId === tileState.boundAttendeeId) {
                if (!tileState.isContent) {
                    if (
                        meetingSessionLocal == null ||
                        meetingSessionLocal.audioVideo == null ||
                        document.getElementById('video-preview') == null
                    )
                        return;
                    //setLocalName(tileState.boundExternalUserId);
                    //meetingSessionLocal.audioVideo.startVideoPreviewForVideoInput(document.getElementById('video-preview'));
                    const videoElementAux = document.getElementById(videoPlaces.LOCAL + '-stream');
                    meetingSessionLocal.audioVideo.bindVideoElement(tileState.tileId, videoElementAux);
                    //console.log(`binding video tile ${tileState.tileId} to ${videoElementAux.id}`);
                    tileIdToMeetingVideoLocal[tileState.tileId] = videoPlaces.LOCAL;
                    //setTileIdToMeetingVideo(tileIdToMeetingVideoLocal);
                }
                return;
            }
            //Se è un tile di condivisione schermo lo metto nell'oggetto video apposito
            if (tileState.isContent) {
                const videoElement = document.getElementById(videoPlaces.SHARE + '-stream');
                //console.log(`binding sharing tile ${tileState.tileId} to ${videoElement.id}`);
                //console.log("Observer:videoTileDidUpdateContent:", tileState);
                //console.log("Observer:videoTileDidUpdate setting setCurrentSharedTile", tileState.active, tileState.tileId);
                const sharerData = rosterLocal[tileState.boundAttendeeId.split('#')[0]];

                shareVideoEntityLocal['extId'] = sharerData.token;
                shareVideoEntityLocal['name'] = sharerData.name;
                shareVideoEntityLocal['surname'] = sharerData.surname;
                shareVideoEntityLocal['initials'] = sharerData.initials;
                shareVideoEntityLocal['entity'] = sharerData.entity;
                shareVideoEntityLocal['nation'] = sharerData.nation;
                shareVideoEntityLocal['color'] = '#fff';
                shareVideoEntityLocal['active'] = false;
                shareVideoEntityLocal['inUse'] = false;
                shareVideoEntityLocal['tileId'] = tileState.tileId;

                setShareVideoEntity(shareVideoEntityLocal);

                meetingSessionLocal.audioVideo.bindVideoElement(tileState.tileId, videoElement);
                setCurrentSharedTile(tileState.tileId);
                currentSharedTileLocal = tileState.tileId;
                tileIdToMeetingVideoLocal[tileState.tileId] = videoPlaces.SHARE;
                //setTileIdToMeetingVideo(tileIdToMeetingVideoLocal);

                const share_btn = document.getElementById('share');
                if (share_btn) share_btn.setAttribute('title', t('no_share_tt'));
            } else {
                let divIdIndex = addTileToRoster(
                    tileState.tileId,
                    tileState.boundAttendeeId,
                    tileState.boundExternalUserId,
                );
                let videoElement = document.getElementById('other-video-' + divIdIndex + '-stream');
                if (videoElement === null) {
                    console.log('Event.videoTileDidUpdate other-video-' + divIdIndex + '-stream not found exiting');
                    return;
                }
                console.log('Event.videoTileDidUpdate ', tileState, videoElement);
                meetingSessionLocal.audioVideo.bindVideoElement(tileState.tileId, videoElement);
            }
        },
        videoTileWasRemoved: (tileId) => {
            //console.log("Observer:videoTileWasRemoved:", tileId, currentSharedTileLocal);
            //Se è stata rimossa la condivisione faccio sparire il div di condivisione annullando la variabile
            if (currentSharedTileLocal === tileId) {
                currentSharedTileLocal = null;
                setCurrentSharedTile(null);
                const share_btn = document.getElementById('share');
                if (share_btn) share_btn.setAttribute('title', t('share_tt'));
            } else if (myCurrentSharedTileLocal === tileId) {
                myCurrentSharedTileLocal = null;
                setMyCurrentSharedTile(null);
            } else {
                releaseTileFromRoster(tileId);
            }
        },
    };

    useEffect(() => {
        console.log('Events.useEffect before setSession', meetingSessionLocal, meetingSession);
        setSession();
        console.log('Events.useEffect after setSession', meetingSessionLocal, meetingSession);
    }, []);

    const setSession = async (restartingVideo = false) => {
        if (meetingSession !== null && meetingSessionLocal !== null && !restartingVideo) return;
        //risetto la variabile locale con quella nello stato permanente e cosi faccio pure
        if (meetingSessionLocal == null && meetingSession != null) meetingSessionLocal = meetingSession;

        //Se sto solo restartando il video non c'è bisogno di crearsi una session nuova
        //Usiamo quella vecchia la creiamo solo se non esiste e non sono stato chiamato dalla restart del video
        if (!restartingVideo) {
            console.log('Events.useEffect tokens ', tokenRoom, tokenAttendee);
            const res = await expoClient.meeting.getInfo(tokenRoom, tokenAttendee);
            //userLocal = Object.assign({}, res.data.user);
            setUser(res.data.user);
            meetingLocal = Object.assign({}, res.data.meeting);
            //setMeeting(meetingLocal);
            attendeeLocal = Object.assign({}, res.data.attendee);
            setAttendee(attendeeLocal);
            attendeesLocal = [...res.data.attendees];
            //setAttendees(attendeesLocal);
            eventImageLocal = res.data.image;
            //setEventImage(eventImageLocal);
            //chamberLocal = res.data.camera;
            //setChamber(chamberLocal);
            //eventLocal = res.data.event;
            //chamberLogoLocal = res.data.cameraLogo;

            //setEventTitle(res.data.event);
            //setChamberLogo(res.data.cameraLogo);
            const headerBar = document.getElementById('headerBar');
            if (headerBar) {
                console.log('Event: replacing headerbar with info meeting', res.data.cameraLogo, res.data.event);
                headerBar.classList.remove('ant-row-center');
                headerBar.classList.add('ant-row-start');
                headerBar.classList.add('eventHeaderBar');
                headerBar.innerHTML = ReactDOMServer.renderToStaticMarkup(
                    <Col justify="start" className="noSelect">
                        <a target="_blank" href="/">
                            <div className="pointer" style={{ fontSize: '24px' }}>
                                <img
                                    src={res.data.cameraLogo}
                                    alt="logo"
                                    style={{ padding: '0 40px 0 10px' }}
                                    height={50}
                                    title={res.data.event}
                                />
                                {window.innerWidth > 900 ? res.data.event : null}
                            </div>
                        </a>
                    </Col>,
                );
            }

            //attendeeId = attendeeLocal.AttendeeId;

            const configuration = new MeetingSessionConfiguration(meetingLocal, attendeeLocal);
            // In the usage examples below, you will use this meetingSession object.
            meetingSessionLocal = new DefaultMeetingSession(configuration, logger, deviceController);
            meetingSessionLocal.audioVideo.realtimeSubscribeToAttendeeIdPresence(presenceObserver);
            meetingSessionLocal.audioVideo.addObserver(observer);
            setMeetingSession(meetingSessionLocal);
            console.log('Events.useEffect setMeetingSession', meetingSessionLocal);
        }
        //Setto i device con il default o con quello che era già scelto in precedenza
        if (chosenAudioInDevice !== null) {
            await meetingSessionLocal.audioVideo.chooseAudioInputDevice(chosenAudioInDevice);
        } else {
            audioInputDevicesLocal = await meetingSessionLocal.audioVideo.listAudioInputDevices();
            await meetingSessionLocal.audioVideo.chooseAudioInputDevice(audioInputDevicesLocal[0]);
            setChosenAudioInDevice(audioInputDevicesLocal[0]);
            //setAudioInDevices(audioInputDevicesLocal);
        }
        if (chosenAudioOuDevice !== null) {
            await meetingSessionLocal.audioVideo.chooseAudioOutputDevice(chosenAudioOuDevice);
        } else {
            audioOutputDevicesLocal = await meetingSessionLocal.audioVideo.listAudioOutputDevices();
            await meetingSessionLocal.audioVideo.chooseAudioOutputDevice(audioOutputDevicesLocal[0]);
            setChosenAudioOuDevice(audioOutputDevicesLocal[0]);
            //setAudioOutDevices(audioOutputDevicesLocal);
        }
        if (chosenVideoDevice !== null) {
            await meetingSessionLocal.audioVideo.chooseVideoInputDevice(chosenVideoDevice);
        } else {
            if (restartingVideo) {
                videoInputLocal = await meetingSessionLocal.audioVideo.listVideoInputDevices();
                await meetingSessionLocal.audioVideo.chooseVideoInputDevice(videoInputLocal[0]);
                setChosenVideoDevice(videoInputLocal[0]);
                //setVideoDevices(videoInputLocal);
            }
        }
        //bind del mio video e del mio audio
        await meetingSessionLocal.audioVideo.bindAudioElement(document.getElementById('meeting-audio'));
        //Handler per l'audio
        meetingSessionLocal.audioVideo.subscribeToActiveSpeakerDetector(
            new DefaultActiveSpeakerPolicy(),
            activeSpeakerHandler,
        );
        meetingSessionLocal.audioVideo.start();

        //if (!restartingVideo) meetingSessionLocal.audioVideo.realtimeMuteLocalAudio(); //TODO Remove before commit

        //faccio partire il mio tile
        if (restartingVideo) {
            startLocalTile();
        }
    };

    const hideBars = () => {
        const footerB = document.getElementById('footerBar');

        if (footerB) footerB.classList.add('barsHidden');
    };

    const showBars = () => {
        const footerB = document.getElementById('footerBar');

        if (footerB) footerB.classList.remove('barsHidden');
    };

    let hideTimer = setTimeout(hideBars, 3000);

    /*
	const guestIncomingHandler = (attendeeId, present, externalUserId) => {
		//console.log("Connesso Attendee:", attendeeId, present);
	};
	*/

    //Funzione hook per il pannello di device nel caso si volesse cambiare dal default
    /*
	const setDevices = (microphone, audio, video) => {
		setChooseDevicesVisible(false);
	};
	*/

    //Funzioni di comparsa/scomparsa drawer
    const onClose = () => {
        setDrawerVisible(false);
    };

    const toggleDrawer = () => {
        //const tab = e.target.getAttribute('value');
        /*console.log('@----- tab: ', tab);*/

        /*setActiveTab(tab);*/
        /*console.log(tab);*/
        setDrawerVisible(!drawerVisible);
    };

    //Funzione che mi dice in quale tab del drawer sono
    const callbackTab = (key) => {
        /*console.log(key);*/
    };

    /*
	const addGuest = () => {
	};
	*/

    /*
	const allowToSpeak = () => {
	};
	*/

    //Funzioni di mute/unmute/stop video e start e leave call
    const setMuteOnCall = (muteValue) => {
        if (muteValue) {
            meetingSession.audioVideo.realtimeMuteLocalAudio();
        } else {
            meetingSession.audioVideo.realtimeUnmuteLocalAudio();
        }
        setMute(muteValue);
    };

    const setShowCameraOnCall = (showCamera) => {
        if (showCamera) {
            setSession(true);
        } else {
            meetingSession.audioVideo.stopVideoPreviewForVideoInput(document.getElementById('video-preview'));
            meetingSession.audioVideo.stopLocalVideoTile();
        }
        setShowCamera(showCamera);
    };

    let showPopupTimer;

    const hidePopup = () => {
        setShowPopup(false);
    };

    const setShareScreenOnCall = () => {
        if (currentSharedTile) {
            clearTimeout(showPopupTimer);
            setShowPopup(true);
            showPopupTimer = setTimeout(hidePopup, 3000);
            return;
        }

        /*console.log("setShareScreenOnCall", myCurrentSharedTile);*/
        if (myCurrentSharedTile == null) {
            /*console.log("setShareScreenOnCall", myCurrentSharedTile);*/
            meetingSession.audioVideo.startContentShareFromScreenCapture();
        } else {
            meetingSession.audioVideo.stopContentShare();
            setMyCurrentSharedTile(null);
            myCurrentSharedTileLocal = null;
        }
    };

    const setLeaveCall = () => {
        meetingSession.audioVideo.stopVideoPreviewForVideoInput(document.getElementById('video-preview'));
        meetingSession.audioVideo.stopLocalVideoTile();
        meetingSession.audioVideo.chooseVideoInputDevice(null);
        meetingSession.audioVideo.stop();
        setOutOfCall(true);
    };

    const buildRosterVisualization = () => {
        console.log('Event.buildRosterVisualization attendee, roster', attendee, roster);
        let ret = [];
        let i = 0;
        if (attendee) ret.push(<RosterLine key={i++} data={localVideoEntity} color={'#A04A6E'} you={true} />);

        if (roster)
            Object.keys(roster).forEach((k) => {
                ret.push(<RosterLine key={i++} data={roster[k]} color={roster[k].color} />);
            });

        return ret;
    };

    /*
	const buildChatVisualization = () => {
		let ret = [];

		for (let i = 0; i < messages.length; i++) {
			const o = messages[i];

			const name = o.name.split('||')[1];

			ret.push(<div>
				<p>{name}</p>
				<p>{o.message}</p>
			</div>)
		}

		return ret;
	}
	*/

    //Costanti per il disegno dell'icona giusta
    const videoEnabledDisabled = showCamera ? (
        <VideoEnabled style={{ width: '45px' }} />
    ) : (
        <VideoDisabled style={{ width: '45px' }} />
    );
    const audioEnabledDisabled = mute ? (
        <AudioDisabled style={{ width: '45px' }} />
    ) : (
        <AudioEnabled style={{ width: '45px' }} />
    );

    /*
	const devicesChooser = chooseDevicesVisible ? <Device setDevices={setDevices} audioInputDevices={audioInDevices} videoInputDevices={videoDevices} audioOutputDevices={audioOutDevices}
	                                                      choosenVideoDevice={chosenVideoDevice} choosenAudioInDevice={chosenAudioInDevice} choosenAudioOuDevice={chosenAudioInDevice}/> : null;
	*/

    const shareDesktopStyle =
        myCurrentSharedTile !== null
            ? 'sharingIconStyle'
            : currentSharedTile
            ? 'cantSharingIconStyle'
            : 'notSharingIconStyle';
    const attendeeNum = roster ? Object.keys(roster).length + 1 : 1;
    // console.log("---> attendeeNum: ", attendeeNum);

    const localVideoEntity = {
        extId: attendee ? attendee.externalUserId : null,
        name: user ? user.name : null,
        surname: user ? user.surname : null,
        role: user ? user.businessRole : null,
        initials: user ? user.name[0] + user.surname[0] : null,
        entity: user ? user.entityName : null,
        nation: user ? user.entityCountry : null,
        color: '#A04A6E',
        active: false,
        inUse: attendeeNum === 1,
        tileId: showCamera ? 100 : null,
    };

    const getVideoSize = (vid, layout) => {
        let col_dim;

        if (layout === EventLayout.LAYOUTS.MOBILE_V)
            if (currentSharedTile !== null) col_dim = attendeeNum < 4 ? 24 : 12;
            else col_dim = attendeeNum < 6 ? 24 : 12;
        else if (currentSharedTile !== null || layout === EventLayout.LAYOUTS.BORDER) col_dim = 24;
        else if (layout === EventLayout.LAYOUTS.MOBILE_H) col_dim = attendeeNum < 3 ? 24 : attendeeNum < 6 ? 12 : 6;
        else col_dim = attendeeNum < 3 ? 24 : attendeeNum < 6 ? 12 : attendeeNum < 11 ? 8 : 6;

        if (availableVideoIndexes) return availableVideoIndexes[vid].available ? 0 : col_dim;
        return 0;
    };

    const videoHeight = (layout) => {
        if (layout === EventLayout.LAYOUTS.MOBILE_V) {
            const video_share_h = window.innerWidth * 0.5625;
            let vh;
            if (currentSharedTile !== null) {
                vh = attendeeNum === 1 ? '100%' : attendeeNum < 4 ? `${100 / (attendeeNum - 1)}%` : video_share_h / 2;
            } else {
                vh = attendeeNum === 1 ? '100%' : attendeeNum < 6 ? `${100 / (attendeeNum - 1)}%` : video_share_h / 2;
            }

            // console.log('----> vh: ', vh);

            return vh;
        }

        if (layout === EventLayout.LAYOUTS.MOBILE_H) {
            if (currentSharedTile !== null) {
                const otherCol = document.getElementById('other-column');
                if (otherCol) {
                    if (otherCol.clientWidth < 1) return 180;
                    return otherCol.clientWidth * 0.5625; //ratio 16:9
                }
            }
            if (attendeeNum < 4) return '100%';
            return '50%';
        }

        if (currentSharedTile !== null || layout === EventLayout.LAYOUTS.BORDER) {
            const otherCol = document.getElementById('other-column');
            if (otherCol) {
                if (otherCol.clientWidth < 1) return 180;
                return otherCol.clientWidth * 0.5625; //ratio 16:9
            }

            return 180;
        }
        if (attendeeNum < 4) return '100%';
        if (attendeeNum < 8) return '50%';
        if (attendeeNum < 14) return '33.3333333%';
        return '25%';
    };

    const other_column_cls =
        currentSharedTile !== null || videoLayout === EventLayout.LAYOUTS.BORDER ? 'sharing' : 'no-sharing';

    const getVideoTiles = (layout) => {
        let ret = [];

        for (let i = layout !== EventLayout.LAYOUTS.BORDER ? 0 : 2; i < 16; i++) {
            const video_span = getVideoSize(i, layout);
            const video_height = videoHeight(layout);
            const video_margin = layout === EventLayout.LAYOUTS.BORDER ? '10px 0 0' : '0';
            // console.log("Event.getVideoTiles idex, videoEntity", i, getEntityFromDivId(i));
            ret.push(
                <Col
                    key={i}
                    span={video_span}
                    style={{
                        height: video_height,
                        margin: video_margin,
                        overflow: 'hidden',
                    }}
                >
                    <MeetingVideo
                        vid={i}
                        videoEntity={getEntityFromDivId(i)}
                        attendeeNum={attendeeNum}
                        sharing={currentSharedTile !== null}
                        layout={layout}
                        pinCB={pinCallback}
                    />
                </Col>,
            );
        }

        return ret;
    };

    const otherHeight =
        currentSharedTile !== null && attendeeNum < 4
            ? '100%'
            : currentSharedTile === null && attendeeNum < 6
            ? '100%'
            : 'auto';

    const getVideoLayout = (layout) => {
        let ret = [];

        switch (layout) {
            case EventLayout.LAYOUTS.TILED:
                const share_dim_t = currentSharedTile !== null ? 20 : 0;
                // console.log("Event.getVideoLayout (TILED) videoEntity", localVideoEntity);
                ret.push(
                    <Row key={1} style={{ height: '100%' }}>
                        <Col style={{ height: '100%' }} span={attendeeNum === 1 ? 24 : 0}>
                            <MeetingVideo
                                vid={videoPlaces.LOCAL}
                                videoEntity={localVideoEntity}
                                attendeeNum={attendeeNum}
                                sharing={currentSharedTile !== null}
                                layout={layout}
                            />
                        </Col>
                        <Col style={{ height: '100%' }} span={share_dim_t}>
                            <MeetingVideo
                                vid={videoPlaces.SHARE}
                                videoEntity={shareVideoEntity}
                                attendeeNum={attendeeNum}
                                sharing={currentSharedTile !== null}
                                layout={layout}
                            />
                        </Col>
                        <Col
                            style={{ height: '100%' }}
                            span={attendeeNum === 1 ? 0 : 24 - share_dim_t}
                            id="other-column"
                        >
                            <Row className={other_column_cls} align="center">
                                {getVideoTiles(layout)}
                            </Row>
                        </Col>
                    </Row>,
                );
                break;
            case EventLayout.LAYOUTS.BORDER:
                let share_dim_b = attendeeNum < 4 ? 24 : 20;
                ret.push(
                    <Row key={1} style={{ height: '100%' }}>
                        <Col span={share_dim_b}>
                            <Row style={{ height: '100%' }}>
                                <Col style={{ height: '200px' }} span={24}>
                                    <Row align="center" style={{ height: '100%' }}>
                                        <Col span={attendeeNum === 1 ? 6 : 0} style={{ height: '100%' }}>
                                            <MeetingVideo
                                                vid={videoPlaces.LOCAL}
                                                videoEntity={localVideoEntity}
                                                attendeeNum={attendeeNum}
                                                sharing={currentSharedTile !== null}
                                                layout={layout}
                                            />
                                        </Col>
                                        <Col
                                            span={attendeeNum >= 2 ? 6 : 0}
                                            style={{ height: '100%', margin: '0 10px' }}
                                        >
                                            <MeetingVideo
                                                vid={0}
                                                videoEntity={getEntityFromDivId(0)}
                                                attendeeNum={attendeeNum}
                                                sharing={currentSharedTile !== null}
                                                layout={layout}
                                            />
                                        </Col>
                                        <Col
                                            span={attendeeNum >= 3 ? 6 : 0}
                                            style={{ height: '100%', margin: '0 10px' }}
                                        >
                                            <MeetingVideo
                                                vid={1}
                                                videoEntity={getEntityFromDivId(1)}
                                                attendeeNum={attendeeNum}
                                                sharing={currentSharedTile !== null}
                                                layout={layout}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col style={{ height: 'calc(100% - 200px)' }} span={24}>
                                    <MeetingVideo
                                        vid={videoPlaces.SHARE}
                                        videoEntity={shareVideoEntity}
                                        attendeeNum={attendeeNum}
                                        sharing={currentSharedTile !== null}
                                        bgImg={true}
                                        layout={layout}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col style={{ height: '100%' }} span={24 - share_dim_b} id="other-column">
                            <Row className={other_column_cls} align="center">
                                {getVideoTiles(layout)}
                            </Row>
                        </Col>
                    </Row>,
                );
                break;
            case EventLayout.LAYOUTS.MOBILE_V:
                const share_dim_mv = currentSharedTile !== null ? 24 : 0;
                const video_share_h = window.innerWidth * 0.5625;
                const video_h = currentSharedTile !== null ? 'calc(100% - ' + video_share_h + 'px)' : '100%';

                ret.push(
                    <Row key={1} style={{ height: '100%' }}>
                        <Col style={{ height: '100%' }} span={attendeeNum === 1 ? 24 : 0}>
                            <MeetingVideo
                                vid={videoPlaces.LOCAL}
                                videoEntity={localVideoEntity}
                                attendeeNum={attendeeNum}
                                sharing={currentSharedTile !== null}
                                layout={layout}
                            />
                        </Col>
                        <Col style={{ height: !isMobile() ? video_share_h : 'auto' }} span={share_dim_mv}>
                            <MeetingVideo
                                vid={videoPlaces.SHARE}
                                videoEntity={shareVideoEntity}
                                attendeeNum={attendeeNum}
                                sharing={currentSharedTile !== null}
                                layout={layout}
                            />
                        </Col>
                        <Col style={{ height: video_h }} span={attendeeNum === 1 ? 0 : 24} id="other-column">
                            <Row className={other_column_cls} style={{ height: otherHeight }}>
                                {getVideoTiles(layout)}
                            </Row>
                        </Col>
                    </Row>,
                );
                break;
            case EventLayout.LAYOUTS.MOBILE_H:
                const share_dim_mh = currentSharedTile !== null ? 18 : 0;
                ret.push(
                    <Row key={1} style={{ height: '100%' }}>
                        <Col style={{ height: '100%' }} span={attendeeNum === 1 ? 24 : 0}>
                            <MeetingVideo
                                vid={videoPlaces.LOCAL}
                                videoEntity={localVideoEntity}
                                attendeeNum={attendeeNum}
                                sharing={currentSharedTile !== null}
                                layout={layout}
                            />
                        </Col>
                        <Col style={{ height: '100%' }} span={share_dim_mh}>
                            <MeetingVideo
                                vid={videoPlaces.SHARE}
                                videoEntity={shareVideoEntity}
                                attendeeNum={attendeeNum}
                                sharing={currentSharedTile !== null}
                                layout={layout}
                            />
                        </Col>
                        <Col
                            style={{ height: '100%' }}
                            span={attendeeNum === 1 ? 0 : 24 - share_dim_mh}
                            id="other-column"
                        >
                            <Row className={other_column_cls} align="center">
                                {getVideoTiles(layout)}
                            </Row>
                        </Col>
                    </Row>,
                );
                break;
            case EventLayout.LAYOUTS.OUT:
                ret.push(
                    <div id="callLeft" key={1} style={{ display: outOfCall ? 'flex' : 'none' }}>
                        <Row className="callLeft-panel box-center-cont">
                            <Col className="callLeft-panel-image box-center-cont" span={24}>
                                <img style={{ width: '640px' }} src={eventImageLocal} alt="event logo" />
                            </Col>
                            <Col className="callLeft-panel-text box-center-cont" span={24}>
                                <p>{t('outOfCall')}</p>
                            </Col>
                            <Col className="callLeft-panel-button box-center-cont" span={24}>
                                <div
                                    className="callLeft-button box-center-cont"
                                    onClick={() => window.location.reload(true)}
                                >
                                    Rejoin
                                </div>
                            </Col>
                        </Row>
                    </div>,
                );
                break;
            default:
                ret.push(
                    <p
                        key={1}
                        style={{
                            height: '100%',
                            width: '100%',
                            textAlign: 'center',
                            fontSize: '50px',
                            padding: '100px',
                        }}
                    >
                        Invalid videoLayout
                    </p>,
                );
        }

        return ret;
    };

    //if (meetingSessionLocal) setupDataMessage();

    return meetingSession ? (
        <>
            {console.log('Event.render session')}
            <Row
                justify="end"
                className="video-top-bar noSelect"
                id="topBar"
                style={{ display: outOfCall ? 'none' : 'flex' }}
            >
                <Col className="drawer-video-col drawer-shadow" span={6} id="openList" value="1" onClick={toggleDrawer}>
                    <People
                        style={{
                            width: '30px',
                            position: 'absolute',
                            transform: 'translate(-50%, -50%)',
                            top: '50%',
                        }}
                    />
                </Col>

                <Col
                    span={myCurrentSharedTile !== null ? 6 : 0}
                    style={{
                        height: '100%',
                        backgroundColor: '#121212',
                        justifyContent: 'center',
                        display: myCurrentSharedTile !== null ? 'flex' : 'none',
                    }}
                >
                    <video id="share-preview" style={{ height: '100%' }} />
                </Col>
                <Col
                    span={6}
                    style={{
                        height: '100%',
                        backgroundColor: '#121212',
                        justifyContent: 'center',
                        display: 'flex',
                    }}
                >
                    <Row style={{ height: '100%' }}>
                        <Col style={{ height: '100%' }} span={showCamera ? 24 : 0}>
                            <video id="video-preview" style={{ height: '100%' }} />
                        </Col>
                        <Col style={{ height: '100%' }} span={showCamera ? 0 : 8} offset={showCamera ? 0 : 8}>
                            <div className="localPH">
                                <p className="attendeeName localName" style={{ color: localVideoEntity.color }}>
                                    {localVideoEntity.initials}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className="video-main-div noSelect">
                {/*devicesChooser*/}
                <audio id="meeting-audio" style={{ display: 'none' }} />
                <div className="video-div">
                    {getVideoLayout(outOfCall ? EventLayout.LAYOUTS.OUT : videoLayout)}
                    <Drawer
                        className="drawer-video"
                        placement="right"
                        closable={false}
                        mask={true}
                        maskStyle={{ background: 'transparent' }}
                        style={{ height: '100%', zIndex: 1100 }}
                        onClose={onClose}
                        visible={drawerVisible}
                        getContainer={false}
                        width={!isMobile() ? 378 : '100%'}
                    >
                        <Tabs tabBarStyle={{ display: 'none' }} onChange={(activeKey) => callbackTab(activeKey)}>
                            <TabPane key="1">
                                {/*tab="People"*/}
                                {buildRosterVisualization()}
                            </TabPane>
                            {/*<TabPane tab="Chat" key="2">
							<div id='chatLines'>
								{buildChatVisualization()}
							</div>
							<div>
								<textarea id="sendMessage" rows={1} onKeyDown={messageKeyDown}/>
							</div>
						</TabPane>*/}
                        </Tabs>
                    </Drawer>
                </div>
                <div className="footer-video-div" id="footerBar" style={{ display: outOfCall ? 'none' : 'block' }}>
                    <Row>
                        {/*<Col className="footer-video-col" span={2}>
						<span onClick={() => setChooseDevicesVisible(!chooseDevicesVisible)}>
							<Settings style={{width: "45px"}}/>
						</span>
					</Col>*/}
                        <Col className="footer-video-col" md={{ span: 4, offset: 10 }} xs={{ span: 18, offset: 3 }}>
                            <Space size={20}>
                                <span className="pointer" onClick={() => setShowCameraOnCall(!showCamera)}>
                                    {videoEnabledDisabled}
                                </span>
                                <span className="pointer" onClick={() => setLeaveCall()}>
                                    <CallClose style={{ width: '45px' }} />
                                </span>
                                <span className="pointer" onClick={() => setMuteOnCall(!mute)}>
                                    {audioEnabledDisabled}
                                </span>
                            </Space>
                        </Col>
                        <Col className="footer-presenting-col" md={{ span: 4, offset: 4 }} xs={{ span: 0, offset: 0 }}>
                            <p className="footer-presenting-label">
                                {myCurrentSharedTile !== null ? t('presenting_text') : ''}{' '}
                            </p>
                        </Col>
                        {!isMobile() ? (
                            <Col className="footer-video-col" xs={3} md={2}>
                                <Popover content={t('no_share_tt')} visible={showPopup} placement="leftBottom">
                                    <span
                                        id="share"
                                        className="pointer"
                                        title={t('share_tt')}
                                        onClick={() => setShareScreenOnCall()}
                                    >
                                        <ShareDesktop className={shareDesktopStyle} style={{ width: '45px' }} />
                                    </span>
                                </Popover>
                            </Col>
                        ) : null}
                    </Row>
                </div>
            </div>
        </>
    ) : null;
};
export default withTranslation()(Event);
