import _ from 'lodash';
import React from 'react';

import './ArrowLR.css';
import { ReactComponent as LeftArrow } from '../icons/sx2.svg';
import { ReactComponent as RightArrow } from '../icons/freccia_album.svg';

const ArrowLR = ({ direction, align, onClick = _.identity }) => {
    const arrow =
        direction === 'right' ? (
            <RightArrow className="true-arrow" onClick={onClick} />
        ) : (
            <LeftArrow className="true-arrow" onClick={onClick} />
        );

    const style = {
        display: 'flex',
        justifyContent: align === 'right' ? 'flex-end' : 'center',
    };

    return (
        <div className="main-arrow" style={style}>
            {arrow}
        </div>
    );
};
export default ArrowLR;
