import _ from 'lodash';
import React, { useState, useRef, useEffect } from 'react';
import { Button, Carousel, Form, Input, Result, Skeleton, Switch, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '../react-auth0-spa';
import PricingTable from './PricingTable';
import PricingSlot from './PricingTable/PricingSlot';
import PricingDetail from './PricingTable/PricingDetail';
import expoConfig from '../config/expo_config.json';
import paypalConfig from '../config/paypal_config.json';
import history from '../utils/history';
import './Subscription.css';
import expoClient from '../expoClient';

const Subscription = (props) => {
    const { expoUser } = useAuth0();
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1);
    const [switchSelected, setSwitchSelected] = useState(false);
    const [planSelected, setPlanSelected] = useState(expoConfig.domain.subscription_plan.BUSINESS);
    const [paypalReady, setPaypalReady] = useState(false);
    const [chamber, setChamber] = useState();
    const [submittingTransaction, setSubmittingTransaction] = useState(false);
    const [form] = Form.useForm();
    const carouselRef = useRef();
    const { REACT_APP_PAYPAL_CLIENT_ID } = process.env;
    const { REACT_APP_PAYPAL_PREMIUM_MONTHLY } = process.env;
    const { REACT_APP_PAYPAL_BUSINESS_MONTHLY } = process.env;
    const { REACT_APP_PAYPAL_EXECUTIVE_MONTHLY } = process.env;
    const { REACT_APP_PAYPAL_PREMIUM_YEARLY } = process.env;
    const { REACT_APP_PAYPAL_BUSINESS_YEARLY } = process.env;
    const { REACT_APP_PAYPAL_EXECUTIVE_YEARLY } = process.env;
    useEffect(() => {
        const addPaypalSdk = () => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            console.log('Paypal clientId', paypalConfig.clientid + ' ' + REACT_APP_PAYPAL_CLIENT_ID);
            script.src =
                `https://www.paypal.com/sdk/js?client-id=` +
                REACT_APP_PAYPAL_CLIENT_ID.concat(`&currency=${paypalConfig.currency}`).concat(
                    `&disable-funding=${paypalConfig.disableFunding}`,
                );
            script.async = true;
            script.onload = () => {
                setPaypalReady(true);
            };
            script.onerror = () => {
                throw new Error('Paypal SDK could not be loaded, try to reload page');
            };
            document.body.appendChild(script);
        };

        if (!paypalReady) {
            addPaypalSdk();
        }
    }, []);

    const buildPricingTable = () => {
        return (
            <>
                <PricingTable>
                    <PricingSlot
                        //onClick={handlePlanSelection}
                        title="Premium"
                        //planId={expoConfig.domain.subscription_plan.PREMIUM}
                        priceText={'€' + (switchSelected ? '390' : '39')}
                        subscriptionText={
                            switchSelected ? t('annual_price_card') + '*' : t('per_month_price_card') + '*'
                        }
                        //highlighted={planSelected === expoConfig.domain.subscription_plan.PREMIUM}
                        shouldDisplayButton={false}
                        backgroundColor="#be6767"
                        border="2px solid #be6767"
                    >
                        <PricingDetail>{_.capitalize(t('subscription_view_company'))}</PricingDetail>
                        <PricingDetail>{_.capitalize(t('subscription_b2b'))}</PricingDetail>
                        <PricingDetail>{_.capitalize(t('subscription_basic_showcase_edit'))}</PricingDetail>
                        <PricingDetail active={false}>
                            {_.capitalize(t('subscription_full_showcase_edit'))}
                        </PricingDetail>
                        <PricingDetail active={false}>
                            {_.capitalize(t('subscription_business_matching_score'))}
                        </PricingDetail>
                        <PricingDetail active={false}>
                            {_.capitalize(t('subscription_view_products_showcase'))}
                        </PricingDetail>
                        <PricingDetail active={false}>{_.capitalize(t('subscription_direct_b2b'))}</PricingDetail>
                        <a href="#" className="scrollToTop">
                            <Button
                                className="button-subscription"
                                style={{
                                    color: 'white',
                                    backgroundColor: '#DE7B7B',
                                    borderColor: '#de7b7b',
                                }}
                                onClick={(e) => {
                                    handlePlanSelection(expoConfig.domain.subscription_plan.PREMIUM, e);
                                    carouselRef.current.next();
                                }}
                            >
                                {t('labelMarkupToolSelect').toUpperCase()}
                            </Button>
                        </a>
                    </PricingSlot>
                    <PricingSlot
                        //onClick={handlePlanSelection}
                        title="Business"
                        //planId={expoConfig.domain.subscription_plan.BUSINESS}
                        //highlighted={planSelected === expoConfig.domain.subscription_plan.BUSINESS}
                        priceText={'€' + (switchSelected ? '490' : '49')}
                        subscriptionText={
                            switchSelected ? t('annual_price_card') + '*' : t('per_month_price_card') + '*'
                        }
                        shouldDisplayButton={false}
                        backgroundColor="#bf3f48"
                        border="2px solid #bf3f48"
                    >
                        <PricingDetail>{_.capitalize(t('subscription_view_company'))}</PricingDetail>
                        <PricingDetail>{_.capitalize(t('subscription_b2b'))}</PricingDetail>
                        <PricingDetail>{_.capitalize(t('subscription_basic_showcase_edit'))}</PricingDetail>
                        <PricingDetail>{_.capitalize(t('subscription_full_showcase_edit'))}</PricingDetail>
                        <PricingDetail>{_.capitalize(t('subscription_business_matching_score'))}</PricingDetail>
                        <PricingDetail>{_.capitalize(t('subscription_view_products_showcase'))}</PricingDetail>
                        <PricingDetail active={false}>{_.capitalize(t('subscription_direct_b2b'))}</PricingDetail>
                        <a href="#" className="scrollToTop">
                            <Button
                                className="button-subscription"
                                style={{
                                    color: 'white',
                                    backgroundColor: '#DE4953',
                                    borderColor: '#de4953',
                                }}
                                onClick={(e) => {
                                    handlePlanSelection(expoConfig.domain.subscription_plan.BUSINESS, e);
                                    carouselRef.current.next();
                                }}
                            >
                                {t('labelMarkupToolSelect').toUpperCase()}
                            </Button>
                        </a>
                    </PricingSlot>
                    {expoUser.chamber.executive ? (
                        <PricingSlot
                            //onClick={handlePlanSelection}
                            title="Executive"
                            //planId={expoConfig.domain.subscription_plan.EXECUTIVE}
                            //highlighted={planSelected === expoConfig.domain.subscription_plan.EXECUTIVE}
                            priceText={'€' + (switchSelected ? '590' : '59')}
                            subscriptionText={
                                switchSelected ? t('annual_price_card') + '*' : t('per_month_price_card') + '*'
                            }
                            shouldDisplayButton={false}
                            backgroundColor="#be1522"
                            border="2px solid #be1522"
                        >
                            <PricingDetail>{_.capitalize(t('subscription_view_company'))}</PricingDetail>
                            <PricingDetail>{_.capitalize(t('subscription_b2b'))}</PricingDetail>
                            <PricingDetail>{_.capitalize(t('subscription_basic_showcase_edit'))}</PricingDetail>
                            <PricingDetail>{_.capitalize(t('subscription_full_showcase_edit'))}</PricingDetail>
                            <PricingDetail>{_.capitalize(t('subscription_business_matching_score'))}</PricingDetail>
                            <PricingDetail>{_.capitalize(t('subscription_view_products_showcase'))}</PricingDetail>
                            <PricingDetail>{_.capitalize(t('subscription_direct_b2b'))}</PricingDetail>
                            <a href="#" className="scrollToTop">
                                <Button
                                    className="button-subscription"
                                    style={{
                                        color: 'white',
                                        backgroundColor: '#DB2926',
                                        borderColor: '#db2926',
                                    }}
                                    onClick={(e) => {
                                        handlePlanSelection(expoConfig.domain.subscription_plan.EXECUTIVE, e);
                                        carouselRef.current.next();
                                    }}
                                >
                                    {' '}
                                    {t('labelMarkupToolSelect').toUpperCase()}{' '}
                                </Button>
                            </a>
                        </PricingSlot>
                    ) : (
                        <></>
                    )}
                </PricingTable>
                <div style={{ textAlign: 'center' }}>
                    <span style={{ marginTop: '20px' }}>{'*' + t('subscription_foreign_adoption')}</span>
                </div>
            </>
        );
    };

    const handleSwitchClick = (checked) => {
        console.log('Subscription switch click', checked);
        form.setFieldsValue({
            duration: checked ? 'Y' : 'M',
        });
        setSwitchSelected(checked);
    };

    const handlePlanSelection = (plan, event) => {
        console.log('Subscription plan click', plan, event);
        form.setFieldsValue({
            planId: plan,
        });
        setPlanSelected(plan);
    };

    const getOrderAmount = () => {
        if (!switchSelected && planSelected === expoConfig.domain.subscription_plan.PREMIUM) return 39;
        if (!switchSelected && planSelected === expoConfig.domain.subscription_plan.BUSINESS) return 49;
        if (!switchSelected && planSelected === expoConfig.domain.subscription_plan.EXECUTIVE) return 59;
        if (switchSelected && planSelected === expoConfig.domain.subscription_plan.PREMIUM) return 390;
        if (switchSelected && planSelected === expoConfig.domain.subscription_plan.BUSINESS) return 490;
        if (switchSelected && planSelected === expoConfig.domain.subscription_plan.EXECUTIVE) return 590;
    };
    const getPlanSelectedId = () => {
        if (!switchSelected && planSelected === expoConfig.domain.subscription_plan.PREMIUM)
            return REACT_APP_PAYPAL_PREMIUM_MONTHLY; //"P-2U4120900F7405649MBOHBIQ";
        if (!switchSelected && planSelected === expoConfig.domain.subscription_plan.BUSINESS)
            return REACT_APP_PAYPAL_BUSINESS_MONTHLY;
        if (!switchSelected && planSelected === expoConfig.domain.subscription_plan.EXECUTIVE)
            return REACT_APP_PAYPAL_EXECUTIVE_MONTHLY;
        if (switchSelected && planSelected === expoConfig.domain.subscription_plan.PREMIUM)
            return REACT_APP_PAYPAL_PREMIUM_YEARLY;
        if (switchSelected && planSelected === expoConfig.domain.subscription_plan.BUSINESS)
            return REACT_APP_PAYPAL_BUSINESS_YEARLY;
        if (switchSelected && planSelected === expoConfig.domain.subscription_plan.EXECUTIVE)
            return REACT_APP_PAYPAL_EXECUTIVE_YEARLY;
    };

    const handleCarouselChange = (page) => {
        setCurrentPage(page + 1);
        if (paypalReady && page === 1) {
            //pagina del pagamento
            const node = document.getElementById('paypal-button-container');
            while (node.hasChildNodes()) {
                node.removeChild(node.lastChild);
            }
            window.paypal
                .Buttons({
                    style: {
                        layout: 'vertical',
                        color: 'gold',
                        shape: 'pill',
                        label: 'paypal',
                    },
                    createSubscription: function (data, actions) {
                        return actions.subscription.create({
                            plan_id: getPlanSelectedId(), // Creates the subscription
                        });
                    },
                    onApprove: function (data, actions) {
                        console.log('subscription.paypal.onApprove', data, actions);

                        form.setFieldsValue({
                            subscriptionId: data.subscriptionID,
                            amount: getOrderAmount(),
                            currency: 'EUR',
                        });
                        return form.submit();
                    },
                    onCancel: function (data) {
                        console.log('Pagamento annullato', data);
                        setSubmittingTransaction(false);
                    },
                })
                .render('#paypal-button-container');
        }
        /*             window.paypal
                        .Buttons({
                            style: {
                                layout: 'vertical',
                                color: 'gold',
                                shape: 'pill',
                                label: 'paypal',
                            },
                            createOrder: function (data, actions) {
                                setSubmittingTransaction(true);
                                return actions.order.create({
                                    purchase_units: [
                                        {
                                            amount: {
                                                value: getOrderAmount(),
                                            },
                                        },
                                    ],
                                });
                            },
                            onApprove: function (data, actions) {
                                console.log('subscription.paypal.onApprove', data, actions);
                                return actions.order.capture().then(function (details) {
                                    console.log('Got Paypal transaction ', details);
                                    if (details.status === 'COMPLETED') {
                                        form.setFieldsValue({
                                            transactionId: details.id,
                                            transactionDate: details.create_time,
                                            amount: details.purchase_units[0].amount.value,
                                            currency: details.purchase_units[0].amount.currency_code,
                                        });
                                        form.submit();
                                    } else {
                                        alert('Transaction not completed ' + details.status);
                                    }
                                });
                            },
                            onCancel: function (data) {
                                console.log('Pagamento annullato', data);
                                setSubmittingTransaction(false);
                            },
                        })
                        .render('#paypal-button-container');
                } */
    };

    const onFinish = (values) => {
        console.log('Subscription submit', values);
        setSubmittingTransaction(true);
        expoClient.subscription
            .post(values, expoUser.token)
            .then(() => {
                carouselRef.current.next();
                setSubmittingTransaction(false);
            })
            .catch((error) => {
                console.error('Submit failed', error);
                setSubmittingTransaction(false);
            });
    };

    return (
        <Skeleton loading={!paypalReady} active>
            <Form
                style={{ textAlign: 'center' }}
                className="subscription-form"
                form={form}
                onFinish={onFinish}
                initialValues={{
                    registrationId: expoUser.registration.id,
                    companyId: expoUser.registration.companyId,
                    planId: planSelected,
                    paymentType: 'P',
                    duration: switchSelected ? 'Y' : 'M',
                }}
            >
                <Form.Item name="registrationId" noStyle>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="companyId" noStyle>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="paymentType" noStyle>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="duration" noStyle>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="planId" noStyle>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="transactionId" noStyle>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="subscriptionId" noStyle>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="transactionDate" noStyle>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="amount" noStyle>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="currency" noStyle>
                    <Input type="hidden" />
                </Form.Item>
                {currentPage < 3 ? (
                    <div className="subscription-title">
                        {t('subscription_title').toUpperCase() + ` ${currentPage}/2`}
                    </div>
                ) : null}
                <Carousel
                    key="subscription-carousel"
                    dots={false}
                    effect="fade"
                    ref={carouselRef}
                    afterChange={handleCarouselChange}
                >
                    <div key="subscription_1">
                        <div className="subscription-subtitle">{_.capitalize(t('subscription_subtitle'))} </div>
                        <div className="switch-container">
                            <span className={switchSelected ? '' : 'selected'} onClick={() => setSwitchSelected(false)}>
                                {t('subscription_month').toUpperCase()}
                            </span>
                            <Switch onClick={handleSwitchClick} checked={switchSelected} />
                            <span className={switchSelected ? 'selected' : ''} onClick={() => setSwitchSelected(true)}>
                                {t('subscription_year').toUpperCase()}
                            </span>
                        </div>
                        {currentPage === 1 ? buildPricingTable() : null}
                        {/*
                        <div className="button-container">
                            <Button
                                shape="round"
                                className="blackMode"
                                htmlType="button"
                                onClick={() => carouselRef.current.next()}
                            >
                                {t('button_forward').toUpperCase()}
                            </Button>
                        </div>
                        */}
                    </div>

                    <div
                        key="subscription_2"
                        style={{
                            display: currentPage === 2 ? 'block' : 'none',
                        }}
                    >
                        <Spin spinning={submittingTransaction}>
                            <div className="subscription-subtitle">{_.capitalize(t('subscription_subtitle_pay'))}</div>
                            <div id="paypal-button-container" className="paypal-container" />
                            <div className="button-container">
                                <Form.Item noStyle>
                                    <Button
                                        shape="round"
                                        className="blackMode"
                                        htmlType="button"
                                        onClick={() => carouselRef.current.prev()}
                                    >
                                        {t('button_back').toUpperCase()}
                                    </Button>
                                </Form.Item>
                            </div>
                        </Spin>
                    </div>

                    <div key="subscription_3">
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div className="b2bLeave-panel">
                                <div className="b2bLeave-image">
                                    {expoUser && expoUser.chamber ? (
                                        <img
                                            style={{ width: '100%' }}
                                            alt="camera cover"
                                            src={expoUser.chamber.bannerPath}
                                        />
                                    ) : null}
                                </div>
                                <div className="b2bLeave-text" style={{ flexDirection: 'column' }}>
                                    <p>
                                        <b>{t('subscription_transaction_ok_title').toUpperCase()}</b>
                                    </p>
                                    <p
                                        style={{ fontSize: '24px', color: '#262626', padding: '0px 40px' }}
                                        dangerouslySetInnerHTML={{
                                            __html: t('subscription_transaction_ok_subtitle', {
                                                interpolation: { escapeValue: false },
                                            }),
                                        }}
                                    ></p>
                                </div>

                                <div className="b2bLeave-div-button">
                                    <Button
                                        style={{ fontSize: '20px' }}
                                        className="blackMode"
                                        type="round"
                                        key="homepage"
                                        onClick={() => history.push('/company')}
                                    >
                                        {t('my_show_case').toUpperCase()}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Carousel>
            </Form>
        </Skeleton>
    );
};
export default Subscription;
