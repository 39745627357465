import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from './react-auth0-spa';
import config from './config/auth_config.json';
import history from './utils/history';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import 'antd/dist/antd.css';
import './index.css';

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState) => {
    const path = appState && appState.targetUrl ? appState.targetUrl : window.location.pathname;
    history.push(path);
};

ReactDOM.render(
    <Auth0Provider
        domain={config.domain}
        client_id={config.clientId}
        redirect_uri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
    >
        <Suspense fallback={<div>LOADING</div>}>
            <I18nextProvider i18n={i18n}>
                <App />
            </I18nextProvider>
        </Suspense>
    </Auth0Provider>,
    document.getElementById('root'),
);

serviceWorker.unregister();
