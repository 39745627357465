import React from 'react';
import { Form, Switch } from 'antd';

import { Accordion, Label, Icon, List } from 'semantic-ui-react';

import { withTranslation } from 'react-i18next';

class AccordionTopics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: [],
        };
        this.handleActiveIndex.bind(this);
    }

    handleActiveIndex(activeIndex) {
        this.setState({
            activeIndex: activeIndex,
        });
    }

    render() {
        const { t, alreadySelectedTags } = this.props;
        const item = [];
        const { activeIndex } = this.state;

        const accordionClick = (e, titleProps) => {
            const { index } = titleProps;
            const newIndex =
                activeIndex.indexOf(index) === -1
                    ? [...activeIndex, index]
                    : activeIndex.filter((item) => item !== index);

            this.setState({ activeIndex: newIndex });
        };

        this.props.topics.forEach((topic) => {
            item.push(
                <AccordionTopicItem
                    key={`topicItem_${topic.id}`}
                    topic={topic}
                    alreadySelectedTags={alreadySelectedTags}
                    activeIndex={activeIndex}
                    accordionClick={accordionClick}
                    t={t}
                />,
            );
        });

        return (
            <Accordion fluid exclusive={false}>
                {item}
            </Accordion>
        );
    }
}
export default withTranslation()(AccordionTopics);

class AccordionTopicItem extends React.Component {
    render() {
        const { t, alreadySelectedTags } = this.props;
        const topic = this.props.topic;
        const activeIndex = this.props.activeIndex;
        const accordionClick = this.props.accordionClick;
        const tags = [];
        topic.tagList.forEach((tag) => {
            let select = alreadySelectedTags && alreadySelectedTags.filter((p) => p.id === tag.id).length !== 0;
            tags.push(
                <List.Item key={`listItem_${tag.id}`}>
                    <Form.Item
                        className="label-tag"
                        labelAlign="left"
                        labelCol={{ xl: { span: 22 }, xs: { span: 20 } }}
                        wrapperCol={{ span: 2 }}
                        label={t(`${tag.name}`)}
                        name={`tag_${tag.id}`}
                        valuePropName="checked"
                    >
                        <Switch size="small" defaultChecked={select} />
                    </Form.Item>
                </List.Item>,
            );
        });
        return (
            <>
                <Accordion.Title
                    index={topic.id}
                    active={activeIndex.indexOf(topic.id) > -1}
                    onClick={accordionClick}
                    className="signup-accordion-title"
                >
                    <Label>
                        {t(`${topic.name}`)}
                        <Label.Detail>
                            <Icon name="dropdown" />
                        </Label.Detail>
                    </Label>
                </Accordion.Title>
                <Accordion.Content active={activeIndex.indexOf(topic.id) > -1} className="signup-accordion-content">
                    <List key={`list_${topic.id}`}>{tags}</List>
                </Accordion.Content>
            </>
        );
    }
}
