/*
Publishers	Estimated Uplink    Simulcast stream 1	        Simulcast stream 2
<= 4 and	>= 1000 kbps        1280x720@15fps 1200 kbps    320x180@15fps 300 kbps
<= 6 and	>= 350 kbps	        640x360@15fps 600 kbps      320x180@15fps 200 kbps
<= 6 and    > 300 kbps          640x360@15fps 600 kbps      320x180@15fps 150 kbps
>  6 and    >= 350 kbps         640x360@15fps 350 kbps      320x180@15fps 200 kbps
>  6 and    >= 300 kbps         640x360@15fps 350 kbps      320x180@15fps 150 kbps
Any         < 300 kbps          Not used                    320x180@15fps 300 kbps
*/

const CamSettings = {
    LOW: {
        width: 320,
        height: 180,
        frameRate: 15,
        maxBandwidthKbps: 200,
    },
    MEDIUM: {
        width: 640,
        height: 360,
        frameRate: 15,
        maxBandwidthKbps: 350,
    },
    HIGH: {
        width: 1280,
        height: 720,
        frameRate: 15,
        maxBandwidthKbps: 1200,
    },
};
export default CamSettings;
