import React from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from 'semantic-ui-react';

import ConfirmModal from './ConfirmModal';
import { ReactComponent as Erase } from '../icons/elimina.svg';
import { ReactComponent as DocumentIconPPT } from '../icons/ppt.svg';
import { ReactComponent as DocumentIconDOC } from '../icons/word.svg';
import { ReactComponent as DocumentIconPDF } from '../icons/pdf.svg';
import { ReactComponent as Scarica } from '../icons/scarica.svg';
import { ReactComponent as MatchingIcon } from '../icons/match-company.svg';
import expo_config from '../config/expo_config.json';
import { Col, Row } from 'antd';
import defaultItemImage from '../images/default-item.jpg';
import is from 'is_js';

import './XtensibleGalleryTile.css';

const XtensibleGalleryTile = (props) => {
    const {
        content,
        isEditable,
        isSelected,
        onDeleteItemCallBack,
        onVieItemCallBack,
        videoId,
        titleVisible,
        titleDesc,
        useEmbeddedModal,
        imagepath,
        previewpath,
        onDownloadItemCallBack,
    } = props;
    const { t } = useTranslation();

    console.log('XTensibleGalleryTile.init content', content);

    if (previewpath && content[previewpath]) {
        content.tile_image = content[previewpath];
    } else {
        if (imagepath) {
            content.tile_image = content[imagepath];
        } else {
            //Normalizziamo in tile_image l'immagine da mostrare
            expo_config.xtensible.tile.image_path.every((image_path) => {
                if (content[image_path]) {
                    content.tile_image = content[image_path];
                    return false;
                }
                return true;
            });
        }
    }

    const getIconDocument = (fileName, title) => {
        const titleOfDocument =
            title && title.length > 50 ? title.substring(0, 50).toUpperCase() + '...' : title.toUpperCase();
        if (fileName.toUpperCase().includes('.PPT'))
            return (
                <div className="documentInSlider documentInSliderPPT">
                    <DocumentIconPPT />
                    <div className="doc_container">
                        <p className="xg_tile_title_doc">{titleOfDocument}</p>
                    </div>
                </div>
            );
        else if (fileName.toUpperCase().includes('.DOC') || fileName.toUpperCase().includes('.ODT'))
            return (
                <div className="documentInSlider documentInSliderDOC">
                    <DocumentIconDOC />
                    <div className="doc_container">
                        <p className="xg_tile_title_doc">{titleOfDocument}</p>
                    </div>
                </div>
            );
        else
            return (
                <div className="documentInSlider documentInSliderPDF">
                    <DocumentIconPDF />
                    <div className="doc_container">
                        <p className="xg_tile_title_doc">{titleOfDocument}</p>
                    </div>
                </div>
            );
    };

    const deleteButtonHook = () => {
        if (onDeleteItemCallBack) {
            onDeleteItemCallBack(content.id);
        }
    };

    const clickButtonHook = () => {
        if (onVieItemCallBack) {
            onVieItemCallBack(content.id);
        }
    };

    const getExtension = (fileName) => {
        return content.tile_image.toUpperCase().includes('.PPT')
            ? 'ppt'
            : content.tile_image.toUpperCase().includes('.DOC')
            ? 'doc'
            : content.tile_image.toUpperCase().includes('.ODT')
            ? 'odt'
            : 'pdf';
    };

    const downloadButtonHook = () => {
        if (onDownloadItemCallBack) {
            onDownloadItemCallBack(content.tile_image, content.name, getExtension(content.tile_image));
        }
    };

    const isVideo = (videoName) => {
        return (
            videoName.toUpperCase().includes('.MP4') ||
            content.tile_image.toUpperCase().includes('.WEBM') ||
            content.tile_image.toUpperCase().includes('.OGG')
        );
    };

    // console.log('xtensiblegallerytile', content);

    const isDocument =
        content.tile_image &&
        (content.tile_image.toUpperCase().includes('.PPT') ||
            content.tile_image.toUpperCase().includes('.DOC') ||
            content.tile_image.toUpperCase().includes('.PDF'));
    const cont =
        content.tile_image && isVideo(content.tile_image) ? (
            <video id={videoId} autoPlay muted loop playsInline className="xg_tile_elem">
                <source src={content.tile_image} />
            </video>
        ) : isDocument ? (
            getIconDocument(content.tile_image, content.name)
        ) : (
            <img
                style={{ height: '100%' }}
                src={content.tile_image ? content.tile_image : defaultItemImage}
                alt="tile logo"
            />
        );
    const matchingScore = content.matchingScore ? (
        <div className="matching_score_wrapper_tile">
            <MatchingIcon className="matching_icon_xtensible_gallery" style={{ marginRight: '2px' }} />
            <div className="matching_score_value_tile">{Math.round(content.matchingScore)}</div>
        </div>
    ) : null;

    const hoverClassName = is.tablet() || is.mobile() ? 'xg_tile_hover_mobile' : 'xg_tile_hover';
    const deleteButtonOffset = isDocument ? (window.innerWidth > 500 ? 6 : 5) : window.innerWidth > 500 ? 10 : 9;
    const eraseObject = isEditable ? (
        useEmbeddedModal ? (
            is.tablet() || is.mobile() ? (
                <Row className="xg_tile_hover_mobile">
                    {isDocument ? (
                        <Col offset={6} span={2}>
                            <Scarica
                                className="eraseInSlider"
                                style={{ cursor: 'pointer' }}
                                onClick={() => downloadButtonHook()}
                            />
                        </Col>
                    ) : null}
                    <Col offset={deleteButtonOffset} span={2}>
                        <ConfirmModal
                            onOk={() => deleteButtonHook()}
                            message={
                                content.tile_image &&
                                (content.tile_image.toUpperCase().includes('.ppt') ||
                                    content.tile_image.toUpperCase().includes('.doc') ||
                                    content.tile_image.toUpperCase().includes('.pdf'))
                                    ? t('ask_for_delete_document')
                                    : content.tile_image && isVideo(content.tile_image)
                                    ? t('ask_for_delete_live')
                                    : t('ask_for_delete_media')
                            }
                        >
                            <Erase className="eraseInSlider" style={{ cursor: 'pointer' }} />
                        </ConfirmModal>
                    </Col>
                </Row>
            ) : (
                <Row className="xg_tile_hover">
                    {isDocument && onDownloadItemCallBack ? (
                        <Col offset={6} span={2}>
                            <Scarica
                                className="eraseInSlider"
                                style={{ cursor: 'pointer' }}
                                onClick={() => downloadButtonHook()}
                            />
                        </Col>
                    ) : null}
                    <Col offset={deleteButtonOffset} span={2}>
                        <ConfirmModal
                            onOk={() => deleteButtonHook()}
                            message={
                                content.tile_image &&
                                (content.tile_image.toUpperCase().includes('.ppt') ||
                                    content.tile_image.toUpperCase().includes('.doc') ||
                                    content.tile_image.toUpperCase().includes('.pdf'))
                                    ? t('ask_for_delete_document')
                                    : content.tile_image && isVideo(content.tile_image)
                                    ? t('ask_for_delete_live')
                                    : t('ask_for_delete_media')
                            }
                        >
                            <Erase className="eraseInSlider" style={{ cursor: 'pointer' }} />
                        </ConfirmModal>
                    </Col>
                </Row>
            )
        ) : (
            <Row className={hoverClassName}>
                {onDeleteItemCallBack ? (
                    <Erase className="eraseInSlider" style={{ cursor: 'pointer' }} onClick={() => deleteButtonHook()} />
                ) : null}
            </Row>
        )
    ) : isDocument ? (
        <Row className={hoverClassName}>
            <Col offset={10} span={3}>
                <Scarica className="eraseInSlider" style={{ cursor: 'pointer' }} onClick={() => downloadButtonHook()} />
            </Col>
        </Row>
    ) : null;
    return (
        <div className="xg_tile_main">
            {matchingScore}
            <div
                className="xg_tile_container"
                style={{
                    cursor: isDocument || !onVieItemCallBack ? 'default' : 'pointer',
                    border: isSelected ? 'none' : 'inherit',
                }}
            >
                <div className="xg_tile_layer">
                    <p className="xg_tile_layer_text">{content.description}</p>
                </div>
                <div className="xg_tile_image" onClick={() => clickButtonHook()}>
                    {cont}
                </div>

                {eraseObject}
                <Transition visible={!isEditable && isSelected} animation="fade" duration={500}>
                    <div className="xg_tile_selected"></div>
                </Transition>
            </div>
            <div>
                {titleVisible && titleDesc ? <p className="xg_tile_title text">{content.description}</p> : null}
                {titleVisible && !titleDesc ? <p className="xg_tile_title">{content.name}</p> : null}
            </div>
        </div>
    );
};
export default XtensibleGalleryTile;
